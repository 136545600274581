import React, { Component } from 'react'
import ToolTip from 'react-portal-tooltip'
import * as d3 from 'd3'
import PropTypes from 'prop-types'
import { style } from '../../utils'
import Legends from '../Legends'

class Stack extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isTooltipActive: false,
      id: '',
      name: '',
      stack: '',
      data: {}
    }
    for (let i = 0; i < props.keys.length; i += 1) {
      this.state[props.keys[i]] = true
    }
    this.graphStack = d3.stack()
    this.state.totalEnabled = props.keys.length
    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
    this.keys = JSON.parse(JSON.stringify(this.props.keys))
  }
  componentWillMount () {
    if (this.props.orient === 'horizontal') {
      this.x = d3.scaleLinear()
      this.y = d3.scaleBand()
      this.y.domain(this.props.data.map(d => d.category))
      this.x.domain([0, d3.max(this.props.data.map(d => +d.value))])
      const offset = this.x.ticks()[2] - this.x.ticks()[0]
      this.x.domain([this.x.domain()[0], this.x.domain()[1] + offset])
      this.x.range([0, this.props.width - 20])
      this.y.range([this.props.height / 1.5, 0]).paddingInner(0.5).paddingOuter(0.5)
    } else {
      this.y = d3.scaleLinear()
      this.x = d3.scaleBand()
      this.x.domain(this.props.data.map(d => d.category))
      this.y.domain([0, d3.max(this.props.data.map(d => +d.value))])
      const offset = this.y.ticks()[2] - this.y.ticks()[0]
      this.y.domain([this.y.domain()[0], this.y.domain()[1] + offset])
      this.x.range([0, this.props.width - 25]).paddingInner(0.4).paddingOuter(0.3)
      this.y.range([this.props.height / 1.5, 0])
    }
  }
  componentDidMount () {
    // const self = this
    this.renderAxis()
    const xnode = this.xaxis
    d3.select(xnode)
      .selectAll('.tick text')
      .style('font-size', '12px')
    // d3.select(xnode)
    //   .selectAll('.tick')
    //   .append('g')
    //   .attr('transform', () => {
    //     const textLen = d3.select(this.previousSibling).node().getComputedTextLength()
    //     const baseLen = 60
    //     const diff = textLen - baseLen
    //     const step = parseInt(diff / 10, 10) + 1
    //     const actualstep = -5 - (step * 3)
    //     return `translate(${-(textLen + actualstep)},${textLen + actualstep})rotate(-45)`
    //   })
    //   .append('circle')
    //   .attr('id', (d, i) => `${this.props.id}_notification${i}`)
    //   .attr('visibility', (d, i) => {
    //     if (this.props.data[i].notification_count > 0) {
    //       return 'visible'
    //     }
    //     return 'hidden'
    //   })
    //   .attr('r', '3')
    //   .style('fill', '#fec200')
  }
  showTooltip (e, id, name, stack, data) {
    console.log(data)
    this.setState({
      isTooltipActive: true,
      id,
      name,
      stack,
      data
    })
  }
  hideTooltip () {
    this.setState({
      isTooltipActive: false
    })
  }
  renderAxis () {
    const xnode = this.xaxis
    const xaxis = d3.axisBottom(this.x).tickSize(0)
    d3.select(xnode).call(xaxis)
    const ynode = this.yaxis
    const yaxis = d3.axisLeft(this.y).tickSize(0).ticks(5)
    d3.select(ynode).call(yaxis)
  }
  renderHorizontalStack () {
    const mapArray = this.graphStack.keys(this.keys)(this.props.data)
    return mapArray.map((arr, i) => arr.map((d, j) => (
      <g key={`${i}_${j}`}>
        <rect
          x={this.x(d[0])}
          y={this.y(d.data.category)}
          width={this.x(d[1]) - this.x(d[0])}
          height={this.y.bandwidth()}
          fill={this.props.color[arr.key]}
          visibility={this.state[arr.key] ? 'visible' : 'hidden'}
        />
      </g>
    )))
  }
  renderVerticalStackElement (arr, i) {
    return arr.map((d, j) => {
      // const id = `${d.data.category.replace(/[/\s/!@#\\$%\\^\\&*\\)\\(\\;+=._-]/g, '')}_${this.keys[i].replace(/\s/g, '')}`
      const id = `${this.props.id}_${i}_${j}`
      return (
        <g
          id={id}
          key={`${i}_${j}`}
          onMouseMove={e => this.showTooltip(e, id, d.data.category, this.keys[i], d.data)}
          onMouseLeave={this.hideTooltip}
          onBlur={() => null}
          onClick={() => this.props.clickStack(d.data.category, this.keys[i], this.props.item, d.data[this.keys[i]])}
          className='handicon'
        >
          <rect
            x={this.x(d.data.category) + ((this.x.bandwidth() - 30) / 2)}
            y={this.y(d[1])}
            height={this.y(d[0]) - this.y(d[1])}
            width={30}
            fill={this.props.color[arr.key]}
            visibility={this.state[arr.key] ? 'visible' : 'hidden'}
          />
        </g>
      )
    })
  }
  renderVerticalStack () {
    const mapArray = this.graphStack.keys(this.keys)(this.props.data)
    return mapArray.map((arr, i) => (
      <g key={i} className={this.keys[i]}>
        {this.renderVerticalStackElement(arr, i)}
      </g>
    ))
  }
  renderTitle () {
    return (
      <g>
        <text
          className='title'
          style={{ textAnchor: 'middle' }}
          x={this.props.width / 2}
          y={-30}
        >
          {this.props.title}
        </text>
      </g>
    )
  }
  renderTooltipContent () {
    const keys = Object.keys(this.state.data)
    return keys.map((d, i) => {
      if (d === 'category' || d === 'value' || Number(this.state.data[d]) === 0) {
        return null
      }
      return (
        <li key={i} role='presentation' className='tooltip-container-tool' onClick={() => this.props.clickStack(this.state.data.category, d, this.props.item, this.state.data[d])}>
          <div className='col1'>
            <div className='tooltipbullet' style={{ opacity: 1, backgroundColor: this.props.color[d] }} />
          </div>
          <div className='col2' ><span className='handicon'>{ d }</span> </div>
          <div className='col3'>{ this.state.data[d] } </div>
        </li>
      )
    })
  }
  render () {
    // const border = [0, 0, 0, 0, 0, 0, 0, 0]
    return (
      <div className='stackbartooltip'>
        <svg paddingOuter={20} width={this.props.width} height={this.props.height} >
          <g transform={`translate(${this.props.transform[0] + 15},${this.props.transform[1]})`}>
            {this.renderTitle()}
            <g
              transform={`translate(0,${this.props.height - (this.props.transform[1] * 1.5)})`}
              ref={(c) => {
                this.xaxis = c;
                return null
              }}
              className='white x axis'
            />
            <g
              transform='translate(0,0)'
              ref={(c) => {
                this.yaxis = c;
                return null
              }}
              className='white y axis'
            />
            {this.props.orient === 'vertical' ? this.renderVerticalStack() : this.renderHorizontalStack()}
            <ToolTip group='tooltip' tooltipTimeout={500} style={style} active={this.state.isTooltipActive} position='top' parent={`#${this.state.id}`}>
              <div role='presentation' className='heading' onClick={() => this.props.clickStack(this.state.data, undefined, 'stack')}>
                { this.state.name }
              </div>
              <ul>
                { this.renderTooltipContent() }
              </ul>
            </ToolTip>
          </g>
        </svg>
        <div className='row'>
          <Legends legends={this.props.color} borderRadius={0} />
          <div className='buttonDiv'>
            <button className='button' onClick={() => this.props.close()}>Close</button>
          </div>
        </div>
      </div>

    )
  }
}
Stack.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.object.isRequired,
  keys: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
  orient: PropTypes.string,
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  clickStack: PropTypes.func.isRequired,
  title: PropTypes.string,
  transform: PropTypes.array,
  item: PropTypes.object.isRequired

}
Stack.defaultProps = {
  height: 200,
  width: 800,
  orient: 'vertical',
  title: '',
  transform: [20, 45]
}
export default Stack
