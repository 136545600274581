import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LegendItem from './legendItem'
import COLOR from '../../container/palette.json'

class Legends extends Component {
  /* Below function pass legend category to parent Concept component */
  onClick(value) {
    if (this.props.clickable) {
      this.props.onClick(value)
    }
  }
  renderLegends() {
    if (Array.isArray(this.props.legends)) {
      return this.props.legends.map((item, i) => (
        <LegendItem
          key={i}
          option={i}
          value={this.props.legendData ? this.props.legendData[item] ? this.props.legendData[item] : this.props.legendData[item] !== 0 ? 1 : 0 : 1}
          color={this.props.color[i]}
          category={item}
          borderRadius={10}
          className={this.props.itemClass}
        />
      ))
    }
    return Object.keys(this.props.legends).map((d, i) => (
      <LegendItem
        key={i}
        option={i}
        value={this.props.legendData ? this.props.legendData[d] ? this.props.legendData[d] : this.props.legendData[d] !== 0 ? 1 : 0 : 1}
        color={this.props.legends[d]}
        category={d}
        borderRadius={10}
        className={this.props.clickable ? `${this.props.itemClass} handicon` : this.props.itemClass}
        onClick={value => this.onClick(value)}
        disabledLegends={this.props.disabledLegends}
        clickable={this.props.clickable}
      />
    ))
  }
  render() {
    return (
      <div className={`legends ${this.props.className}`}>
        {this.renderLegends()}
      </div>
    )
  }
}

Legends.propTypes = {
  legends: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  legendData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  color: PropTypes.array,
  itemClass: PropTypes.string,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  disabledLegends: PropTypes.array
}

Legends.defaultProps = {
  className: '',
  color: COLOR,
  itemClass: '',
  onClick: null,
  clickable: false,
  disabledLegends: [],
  legendData: null
}

export default Legends
