import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

class BubbleChartDynamic extends Component {
  constructor (props) {
    super(props)
    this.marginLeft = 100
    this.marginRight = 50
    this.marginTop = 50
    this.marginBottom = this.props.labelX ? 100 : 50
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
    this.zoom = d3.zoom()
      .scaleExtent([1, 50])
      .translateExtent([[-100, -100], [this.width + 90, this.height + 100]])
      .on('zoom', () => {
        this.zoomed()
      });
  }
  componentWillMount () {
    this.x = d3.scaleLinear()
    this.y = d3.scaleLinear()
    this.radius = d3.scaleLinear()

    const xDom = []
    const rDom = []
    const yDom = []

    this.props.data.forEach((d) => {
      xDom.push(d.x)
      rDom.push(d.radius)
      yDom.push(d.y)
    })
    this.x.domain([0, d3.max(xDom)])
      .range([0, this.width])
    this.y.domain([0, d3.max(yDom)])
      .range([this.height, 0])
    this.radius.domain(d3.extent(rDom))
      .range([10, 30])
    const offsetx = this.x.ticks()[1] - this.x.ticks()[0]
    this.x.domain([this.x.domain()[0], this.x.domain()[1] + offsetx])
    const offsety = this.y.ticks()[1] - this.y.ticks()[0]
    this.y.domain([this.y.domain()[0], this.y.domain()[1] + offsety])
    this.setState({
      x: this.x,
      y: this.y
    })
  }
  componentDidMount () {
    this.renderAxis()
    const self = this
    const ynode = '#yaxis'
    this.factor = 1
    d3.select(ynode)
      .selectAll('.tick text')
      .attr('id', (d, i) => `innovation-timeline-bar-id-${this.props.id}-${i}`)
      .call(self.wrapLabel, self)
    d3.select(`.rect-zoom-${this.props.id}`)
      .call(this.zoom)
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.factor !== this.props.factor) {
      this.zoom.scaleTo(d3.select(`#graph-${this.props.id}`), nextProps.factor)
    }
  }
  getTransformToElement = (svg, elem) => (
    elem.getScreenCTM().inverse().multiply(svg.getScreenCTM())
  )
  wrapLabel = (text1, self) => {
    text1.each((d, i) => {
      const text = d3.select(`#innovation-timeline-bar-id-${self.props.id}-${i}`)
      const words = text.text()
      if (words.length > 20) {
        text.text(`${words.substring(0, 20)}..`)
      }
    })
  }
  zoomed = () => {
    const xnode = '#xaxis'
    const xaxis = d3.axisBottom(this.x)
    const ynode = '#yaxis'
    const yaxis = d3.axisLeft(this.y)
    // const t = d3.event.transform;
    d3.select(ynode).call(yaxis.scale(d3.event.transform.rescaleY(this.y)));
    d3.select(xnode).call(xaxis.scale(d3.event.transform.rescaleX(this.x)));
    this.setState({
      x: d3.event.transform.rescaleX(this.x)
    })
  }
  renderAxis () {
    const xnode = '#xaxis'
    const xaxis = d3.axisBottom(this.x)
    d3.select(xnode).call(xaxis)
    const ynode = '#yaxis'
    const yaxis = d3.axisLeft(this.y).ticks(8)
    d3.select(ynode).call(yaxis)
  }
  renderCircle () {
    return this.props.data.map((d, j) => (
      <g
        key={j}
        transform={`translate(${this.state.x(d.x)}, ${this.state.y(d.y)})`}
        className='bubble-dynamic handicon'
        onClick={() => {
            if (this.props.yaxis === 'standardized_assignee.normalize_assignee') {
              this.props.search({
                search_applicant: d.label
              })
            } else if (this.props.yaxis === 'inventors') {
              this.props.search({
                search_inventor: d.label
              })
            }
          }}
      >
        <circle
          r={this.radius(d.radius)}
          fill='#0088ff'
          opacity='0.7'
          strokeOpacity='1'
          strokeWidth='1px'
          id={`bubble_${j}`}
        />
        <text
          dy='0.3em'
          textAnchor='middle'
          className='graph-font-family-labels'
        >
          { d.label }
        </text>
      </g>
    ))
  }
  renderLabelX () {
    return (
      <text
        transform={`translate(${this.width / 2}, ${this.height + (this.marginBottom / 2)})`}
        textAnchor='middle'
        className='graph-font-family-labels capitalize-me'
      >
        {this.props.labelX}
      </text>
    )
  }
  renderLabelY () {
    return (
      <text
        transform={`translate(${-this.marginLeft / 2}, ${this.height / 2})rotate(-90)`}
        textAnchor='middle'
        className='graph-font-family-labels capitalize-me'
      >
        {this.props.labelY}
      </text>
    )
  }
  renderRect () {
    return (
      <rect
        width={this.width}
        height={this.height}
        fill='none'
        pointerEvents='all'
        className={`rect-zoom-${this.props.id}`}
        onClick={(e) => {
          // d3.select(`.rect-zoom-${this.props.id}`)
          //   .attr('pointer-events', 'none')
          const svg = document.getElementById(`graph-${this.props.id}`)
          const g = document.getElementById(`group-${this.props.id}`)
          const p = svg.createSVGPoint()
          p.x = e.clientX
          p.y = e.clientY
          const transformSvg = this.getTransformToElement(svg, g);
          const trans = transformSvg.multiply(svg.getScreenCTM().inverse());
          const svgp = p.matrixTransform(trans)
          // const svgp = p.matrixTransform(svg.getScreenCTM().inverse())
          this.props.data.forEach((d) => {
            // const posx = this.x.invert(svgp.x)
            if (this.state.x(d.x) - this.radius(d.radius) <= svgp.x && svgp.x <= this.state.x(d.x) + this.radius(d.radius)) {
              // if (this.props.yaxis === 'standardized_assignee.normalize_assignee') {
              this.props.search({
                search_applicant: d.label
              })
              // } else if (this.props.yaxis === 'inventors') {
              //   this.props.search({
              //     search_inventor: d.label
              //   })
              // }
            }
          })
        }}
      />
    )
  }
  render () {
    return (
      <g
        transform={`translate(${this.marginLeft}, ${this.marginTop})`}
        id={`group-${this.props.id}`}
      >
        <g id='xaxis' className='bubble-x axis' transform={`translate(0, ${this.height})`} />
        <g id='yaxis' className='bubble-y axis' />
        { this.renderCircle() }
        { this.renderRect() }
        { this.props.labelX ? this.renderLabelX() : null}
        { this.props.labelY ? this.renderLabelY() : null}
      </g>
    )
  }
}

BubbleChartDynamic.propTypes = {
  labelX: PropTypes.string.isRequired,
  labelY: PropTypes.string.isRequired,
  yaxis: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  search: PropTypes.func.isRequired,
  factor: PropTypes.number.isRequired
}

BubbleChartDynamic.defaultProps = {
}

export default BubbleChartDynamic
