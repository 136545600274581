import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
// import { FormattedMessage } from 'react-intl';
// import ReactHtmlParser from 'react-html-parser'
import { ucFirst } from '../../utils'
// import { T } from '../../components'

const GuideLines = (props) => {
  // const abstract = props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
  console.log()
  return (
    <Fragment>
      {/* <div className='card-body-content'>
        <div className='sub-title'><FormattedMessage id="Abstract/Overview" defaultMessage="Abstract/Overview" /></div>
        {props.data.Abstract ? <div data-test='guidelines-abstract' id='annotation-highlight-1'>{ReactHtmlParser(abstract)}</div> : <T data='' />}
      </div> */}
      {props.data['Table Data'].map(table => (
        <div data-test='guidelines-table' className='card-body-content'>
          <div className='sub-title'>{table.table_title}</div>
          <div className='table-list guidlines'>
            {
              table.table_data.map(card => (
                <div className='content-list-grey tbl-margin-bottom-5'>
                  {Object.entries(card).map(row =>
                    row[1] && (
                      <div className='row'>
                        <div className='title row-cell'>{ucFirst(row[0].replace(/_/g, ' ').trim())}</div>
                        <div className='value row-cell'>{row[1]}</div>
                      </div>
                  ))}
                </div>
              ))
              }
          </div>
        </div>
      ))}
    </Fragment>
  )
}

GuideLines.propTypes = {
  data: PropTypes.object.isRequired
}

export default GuideLines
