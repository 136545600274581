import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NotificationSystem from 'react-notification-system'
import { parse, stringify } from 'query-string'
import mixpanel from 'mixpanel-browser'
import { getCookie, removeCookies, PATHS, setAnalytics, checkPermission } from '../../utils'
import { statusCancel, customMessage } from './logic'
// import { notificationCountAction } from '../Notification/logic'
import { modalCloseAction } from '../Modal/logic'
import { ChatBox, OutsideClick, ErrorBoundary } from '../../components'
import { setDataLang, LangSet } from '../../lang/logic'
import Mixpanel from '../../mixpanel'

const setFavicons = () => {
  // const target = checkPermission('ontosight_academics_user') && getCookie('accessToken') ? 'academics-favicon' : 'favicon'
  // if (document) {
  //   document.querySelector('link[rel=icon][sizes="16x16"]').href = `/images/${target}/favicon-16x16.png?${new Date().getTime()}`
  //   document.querySelector('link[rel=icon][sizes="32x32"]').href = `/images/${target}/favicon-32x32.png?${new Date().getTime()}`
  //   document.querySelector('link[rel="shortcut icon"]').href = `/images/${target}/favicon.ico?${new Date().getTime()}`
  //   document.querySelector('link[rel=apple-touch-icon][sizes="180x180"]').href = `/images/${target}/apple-touch-icon.png?${new Date().getTime()}`
  //   document.querySelector('link[rel=manifest]').href = `/images/${target}/site.webmanifest?${new Date().getTime()}`
  // }
}

class Status extends Component {
  constructor (props) {
    super(props)
    try {
      if (feedback) {
        window.feedback = feedback
      }
    } catch (error) {
      window.feedback = null
    }
  }
  componentDidMount() {
    if (typeof (getCookie('accessToken')) === 'undefined' && (PATHS.indexOf(this.props.location.pathname.replace(/\/$/, '')) === -1) && this.props.location.pathname !== '/') {
      // this.props.history.replace(process.env.mainRoute)
      window.location.href = process.env.mainAppUrl
    }
    if (getCookie('accessToken')) {
      // document.body.style.overflow = 'hidden'
      document.body.className = ''
      Mixpanel.track('checkloginEvent', {
        action: 'Check Login',
        userDetails: `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`
      })
      setAnalytics('checkLoginTrigger', 'userId', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
      setFavicons()
    }
    this.checkLocalization(this.props.lang)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.status.type !== this.props.status.type && nextProps.status.type !== '') {
      this.showNotification(nextProps.status)
      this.activityFlow(nextProps.status.type, nextProps)
      this.props.statusCancel()
    }
    if ((typeof (getCookie('accessToken')) === 'undefined' || !getCookie('accessToken')) && (PATHS.indexOf(nextProps.location.pathname.replace(/\/$/, '')) === -1) && nextProps.location.pathname !== '/') {
      // let redirectRoute = process.env.mainRoute
      // if (nextProps.status.type === '401') {
      //   const query = stringify({
      //     redirect_url: this.props.location.pathname + this.props.location.search
      //   })
      //   redirectRoute += `?${query}`
      //   this.props.history.replace(redirectRoute)
      // } else if (process.env.standalone) {
      //   this.props.history.replace(redirectRoute)
      // } else {
      //   window.location.href = getRedirectUrl('app', {}, {}, false)
      // }
      setTimeout(() => {
        localStorage.clear()
        removeCookies()
        window.location.href = `${process.env.mainAppUrl}/logout`
      }, 500)
    }
    if (getCookie('accessToken')) {
      // document.body.style.overflow = 'hidden'
      document.body.className = ''
    }
  }
  checkLocalization (lang) {
    if (!checkPermission('language_localization') && lang !== 'en' && getCookie('accessToken')) {
      setTimeout(() => {
        this.props.customMessage({
          message: 'You do not have Localization permission, you will still be able to browse platform in English. Please contact for permission.',
          status: 'info',
          title: 'Info'
        })
      }, 300)
      const langCode = 'en';
      this.props.LangSet(langCode);
      this.props.setDataLang(langCode);
    }
  }
  activityFlow(value, nextProps) {
    switch (value) {
      case '401': {
        mixpanel.reset();
        Mixpanel.track('sessionExpiredEvent', {
          action: 'Session Expired',
          userDetails: `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`
        })
        setAnalytics('sessionExpiredTrigger', 'sessionExpired', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        removeCookies()
        window.location.href = `${process.env.mainAppUrl}/logout`
        break
      }
      case '403': {
        break
      }
      case 'login': {
        Mixpanel.identify(`${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        setAnalytics('userIdTrigger', 'userId', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        setFavicons()
        // if (getCookie('accessToken') && (checkPermission('marketplace_permission') && !checkPermission('ontosight_academics_user'))) {
        //   this.props.notificationCountAction()
        // }
        this.props.modalCloseAction()
        this.checkLocalization(nextProps.lang)
        const {
          alreadyLoggedIn, hashCode, code, trialUser, ...others
        } = parse(this.props.location.search)
        if (process.env.current === 'app') {
          if (checkPermission('discover_permission')) {
            const redirectRoute = parse(this.props.location.search).redirect_url ||
              `/search?${stringify({ ...others })}`
            this.props.history.replace(redirectRoute)
          } else {
            const redirectRoute = parse(this.props.location.search).redirect_url ||
              `${process.env.redirectRoute}?${stringify({ ...others })}`
            this.props.history.replace(redirectRoute)
          }
          // else if (checkPermission('myworkspace_permission')) {
          //   if (parse(this.props.location.search).redirect_url) {
          //     const redirectRoute = parse(this.props.location.search).redirect_url
          //     this.props.history.replace(redirectRoute)
          //   } else {
          //     window.location.replace(`${getRedirectUrl('workspace', { ...others }, {}, false)}`)
          //   }
          // }
        } else {
          const redirectRoute = parse(this.props.location.search).redirect_url ||
            `${process.env.redirectRoute}?${stringify({ ...others })}`
          this.props.history.replace(redirectRoute)
        }
        break
      }
      case 'trial-expiry': {
        localStorage.clear()
        removeCookies()
        window.location.href = `${process.env.mainAppUrl}/logout`
        setFavicons()
        break
      }
      case 'logout': {
        mixpanel.reset();
        removeCookies()
        localStorage.clear()
        window.location.href = `${process.env.mainAppUrl}/logout`
        setFavicons()
        break
      }
      case 'deleteAccount': {
        removeCookies()
        break
      }
      default:
        break
    }
  }
  handleClickOutside() {
    if ((typeof (getCookie('accessToken')) === 'undefined' || !getCookie('accessToken')) && (PATHS.indexOf(this.props.location.pathname.replace(/\/$/, '')) === -1) && this.props.location.pathname !== '/') {
      // const redirectRoute = process.env.mainRoute
      if (process.env.standalone) {
        window.location.href = process.env.mainAppUrl
        // this.props.history.push(redirectRoute)
        setFavicons()
      } else {
        setTimeout(() => {
          window.location.href = process.env.mainAppUrl
        }, 300)
      }
    }
  }
  showNotification(value) {
    const {
      title, message, status, hideNotification,
    } = value
    if (!hideNotification) {
      this.statusMessage.addNotification({
        title,
        message,
        level: status,
        autoDismiss: 4,
        position: 'tc'
      })
    }
  }
  render() {
    return (
      <Fragment>
        <NotificationSystem ref={(ref) => { this.statusMessage = ref }} />
        <OutsideClick onClickOutside={() => this.handleClickOutside()} />
        {getCookie('accessToken') && window.feedback && (
          <ErrorBoundary className='hide'>
            <ChatBox />
          </ErrorBoundary>
        )}
      </Fragment>
    )
  }
}

Status.propTypes = {
  status: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string
  }),
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired,
  statusCancel: PropTypes.func.isRequired,
  // notificationCountAction: PropTypes.func.isRequired,
  modalCloseAction: PropTypes.func.isRequired,
  setDataLang: PropTypes.func.isRequired,
  LangSet: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  customMessage: PropTypes.func.isRequired,
}

Status.defaultProps = {
  status: {
    message: '',
    status: '',
    type: '',
    title: ''
  }
}

const mapStateToProps = state => ({
  status: state.status,
  lang: state.language.lang
})

// export default withRouter(connect(mapStateToProps, {
//   statusCancel, notificationCountAction, modalCloseAction, setDataLang, LangSet, customMessage
// })(Status))

export default withRouter(connect(mapStateToProps, {
  statusCancel, modalCloseAction, setDataLang, LangSet, customMessage
})(Status))
