import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { stringify } from 'query-string'
import Pagination from 'react-js-pagination'
import { listV1FetchAction, listV1BottomFetchAction, listV1TopFetchAction, listV1FetchCancel } from './logic'
import { LazyLoadOnDivList, CardList } from '../../components'
import { bookmarkStatusToggle, bibliographyStatusToggle, annotationStatusToggle, parseHashUrl, generateHashUrl, setAnalytics, generateRandomString } from '../../utils'
import Mixpanel from '../../mixpanel';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false,
  from: 0,
  listType: ''
}

class GenerateListV1 extends Component {
  constructor (props) {
    super(props)
    const q = parseHashUrl(this.props)
    this.state = {
      fromTop: q.from ? Number(q.from) : this.props.from,
      fromBottom: q.from ? Number(q.from) : this.props.from,
      list: [],
      listType: this.props.listType
    }
    this.apiCall = this.apiCall.bind(this)
    this.loadBottomRows = this.loadBottomRows.bind(this)
    this.loadTopRows = this.loadTopRows.bind(this)
    this.goToTop = this.goToTop.bind(this)
    this.scrollTop = this.scrollTop.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.el = React.createRef()
    this.apiCall()
  }
  componentWillReceiveProps (nextProps) {
    if (JSON.stringify(nextProps.params) !== JSON.stringify(this.props.params)) {
      this.setState({
        fromTop: 1,
        fromBottom: 1,
        list: []
      }, () => this.apiCall())
    }
    if (nextProps.listBottom.flag !== this.props.listBottom.flag && nextProps.listBottom.flag) {
      this.setState({
        list: [...this.state.list, ...nextProps.listBottom.data]
      })
    }
    if (nextProps.listTop.flag !== this.props.listTop.flag && nextProps.listTop.flag) {
      this.setState({
        list: [...nextProps.listTop.data, ...this.state.list]
      })
    }
    if (nextProps.list.flag !== this.props.list.flag && nextProps.list.flag) {
      this.setState({
        list: [...nextProps.list.data]
      })
    }
    if ((this.props.bookmark.id !== nextProps.bookmark.id && nextProps.bookmark.id) || (this.props.bookmark.id === nextProps.bookmark.id && nextProps.bookmark.id && this.props.bookmark.status !== nextProps.bookmark.status)) {
      const list = bookmarkStatusToggle(this.state.list, nextProps.bookmark.id, nextProps.bookmark.status)
      this.setState({
        list
      })
    }
    if ((this.props.bibliography.id !== nextProps.bibliography.id && nextProps.bibliography.id) || (this.props.bibliography.id === nextProps.bibliography.id && nextProps.bibliography.id && this.props.bibliography.status !== nextProps.bibliography.status)) {
      const list = bibliographyStatusToggle(this.state.list, nextProps.bibliography.id, nextProps.bibliography.status)
      this.setState({
        list
      })
    }
    if (this.props.annotation.flag !== nextProps.annotation.flag && nextProps.annotation.flag) {
      const list = annotationStatusToggle(this.state.list, nextProps.annotation.data.id || '', !!nextProps.annotation.data.data.length)
      this.setState({
        list
      })
    }
  }
  shouldComponentUpdate (nextProps) {
    if (nextProps.listBottom.listType !== this.state.listType) {
      return false
    }
    if (nextProps.listTop.listType !== this.state.listType) {
      return false
    }
    if (nextProps.list.listType !== this.state.listType) {
      return false
    }
    return true
  }
  apiCall (more) {
    const q = {
      params: stringify({
        ...this.props.params.params,
        from: more === 'top' ? this.state.fromTop : this.state.fromBottom,
        size: this.props.size
      }),
      data: this.props.params.data
    }
    if (more === 'bottom') {
      this.props.listV1BottomFetchAction(q, this.props.apiUrl, this.state.fromBottom, this.state.listType, this.props.uniqueId, this.props.apiType)
    } else if (more === 'top') {
      this.props.listV1TopFetchAction(q, this.props.apiUrl, this.state.fromTop, this.state.listType, this.props.uniqueId, this.props.apiType)
    } else {
      this.props.listV1FetchCancel(this.props.uniqueId)
      this.props.listV1FetchAction(q, this.props.apiUrl, this.state.fromBottom, this.state.listType, this.props.uniqueId, this.props.apiType)
      this.scrollTop()
    }
  }
  goToTop () {
    this.setState({
      fromBottom: 1,
      fromTop: 1
    }, () => {
      const { from, ...q } = parseHashUrl(this.props)
      this.props.history.replace(`?${generateHashUrl({ ...q })}`)
      this.apiCall()
    })
  }
  loadBottomRows () {
    this.setState({
      fromBottom: this.state.fromBottom + 1
    }, () => this.apiCall('bottom'))
  }
  loadTopRows () {
    this.setState({
      fromTop: this.state.fromTop - 1
    }, () => this.apiCall('top'))
  }
  scrollTop() {
    if (this.props.parent) {
      const myDiv = document.getElementById(this.props.parent);
      if (myDiv) {
        myDiv.scrollIntoView()
      }
    } else if (this.props.toId && this.props.id) {
      const toId = document.getElementById(this.props.toId)
      const id = document.getElementById(this.props.id)
      if (id && toId) {
        id.scrollTop = toId.offsetTop - 20
      }
    } else if (this.props.id) {
      const id = document.getElementById(this.props.id)
      if (id) {
        id.scrollTop = 0
      }
    }
  }
  handlePageChange (page) {
    this.setState({
      fromBottom: page
    }, () => {
      if (this.props.parent === 'reSearchActivity') {
        const AnalyticsObj = {};
        AnalyticsObj.pageNo = page;
        AnalyticsObj.query = this.props.params.data.query;
        Mixpanel.track('kolsPageChangeEventInResearchActivityEvent', {
          action: 'Kols Page Change Event In Research Activity',
          eventData: JSON.stringify(AnalyticsObj)
        })
        setAnalytics('kolsPageChangeEventInResearchActivityTrigger', 'kolsPageChangeEventInResearchActivity', JSON.stringify(AnalyticsObj));
      }
      this.apiCall()
      this.scrollTop()
    })
  }
  renderList() {
    return <CardList onScroll={this.scrollTop} {...this.props} urlParams={this.props.urlParams} version='v1' message={this.props.message} list={this.state.list} loading={this.props.list.loading || this.props.loading} error={this.props.list.error} height={this.props.height} likeParams={this.props.likeParams} />
  }
  renderLazyLoadedList() {
    return (
      <div className='list-scrollbar' >
        {this.state.fromTop !== 1 && <div role='presentation' onClick={() => this.goToTop()} className='list-top-badge'><FormattedMessage id="More list items are available at the top" defaultMessage="More list items are available at the top" /></div>}
        <LazyLoadOnDivList loadDataTop={this.props.listTop.flag} loadDataBottom={this.props.listBottom.flag} loadTop={false /* && this.state.fromTop !== 1 */} loadBottom={this.state.fromBottom * this.props.size < this.props.list.total} id={this.props.id} height={this.props.height} loadBottomRows={() => this.loadBottomRows()} loadTopRows={() => this.loadTopRows()} >
          {this.renderList()}
        </LazyLoadOnDivList>
      </div>
    )
  }
  renderPaginatedList() {
    return (
      <div className='list-scrollbar' >
        {this.renderList()}
        {this.props.list.total > this.props.size && (
          <div className='pagination-main'>
            <Pagination
              itemClassFirst='first'
              itemClassPrev='prev'
              itemClassNext='next'
              itemClassLast='last'
              activePage={this.state.fromBottom}
              itemsCountPerPage={10}
              totalItemsCount={this.props.list.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        )}
      </div>
    )
  }
  render () {
    // const { data } = this.state
    return this.props.paginate
      ? this.renderPaginatedList()
      : this.renderLazyLoadedList()
  }
}

GenerateListV1.propTypes = {
  params: PropTypes.object.isRequired,
  apiUrl: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.number,
  list: PropTypes.object,
  listTop: PropTypes.object,
  listBottom: PropTypes.object,
  listV1FetchAction: PropTypes.func.isRequired,
  listV1BottomFetchAction: PropTypes.func.isRequired,
  listV1TopFetchAction: PropTypes.func.isRequired,
  from: PropTypes.number,
  history: PropTypes.object,
  bookmark: PropTypes.object.isRequired,
  bibliography: PropTypes.object.isRequired,
  paginate: PropTypes.bool,
  listType: PropTypes.string,
  likeParams: PropTypes.object,
  message: PropTypes.string,
  parent: PropTypes.string,
  id: PropTypes.string,
  toId: PropTypes.string,
  listV1FetchCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  urlParams: PropTypes.object,
  uniqueId: PropTypes.string,
  apiType: PropTypes.string,
  annotation: PropTypes.object.isRequired,
}

GenerateListV1.defaultProps = {
  height: '70vh',
  size: 10,
  from: 1,
  history: {},
  paginate: false,
  listType: '',
  likeParams: {},
  message: '',
  parent: '',
  id: 'list',
  loading: false,
  urlParams: {},
  uniqueId: generateRandomString(),
  list: INITIAL_STATE,
  listBottom: INITIAL_STATE,
  listTop: INITIAL_STATE,
  apiType: 'POST',
  toId: '',
}

const mapStateToProps = (state, ownProps) => ({
  list: state.listV1[ownProps.uniqueId],
  listTop: state.listV1Top[ownProps.uniqueId],
  listBottom: state.listV1Bottom[ownProps.uniqueId],
  bookmark: state.bookmark,
  bibliography: state.bibliography,
  annotation: state.annotationFetch
})

export default withRouter(connect(mapStateToProps, {
  listV1FetchAction, listV1BottomFetchAction, listV1TopFetchAction, listV1FetchCancel
})(GenerateListV1))
