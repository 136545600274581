import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Slider extends Component {
  constructor(props) {
    super(props);
    this.offsetWidth = 30;
    this.state = {
      left: this.offsetWidth,
      slideActive: null
    }
    this.slideLeft = this.slideLeft.bind(this);
    this.slideRight = this.slideRight.bind(this);
    this.canSlideLeft = this.canSlideLeft.bind(this);
    this.canSlideRight = this.canSlideRight.bind(this);
  }

  componentDidMount() {
    this.height = `${this.sliderItems.clientHeight}px`;
    this.setState({
      slideActive: this.sliderContainer.offsetWidth < this.sliderItems.scrollWidth,
      left: this.sliderContainer.offsetWidth < this.sliderItems.scrollWidth ? this.offsetWidth : 0
    })
  }

  componentWillReceiveProps () {
    this.height = `${this.sliderItems.clientHeight}px`;
    this.setState({
      slideActive: this.sliderContainer.offsetWidth < this.sliderItems.scrollWidth,
      left: this.sliderContainer.offsetWidth < this.sliderItems.scrollWidth ? this.offsetWidth : 0
    })
  }

  slideLeft() {
    if (this.canSlideLeft()) {
      const currentPos = (Math.abs(this.state.left) > this.props.slideBy) ?
        (this.state.left + this.props.slideBy) : this.offsetWidth;
      this.setState({
        left: currentPos
      });
    }
  }

  canSlideRight() {
    return ((this.sliderItems.scrollWidth - this.sliderContainer.offsetWidth
      - Math.abs(this.state.left)) > -20)
  }

  canSlideLeft() {
    return (this.state.left < 0);
  }

  slideRight() {
    if (this.canSlideRight()) {
      let currentPos;
      if ((this.sliderItems.scrollWidth - this.sliderContainer.offsetWidth
        - Math.abs(this.state.left)) > this.props.slideBy) {
        currentPos = this.state.left - this.props.slideBy;
      } else {
        currentPos = this.sliderContainer.offsetWidth - this.offsetWidth
        - this.sliderItems.scrollWidth;
      }

      this.setState({
        left: currentPos
      })
    }
  }

  render() {
    return (
      <div ref={(element) => { this.sliderContainer = element }} className={this.state.slideActive ? 'slider active' : 'slider'} style={{ height: (parseInt(this.height, 10) || 30) + 7 }}>
        { this.state.slideActive &&
          (
            <div className='slider-controls' style={{ height: this.height }}>
              <div className='slider-actions-controller slider-left-arrow'>
                <button style={{ height: (parseInt(this.height, 10) || 30) + 7 }} disabled={!this.canSlideLeft()} className='slide left' onClick={this.slideLeft} />
              </div>
              <div className='slider-actions-controller slider-right-arrow'>
                <button style={{ height: (parseInt(this.height, 10) || 30) + 7 }} disabled={!this.canSlideRight()} className='slide right' onClick={this.slideRight} />
              </div>
            </div>
          ) }
        <div ref={(element) => { this.sliderItems = element }} className='slider-content' style={{ left: this.state.left }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  slideBy: PropTypes.number
}

Slider.defaultProps = {
  slideBy: 200,
  children: null
}

export default Slider;
