import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'

import { ERROR } from '../../../common/container/Status/logic'
import { apiCall, innoPattern } from '../../../common/utils'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

const getSubGraph = innoPattern('GET_SUB_GRAPH', 'patent')
const GETSUBGRAPH = getSubGraph.actionType('graph')
const GETSUBGRAPH_SUCCESS = getSubGraph.actionType('success')
const GETSUBGRAPH_FAILURE = getSubGraph.actionType('failure')

export const getSubGraphAction = getSubGraph.createAction(GETSUBGRAPH)
const getSubGraphSuccess = getSubGraph.createAction(GETSUBGRAPH_SUCCESS)

export function getSubGraphReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case GETSUBGRAPH:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        flag: false
      }
    case GETSUBGRAPH_SUCCESS:
      return {
        ...state,
        data: action.payload.response.data,
        reference: action.payload.response.ui_reference,
        error: false,
        loading: false,
        flag: true
      }
    case GETSUBGRAPH_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        flag: false
      }
    default:
      return state
  }
}

export const getSubGraphEpic = action$ => action$
  .ofType(GETSUBGRAPH)
  .mergeMap(action => staticAjax(apiCall(`https://staging.innopat.io/api/v0/dashboards/patent/landscape?${action.payload}`, 'GET', true))
    .map(response => getSubGraphSuccess(response))
    .catch(error => Observable.of({
      type: GETSUBGRAPH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
