import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ListType from '../ListType'
import Publication from './publication'
import Congresses from './congresses'
import CtList from './ct'
import RecentEvents from './recentEvents'
import GuideLines from './guidelines'
import SocialMedia from './socialmedia'
import Thesis from './thesis'
import Grant from './grants'
import Patent from './patent'
import UnPublished from './unPublished'
import PublicationWiley from './publicationWiley'
import GuidelinesNotify from './guidelinesNotify'
import ExpressionData from './expressionData'

class AbstractModal extends Component {
  constructor (props) {
    super(props)
    this.renderBody = this.renderBody.bind(this)
  }

  renderBody () {
    if (Object.keys(this.props.abstract.data).length === 0) {
      return null
    }
    switch (this.props.type) {
      case 'publications':
        return <Publication {...this.props} list={this.props.data} data={this.props.abstract.data} />
      case 'clinicaltrials':
        return <CtList {...this.props} list={this.props.data} data={this.props.abstract.data} />
      case 'congresses':
        return <Congresses data={this.props.abstract.data} />
      case 'grants':
        return <Grant data={this.props.abstract.data} />
      case 'recent_events':
        return <RecentEvents data={this.props.abstract.data} />
      case 'guidelines':
        return <GuideLines data={this.props.abstract.data} />
      case 'socialmedia':
        return <SocialMedia data={this.props.abstract.data} />
      case 'patent':
        return <Patent {...this.props} list={this.props.data} data={this.props.abstract.data} />
      case 'thesis':
        return <Thesis data={this.props.abstract.data} />
      case 'Pre Clinical':
        return <UnPublished data={this.props.abstract.data} />
      case 'guidelines_notify':
        return <GuidelinesNotify data={this.props.abstract.data} />
      case 'Drug Discovery':
        return <UnPublished data={this.props.abstract.data} />
      case 'Clinical':
        return <UnPublished data={this.props.abstract.data} />
      case 'publications_w':
        return <PublicationWiley {...this.props} data={this.props.abstract.data} />
      case 'expression_data':
        return <ExpressionData {...this.props} data={this.props.abstract.data} />
      default:
        return null
    }
  }
  render () {
    const data_from_props = this.props.data;
    data_from_props.Tags = this.props.abstract.data.Tags;
    return (
      <Fragment>
        <ListType overlay={false} abstract={this.props.abstract.data} showMore type={this.props.type} data={data_from_props} flag={false} />
        {this.renderBody()}
      </Fragment>
    )
  }
}

AbstractModal.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  abstract: PropTypes.object.isRequired
}

export default AbstractModal
