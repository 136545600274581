import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom'
import { stringify } from 'query-string'
import { connect } from 'react-redux'
import { CheckboxGroup, Loader, NoDataFound } from '../../components'
import { modalCloseAction } from '../Modal/logic'
import { bookmarkFolderFetchAction, createFolderAction, createBookmarkAction, deleteBookmarkAction, editBookmarkAction } from './logic'
import { setAnalytics, parseHashUrl } from '../../utils'
import Mixpanel from '../../mixpanel';

class BookmarkModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      folders: [],
      active: [],
      input: '',
      from: 1,
      flag: false,
      size: 200
    }
    this.onChange = this.onChange.bind(this)
    this.onClickOutsideDuringSearch = this.onClickOutsideDuringSearch.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onEnter = this.onEnter.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onCreate = this.onCreate.bind(this)
  }
  componentDidMount () {
    if (this.props.params.id) {
      this.props.bookmarkFolderFetchAction(stringify({
        doc_id: this.props.params.id,
        from: this.state.from,
        size: this.state.size
      }))
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.folders.flag !== this.props.folders.flag && nextProps.folders.flag) {
      this.setState({
        folders: nextProps.folders.data,
        active: nextProps.folders.data.filter(item => item.flag).map(item => item.folder_name)
      })
    }
    if (nextProps.create.flag !== this.props.create.flag && nextProps.create.flag) {
      const a = {
        ...nextProps.create.data,
        flag: true
      }
      const b = JSON.parse(JSON.stringify([a, ...this.state.folders]))
      const c = JSON.parse(JSON.stringify(this.state.active))
      c.push(nextProps.create.data.folder_name)
      this.setState({
        folders: b,
        active: c,
        input: '',
        flag: false
      })
    }
    if (nextProps.createBookmark.flag !== this.props.createBookmark.flag && nextProps.createBookmark.flag) {
      this.props.modalCloseAction()
    }
    if (nextProps.editBookmark.flag !== this.props.editBookmark.flag && nextProps.editBookmark.flag) {
      this.props.modalCloseAction()
    }
    if (nextProps.deleteBookmark.flag !== this.props.deleteBookmark.flag && nextProps.deleteBookmark.flag) {
      this.props.modalCloseAction()
    }
  }
  onChange(active) {
    this.setState({
      active
    })
  }
  onInputChange (e) {
    this.setState({
      input: e.target.value
    })
  }
  onEnter (e) {
    if (e.key === 'Enter') {
      this.onCreate()
    }
  }
  onCreate() {
    if (this.state.input.trim().length > 0) {
      Mixpanel.track('createPotliFolderEvent', {
        action: 'Create Potli Folder',
        folder_name: this.state.input.trim(),
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      })
      setAnalytics('createPotliFolderTrigger', 'createPotliFolder', JSON.stringify({
        folder_name: this.state.input.trim(),
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      }))
      this.props.createFolderAction({
        folder_name: this.state.input.trim()
      })
    }
  }
  onSave () {
    if (this.props.type === 'create') {
      Mixpanel.track('addToPotliEvent', {
        action: 'Add To Potli',
        folder_name: this.state.active,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group,
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      })
      setAnalytics('addToPotliTrigger', 'addToPotli', JSON.stringify({
        folder_name: this.state.active,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group,
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      }))
      this.props.createBookmarkAction({
        folder_name: this.state.active,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      })
    } else {
      const folderId = []
      for (let i = 0; i < this.state.folders.length; i += 1) {
        if (this.state.active.indexOf(this.state.folders[i].folder_name) > -1) {
          folderId.push(this.state.folders[i].folder_id)
        }
      }

      Mixpanel.track('editPotliEvent', {
        action: 'Edit Potli',
        folder_id: folderId,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group,
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      })

      setAnalytics('editPotliTrigger', 'editPotli', JSON.stringify({
        folder_id: folderId,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group,
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      }))
      this.props.editBookmarkAction({
        folder_id: folderId,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      })
    }
  }
  onDelete () {
    const {
      editBookmark, deleteBookmark, createBookmark, create
    } = this.props
    const loading = editBookmark.loading || deleteBookmark.loading || createBookmark.loading || create.loading
    if (!loading) {
      Mixpanel.track('deletePotliFromAll', {
        action: 'Delete Potli From All',
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group,
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      })
      setAnalytics('deletePotliFromAllTrigger', 'deletePotliFromAll', JSON.stringify({
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group,
        ...(process.env.current === 'app' && parseHashUrl(this.props).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel })
      }))
      this.props.deleteBookmarkAction({
        folder_id: [],
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      })
    }
  }
  onClickOutsideDuringSearch() {
    if (!this.props.create.loading) {
      this.setState({
        input: '',
        flag: false
      })
    }
  }
  renderAdd () {
    if (this.state.flag) {
      return (
        <div
          style={{
            width: '100%', position: 'relative', display: 'flex', alignItems: 'center', height: 30
          }}
        >
          <input maxLength={30} autoFocus className='potli-input' placeholder='Folder name' value={this.state.input} onKeyPress={this.onEnter} onChange={this.onInputChange} />
          {this.state.input.trim().length > 0 && <i role='presentation' className='enter-icon' onClick={this.onCreate} />}
          <i role='presentation' className='close-icon' onClick={this.onClickOutsideDuringSearch} />
        </div>
      )
    }
    return (
      <div role='presentation' onClick={() => this.setState({ flag: true })} className='disp-flex' >
        <a><FormattedMessage id="Create new folder" defaultMessage="Create new folder" /></a>
      </div>
    )
  }
  renderCheckbox () {
    if (this.state.folders.length === 0) {
      return <NoDataFound className='no-potli' message='No folders available' />
    }
    return <CheckboxGroup id='bookmark-folders-id' options={this.state.folders.map(item => item.folder_name)} active={this.state.active} onChange={this.onChange} />
  }
  render () {
    const { editBookmark, deleteBookmark, createBookmark } = this.props
    const loading = editBookmark.loading || deleteBookmark.loading || createBookmark.loading
    return (
      <Fragment>
        <div className='modal-header'>
          <div className='modal-title'><FormattedMessage id="Add to My Favourites" defaultMessage="Add to My Favourites" /></div>
        </div>
        <div className='modal-body'>
          <Loader height={150} loading={this.props.folders.loading} error={this.props.folders.error}>
            {this.renderAdd()}
            <div className='bookmark-modal-folders scrollbar'>
              {this.renderCheckbox()}
            </div>
          </Loader>
        </div>
        <div className='modal-footer has-space-btwn'>
          {this.props.type === 'delete' && <button disabled={this.props.create.loading || loading} className='remove-all-btn' onClick={() => this.onDelete()}><FormattedMessage id="Remove from all" defaultMessage="Remove from all" /></button>}
          <button className='btn btn-default delete-btn invisible' onClick={this.props.modalCloseAction}>Close</button>
          <button className='btn btn-base delete-btn' disabled={this.state.active.length === 0 || this.props.create.loading || loading} onClick={() => this.onSave()}><FormattedMessage id="Save" defaultMessage="Save" /></button>
        </div>
      </Fragment>
    )
  }
}

BookmarkModal.propTypes = {
  modalCloseAction: PropTypes.func.isRequired,
  folders: PropTypes.object.isRequired,
  createFolderAction: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['create', 'delete']).isRequired,
  params: PropTypes.object.isRequired,
  bookmarkFolderFetchAction: PropTypes.func.isRequired,
  create: PropTypes.object.isRequired,
  editBookmark: PropTypes.object.isRequired,
  deleteBookmark: PropTypes.object.isRequired,
  createBookmark: PropTypes.object.isRequired,
  createBookmarkAction: PropTypes.func.isRequired,
  deleteBookmarkAction: PropTypes.func.isRequired,
  editBookmarkAction: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  folders: state.bookmarkFolder,
  create: state.createFolder,
  editBookmark: state.editBookmark,
  deleteBookmark: state.deleteBookmark,
  createBookmark: state.createBookmark
})

export default withRouter(connect(mapStateToProps, {
  modalCloseAction,
  bookmarkFolderFetchAction,
  createFolderAction,
  createBookmarkAction,
  deleteBookmarkAction,
  editBookmarkAction
})(BookmarkModal))
