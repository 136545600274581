import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { T } from '../'

const ExpressionData = (props) => {
  const abstract = props.data.Summary.replace(/<(?!span)(?!\/)/g, '< ')
  return (
    <Fragment>
      <div className='card-body-content' >
        <div className='sub-title' >Submitted</div>
        <div><T data={props.data['Submission Date']} /></div>
      </div>
      <div className='card-body-content' >
        <div className='sub-title' >Last Updated</div>
        <div><T data={props.data['Last Updated Date']} /></div>
      </div>
      <div className='card-body-content' >
        <div className='sub-title' >Organism</div>
        <div><T data={props.data.Organism} /></div>
      </div>
      <div className='card-body-content' >
        <div className='sub-title' >Experimental Type</div>
        <div><T data={props.data['Experimental Type']} /></div>
      </div>
      <div className='card-body-content' >
        <div className='sub-title' >Summary</div>
        <div data-test='congress-abstract' id='annotation-highlight-1' >{ReactHtmlParser(abstract)}</div>
      </div>
      <div className='card-body-content' >
        <div className='sub-title' >Contact Information</div>
        <div>
          {props.data['Contact Information'] && props.data['Contact Information'].length ? props.data['Contact Information'].map((item, i) => (
            <div key={i}>
              {item.contact_author_name} {item.contact_author_name && item.contact_author_email ? ` - ${item.contact_author_email}` : (item.contact_author_email || '')}
            </div>
          )) : (
            <T data='' />
          )}
        </div>
      </div>
      <div className='card-body-content' >
        <div className='sub-title' >Citation</div>
        <div><T data={props.data.Citation} /></div>
      </div>
      {props.data.pmid && (
        <div className='card-body-content disp-flex' >
          <div data-test='pmid' className='pmid'>
            <div className='sub-title'>PMID</div>
            <a target='_blank' rel="noopener noreferrer" href={`https://pubmed.ncbi.nlm.nih.gov/${props.data.pmid}/`} className='sub-text'> {props.data.pmid} </a>
          </div>
        </div>
      )}
      <div className='card-body-content' >
        <div className='sub-title' >files</div>
        <div>
          {props.data.files && props.data.files.length ? props.data.files.map((item, i) => (
            <div key={i}>
              <a href={item.file_url} target='_blank' rel="noopener noreferrer">{item.file_name}</a>
            </div>
          )) : (
            <T data='' />
          )}
        </div>
      </div>
    </Fragment>
  )
}

ExpressionData.propTypes = {
  data: PropTypes.object.isRequired
}

export default ExpressionData
