import React from 'react'
import PropTypes from 'prop-types'
import { getAssetClassMapping } from '../../utils'

const AssetTag = props => (props.asset ? <span data-test='asset-tag' className='asset-tag'>{getAssetClassMapping(props.asset, true)}</span> : null)
AssetTag.propTypes = {
  asset: PropTypes.string
}

AssetTag.defaultProps = {
  asset: null
}

export default AssetTag
