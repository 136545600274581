import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const MOA = 'MOA'
export const MOA_SUCCESS = 'MOA_SUCCESS'
export const MOA_FAILURE = 'MOA_FAILURE'

export const MOA_DRUGS = 'MOA_DRUGS'
export const MOA_DRUGS_SUCCESS = 'MOA_DRUGS_SUCCESS'
export const MOA_DRUGS_FAILURE = 'MOA_DRUGS_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const moaAction = payload => ({
  type: MOA,
  payload
})

export const moaDrugsAction = payload => ({
  type: MOA_DRUGS,
  payload
})

export const moaSuccess = payload => ({
  type: MOA_SUCCESS,
  payload
})

export const moaDrugsSuccess = payload => ({
  type: MOA_DRUGS_SUCCESS,
  payload
})


export const moaEpic = action$ => action$
  .ofType(MOA)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/moadrugs/?${action.payload}`, 'GET'))
    .map(response => moaSuccess(response))
    .catch(error => Observable.of({
      type: MOA_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const moaDrugsEpic = action$ => action$
  .ofType(MOA_DRUGS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/moadrugs/?${action.payload}`, 'GET'))
    .map(response => moaDrugsSuccess(response))
    .catch(error => Observable.of({
      type: MOA_DRUGS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function moaReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case MOA: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case MOA_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case MOA_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function moaDrugsReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case MOA_DRUGS: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case MOA_DRUGS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case MOA_DRUGS_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
