import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

// const color = ['#ffc8cc', '#fead93', '#94f1e8', '#ff7c77', '#3aafa8', '#f1e078', '#a0a3ed']
// const border = ['#e3898f', '#de744f', '#59c7bd', '#ce625d', '#288c86', '#c0b45a', '#8081bf']
class LineGraph extends Component {
  constructor (props) {
    super(props)
    this.marginLeft = 100
    this.marginRight = 50
    this.marginTop = 50
    this.marginBottom = this.props.labelX ? 100 : 50
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
  }
  componentWillMount () {
    this.x = d3.scaleBand()
    this.y = d3.scaleLinear()

    const xDom = []
    const yDom = []
    Object.keys(this.props.data).forEach((element) => {
      this.props.data[element].forEach((d) => {
        xDom.push(Number(d.category))
        yDom.push(Number(d.value))
      })
    })

    xDom.sort()
    this.x.domain(xDom)
      .range([0, this.width])
    this.y.domain([0, d3.max(yDom)])
      .range([this.height, 0])
    const offset = this.y.ticks()[2] - this.y.ticks()[0]
    this.y.domain([this.y.domain()[0], this.y.domain()[1] + offset])
  }
  componentDidMount () {
    this.renderAxis()
    // const totalLength = d3.select('.trends-line').node().getTotalLength();
    // d3.selectAll('.trends-line')
    //   .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
    //   .attr('stroke-dashoffset', totalLength)
    //   .transition()
    //   .duration(2000)
    //   .ease(d3.easeLinear)
    //   .attr('stroke-dashoffset', 0);
  }
  renderAxis () {
    const xnode = '#xaxis'
    const domStep = Math.round(this.x.domain().length / 8)
    const domain = []
    for (let i = 0; i < this.x.domain().length; i += domStep) {
      domain.push(this.x.domain()[i])
    }
    const xaxis = d3.axisBottom(this.x).tickValues(domain)
    d3.select(xnode).call(xaxis)
    const ynode = '#yaxis'
    const yaxis = d3.axisLeft(this.y).tickSizeOuter(-this.width)
    d3.select(ynode).call(yaxis)
  }
  renderGraph () {
    const valueline = d3.line()
      .x(d => this.x(Number(d.category)) + (this.x.bandwidth() / 2))
      .y(d => this.y(Number(d.value)))
    return Object.keys(this.props.data).map((el, i) => (
      <path
        d={valueline(this.props.data[el])}
        key={i}
        fill='none'
        stroke={this.props.color[i]}
        strokeWidth='1.5px'
        className='trends-line'
      />
    ))
  }
  renderLabelX () {
    return (
      <text
        transform={`translate(${this.width / 2}, ${this.height + (this.marginBottom / 2)})`}
        textAnchor='middle'
        className='graph-font-family capitalize-me'
      >
        {this.props.labelX}
      </text>
    )
  }
  renderLabelY () {
    return (
      <text
        transform={`translate(${-this.marginLeft / 2}, ${this.height / 2})rotate(-90)`}
        textAnchor='middle'
        className='graph-font-family capitalize-me'
      >
        {this.props.labelY}
      </text>
    )
  }
  render () {
    return (
      <g
        transform={`translate(${this.marginLeft}, ${this.marginTop})`}
      >
        <g id='xaxis' className='x axis' transform={`translate(0, ${this.height})`} />
        <g id='yaxis' className='y axis' />
        { this.renderGraph() }
        { this.props.labelX ? this.renderLabelX() : null}
        { this.props.labelY ? this.renderLabelY() : null}
      </g>
    )
  }
}

LineGraph.propTypes = {
  labelX: PropTypes.string.isRequired,
  labelY: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  color: PropTypes.array.isRequired
}
LineGraph.defaultProps = {
  // data: [],
}

export default LineGraph
