import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ListIcons, AssetTag, AnnotationIcon } from '..'
import { checkPermission } from '../../utils'

const RegulatoryReview = props => (
  <Fragment>
    { props.flag &&
      <div className='card-actions'>
        <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Regulatory Data' />
        {!checkPermission('ontosight_academics_user') && <AnnotationIcon data={props.data} />}
      </div>
    }
    <div className='card-details'>
      <div className='card-meta'>
        <AssetTag asset={props.data.type} />
        {/* {props.data['Application Number'] && (
          <div className='card-meta-title text-elips'>
            {props.data['Application Number']}
          </div>
        )} */}
        {props.data.Date && (
          <div className='date'>
            {props.data.Date}
          </div>
        )}
        {props.data['Regulatory Body'] && <div className='date padding-small-left'>{props.data['Regulatory Body']}</div>}
      </div>
      <h3 className='card-title'>
        {ReactHtmlParser(props.data.Title)}  {props.data.Title && props.data['Application Number'] && `for application number ${props.data['Application Number']}`}
      </h3>
      <div className='card-text'>
        {props.data['Drug Name'] && (
          <div className='card-subtext'>
            <div className='title'>Brand Name: </div>
            <div>{props.data['Drug Name']}</div>
          </div>
        )}
        {props.data['Active Ingredient'] && (
          <div className='card-subtext'>
            <div className='title'>Active ingredient: </div>
            <div>{props.data['Active Ingredient']}</div>
          </div>
        )}
        {props.data.Types && Array.isArray(props.data.Types) && !!props.data.Types.length && (
          <div className='card-subtext'>
            <div className='title'>Document type: </div>
            <div>{props.data.Types.join(', ')}</div>
          </div>
        )}
      </div>
    </div>
    {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
  </Fragment>
)

RegulatoryReview.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  likeParams: PropTypes.object,
  flag: PropTypes.bool,
  takeFeedback: PropTypes.bool,
  showMore: PropTypes.bool,
  overlay: PropTypes.bool
}

RegulatoryReview.defaultProps = {
  flag: true,
  likeParams: {},
  takeFeedback: true,
  showMore: false,
  overlay: true
}

export default RegulatoryReview
