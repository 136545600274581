import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

export const ANNOTATION_FETCH = 'ANNOTATION_FETCH'
export const ANNOTATION_FETCH_SUCCESS = 'ANNOTATION_FETCH_SUCCESS'
export const ANNOTATION_FETCH_FAILURE = 'ANNOTATION_FETCH_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false,
}

export const annotationFetchAction = payload => ({
  type: ANNOTATION_FETCH,
  payload
})

export const annotationFetchSuccess = payload => ({
  type: ANNOTATION_FETCH_SUCCESS,
  payload
})

export const annotationFetchEpic = action$ => action$
  .ofType(ANNOTATION_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/highlights/text?${action.payload.params}`, 'GET', true))
    .map(response => annotationFetchSuccess({ data: response.response.data || [], id: action.payload.id }))
    .catch(error => Observable.of({
      type: ANNOTATION_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Auth reducer updates both login and logout
export function annotationFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case ANNOTATION_FETCH: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case ANNOTATION_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        flag: true
      }
    }
    case ANNOTATION_FETCH_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
