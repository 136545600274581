import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

export const EQUALIZER = 'EQUALIZER'
export const EQUALIZER_SUCCESS = 'EQUALIZER_SUCCESS'
export const EQUALIZER_FAILURE = 'EQUALIZER_FAILURE'
export const EQUALIZER_SAVE = 'EQUALIZER_SAVE'
export const EQUALIZER_SAVE_SUCCESS = 'EQUALIZER_SAVE_SUCCESS'
export const EQUALIZER_SAVE_FAILURE = 'EQUALIZER_SAVE_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}


export const equalizerAction = payload => ({
  type: EQUALIZER,
  payload
})

export const equalizerSuccess = payload => ({
  type: EQUALIZER_SUCCESS,
  payload
})

export const equalizerSaveAction = payload => ({
  type: EQUALIZER_SAVE,
  payload
})

export const equalizerSaveSuccess = payload => ({
  type: EQUALIZER_SAVE_SUCCESS,
  payload
})

export const equalizerEpic = action$ => action$
  .ofType(EQUALIZER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/equalizer?${action.payload}`, 'GET', true))
    .map(response => equalizerSuccess(response))
    .catch(error => Observable.of({
      type: EQUALIZER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const equalizerSaveEpic = action$ => action$
  .ofType(EQUALIZER_SAVE)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/customSort/saveCustomSort/?${action.payload.params}`, 'POST', true, action.payload.data))
    .map(response => equalizerSaveSuccess(response))
    .catch(error => Observable.of({
      type: EQUALIZER_SAVE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function equalizerReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case EQUALIZER: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case EQUALIZER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case EQUALIZER_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function equalizerSaveReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case EQUALIZER_SAVE: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case EQUALIZER_SAVE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case EQUALIZER_SAVE_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
