import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { checkBrowserSupport } from '../../utils'
import AlreadyLoggedIn from '../../container/AlreadyLoggedIn';
import BrowserSupportPage from '../BrowserSupportPage';

class BrowserSupport extends Component {
  constructor() {
    super()
    this.isBrowserSupported = checkBrowserSupport()
  }
  render() {
    if (!this.isBrowserSupported) {
      return <BrowserSupportPage />
    }

    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    );
  }
}

BrowserSupport.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

BrowserSupport.defaultProps = {
  children: null
}

export default AlreadyLoggedIn()(BrowserSupport)
