import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const DOWNLOAD = 'DOWNLOAD'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const downloadAction = payload => ({
  type: DOWNLOAD,
  payload
})

export const downloadSuccess = payload => ({
  type: DOWNLOAD_SUCCESS,
  payload
})

export const downloadEpic = action$ => action$
  .ofType(DOWNLOAD)
  .mergeMap(() => staticAjax(apiCall('http://192.168.9.170:3001/api/v0/search/?query=lung&dataset=publications&sortby=latest&from=1&size=10&sponsor=All&download=true', 'GET', true, {}, {
    'Response-Type': 'arraybuffer'
  }))
    .map(response => downloadSuccess(response))
    .catch(error => Observable.of({
      type: DOWNLOAD_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function downloadReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case DOWNLOAD: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case DOWNLOAD_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        flag: true
      }
    }
    case DOWNLOAD_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
