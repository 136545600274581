import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import Tooltip from 'react-portal-tooltip'
import { Legends } from '../../../../common/components'
import { style } from '../../../../common/utils'

class Stacked extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isTooltipActive: false,
      index: 0,
      id: ''
    }
    this.marginLeft = this.props.marginLeft
    this.marginRight = 50
    this.marginTop = 50
    this.marginBottom = 120
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
    this.color = [
      '#1B5082',
      '#6374C3',
      '#50D5E3',
      '#D2668F',
      '#9B9D1C',
      '#ca9543',
      '#EFE99F',
      '#4a65ac',
    ]
    this.id = (new Date()).getTime()
  }
  componentWillMount () {
    this.y = d3.scaleLinear()
    this.x = d3.scaleBand()
      .padding(0.25)
    this.x.domain(this.props.data.map(d => d.category))
    this.keys = []
    this.max = 0
    this.props.data.forEach((d) => {
      let total = 0
      Object.keys(d).forEach((el) => {
        if (el !== 'category' && this.keys.indexOf(el) < 0) {
          this.keys.push(el)
        }
        if (el !== 'category') {
          total += d[el]
        }
      })
      if (total > this.max) {
        this.max = total
      }
    })
    // this.keys = ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4', 'Others']
    this.x.range([0, this.width]).paddingInner(0.3).paddingOuter(0.1)
    this.offset = this.y.ticks()[2] - this.y.ticks()[0]
    this.graphStack = d3.stack()
    this.y.domain([0, this.max])
      .range([this.height, 0])
  }
  componentDidMount () {
    this.renderAxis()
    const xnode = `#xaxis_${this.props.id}`
    const ynode = `#yaxis_${this.props.id}`
    d3.select(xnode)
      .selectAll('.tick text')
      .style('text-anchor', 'end')
      .text(d => (d.length > 12 ? `${d.substring(0, 12)}...` : d))
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-35)');
    d3.select(xnode)
      .selectAll('.tick text')
      .append('title')
      .text(d => d)
    d3.select(ynode)
      .selectAll('.tick text')
      .append('title')
      .text(d => d)
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.width !== this.props.width || nextProps.height !== this.props.height) {
      this.width = nextProps.width - this.marginLeft - this.marginRight
      this.height = nextProps.height - this.marginTop - this.marginBottom
      this.x.range([0, this.width])
      this.y.range([this.height, 0])
      this.renderAxis()
      const xnode = `#xaxis_${this.props.id}`
      const ynode = `#yaxis_${this.props.id}`
      d3.select(xnode)
        .selectAll('.tick text')
        .style('text-anchor', 'end')
        .text(d => (d.length > 12 ? `${d.substring(0, 12)}...` : d))
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-35)');
      d3.select(xnode)
        .selectAll('.tick text')
        .append('title')
        .text(d => d)
      d3.select(ynode)
        .selectAll('.tick text')
        .append('title')
        .text(d => d)
    }
  }
  getMapping () {
    const map = {}
    this.keys.forEach((d, i) => {
      map[this.keys[i]] = this.color[i]
    })
    return map
  }
  renderAxis () {
    const xnode = `#xaxis_${this.props.id}`
    const xaxis = d3.axisBottom(this.x)
    d3.select(xnode).call(xaxis)
    const ynode = `#yaxis_${this.props.id}`
    const yaxis = d3.axisLeft(this.y)
    d3.select(ynode).call(yaxis)
  }
  renderElement (arr, index) {
    // const { keys } = Object.keys(this.props.color).map(key => key)
    // const dat = []
    // for (let i = 0; i < keys.length; i += 1) {
    //   const key = keys[i]
    //   if (Object.keys(arr).indexOf(key) > -1 && arr[key] > 0) {
    //     dat.push({
    //       key,
    //       value: arr[key],
    //     })
    //   } else {
    //     dat.push({
    //       key,
    //       value: 0
    //     })
    //   }
    // }
    return arr.map((d, j) => {
      const id = `stackbar${this.id}_${index}_${j}`
      return (
        <g
          key={`grp-${index}_${j}`}
        >
          <rect
            key={`stacked${this.id}${index}_${j}`}
            className='handicon'
            id={id}
            x={this.x(d.data.category) + ((this.x.bandwidth() - 30) / 2)}
            y={!Number.isNaN(d[1]) ? this.y(d[1]) : 0}
            height={!Number.isNaN(d[1]) && !Number.isNaN(d[0]) ? this.y(d[0]) - this.y(d[1]) : 0}
            width={30}
            fill={this.props.color[this.keys[index]] ? this.props.color[this.keys[index]] : this.props.color[index]}
            onClick={() => {
              const obj = {}
              obj[this.props.labelY] = this.keys
              obj[this.props.labelX] = this.props.data.map(d1 => d1.category)
              obj.x_axis_filter = d.data.category
              obj.y_axis_filter = this.keys[index]
              obj.x_axis = this.props.labelX
              obj.y_axis = this.props.labelY
              obj.graph_type = 'stack_bar_graph'
              this.props.redirect(obj)
}}
            onMouseMove={() => {
              this.setState({
              isTooltipActive: true,
              id,
              index: j
            })
          }
          }
            onMouseLeave={() => this.setState({
              isTooltipActive: false
            })}
          />
        </g>
      )
    })
  }
  renderGraph () {
    const mapArray = this.graphStack.keys(this.keys)(this.props.data)
    return mapArray.map((arr, i) => (
      <g key={i} className={this.keys[i]}>
        {this.renderElement(arr, i)}
      </g>
    ))
  }
  renderDiv() {
    return Object.keys(this.props.data[this.state.index]).map((key, i) => (
      <tr
        key={`${key}_${i}`}
        role='presentation'
        className='tooltipList'
        onClick={() => {
        const obj = {}
        obj[this.props.labelY] = this.keys
        obj[this.props.labelX] = this.props.data.map(d1 => d1.category)
        obj.x_axis_filter = this.props.data[this.state.index].category
        obj.y_axis_filter = key
        obj.x_axis = this.props.labelX
        obj.y_axis = this.props.labelY
        obj.graph_type = 'stack_bar_graph'
        // obj['x_axis'] = this.props.data[this.state.index].category
        // obj[`Selected${this.props.labelY}`] = key

        this.props.redirect(obj)
      }}
      >
        <td>
          <div
            className='bullets'
            style={{
              opacity: 1,
              backgroundColor: this.props.color[key],
              width: '10px',
              height: '10px',
              borderRadius: '50%'
          }}
          />
        </td>
        {key !== 'category' && (
          <td>
            {key}
          </td>
        )}
        <td style={{ fontWeight: '700' }}>
          {this.props.data[this.state.index][key] ? this.props.data[this.state.index][key] : 0}
        </td>
      </tr>
    ))
  }
  renderLabelX () {
    return (
      <text
        transform={`translate(${this.marginLeft + (this.width / 2)}, ${this.height + this.marginTop + (this.marginBottom / 1.5)})`}
        textAnchor='middle'
        className='graph-font-family'
      >
        {this.props.labelX}
      </text>
    )
  }
  renderLabelY () {
    return (
      <text
        transform={`translate(${this.marginLeft / 4}, ${(this.height / 1.9) + this.marginTop})rotate(-90)`}
        textAnchor='middle'
        className='graph-font-family'
      >
        {this.props.labelY}
      </text>
    )
  }
  render () {
    const customStyle = {
      style: {
        ...style.style,
        'z-index': 21,
      },
      arrowStyle: {
        ...style.arrowStyle
      }
    }
    return [
      <svg>
        <g>
          <g transform={`translate(${this.marginLeft},${this.marginTop})`}>
            <g className='x axis' id={`xaxis_${this.props.id}`} transform={`translate(0, ${this.height})`} />
            <g className='y axis' id={`yaxis_${this.props.id}`} />
            {this.renderGraph()}
          </g>
          { this.props.labelX ? this.renderLabelX() : null}
          { this.props.labelY ? this.renderLabelY() : null}
        </g>
      </svg>,
      <div>
        <Legends legends={this.getMapping()} borderRadius={this.props.border} />
      </div>,
      <Tooltip className='tooltip' style={customStyle} id={`StackbarGraphTooltip${this.id}`} active={this.state.isTooltipActive} position='top' parent={`#${this.state.id}`}>
        <table>
          <tbody>
            {this.renderDiv()}
          </tbody>
        </table>
      </Tooltip>
    ]
  }
}

Stacked.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  id: PropTypes.any.isRequired,
  border: PropTypes.number,
  labelX: PropTypes.string.isRequired,
  labelY: PropTypes.string.isRequired,
  color: PropTypes.object,
  marginLeft: PropTypes.number,
  redirect: PropTypes.func
}


Stacked.defaultProps = {
  border: 0,
  color: {},
  marginLeft: 100,
  redirect: null
}

export default Stacked
