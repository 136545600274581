import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ToolTip from 'react-portal-tooltip'
import { Screenshot } from '../index'
import { style, generateRandomString } from '../../utils'


class PanelHeading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTooltipActive: false,
      id: generateRandomString()
    }
  }
  render() {
    const custom = {
      style: {
        ...style.style,
        width: 500,
      },
      arrowStyle: {
        ...style.arrowStyle
      }
    }
    return (
      <div className='panel-heading has-space-btwn'>
        <div>
          <h3 className='title'>
            <span className='ff-medium'>{this.props.title}</span>
            <span>{this.props.titleText}</span>
          </h3>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
          {this.props.downloadId &&
            <div className='filter-icon'>
              <Screenshot title={this.props.title} branding={this.props.branding} id={this.props.downloadId} />
            </div>}
          {this.props.tooltip && <div onBlur={() => null} id={this.state.id} onMouseEnter={() => this.setState({ isTooltipActive: true })} onMouseLeave={() => this.setState({ isTooltipActive: false })} className='info-png-icon' />}
          <ToolTip tooltipTimeout={0} arrow='center' style={custom} active={this.state.isTooltipActive} position='left' parent={`#${this.state.id}`}>
            {this.props.children}
          </ToolTip>
        </div>
      </div>
    )
  }
}

PanelHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  titleText: PropTypes.string,
  tooltip: PropTypes.bool,
  downloadId: PropTypes.string,
  branding: PropTypes.bool,
}

PanelHeading.defaultProps = {
  children: null,
  title: '',
  titleText: '',
  tooltip: false,
  downloadId: '',
  branding: false
}

export default PanelHeading
