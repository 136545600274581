import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas'
import { FormattedMessage, injectIntl } from 'react-intl';
import ToolTip from 'react-portal-tooltip'
import { ClickOutside } from 'reusable-react-components'
import { Dropdown } from '../../../common/components'
import { style, setAnalytics } from '../../../common/utils'
import Mixpanel from '../../mixpanel';

const options = ['Medium', 'High']
const scale = {
  Medium: 1,
  High: 2
}
const exportOptions = ['jpeg', 'png']
const styleObj = {
  flex: '1 1 0%',
  background: 'transparent',
  border: 'solid 1px #fff',
  borderRadius: 2,
  color: '#fff',
}

class Screenshot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTooltipActive: false,
      loading: false,
      quality: [options[0]],
      file_format: [exportOptions[0]],
    }
  }

  onChange(value, key) {
    this.setState({
      [key]: value
    })
  }

  onClear() {
    this.setState({
      isTooltipActive: false,
    })
  }

  showTooltip() {
    this.setState({
      isTooltipActive: true,
    })
  }

  hideTooltip() {
    this.setState({ isTooltipActive: false })
  }

  capture = (id) => {
    this.setState({ loading: true });
    html2canvas(document.getElementById(id), {
      scale: scale[this.state.quality],
      allowTaint: true,
      onclone: (canvas) => {
        const chat = canvas.getElementById('chat-box-container')
        const input = canvas.querySelectorAll('input[type=text]')
        if (input && input.length) {
          input.forEach((e) => {
            e.setAttribute('placeholder', '')
          });
        }
        const svgElem = canvas.querySelectorAll('svg');
        if (svgElem && svgElem.length) {
          svgElem.forEach((node) => {
            node.setAttribute('font-family', window.getComputedStyle(node, null).getPropertyValue('font-family'));
            node.setAttribute('font-size', window.getComputedStyle(node, null).getPropertyValue('font-size'))
            node.replaceWith(node);
          })
        }
        if (chat) {
          chat.parentNode.removeChild(chat)
        }
        if (this.props.branding) {
          const data = canvas.getElementById(id)
          const year = new Date().getFullYear()
          data.insertAdjacentHTML('afterbegin', '<header class="unified-header" style="display: block;"><div class="unified-header-top-container"><ul class="left-nav-group"><li class="nav-logo-wrap"><a class="logo"><i class="icon icon-logo-iplexus"></i></a></li></ul><ul class="right-nav-group" /></div></header>')
          data.insertAdjacentHTML('beforeend', `<div class="container"><div class="center"><div class="footer-innoplexus-logo"></div><div class="divider"></div><div class="copy">© ${year} - <a rel="noopener" href="http://www.innoplexus.com" target="blank"><span>Innoplexus AG</span></a> - <span>All Rights Reserved.</span></div></div></div>`)
        }
      }
    }).then((canvas) => {
      const tempDate = new Date()
      canvas.toBlob((blob) => {
        const objurl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `Ontosight(®)${this.props.title ? `_${this.props.title}` : ''}_${tempDate.getUTCFullYear()}-${tempDate.getUTCMonth() + 1}-${tempDate.getUTCDate()}`;
        link.href = objurl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, `image/${this.state.file_format}`);
      this.setState({ loading: false });
      Mixpanel.track('downloadFileEvent', {
        action: 'download file',
        scale: this.state.quality,
        fileFormat: this.state.file_format,
        title: this.props.title
      })
      setAnalytics('downloadFileTrigger', 'downloadFile', { scale: this.state.quality, fileFormat: this.state.file_format, title: this.props.title })
    });
  }

  renderDropdown() {
    const { intl } = this.props
    return (
      <div>
        <div style={{ padding: '10px' }}>
          <div><FormattedMessage id="Quality" defaultMessage="Quality" />:</div>
          <Dropdown title={intl.formatMessage({ id: 'Quality', defaultMessage: 'Quality' })} options={options || []} active={this.state.quality || []} onChange={value => this.onChange(value, 'quality')} />
        </div>
        <div style={{ padding: '10px' }}>
          <div><FormattedMessage id="File Format" defaultMessage="File Format" />:</div>
          <Dropdown title={intl.formatMessage({ id: 'File Format', defaultMessage: 'File Format' })} options={exportOptions || []} active={this.state.file_format || []} onChange={value => this.onChange(value, 'file_format')} />
        </div>
      </div>
    )
  }

  render() {
    const { id } = this.props
    return (
      <Fragment>
        {id ?
          <div data-test='download-link' title="download" className='filter-icon download-export' role='presentation' ref={(screenshot) => { this.screenshot = screenshot }} onClick={() => this.showTooltip()} >
            <i className='sort-filter filter-icon hand' />
          </div> : null
        }
        <ToolTip style={style} active={this.state.isTooltipActive} position='bottom' arrow='right' parent={this.state.isTooltipActive && this.screenshot}>
          <ClickOutside onClickOutside={() => this.hideTooltip()} >
            <div><FormattedMessage id="Download" defaultMessage="Download Results" /></div>
            <hr />
            <div className='tooltip-container filter-container'>
              {this.renderDropdown()}
            </div>
            <div className='filter-buttons'>
              <button className='btn' style={styleObj} onClick={() => this.onClear()} ><FormattedMessage id="Cancel" defaultMessage="Cancel" /></button>
              {!this.state.loading ? <button className='btn' style={{ flex: '1' }} onClick={() => this.capture(id)} ><FormattedMessage id="Download" defaultMessage="Download" /> </button> : <span className='btn'><FormattedMessage id="Downloading..." defaultMessage="Downloading..." /></span>}
            </div>
          </ClickOutside>
        </ToolTip>
      </Fragment>
    )
  }
}

Screenshot.propTypes = {
  id: PropTypes.string,
  intl: PropTypes.object.isRequired,
  branding: PropTypes.bool,
  title: PropTypes.string
}

Screenshot.defaultProps = {
  id: '',
  branding: false,
  title: ''
}

export default injectIntl(Screenshot)
