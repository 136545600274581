import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const EDIT_CLUSTER = 'EDIT_CLUSTER'
export const EDIT_CLUSTER_SUCCESS = 'EDIT_CLUSTER_SUCCESS'
export const EDIT_CLUSTER_FAILURE = 'EDIT_CLUSTER_FAILURE'

export const GET_CLUSTER_DETAIL = 'GET_CLUSTER_DETAIL'
export const GET_CLUSTER_DETAIL_SUCCESS = 'GET_CLUSTER_DETAIL_SUCCESS'
export const GET_CLUSTER_DETAIL_FAILURE = 'GET_CLUSTER_DETAIL_FAILURE'

export const DELETE_CLUSTER = 'DELETE_CLUSTER'
export const DELETE_CLUSTER_SUCCESS = 'DELETE_CLUSTER_SUCCESS'
export const DELETE_CLUSTER_FAILURE = 'DELETE_CLUSTER_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

const INITIAL_EDIT_STATE = {
  data: {},
  loading: false,
  folder: '',
  error: false,
  flag: false
}

export const editClusterAction = (payload, folder) => ({
  type: EDIT_CLUSTER,
  payload,
  folder
})

export const editClusterSuccess = payload => ({
  type: EDIT_CLUSTER_SUCCESS,
  payload
})

export const getClusterDetailAction = payload => ({
  type: GET_CLUSTER_DETAIL,
  payload
})

export const getClusterDetailSuccess = payload => ({
  type: GET_CLUSTER_DETAIL_SUCCESS,
  payload
})

export const deleteClusterAction = payload => ({
  type: DELETE_CLUSTER,
  payload
})

export const deleteClusterSuccess = payload => ({
  type: DELETE_CLUSTER_SUCCESS,
  payload
})

export const editClusterEpic = action$ => action$
  .ofType(EDIT_CLUSTER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/workspace_v1/editworkspace?${action.payload.params}`, 'PUT', true, action.payload.data))
    .map(response => editClusterSuccess(response))
    .catch(error => Observable.of({
      type: EDIT_CLUSTER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const getClusterDetailEpic = action$ => action$
  .ofType(GET_CLUSTER_DETAIL)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/workspace/clusterdetail?${action.payload}`, 'GET'))
    .map(response => getClusterDetailSuccess(response))
    .catch(error => Observable.of({
      type: GET_CLUSTER_DETAIL_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const deleteClusterEpic = action$ => action$
  .ofType(DELETE_CLUSTER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/workspace_v1/deleteworkspace?${action.payload}`, 'DELETE'))
    .map(response => deleteClusterSuccess(response))
    .catch(error => Observable.of({
      type: DELETE_CLUSTER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function editClusterReducer (state = INITIAL_EDIT_STATE, action = null) {
  switch (action.type) {
    case EDIT_CLUSTER: {
      return {
        ...state,
        data: {},
        loading: true,
        folder: action.folder,
        error: false,
        flag: false
      }
    }
    case EDIT_CLUSTER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case EDIT_CLUSTER_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        folder: '',
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function getClusterDetailReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case GET_CLUSTER_DETAIL: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case GET_CLUSTER_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case GET_CLUSTER_DETAIL_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function deleteClusterReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case DELETE_CLUSTER: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_CLUSTER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_CLUSTER_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
