import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formSearchTreeQuery, parseHashUrl, isJsonString, equalizerQuery, filterArrayJoin } from '../../../common/utils'
import GenerateListV1 from '../../../common/container/GenerateListV1'

class List extends Component {
  constructor (props) {
    super(props)
    const q = parseHashUrl(this.props)
    const actualQuery = isJsonString(q.aq, [])
    this.state = {
      query: actualQuery.length > 0 ? formSearchTreeQuery(actualQuery) : isJsonString(q.q, []),
      dataset: q.tab,
      size: 20,
      filters: q.filters ? filterArrayJoin(q.filters) : {},
      sortby: q.sortby || '',
      equilizer: q.seq ? equalizerQuery(JSON.parse(q.seq)) : '',
      language: q.language,
      start: q.start,
      end: q.end,
      publication_review_status: q.publication_review_status || ''
    }
  }
  componentWillReceiveProps (nextProps) {
    const oldQuery = parseHashUrl(this.props)
    const newQuery = parseHashUrl(nextProps)
    const actualQuery = isJsonString(newQuery.aq, [])
    const query = isJsonString(newQuery.q, [])
    if (newQuery.tab) {
      if (oldQuery.tab !== newQuery.tab) {
        this.setState({
          dataset: newQuery.tab,
          query: actualQuery.length > 0 ? formSearchTreeQuery(actualQuery) : query,
          filters: {},
          sortby: newQuery.sortby || 'latest',
          equilizer: newQuery.seq ? equalizerQuery(JSON.parse(newQuery.seq)) : '',
          language: newQuery.language,
          publication_review_status: newQuery.publication_review_status || ''
        })
      } else if (oldQuery.q !== newQuery.q && newQuery.q && actualQuery.length < 1) {
        this.setState({
          dataset: newQuery.tab,
          query,
          filters: {},
          sortby: newQuery.sortby || 'latest',
          equilizer: newQuery.seq ? equalizerQuery(JSON.parse(newQuery.seq)) : '',
          language: newQuery.language,
          publication_review_status: newQuery.publication_review_status || ''
        })
      } else if (oldQuery.aq !== newQuery.aq && actualQuery.length) {
        this.setState({
          dataset: newQuery.tab,
          query: formSearchTreeQuery(actualQuery),
          equilizer: newQuery.seq ? equalizerQuery(JSON.parse(newQuery.seq)) : '',
          language: newQuery.language,
          publication_review_status: newQuery.publication_review_status || ''
        })
      } else if (oldQuery.sortby !== newQuery.sortby && newQuery.sortby) {
        this.setState({
          sortby: newQuery.sortby,
          equilizer: newQuery.seq ? equalizerQuery(JSON.parse(newQuery.seq)) : '',
          language: newQuery.language,
          publication_review_status: newQuery.publication_review_status || ''
        })
      } else if (oldQuery.filters !== newQuery.filters && newQuery.filters) {
        this.setState({
          filters: filterArrayJoin(newQuery.filters),
          equilizer: newQuery.seq ? equalizerQuery(JSON.parse(newQuery.seq)) : '',
          language: newQuery.language,
          publication_review_status: newQuery.publication_review_status || ''
        })
      } else if (oldQuery.seq !== newQuery.seq) {
        this.setState({
          equilizer: newQuery.seq ? equalizerQuery(JSON.parse(newQuery.seq)) : '',
          language: newQuery.language,
          publication_review_status: newQuery.publication_review_status || ''
        })
      } else if (oldQuery.publication_review_status !== newQuery.publication_review_status) {
        this.setState({
          publication_review_status: newQuery.publication_review_status
        })
      }
    }
  }
  render () {
    if (!parseHashUrl(this.props).tab) {
      return null;
    }
    const {
      query, dataset, size, filters, sortby, equilizer, language, start, end, publication_review_status
    } = this.state
    const params = {
      params: {
        ...(start && parseInt(start, 10) ? { start_year: parseInt(start, 10) } : {}),
        ...(end && parseInt(end, 10) ? { end_year: parseInt(end, 10) } : {}),
        dataset,
        ...(sortby && { sortby }),
        ...filters,
        ...(equilizer && sortby && sortby === 'custom' ? { equilizer } : {}),
        language: language || 'english',
        ...(publication_review_status ? { publication_review_status } : {})
      },
      data: {
        query
      }
    }
    return <GenerateListV1 uniqueId='discovery-generate-list' likeParams={{ ...params.params, ...params.data }} loading={this.props.loading} id='search-list-discover' params={params} apiUrl={`${process.env.faceliftApiUrl}v1/search/`} size={size} height='calc(100vh - 75px)' />
  }
}

List.propTypes = {
  loading: PropTypes.bool
}
List.defaultProps = {
  loading: false
}

export default withRouter(List)
