export const sidOpenable = [
  'publications_w',
  'publications',
  'publications_w_congress',
  'springer_publications',
]

export const deepDives = [
  'publications',
  'clinicaltrials',
  'grants',
  'guidelines',
  'socialmedia',
  'patent',
  'thesis',
  'congresses',
  'Pre Clinical',
  'Drug Discovery',
  'Clinical',
  'expression_data'
  // 'publications_w'
]

export const setGroup = {
  springer_publications: 'Partner Data',
  publications_w: 'Partner Data',
  publications_w_congress: 'Partner Data',
  'Pre Clinical': 'Unpublished',
  'Drug Discovery': 'Unpublished',
  Clinical: 'Unpublished',
  expression_data: 'Experimental Data',
  labels: 'Regulatory Data',
  regulatory_decision: 'Regulatory Data',
  regulatory_review: 'Regulatory Data',
  advisory_committee: 'Regulatory Data',
  regulatory_guidance_drugs: 'Regulatory Data',
  regulatory_guidance_general: 'Regulatory Data',
}

export const downloadRequired = [
  'publications',
  'clinicaltrials',
  'grants',
  'guidelines',
  'patent',
  'thesis',
  'congresses',
  'deals',
  'recent_events'
]
