import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { trialExpired } from './logic'
import { getCookie } from '../../utils'

const zeroConvert = number => (number <= 9 ? `0${number}` : number)

class CountDown extends Component {
  constructor (props) {
    super(props)
    const distance = localStorage.getItem('expiry-trial-period') ? parseInt(localStorage.getItem('expiry-trial-period'), 10) : 0
    if (!(getCookie('accessToken') && distance)) {
      localStorage.removeItem('expiry-trial-period')
      this.props.trialExpired()
    }
    this.state = {
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000),
      distance
    }
  }
  componentDidMount () {
    this.a = setInterval(() => {
      const distance = this.state.distance - 1000
      if (distance) {
        this.setState({
          distance,
          hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        }, localStorage.setItem('expiry-trial-period', distance))
      } else {
        this.props.trialExpired()
      }
    }, 1000)
  }
  componentWillUnmount () {
    window.clearInterval(this.a)
  }
  render() {
    // const color = {
    //   color: '#09b0cd'
    // }
    // if (this.state.minutes === 0 && this.state.seconds < 20) {
    //   color.color = 'red'
    // }
    const style = {
      fontSize: 20, background: '#ffffff', padding: '5px 15px', borderRadius: 200, cursor: 'default', textTransform: 'initial', color: '#09b0cd'
    }
    return (
      <div style={style} className='disp-flex vcenter' >
        <div title='Verify your account for further access' style={{ marginRight: 10 }} >
          <div className='text-center' style={{ fontSize: 12 }} ><div>Session expires in</div> <div className='bold margin-small-left' >{zeroConvert(this.state.hours)} : {zeroConvert(this.state.minutes)} : {zeroConvert(this.state.seconds)}</div></div>
          {/* <div style={{ fontSize: 12, marginTop: -5 }} >(Verify your account for further access)</div> */}
        </div>
      </div>
    )
  }
}

CountDown.propTypes = {
  trialExpired: PropTypes.func.isRequired,
}

export default connect(null, { trialExpired })(CountDown)
