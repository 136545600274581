import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

const AccordionHead = () => null

class Accordion extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open
    }
    this.onToggle = this.onToggle.bind(this)
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({
        open: nextProps.open
      })
    }
  }
  onToggle () {
    this.setState({
      open: !this.state.open
    }, () => this.props.onToggle())
  }
  renderBody () {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    )
  }
  renderHeader () {
    if (this.props.header) {
      return (
        <span data-test='accordion-header-container' className={this.props.headerClassName ? `${this.props.headerClassName} ${this.state.open ? 'open' : ''}` : this.state.open ? 'open' : ''} role='presentation' onClick={this.onToggle} >
          {this.props.header}
        </span>
      )
    }
    return null
  }
  render () {
    return (
      <Fragment>
        {this.renderHeader()}
        <div data-test='accordion-body-container' className={this.state.open ? this.props.className : ''} >
          {this.state.open ? this.renderBody() : null}
        </div>
      </Fragment>
    )
  }
}

Accordion.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  header: PropTypes.node,
  onToggle: PropTypes.func,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
}

Accordion.defaultProps = {
  open: false,
  children: null,
  header: <AccordionHead />,
  onToggle: () => null,
  className: 'group-list',
  headerClassName: ''
}

export default Accordion
