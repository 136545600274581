import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils/index'
import { ERROR } from '../../../common/container/Status/logic'


export const STATUS_CANCEL = 'STATUS_CANCEL';
const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
};

// NETWORK PURCHASE LOGIC START

export const PURCHASE_FETCH = 'PURCHASE_FETCH';
export const PURCHASE_FETCH_SUCCESS = 'PURCHASE_FETCH_SUCCESS';
export const PURCHASE_FETCH_FAILURE = 'PURCHASE_FETCH_FAILURE';

export const purchaseFetchAction = payload => ({
  type: PURCHASE_FETCH,
  payload
})

export const purchaseFetchSuccess = payload => ({
  type: PURCHASE_FETCH_SUCCESS,
  payload
});

// ${action.payload}
// const url = 'https://staging.kplexus.net/api/v2/kols/profile/c1d0e361a1794add8cd9cb74a88ebbee/purchase?group=org'
export const purchaseFetchEpic = action$ => action$
  .ofType(PURCHASE_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/3d/documents/request_for_buy/`, 'POST', true, action.payload))
    .map(response => purchaseFetchSuccess(response))
    .catch(error => Observable.of({
      type: PURCHASE_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })));

export function purchaseFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case PURCHASE_FETCH: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case PURCHASE_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case PURCHASE_FETCH_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

