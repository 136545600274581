import { combineEpics } from 'redux-observable'
import { loginEpic, logoutEpic } from './Login/logic'
import { globalFilterDashboardEpic } from './GlobalFilter/logic'
import { listFetchEpic, listBottomFetchEpic, listTopFetchEpic } from './GenerateList/logic'
import { listV1FetchEpic, listV1BottomFetchEpic, listV1TopFetchEpic } from './GenerateListV1/logic'
import { fetchAbstractEpic } from './Abstract/logic'
import { annotationFetchEpic } from './Annotation/logic'
import { viewSearchAutoCompleteEpic } from './ViewSearch/logic'
import { notificationEpic, notificationCountEpic } from './Notification/logic'
import { bookmarkFolderFetchEpic, createBookmarkEpic, deleteBookmarkEpic, editBookmarkEpic, createFolderEpic, deleteFolderEpic } from './BookmarkModal/logic'
import { bibliographyFolderFetchEpic, createBibliographyEpic, deleteBibliographyEpic, editBibliographyEpic, createBibliographyFolderEpic, deleteBibliographyFolderEpic } from './BibliographyModal/logic'
import { equalizerEpic, equalizerSaveEpic } from './Equalizer/logic'
import { tabsNavigationEpic } from './Tabs/logic'
import { groupCommonFetchEpic, groupUpdateFetchEpic, groupCommonFetchV1Epic, groupUpdateFetchV1Epic } from './Groups/logic'
import { editableAutoCompleteCommonEpic } from './EditableSearchCommon/logic'
import { fullAbstractEpic } from './FullAbstract/logic'
import { checkTokenEpic } from './CheckAccessToken/logic'
import { requestDemoEpic } from './RequestDemo/logic'

const epics = {
  globalFilterDashboardEpic,
  loginEpic,
  logoutEpic,
  listFetchEpic,
  listBottomFetchEpic,
  listTopFetchEpic,
  listV1FetchEpic,
  listV1BottomFetchEpic,
  listV1TopFetchEpic,
  fetchAbstractEpic,
  annotationFetchEpic,
  viewSearchAutoCompleteEpic,
  notificationEpic,
  notificationCountEpic,
  bookmarkFolderFetchEpic,
  createBookmarkEpic,
  deleteBookmarkEpic,
  editBookmarkEpic,
  createFolderEpic,
  deleteFolderEpic,
  equalizerEpic,
  equalizerSaveEpic,
  tabsNavigationEpic,
  groupCommonFetchEpic,
  groupUpdateFetchEpic,
  editableAutoCompleteCommonEpic,
  groupCommonFetchV1Epic,
  groupUpdateFetchV1Epic,
  bibliographyFolderFetchEpic,
  createBibliographyEpic,
  deleteBibliographyEpic,
  editBibliographyEpic,
  createBibliographyFolderEpic,
  deleteBibliographyFolderEpic,
  fullAbstractEpic,
  checkTokenEpic,
  requestDemoEpic
}

export const rootEpic = combineEpics(...Object.values(epics))

export default epics
