import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
export const EDITABLE_AUTO_COMPLETE_COMMON = 'EDITABLE_AUTO_COMPLETE_COMMON'
export const EDITABLE_AUTO_COMPLETE_COMMON_SUCCESS = 'EDITABLE_AUTO_COMPLETE_COMMON_SUCCESS'
export const EDITABLE_AUTO_COMPLETE_COMMON_FAILURE = 'EDITABLE_AUTO_COMPLETE_COMMON_FAILURE'
export const EDITABLE_AUTO_COMPLETE_COMMON_CANCEL = 'EDITABLE_AUTO_COMPLETE_COMMON_CANCEL'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

// Auto Complete cancel action
export const editableAutoCompleteCommonCancelAction = () => ({
  type: EDITABLE_AUTO_COMPLETE_COMMON_CANCEL
})

// Auto Complete action
export const editableAutoCompleteCommonAction = payload => ({
  type: EDITABLE_AUTO_COMPLETE_COMMON,
  payload
})

// Auto Complete Success action
export const editableAutoCompleteCommonSuccess = payload => ({
  type: EDITABLE_AUTO_COMPLETE_COMMON_SUCCESS,
  payload
})

// Auto Complete epic
export const editableAutoCompleteCommonEpic = action$ => action$
  .ofType(EDITABLE_AUTO_COMPLETE_COMMON)
  .debounceTime(500)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/autocomplete?${action.payload}`, 'GET'))
    .map(response => editableAutoCompleteCommonSuccess(response))
    .takeUntil(action$.ofType(EDITABLE_AUTO_COMPLETE_COMMON_CANCEL))
    .catch(error => Observable.of({
      type: EDITABLE_AUTO_COMPLETE_COMMON_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Auth reducer updates both editableAutoCompleteCommon and logout
export function editableAutoCompleteCommonReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case EDITABLE_AUTO_COMPLETE_COMMON: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case EDITABLE_AUTO_COMPLETE_COMMON_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data.length ? action.payload.response.data[0] : {},
        loading: false,
        error: false,
        flag: true
      }
    }
    case EDITABLE_AUTO_COMPLETE_COMMON_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
