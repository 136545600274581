export const CREATE_BOOKMARK_SUCCESS = 'CREATE_BOOKMARK_SUCCESS'
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS'

const INITIAL_STATE = {
  id: '',
  status: false
}

export const createBookmarkSuccessAction = payload => ({
  type: CREATE_BOOKMARK_SUCCESS,
  payload
})

export const deleteBookmarkSuccessAction = payload => ({
  type: DELETE_BOOKMARK_SUCCESS,
  payload
})

export function bookmarkReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case CREATE_BOOKMARK_SUCCESS: {
      return {
        id: action.payload.response.data.doc_id,
        status: true
      }
    }
    case DELETE_BOOKMARK_SUCCESS: {
      return {
        id: action.payload.response.data.doc_id,
        status: false
      }
    }
    default:
      return state
  }
}
