import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

class BubbleChart extends Component {
  constructor(props) {
    super(props)
    this.marginLeft = 140
    this.marginRight = 50
    this.marginTop = 50
    this.marginBottom = this.props.labelX ? 100 : 50
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
  }
  componentWillMount() {
    this.x = d3.scaleBand()
    this.y = d3.scaleBand()
    this.radius = d3.scaleLinear()

    const xDom = []
    const rDom = []
    const yDom = []

    Object.keys(this.props.data).forEach((element) => {
      this.props.data[element].forEach((d) => {
        xDom.push(Number(d.category) ? Number(d.category) : d.category)
        rDom.push(Number(d.value))
      })
      yDom.push(element)
    })
    if (this.props.sort) {
      xDom.sort()
    }
    this.xDom = []
    xDom.forEach((d) => {
      if (this.xDom.indexOf(d) === -1) {
        this.xDom.push(d)
      }
    })
    this.yDom = yDom
    this.x.domain(xDom)
      .range([0, this.width])
    this.y.domain(yDom)
      .range([this.height, 0])
    const radiusRange = Math.min(this.y.bandwidth(), this.x.bandwidth())
    this.radius.domain(d3.extent(rDom))
      .range([4, (radiusRange / 2.2)])
  }
  componentDidMount() {
    this.renderAxis()
    const ynode = `#yaxis_${this.props.id}`
    const xnode = `#xaxis_${this.props.id}`
    const self = this
    d3.select(ynode)
      .selectAll('.tick text')
      .attr('id', (d, i) => `innovation-timeline-bar-id-${this.props.id}-${i}`)
      .call(self.wrapLabel, self)
    d3.select(ynode)
      .selectAll('.tick text')
      .append('title')
      .text(d => d)
    d3.select(xnode)
      .selectAll('.tick text')
      .append('title')
      .text(d => d)
    d3.select(xnode)
      .selectAll('.tick text')
      .attr('text-anchor', () => {
        if (d3.select(xnode).selectAll('.tick text')._groups[0].length > 15) {
          return 'end'
        }
        return 'middle'
      })
      .attr('transform', () => {
        if (d3.select(xnode).selectAll('.tick text')._groups[0].length > 15) {
          return `translate(-${(this.x.bandwidth() / (7 / (this.x.domain().length / 10)))}, 8)rotate(-90)`
          // return `rotate(-90)translate(-8, -${(this.x.bandwidth() / 2) * (0.5) * (15 / this.x.domain().length)})`
        }
        return ''
      })
    Object.keys(this.props.data).forEach((ele, i) => {
      this.props.data[ele].forEach((d, j) => {
        d3.select(`#bubble_${i}_${j}_${this.props.id}`)
          .on('mouseover', () => {
            const tbox = d3.event.target.getBBox()
            const {
              x, height, y
            } = tbox
            const matrix = d3.event.target.getScreenCTM()
            const point = document.getElementById(`graph-${this.props.id}`).createSVGPoint()
            const bbox = {}
            point.x = x
            point.y = y
            point.y -= height / 2
            bbox.n = point.matrixTransform(matrix)
            point.y += height
            bbox.s = point.matrixTransform(matrix)
            // const node = d3.select(`.graph-tooltip-${this.props.id}`).node()
            // const content = `<div className='graph-tooltip-content'>
            //   <div className='disp-flex header'>${ele} (${d.category})</div>
            //   <div className='sub-content'><span>Count : </span> ${d.value}</div>
            // </div>
            // `
            // console.log(width, node, content)
          })
      })
    })
  }
  wrapLabel = (text1, self) => {
    // console.log(text1, self)
    text1.each((d, i) => {
      const text = d3.select(`#innovation-timeline-bar-id-${self.props.id}-${i}`)
      // console.log(text._groups[0])
      if (text._groups[0][0] !== null) {
        const words = text.text()
        const len = words.length - words.replace(/[A-Z]/g, '').length;
        const limit = len > 5 ? 9 : 15
        if (words.length > limit) {
          text.text(`${words.substring(0, limit)}..`)
        }
      }
    })
  }
  renderAxis() {
    const xnode = `#xaxis_${this.props.id}`
    const xaxis = d3.axisBottom(this.x)
    d3.select(xnode).call(xaxis)
    const ynode = `#yaxis_${this.props.id}`
    const yaxis = d3.axisLeft(this.y)
    d3.select(ynode).call(yaxis)
  }
  renderCircle() {
    return Object.keys(this.props.data).map((ele, i) => this.props.data[ele].map((d, j) => {
      if (d.value > 0) {
        return (
          <g
            key={`${i}_${j}`}
            transform={`translate(${this.x(d.category) + (this.x.bandwidth() / 2)}, ${this.y(ele) + (this.y.bandwidth() / 2)})`}
          >
            <circle
              r={this.radius(d.value)}
              fill={typeof this.props.color === 'string' ? this.props.color : this.props.color[i]}
              stroke={typeof this.props.border === 'string' ? this.props.border : this.props.border[i]}
              opacity='0.8'
              strokeOpacity='1'
              strokeWidth='1px'
              className={`bubble-chart-${this.props.id} handicon`}
              id={`bubble_${i}_${j}_${this.props.id}`}
              onClick={() => {
                const obj = {}
                obj[this.props.labelY] = this.yDom
                obj[this.props.labelX] = this.xDom
                // obj[`Selected${this.props.labelX}`] = d.category
                // obj[`Selected${this.props.labelY}`] = ele
                obj.x_axis_filter = d.category
                obj.y_axis_filter = ele
                obj.x_axis = this.props.labelX
                obj.y_axis = this.props.labelY
                obj.graph_type = 'bubble_graph'
                this.props.redirect(obj)
                if (this.props.yaxis === 'standardized_assignee.normalize_assignee') {
                  this.props.search({
                    search_applicant: ele,
                    search_start_date: new Date(d.category, 0, 1),
                    search_end_date: new Date(d.category, 11, 31)
                  })
                } else if (this.props.yaxis === 'inventors') {
                  this.props.search({
                    search_start_date: new Date(d.category, 0, 1),
                    search_end_date: new Date(d.category, 11, 31),
                    search_inventor: ele
                  })
                }
              }}
              onMouseOver={(e) => {
                const svg = document.getElementById(`graph-${this.props.id}`)
                const p = svg.createSVGPoint()
                p.x = e.clientX
                p.y = e.clientY
                const svgp = p.matrixTransform(svg.getScreenCTM().inverse())
                const content = `<div className='graph-tooltip-content'>
                      <div className='disp-flex header'>${ele} (${d.category})</div>
                      <div className='sub-content'><span>Count : </span> ${d.value}</div>
                    </div>
                    `
                d3.select(`.graph-tooltip-${this.props.id}`)
                  .html(content)
                const node = d3.select(`#graph-tooltip-id-${this.props.id}`).node()
                d3.select(`.graph-tooltip-${this.props.id}`)
                  .transition()
                  .duration(200)
                  .style('visibility', 'visible')
                  .style('left', `${svgp.x - (node.offsetWidth / 2)}px`)
                  .style('top', `${svgp.y - (node.offsetHeight * 1.2)}px`)
              }}
              onFocus={() => null}
              onMouseOut={() => {
                d3.select(`.graph-tooltip-${this.props.id}`)
                  .transition()
                  .duration(200)
                  .style('visibility', 'hidden')
              }}
              onBlur={() => null}
            />
          </g>
        )
      }
      return null
    }))
  }
  renderGrid() {
    return this.y.domain().map((d, i) => (
      <line
        key={i}
        x1={0}
        x2={this.width}
        y1={this.y(d)}
        y2={this.y(d)}
        className='graph-grid'
      />
    ))
  }
  renderLabelX() {
    const yHeightOffset = this.x.domain().length > 15 ? 30 : 0
    return (
      <text
        transform={`translate(${this.width / 2}, ${this.height + yHeightOffset + (this.marginBottom / 2)})`}
        textAnchor='middle'
        className='graph-font-family-labels capitalize-me'
      >
        {this.props.labelX}
      </text>
    )
  }
  renderLabelY() {
    return (
      <text
        transform={`translate(${-this.marginLeft / 1.2}, ${this.height / 2})rotate(-90)`}
        textAnchor='middle'
        className='graph-font-family-labels capitalize-me'
      >
        {this.props.labelY}
      </text>
    )
  }
  render() {
    return (
      <g
        transform={`translate(${this.marginLeft}, ${this.marginTop})`}
      >
        <g id={`xaxis_${this.props.id}`} className='x axis' transform={`translate(0, ${this.height})`} />
        <g id={`yaxis_${this.props.id}`} className='y axis' />
        {this.renderGrid()}
        {this.renderCircle()}
        {this.props.labelX ? this.renderLabelX() : null}
        {this.props.labelY ? this.renderLabelY() : null}
      </g>
    )
  }
}

BubbleChart.propTypes = {
  labelX: PropTypes.string.isRequired,
  labelY: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  yaxis: PropTypes.string.isRequired,
  search: PropTypes.func,
  color: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  border: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  redirect: PropTypes.func,
  sort: PropTypes.bool,
}
BubbleChart.defaultProps = {
  search: () => null,
  color: '#000',
  border: '#000',
  redirect: null,
  sort: true
}

export default BubbleChart
