
export const ARTICLE_FEEDBACK_SUCCESS = 'ARTICLE_FEEDBACK_SUCCESS'
export const ARTICLE_FEEDBACK_FAILURE = 'ARTICLE_FEEDBACK_FAILURE'

export const articleFeedbackSuccess = () => ({
  type: ARTICLE_FEEDBACK_SUCCESS
})

export const articleFeedbackFailure = () => ({
  type: ARTICLE_FEEDBACK_FAILURE
})
