import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ucFirst } from '../../utils'

class CheckboxGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: this.props.active
    }
    this.onChange = this.onChange.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.props.active) {
      this.setState({
        active: nextProps.active
      })
    }
  }
  onChange(event) {
    const { active } = JSON.parse(JSON.stringify(this.state))
    if (event.target.checked) {
      active.push(event.target.name)
    } else {
      active.splice(active.indexOf(event.target.name), 1)
    }
    const i = active.map(item => this.props.options.indexOf(item))
    this.props.onChange(active, event, i)
  }
  renderOptions() {
    return this.props.options.map((item, i) => {
      if (typeof (item) === 'object') {
        if (item === null) {
          return null
        }
        const { keys, values, valueSeperator } = this.props
        const value = values.length > 1 ? values.map(a => item[a]).join(valueSeperator) : item[values.length ? values[0] : keys[0]]
        return (
          <li data-test='reusable-checkbox-item' key={i} className={this.props.inline ? 'inline reusable-checkbox-item' : 'reusable-checkbox-item'} >
            <input data-test='reusable-checkbox-item-input' id={`${this.props.id}_${i}`} name={value} type='checkbox' checked={this.state.active.indexOf(value) > -1} onChange={this.onChange} />
            <label htmlFor={`${this.props.id}_${i}`} className='label' >
              {this.props.serial && <span data-test='reusable-checkbox-item-serial' >{i + 1}. </span>}<span>{this.props.capitalize ? ucFirst(item[keys[0]]) : item[keys[0]]}</span> {!!keys[1] && !!item[keys[1]] && <span>({item[keys[1]]})</span>}
            </label>
          </li>
        )
      }
      return (
        <li data-test='reusable-checkbox-item' key={i} className={this.props.inline ? 'inline reusable-checkbox-item' : 'reusable-checkbox-item'} >
          <input data-test='reusable-checkbox-item-input' id={`${this.props.id}_${i}`} name={item} type='checkbox' checked={this.state.active.indexOf(item) > -1} onChange={this.onChange} />
          <label htmlFor={`${this.props.id}_${i}`} className='label' >
            {this.props.serial && <span data-test='reusable-checkbox-item-serial' >{i + 1}. </span>}<span>{this.props.capitalize ? ucFirst(item) : item}</span>
          </label>
        </li>
      )
    })
  }
  render() {
    return (
      <ul data-test='reusable-checkbox' className='reusable-checkbox scrollbar' >
        {this.renderOptions()}
      </ul>
    )
  }
}

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  active: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  keys: PropTypes.array,
  values: PropTypes.array,
  valueSeperator: PropTypes.string,
  serial: PropTypes.bool,
  capitalize: PropTypes.bool
}

CheckboxGroup.defaultProps = {
  inline: false,
  keys: ['name'],
  values: [],
  serial: false,
  valueSeperator: '',
  capitalize: false
}

export default CheckboxGroup
