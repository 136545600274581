import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import * as cloud from 'd3-cloud'

class WordCloud extends Component {
  constructor (props) {
    super(props)
    this.state = {
      active: this.props.center
    }
    this.marginLeft = 100
    this.marginRight = 50
    this.marginTop = 10
    this.marginBottom = 10
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
  }
  componentWillMount () {
    this.word = {}
    this.props.data.forEach((d) => {
      this.word[d.name] = d.count
    })
    this.color = d3.scaleOrdinal(d3.schemeCategory20);
    this.wordEntries = d3.entries(this.word)
    this.xScale = d3.scaleLinear()
      .domain([0, d3.max(this.wordEntries, d => d.value)])
      .range([10, 50])
    this.layout = cloud().size([this.width, this.height])
      .timeInterval(10)
      .words(this.wordEntries)
      .rotate(() => 0)
      .fontSize(d => this.xScale(+d.value))
      .text(d => d.key)
      .font('Impact')
      .on('end', this.draw)
      .start();
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.center !== this.props.center) {
      this.setState({
        active: nextProps.center
      })
    }
  }
  draw = () => {
    this.wordEntries.forEach((d, i) => {
      d3.select(`#id_${this.props.id}_${i}`)
        .attr('transform', `translate(${d.x},${d.y})rotate(${d.rotate})`)
    })
  }
  renderText () {
    return this.wordEntries.map((d, i) => (
      <text
        key={i}
        className='handicon'
        fontSize={`${this.xScale(d.value)}px`}
        fill={this.state.active === d.key ? '#5593fb' : '#b4b4b4'}
        textAnchor='middle'
        fontFamily='lato-regular'
        id={`id_${this.props.id}_${i}`}
        onClick={() => {
            this.setState({
              active: d.key
            }, () => {
              this.props.showNetwork(d.key)
            })
          }}
      >
        { d.key }
      </text>
    ))
  }
  render () {
    return (
      <g
        transform={`translate(${this.width / 2}, ${this.height / 2})`}
      >
        { this.renderText() }
      </g>
    )
  }
}

WordCloud.propTypes = {
  showNetwork: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  center: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
}
WordCloud.defaultProps = {
  // data: [],
}
export default WordCloud
