import React from 'react'
import PropTypes from 'prop-types'

const SpecialLoader = ({ height }) => (
  <div style={{ height, background: '#ffffff' }} className='big-loader relative'>
    <div className='spinner'>
      <div className='bounce1' />
      <div className='bounce2' />
      <div className='bounce3' />
      <div className="loading-text">Loading {process.env.dashboard ? 'Dashboard' : 'Discover'}...</div>
    </div>
  </div>
)

SpecialLoader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SpecialLoader.defaultProps = {
  height: '100vh'
}

export default SpecialLoader
