import React, { Component, Fragment } from 'react'

class MarvinJs extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <Fragment>
        <iframe
          title='marvineditor'
          id="sketch"
          src="https://staging.search.ontosight.com/marvin/editorws.html"
          style={{
            overflow: 'hidden',
            minWidth: '760px',
            minHeight: '450px',
            border: '1px solid darkgray'
          }}
        />
      </Fragment>
    )
  }
}

export default MarvinJs
