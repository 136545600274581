import React from 'react'
import PropTypes from 'prop-types'
import PublicationList from './publication'
import CtList from './ct'
import Grant from './grants'
import RecentEvents from './recentEvents'
import GuideLines from './guidelines'
import SocialMedia from './socialmedia'
import Patent from './patent'
import Thesis from './thesis'
import Congresses from './congresses'
import ThreeDIntegration from './threeDIntegration'
import PublicationWileyList from './publicationWiley'
import Others from './others'
import GuideLinesNotify from './guidelinesNotify'
import Societies from './societies'
import Drugs from './drugs'
import DrugList from './druglist'
import DrugListNew from './druglistnew'
import RegulatoryUpdates from './regulatoryUpdates'
import Deals from './deals'
import MoleculeStructures from './molecules'
import CongressesWiley from './congressesWiley'
import ExpressionData from './expressionData'
import Labels from './labels'
import RegulatoryDecision from './regulatoryDecision'
import RegulatoryReview from './regulatoryReview'
import AdvisoryCommittee from './advisoryCommittee'
import RegulatoryGuidanceDrugs from './regulatoryGuidanceDrugs'
import RegulatoryGuidanceGeneral from './regulatoryGuidanceGeneral'
import EmaRegulatoryGuidanceDrugs from './emaRegulatoryGuidanceDrugs'

const ListType = (props) => {
  switch (props.type) {
    case 'publications':
      return <PublicationList {...props} />;
    case 'clinicaltrials':
      return <CtList {...props} />;
    case 'grants':
      return <Grant {...props} />;
    case 'recent_events':
      return <RecentEvents {...props} />;
    case 'guidelines':
      return <GuideLines {...props} />;
    case 'socialmedia':
      return <SocialMedia {...props} />;
    case 'patent':
      return <Patent {...props} />;
    case 'thesis':
      return <Thesis {...props} />;
    case 'congresses':
      return <Congresses {...props} />;
    case 'publications_w_congress':
      return <CongressesWiley {...props} />;
    case 'Pre Clinical':
      return <ThreeDIntegration {...props} />;
    case 'Drug Discovery':
      return <ThreeDIntegration {...props} />;
    case 'Clinical':
      return <ThreeDIntegration {...props} />;
    case 'publications_w':
      return <PublicationWileyList {...props} />;
    case 'springer_publications':
      return <PublicationWileyList {...props} />;
    case 'guidelines_notify':
      return <GuideLinesNotify {...props} />;
    case 'society':
      return <Societies {...props} />;
    case 'drugs':
      return <Drugs {...props} />
    case 'drug-list':
      return <DrugList {...props} />
    case 'drug-list-new':
      return <DrugListNew {...props} />
    case 'deals':
      return <Deals {...props} />
    case 'regulatory_updates':
      return <RegulatoryUpdates {...props} />
    case 'molecules':
      return <MoleculeStructures {...props} />
    case 'expression_data':
      return <ExpressionData {...props} />
    case 'labels':
      return <Labels {...props} />
    case 'regulatory_decision':
      return <RegulatoryDecision {...props} />
    case 'regulatory_review':
      return <RegulatoryReview {...props} />
    case 'advisory_committee':
      return <AdvisoryCommittee {...props} />
    case 'ema_regulatory_guidance_drugs':
      return <EmaRegulatoryGuidanceDrugs {...props} />
    case 'regulatory_guidance_drugs':
      return <RegulatoryGuidanceDrugs {...props} />
    case 'regulatory_guidance_general':
      return <RegulatoryGuidanceGeneral {...props} />
    default:
      return <Others {...props} />;
  }
};

ListType.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}
ListType.defaultProps = {}

export default ListType;
