import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ClickOutside } from 'reusable-react-components'

class Custom extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }
  onCustomClick = (item) => {
    this.setState({
      open: false
    }, () => this.props.onCustomClick(item))
  }
  outsideClick = () => {
    this.setState({
      open: false
    })
  }
  renderOptions () {
    return this.props.options.map((item, i) => {
      if (item.name !== 'Custom') {
        return (
          <div role='presentation' onClick={() => this.onCustomClick(item)} className='s-dp-special-dropdown-container-list-item' key={i} >{item.name}</div>
        )
      }
      return null
    })
  }
  render() {
    return (
      <ClickOutside onClickOutside={e => this.outsideClick(e)}>
        <div className='s-dp-special-dropdown' >
          {this.props.active && this.props.active.name &&
          <div role='presentation' onClick={() => this.setState({ open: !this.state.open })} className='s-dp-special-dropdown-box' >
            {this.props.active.name}
          </div>}
          {
            this.state.open && (
              <div className='s-dp-special-dropdown-container' >
                <div className='s-dp-special-dropdown-container-list' >
                  {this.renderOptions()}
                </div>
              </div>
            )
          }
        </div>
      </ClickOutside>
    )
  }
}
Custom.propTypes = {
  options: PropTypes.array,
  onCustomClick: PropTypes.func,
  active: PropTypes.object.isRequired,
}
Custom.defaultProps = {
  options: [],
  onCustomClick: () => null
}

export default Custom
