import { combineEpics } from 'redux-observable'
import common from '../../common/container/epics'
import { autoCompleteEpic } from './SearchBar/logic'
import { groupFetchEpic, groupUpdateFetchEpic } from './Groups/logic'
import { productFetchEpic } from './Products/logic'
import { sponsorFetchEpic } from './Sponsors/logic'
import { hookFetchEpic } from './Hooks/logic'
import { filterConfigEpic, filterEpic, filterSearchEpic } from './FilterConfig/logic'
import { initialQueryFormatEpic } from './AdvancedSearch/logic'
import { advancedSearchAutoCompleteEpic } from './CategoryFilter/logic'
import { wordcloudFetchEpic } from './Concepts/logic'
import { getSubGraphEpic } from './WordCloud/logic'
import { sortByFetchEpic } from './SortBy/logic'
import { trendsEpic } from './Trends/logic'
import { purchaseFetchEpic } from './Purchase/logic'
import { searchTreeFetchEpic, searchTreeUpdateFetchEpic } from './SearchTree/logic'
import { wordcloudHookFetchEpic } from './ViewDetails/logic'
import { saveClusterFetchEpic } from './SaveAsCluster/logic'
import { rawdataListEpic, rawdataListMoreEpic, rawdataCountEpic, rawdataInnerEpic, rawdataInnerMoreEpic } from './RawDataResults/logic'
import { downloadEpic } from './Download/logic'
import { moaEpic, moaDrugsEpic } from './MOA/logic'
import { searchEqualizerEpic } from './SearchEqualizer/logic'
import { editableAutoCompleteEpic, createHistoryEpic } from './EditableSearch/logic'
import { advancedEditableAutoCompleteEpic } from './AdvanceSearchCategory/logic'
import { summaryFetchEpic } from './Summary/logic'
import { graphDataEpic } from './summaryGraph/logic'

const rootEpic = combineEpics(
  ...Object.values(common),
  autoCompleteEpic,
  groupFetchEpic,
  groupUpdateFetchEpic,
  createHistoryEpic,
  productFetchEpic,
  sponsorFetchEpic,
  searchTreeFetchEpic,
  searchTreeUpdateFetchEpic,
  hookFetchEpic,
  filterConfigEpic,
  filterEpic,
  filterSearchEpic,
  initialQueryFormatEpic,
  advancedSearchAutoCompleteEpic,
  wordcloudFetchEpic,
  wordcloudHookFetchEpic,
  getSubGraphEpic,
  sortByFetchEpic,
  purchaseFetchEpic,
  trendsEpic,
  saveClusterFetchEpic,
  rawdataListEpic,
  rawdataListMoreEpic,
  rawdataInnerEpic,
  rawdataInnerMoreEpic,
  downloadEpic,
  rawdataCountEpic,
  moaEpic,
  moaDrugsEpic,
  searchEqualizerEpic,
  editableAutoCompleteEpic,
  advancedEditableAutoCompleteEpic,
  summaryFetchEpic,
  graphDataEpic
)

export default rootEpic
