import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { graphColor } from '../../utils'

class BarGraph extends Component {
  constructor (props) {
    super(props)
    this.marginLeft = 100
    this.marginRight = 50
    this.marginTop = 50
    this.marginBottom = this.props.labelX ? 100 : 50
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
  }
  componentWillMount () {
    this.x = d3.scaleBand()
    this.y = d3.scaleLinear()

    const xDom = []
    const yDom = []
    this.props.data.forEach((d) => {
      xDom.push(d.category)
      yDom.push(Number(d.value))
    })

    this.x.domain(xDom)
      .range([0, this.width])
    this.y.domain([0, d3.max(yDom)])
      .range([this.height, 0])
    const offset = this.y.ticks()[2] - this.y.ticks()[0]
    this.y.domain([this.y.domain()[0], this.y.domain()[1] + offset])
    // console.log(this.x.domain())
  }
  componentDidMount () {
    this.renderAxis()
    // const self = this
    const xnode = `#xaxis_${this.props.id}`
    d3.select(xnode)
      .selectAll('.tick text')
      .attr('id', (d, i) => `bar-id-${this.props.id}-${i}`)
      .attr('text-anchor', 'middle')
      // .call(self.wrapLabel, 60, self)
  }
  wrapLabel = (text1, width, self) => {
    text1.each((i) => {
      const text = d3.select(`#bar-id-${self.props.id}-${i}`)
      const words = text.text().trim().split(/\s+/).reverse()
      let word = words.pop()
      let line = []
      const lineNumber = 0
      const lineHeight = 1.1 // ems
      const y1 = text.attr('y')
      const dy = parseFloat(text.attr('dy'))
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y1)
        .attr('dy', `${dy}em`)
      while (word) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan.node().getComputedTextLength() > width) {
          line.pop()
          tspan.text(line.join(' '))
          line = [word]
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y1)
            .attr('dy', `${((1 + lineNumber) * lineHeight) + dy}em`)
            .text(word)
        }
        word = words.pop()
      }
    })
  }
  renderAxis () {
    const xnode = `#xaxis_${this.props.id}`
    const xaxis = d3.axisBottom(this.x)
    d3.select(xnode).call(xaxis)
    const ynode = `#yaxis_${this.props.id}`
    const yaxis = d3.axisLeft(this.y).tickSizeOuter(-this.width)
    d3.select(ynode).call(yaxis)
  }
  renderGraph () {
    return this.props.data.map((d, i) => (
      <rect
        key={i}
        x={this.x(d.category) + ((this.x.bandwidth() - Math.min(this.x.bandwidth(), 20)) / 2)}
        y={this.y(d.value)}
        height={this.height - this.y(d.value)}
        width={Math.min(this.x.bandwidth(), 20)}
        fill={graphColor[0]}
      />
    ))
  }
  renderLabelX () {
    return (
      <text
        transform={`translate(${this.width / 2}, ${this.height + (this.marginBottom / 1.2)})`}
        textAnchor='middle'
        className='graph-font-family-labels capitalize-me'
      >
        {this.props.labelX}
      </text>
    )
  }
  renderLabelY () {
    return (
      <text
        transform={`translate(${-(this.marginLeft + 20) / 2}, ${this.height / 2})rotate(-90)`}
        textAnchor='middle'
        className='graph-font-family-labels capitalize-me'
      >
        {this.props.labelY}
      </text>
    )
  }
  render () {
    return (
      <svg className='dashboard-graphs' width={this.props.width} height={this.props.height} >
        <g
          transform={`translate(${this.marginLeft}, ${this.marginTop})`}
        >
          <g id={`xaxis_${this.props.id}`} className='x axis' transform={`translate(0, ${this.height})`} />
          <g id={`yaxis_${this.props.id}`} className='y axis' />
          { this.renderGraph() }
          { this.props.labelX ? this.renderLabelX() : null}
          { this.props.labelY ? this.renderLabelY() : null}
        </g>
      </svg>
    )
  }
}

BarGraph.propTypes = {
  labelX: PropTypes.string.isRequired,
  labelY: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}
BarGraph.defaultProps = {
}

export default BarGraph
