import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const INITIAL_QUERY_FORMAT = 'INITIAL_QUERY_FORMAT'
export const INITIAL_QUERY_FORMAT_SUCCESS = 'INITIAL_QUERY_FORMAT_SUCCESS'
export const INITIAL_QUERY_FORMAT_FAILURE = 'INITIAL_QUERY_FORMAT_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const initialQueryFormatAction = payload => ({
  type: INITIAL_QUERY_FORMAT,
  payload
})

export const initialQueryFormatSuccess = payload => ({
  type: INITIAL_QUERY_FORMAT_SUCCESS,
  payload
})

export const initialQueryFormatEpic = action$ => action$
  .ofType(INITIAL_QUERY_FORMAT)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/tree?size=10&dataset=publications&query=${action.payload}`, 'GET'))
    .map(response => initialQueryFormatSuccess(response))
    .catch(error => Observable.of({
      type: INITIAL_QUERY_FORMAT_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function initialQueryFormatReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case INITIAL_QUERY_FORMAT: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case INITIAL_QUERY_FORMAT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case INITIAL_QUERY_FORMAT_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
