export const CREATE_BIBLIOGRAPHY_SUCCESS = 'CREATE_BIBLIOGRAPHY_SUCCESS'
export const DELETE_BIBLIOGRAPHY_SUCCESS = 'DELETE_BIBLIOGRAPHY_SUCCESS'

const INITIAL_STATE = {
  id: '',
  status: false
}

export const createBibliographySuccessAction = payload => ({
  type: CREATE_BIBLIOGRAPHY_SUCCESS,
  payload
})

export const deleteBibliographySuccessAction = payload => ({
  type: DELETE_BIBLIOGRAPHY_SUCCESS,
  payload
})

export function bibliographyReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case CREATE_BIBLIOGRAPHY_SUCCESS: {
      return {
        id: action.payload.response.data.doc_id,
        status: true
      }
    }
    case DELETE_BIBLIOGRAPHY_SUCCESS: {
      return {
        id: action.payload.response.data.doc_id,
        status: false
      }
    }
    default:
      return state
  }
}
