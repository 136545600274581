import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  className: PropTypes.string,
  LabelOffText: PropTypes.string,
  LabelOnText: PropTypes.string,
  onDisabledClick: PropTypes.func,
};

const defaultProps = {
  enabled: true,
  className: '',
  LabelOffText: '',
  LabelOnText: '',
  onDisabledClick: () => null
};

function Switch({
  on, onClick, enabled, className, LabelOffText, LabelOnText, onDisabledClick
}) {
  const classes = ['switch-wrap', className, (on ? 'on ' : ''), (enabled ? '' : 'disabled ')].join(' ');
  return (
    <div role='presentation' className={classes} onClick={e => (enabled ? onClick(e) : onDisabledClick(e))}>
      <span className='label-off'>{LabelOffText}</span>
      <div className='switch'>
        <div className="switch-toggle" />
      </div>
      <span className='label-on'>{LabelOnText}</span>
    </div>
  );
}

Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;

export default Switch;
