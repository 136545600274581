import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
// import ReactHtmlParser from 'react-html-parser'
import GenerateListV1 from '../../container/GenerateListV1'
import { ClickableDOI, Accordion } from '../../components'
import { checkPermission } from '../../utils'

class Publication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.openAccordion
    }
  }
  render() {
    const citation = this.props.list && this.props.list['Citation count'] ? this.props.list['Citation count'] : 0
    // const abstract = this.props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
    const params = {
      data: {},
      params: {
        dataset: this.props.type,
        doc_id: this.props.id
      }
    }
    // {this.props.data.Abstract
    //   ? (
    //     <div className='card-body-content'>
    //       <div className='sub-title'><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
    //       {/* <div data-test='publication-abstract' id='annotation-highlight-1' >{ReactHtmlParser(abstract)}</div> */}
    //     </div>
    //   ) : null}
    return (
      <Fragment>
        {/* <div className='card-body-content'>
          <div className='bold' >This is a placeholder for user message.</div>
        </div> */}
        <div className='card-body-content disp-flex' >
          {this.props.data['Publication ID'] ? <Fragment><div data-test='pmid' className='pmid'><div className='sub-title'><FormattedMessage id="PMID" defaultMessage="PMID" /></div><div className='sub-text'> {this.props.data['Publication ID']} </div></div> </Fragment> : null}
          <ClickableDOI type='publications' {...this.props} />
        </div>
        {
          checkPermission('citation_index') && !!citation && (
            <div className='card-body-content' >
              <div id='abstract-citation-list' role='presentation' onClick={() => this.setState({ open: !this.state.open })} className={this.state.open ? 'abstract-accordion-header active sub-title' : 'abstract-accordion-header sub-title'}><FormattedMessage id="Cited by" defaultMessage='Cited by' />&nbsp;({citation})</div>
              <Accordion open={this.state.open} className='abstract-accordion-body' >
                {this.props.id && <GenerateListV1 openIndependent takeFeedback={false} paginate uniqueId='abstract-citation-list' apiType='GET' loading={false} toId='abstract-citation-list' id='abstract-modal-id-special' params={params} apiUrl={`${process.env.faceliftApiUrl}v1/citation/getList/`} size={10} height={500} />}
              </Accordion>
            </div>
          )
        }
      </Fragment>
    )
  }
}


Publication.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  list: PropTypes.object,
  openAccordion: PropTypes.bool,
}

Publication.defaultProps = {
  type: 'publications',
  list: {},
  openAccordion: false
}

export default Publication
