import React from 'react'

const SearchExamples = () => (
  <div className='user-educate' >
    <h3 className='title'> Search Samples</h3>
    <div className='user-educate-blk'>
      <div className='txt'>
        Multiple concepts stringed by<span>and/not</span>,help to specify the context in more detail
      </div>
      <div className='query'>
      metformin <span className='txt1'>and</span> breast cancer <span className='txt1'>not</span> Diabetes Mellitus
      </div>
    </div>
    <div className='user-educate-blk'>
      <div className='txt'>
      Author Searches using <span>@</span>, help to retreive all publications, trials, congresses etc. involvements of an author
      </div>
      <div className='query'>
        <span className='name'>@Dmitry <span className='txt1'>and</span> breast cancer</span> <span className='name'>@Elena-Strekalova <span className='txt1'>and</span> breast cancer</span> <span className='name'>@Elena-Strekalova</span>
      </div>
    </div>
    <div className='user-educate-blk'>
      <div className='txt'>
      Exact Match Searches using <span>“ “</span> are good for Title matches and exact concept lookouts
      </div>
      <div className='query'>
        “Metformin sensitizes triple-negative breast cancer”
      </div>
    </div>
  </div>
)

export default SearchExamples
