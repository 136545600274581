import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

class NetworkGraph extends Component {
  constructor(props) {
    super(props)
    this.simulation = d3.forceSimulation()
      .force('link', d3.forceLink().id(d => d.id))
      .force('charge', d3.forceManyBody().strength(-100))
      .force('collide', d3.forceCollide(30))
      .force('center', d3.forceCenter(this.props.width / 2, this.props.height / 2))
  }
  ticked = () => {
    const radius = 15
    this.props.data.nodes.forEach((d, i) => {
      d3.select(`#node_${d.id}`)
        .attr('cx', () => {
          if (i === 0) {
            d.x = this.props.width / 2
          }
          d.x = Math.max(radius, Math.min(this.props.width - radius, d.x));
          return d.x
        })
        .attr('cy', () => {
          if (i === 0) {
            d.y = this.props.height / 2
          }
          d.y = Math.max(radius, Math.min(this.props.height - radius, d.y));
          return d.y
        })
      d3.select(`#label_${d.id}`)
        .attr('x', () => {
          if (i === 0) {
            d.x = this.props.width / 2
          }
          return d.x
        })
        .attr('y', () => {
          if (i === 0) {
            d.y = this.props.height / 2
          }
          return d.y
        })
    })
    this.props.data.links.forEach((d, i) => {
      d3.select(`#links_${i}`)
        .attr('x1', d.source.x)
        .attr('y1', d.source.y)
        .attr('x2', d.target.x)
        .attr('y2', d.target.y)
    })
  }
  simulate() {
    this.simulation.nodes(this.props.data.nodes)
    this.simulation.force('link')
      .links(this.props.data.links)
    this.simulation.on('tick', this.ticked)
  }
  renderLine() {
    return this.props.data.links.map((d, i) => (
      <line
        id={`links_${i}`}
        className='links'
        key={i}
        stroke='#ccc'
        strokeWidth={1}
        opacity={0.3}
      />
    ))
  }

  renderLinks() {
    return (
      <g>
        {this.renderLine()}
      </g>
    )
  }

  renderNode() {
    return this.props.data.nodes.map((d, i) => (
      <g
        key={i}
        onClick={() => {
          if (this.props.yaxis === 'standardized_assignee.normalize_assignee') {
            this.props.search({
              search_applicant: d.node
            })
          } else if (this.props.yaxis === 'inventors') {
            this.props.search({
              search_inventor: d.node
            })
          }
        }}
        onMouseOver={(e) => {
          const svg = document.getElementById(`graph-${this.props.id}`)
          const p = svg.createSVGPoint()
          p.x = e.clientX
          p.y = e.clientY
          const svgp = p.matrixTransform(svg.getScreenCTM().inverse())
          const content = `<div className='graph-tooltip-content'>
              <div className='disp-flex header'>${d.node})</div>
            </div>
            `
          d3.select(`.graph-tooltip-${this.props.id}`)
            .html(content)
          const node = d3.select(`#graph-tooltip-id-${this.props.id}`).node()
          d3.select(`.graph-tooltip-${this.props.id}`)
            .transition()
            .duration(200)
            .style('visibility', 'visible')
            .style('left', `${svgp.x - (node.offsetWidth / 2)}px`)
            .style('top', `${svgp.y - (node.offsetHeight * 1.2)}px`)
        }}
        onFocus={() => null}
        onMouseOut={() => {
          d3.select(`.graph-tooltip-${this.props.id}`)
            .transition()
            .duration(200)
            .style('visibility', 'hidden')
        }}
        onBlur={() => null}
      >
        <circle
          id={`node_${d.id}`}
          className='nodes handicon'
          stroke='black'
          fill='#f5aa61'
          r='15'
          strokeWidth='1px'
        />
        <text
          id={`label_${d.id}`}
          className='labels handicon'
          fill='black'
          textAnchor='middle'
          dy='0.3em'
        >
          {d.node.length > 10 ? d.node.substring(0, 10) : d.node}
        </text>
      </g>
    ))
  }

  renderNodes() {
    return (
      <g>
        {this.renderNode()}
      </g>
    )
  }

  render() {
    return (
      <g className='network' id='network'>
        {this.renderLinks()}
        {this.renderNodes()}
        {this.simulate()}
      </g>
    )
  }
}

NetworkGraph.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  search: PropTypes.func,
  yaxis: PropTypes.string.isRequired
}
NetworkGraph.defaultProps = {
  search: () => null,
}

export default NetworkGraph
