import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Sunburst, WordCloud, ScatterPlot, BubbleChart, BubbleChartDynamic, ScatterPlotDiscrete, Legends, Pie, NetworkGraph, LineGraph1 } from '../index'
import data from './data.js'
import Bar from '../../../custom/components/Graph/charts/bar'
import StackedBar from '../../../custom/components/Graph/charts/stacked'
import COLOR from '../../container/palette.json'

class Graph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      factor: 1,
    }
    // this.props.id = (new Date()).getTime().toString();
  }
  renderGraph() {
    if (this.props.type === 'innovation-timeline-bubble' || this.props.type === 'bubble_graph') {
      return (
        <BubbleChart
          width={this.props.width}
          height={this.props.height}
          labelX={this.props.labelX}
          labelY={this.props.labelY}
          data={this.props.data && !Array.isArray(this.props.data) ? this.props.data : {}}
          border={this.props.border}
          color={this.props.color}
          search={this.props.search}
          id={this.props.id}
          xaxis={this.props.xaxis}
          yaxis={this.props.yaxis}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent) {
              Object.keys(tooltipPresent).forEach((d) => {
                if (tooltipPresent[d].childNodes[0]) {
                  tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                }
              })
            }
            this.props.redirect(param)
          }}
        />
      )
    } else if (this.props.type === 'innovation-timeline-bubble-yscore') {
      return (
        <BubbleChartDynamic
          width={this.props.width}
          height={this.props.height}
          labelX={this.props.labelX}
          labelY={this.props.labelY}
          data={this.props.data}
          id={this.props.id}
          xaxis={this.props.xaxis}
          search={this.props.search}
          yaxis={this.props.yaxis}
          factor={this.state.factor}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent) {
              Object.keys(tooltipPresent).forEach((d) => {
                if (tooltipPresent[d].childNodes[0]) {
                  tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                }
              })
            }
            this.props.redirect(param)
          }}
        />
      )
    } else if (this.props.type === 'innovation-timeline-line') {
      return (
        <LineGraph1
          width={this.props.width}
          height={this.props.height}
          labelX={this.props.labelX}
          labelY={this.props.labelY}
          data={this.props.data}
          id={this.props.id}
          color={this.props.color}
          xaxis={this.props.xaxis}
          yaxis={this.props.yaxis}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent) {
              Object.keys(tooltipPresent).forEach((d) => {
                if (tooltipPresent[d].childNodes[0]) {
                  tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                }
              })
            }
            this.props.redirect(param)
          }}
        />
      )
    } else if (this.props.type === 'scatter') {
      if (this.props.yType === 'linear') {
        return (
          <ScatterPlot
            width={this.props.width}
            height={this.props.height}
            data={data}
            markers={this.props.markers}
            labelX={this.props.labelX}
            labelY={this.props.labelY}
            id={this.props.id}
            yType={this.props.yType}
            xaxis={this.props.xaxis}
            yaxis={this.props.yaxis}
            redirect={(param) => {
              const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
              if (tooltipPresent) {
                Object.keys(tooltipPresent).forEach((d) => {
                  if (tooltipPresent[d].childNodes[0]) {
                    tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                  }
                })
              }
              this.props.redirect(param)
            }}
          />
        )
      } return (
        <ScatterPlotDiscrete
          width={this.props.width}
          height={this.props.height}
          data={this.props.data}
          markers={this.props.markers}
          labelX={this.props.labelX}
          labelY={this.props.labelY}
          color={this.props.color}
          id={this.props.id}
          xaxis={this.props.xaxis}
          yaxis={this.props.yaxis}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent) {
              Object.keys(tooltipPresent).forEach((d) => {
                if (tooltipPresent[d].childNodes[0]) {
                  tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                }
              })
            }
            this.props.redirect(param)
          }}
        />
      )
    } else if (this.props.type === 'bar' || this.props.type === 'bar_graph') {
      return (
        <Bar
          xaxisWrap={this.props.xaxisWrap}
          changeOnClick={this.props.changeOnClick}
          backgroundBar={this.props.backgroundBar}
          selectBar={this.props.selectBar}
          orient={this.props.orient}
          width={this.props.width}
          height={this.props.height}
          labelX={this.props.labelX}
          labelY={this.props.labelY}
          data={this.props.data}
          id={this.props.id}
          border={8}
          color={this.props.color}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent && tooltipPresent[0]) {
              tooltipPresent[0].childNodes[0].style.visibility = 'hidden'
            }
            this.props.redirect(param)
          }}
          marginLeft={this.props.marginLeft}
          tooltipBlack={this.props.tooltipBlack}
          multipleColor={this.props.multipleColor}
          yaxisClass={this.props.yaxisClass}
          offsetX={this.props.offsetX}
          offsetY={this.props.offsetY}
        />
      )
    } else if (this.props.type === 'pie' || this.props.type === 'donut' || this.props.type === 'donut_chart') {
      return (
        <Pie
          width={this.props.showLegends && !this.props.donutFull ? this.props.width - 250 : this.props.width}
          height={this.props.height}
          data={this.props.data}
          key={this.props.data.map(d => d.category)}
          id={this.props.id}
          color={this.props.color}
          xaxis={this.props.xaxis}
          yaxis={this.props.yaxis}
          labelX={this.props.labelX}
          labelY={this.props.labelY}
          type={this.props.type}
          customTextCenter={this.props.customTextCenter}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent) {
              Object.keys(tooltipPresent).forEach((d) => {
                if (tooltipPresent[d].childNodes[0]) {
                  tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                }
              })
            }
            this.props.redirect(param)
          }}
        />
      )
    } else if (this.props.type === 'word_cloud' && this.props.data && this.props.data.length > 0) {
      return (
        <WordCloud
          key={1}
          width={this.props.width}
          height={this.props.height}
          data={this.props.data}
          id={this.props.id}
          center={this.props.center}
          showNetwork={this.props.showNetwork}
          xaxis={this.props.xaxis}
          yaxis={this.props.yaxis}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent) {
              Object.keys(tooltipPresent).forEach((d) => {
                if (tooltipPresent[d].childNodes[0]) {
                  tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                }
              })
            }
            this.props.redirect(param)
          }}
        />
      )
    } else if (this.props.type === 'network') {
      if (this.props.data.nodes) {
        return (
          <NetworkGraph
            key={2}
            width={this.props.width}
            height={this.props.height}
            data={this.props.data}
            id={this.props.id}
            xaxis={this.props.xaxis}
            yaxis={this.props.yaxis}
            search={this.props.search}
            redirect={(param) => {
              const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
              if (tooltipPresent) {
                Object.keys(tooltipPresent).forEach((d) => {
                  if (tooltipPresent[d].childNodes[0]) {
                    tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                  }
                })
              }
              this.props.redirect(param)
            }}
          />
        )
      }
    } else if (this.props.type === 'sunburst') {
      return (
        <Sunburst
          key={2}
          width={this.props.width}
          height={this.props.height}
          data={this.props.data}
          id={this.props.id}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent) {
              Object.keys(tooltipPresent).forEach((d) => {
                if (tooltipPresent[d].childNodes[0]) {
                  tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                }
              })
            }
            this.props.redirect(param)
          }}
        />
      )
    } else if (this.props.type === 'stack_bar_graph') {
      return (
        <StackedBar
          width={this.props.width}
          height={this.props.height}
          color={this.props.color}
          labelX={this.props.labelX}
          labelY={this.props.labelY}
          data={this.props.data}
          id={this.props.id}
          border={8}
          redirect={(param) => {
            const tooltipPresent = document.getElementsByClassName('ToolTipPortal')
            if (tooltipPresent) {
              Object.keys(tooltipPresent).forEach((d) => {
                if (tooltipPresent[d].childNodes[0]) {
                  tooltipPresent[d].childNodes[0].style.visibility = 'hidden'
                }
              })
            }
            this.props.redirect(param)
          }}
          marginLeft={this.props.marginLeft}
        />
      )
    }
    return null
  }
  getData() {
    const legendData = {}
    this.props.data.forEach((d) => {
      legendData[d.category] = d.value
    })
    return legendData
  }
  renderLegends() {
    if (this.props.legends && this.props.legends[0] !== 'root') {
      return this.props.legends.map((d, i) => (
        <Legends
          key={i}
          option={i}
          category={d}
          legends={{
            [d]: this.props.color[d],
          }}
          translateX={10}
          orientation={this.props.orient ? this.props.orient : 'horizontal'}
        />
      ))
    }
    return null
  }
  render() {
    return (
      [
        <div className='zoom' key={3} style={{ display: this.props.type === 'innovation-timeline-bubble-yscore' ? 'block' : 'none' }}>
          <button className='increase-zoom handicon' onClick={() => { this.setState({ factor: this.state.factor + 0.5 }) }} />
          <button className='decrease-zoom handicon' onClick={() => { this.setState({ factor: this.state.factor - 0.5 }) }} />
        </div>,
        <div key={1} style={{ position: 'relative', display: (this.props.type === 'pie' || this.props.type === 'donut' || this.props.type === 'donut_chart') && !this.props.donutFull ? 'flex' : '' }}>
          <div className='disp-flex vcenter hcenter' >
            <svg width={(this.props.type === 'pie' || this.props.type === 'donut' || this.props.type === 'donut_chart') && this.props.showLegends && !this.props.donutFull ? this.props.width - 300 : this.props.width} height={this.props.height} key={1} id={`graph-${this.props.id}`} className={this.props.className}>
              {this.renderGraph()}
            </svg>
          </div>
          {
            this.props.type === 'stack_bar_graph' && this.props.showLegends ?
              <div className='stack'>
                <Legends className='hcenter wrap' legends={this.props.color} borderRadius={this.props.border} />
              </div> :
              <Fragment>
                {Array.isArray(this.props.legends) && !!this.props.legends.length && (
                  <div className='legends' key={2}>
                    {this.renderLegends()}
                  </div>
                )}
              </Fragment>
          }
          {
            this.props.type === 'donut_chart' && this.props.showLegends && (
              <Legends itemClass='padding-medium-bottom' legends={this.props.data.map(item => item.category)} color={this.props.color} legendData={this.getData()} />
            )
          }
        </div>,
        <div key={2} className={`graph-tooltip graph-tooltip-${this.props.id}`} id={`graph-tooltip-id-${this.props.id}`} style={{ visibility: 'hidden' }} />
      ]
    )
  }
}
Graph.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  labelX: PropTypes.string,
  labelY: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  center: PropTypes.string,
  legends: PropTypes.array,
  showNetwork: PropTypes.func,
  border: PropTypes.array,
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  orient: PropTypes.string,
  className: PropTypes.string,
  search: PropTypes.func,
  yType: PropTypes.string,
  markers: PropTypes.array,
  xaxis: PropTypes.string,
  yaxis: PropTypes.string,
  showLegends: PropTypes.bool,
  marginLeft: PropTypes.number,
  redirect: PropTypes.func,
  tooltipBlack: PropTypes.bool,
  backgroundBar: PropTypes.bool,
  selectBar: PropTypes.string,
  changeOnClick: PropTypes.bool,
  multipleColor: PropTypes.bool,
  xaxisWrap: PropTypes.string,
  yaxisClass: PropTypes.bool,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  donutFull: PropTypes.bool,
  customTextCenter: PropTypes.bool,
}
Graph.defaultProps = {
  redirect: null,
  center: null,
  showNetwork: () => null,
  border: [],
  color: COLOR,
  orient: 'vertical',
  className: '',
  search: () => null,
  yType: '',
  markers: [],
  xaxis: '',
  yaxis: '',
  legends: [],
  showLegends: true,
  marginLeft: 100,
  tooltipBlack: false,
  backgroundBar: false,
  selectBar: '',
  changeOnClick: false,
  xaxisWrap: 'ellipsis',
  multipleColor: false,
  yaxisClass: false,
  offsetX: 0,
  offsetY: 0,
  donutFull: false,
  labelX: '',
  customTextCenter: false
}
export default withRouter(Graph)
