import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const LIST = 'LIST'
export const LIST_CANCEL = 'LIST_CANCEL'
export const LIST_SUCCESS = 'LIST_SUCCESS'
export const LIST_FAILURE = 'LIST_FAILURE'

export const LIST_TOP = 'LIST_TOP'
export const LIST_TOP_CANCEL = 'LIST_TOP_CANCEL'
export const LIST_TOP_SUCCESS = 'LIST_TOP_SUCCESS'
export const LIST_TOP_FAILURE = 'LIST_TOP_FAILURE'

export const LIST_BOTTOM = 'LIST_BOTTOM'
export const LIST_BOTTOM_CANCEL = 'LIST_BOTTOM_CANCEL'
export const LIST_BOTTOM_SUCCESS = 'LIST_BOTTOM_SUCCESS'
export const LIST_BOTTOM_FAILURE = 'LIST_BOTTOM_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false,
  from: 0,
  listType: ''
}

// Delete this function once api has been changed in getbookmark
const tempMod = (data) => {
  if (data.length > 0 && data[0].date) {
    const list = []
    data.forEach((item) => {
      list.push(...item.data)
    })
    return list
  }
  return data
}

export const listFetchAction = (payload, url, from, listType) => ({
  type: LIST,
  payload,
  url,
  from,
  listType
})

export const listFetchCancel = payload => ({
  type: LIST_CANCEL,
  payload
})

export const listTopFetchCancel = payload => ({
  type: LIST_TOP_CANCEL,
  payload
})

export const listBottomFetchCancel = payload => ({
  type: LIST_BOTTOM_CANCEL,
  payload
})

export const listFetchSuccess = payload => ({
  type: LIST_SUCCESS,
  payload
})

export const listBottomFetchAction = (payload, url, from, listType) => ({
  type: LIST_BOTTOM,
  payload,
  url,
  from,
  listType
})

export const listBottomFetchSuccess = payload => ({
  type: LIST_BOTTOM_SUCCESS,
  payload
})

export const listTopFetchAction = (payload, url, from, listType) => ({
  type: LIST_TOP,
  payload,
  url,
  from,
  listType
})

export const listTopFetchSuccess = payload => ({
  type: LIST_TOP_SUCCESS,
  payload
})

export const listFetchEpic = action$ => action$
  .ofType(LIST)
  .mergeMap(action => staticAjax(apiCall(`${action.url}?${action.payload}`, 'GET', true))
    .map(response => listFetchSuccess(response))
    .takeUntil(action$.ofType(LIST_CANCEL))
    .catch(error => Observable.of({
      type: LIST_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const listBottomFetchEpic = action$ => action$
  .ofType(LIST_BOTTOM)
  .mergeMap(action => staticAjax(apiCall(`${action.url}?${action.payload}`, 'GET', true))
    .map(response => listBottomFetchSuccess(response))
    .takeUntil(action$.ofType(LIST_BOTTOM_CANCEL))
    .catch(error => Observable.of({
      type: LIST_BOTTOM_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const listTopFetchEpic = action$ => action$
  .ofType(LIST_TOP)
  .mergeMap(action => staticAjax(apiCall(`${action.url}?${action.payload}`, 'GET', true))
    .map(response => listTopFetchSuccess(response))
    .takeUntil(action$.ofType(LIST_TOP_CANCEL))
    .catch(error => Observable.of({
      type: LIST_TOP_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function listFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case LIST: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false,
        total: 0,
        from: action.from,
        listType: action.listType
      }
    }
    case LIST_SUCCESS: {
      return {
        ...state,
        data: tempMod(action.payload.response.data.map(item => ({ ...item, from: state.from }))),
        loading: false,
        error: false,
        flag: true,
        total: action.payload.response.totalcount || action.payload.response.total
      }
    }
    case LIST_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false,
        total: 0
      }
    }
    default:
      return state
  }
}

export function listBottomFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case LIST_BOTTOM: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false,
        from: action.from,
        listType: action.listType
      }
    }
    case LIST_BOTTOM_SUCCESS: {
      return {
        ...state,
        data: tempMod(action.payload.response.data.map(item => ({ ...item, from: state.from }))),
        loading: false,
        error: false,
        flag: true
      }
    }
    case LIST_BOTTOM_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function listTopFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case LIST_TOP: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false,
        from: action.from,
        listType: action.listType
      }
    }
    case LIST_TOP_SUCCESS: {
      return {
        ...state,
        data: tempMod(action.payload.response.data.map(item => ({ ...item, from: state.from }))),
        loading: false,
        error: false,
        flag: true
      }
    }
    case LIST_TOP_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
