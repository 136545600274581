import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage } from 'react-intl';
import GenerateListV1 from '../../container/GenerateListV1'
import { Accordion } from '../../components'
import { checkPermission } from '../../utils'

class Patent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.openStructureAccordion
    }
  }
  renderClaims = () => this.props.data.Claims.map((item, i) => (
    <div key={i} >{ReactHtmlParser(item)}</div>
  ))

  render() {
    const molecules = this.props.list && this.props.list['Molecule Counts'] ? this.props.list['Molecule Counts'] : 0
    const abstract = this.props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
    const params = {
      data: {},
      params: {
        dataset: this.props.type,
        doc_id: this.props.id
      }
    }
    return (
      <Fragment>
        {this.props.data.Abstract && (
          <div className='card-body-content'>
            <div className='sub-title' ><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
            <div id='annotation-highlight-1' >{ReactHtmlParser(abstract)}</div>
          </div>)}
        {this.props.data.Claims.length > 0 && (
          <div className='card-body-content'>
            <div className='sub-title'><FormattedMessage id="Claims" defaultMessage="Claims" /></div>
            <div>{this.renderClaims()}</div>
          </div>)}
        {
          checkPermission('molecule_structure') && this.props.list['Molecule Counts'] && (
            <div className='card-body-content' >
              <div id='abstract-structure-list' role='presentation' onClick={() => this.setState({ open: !this.state.open })} className={this.state.open ? 'abstract-accordion-header active sub-title' : 'abstract-accordion-header sub-title'}><FormattedMessage id="Molecule Structures" defaultMessage='Molecule Structures' />&nbsp;({molecules})</div>
              <Accordion open={this.state.open} className='abstract-accordion-body' >
                {this.props.id && <GenerateListV1 openIndependent takeFeedback={false} params={params} paginate uniqueId='abstract-structure-list' apiType='GET' loading={false} toId='abstract-structure-list' id='abstract-modal-id-special' apiUrl={`${process.env.faceliftApiUrl}v1/chemonto/moleculeDetails/`} size={10} height={500} />}
              </Accordion>
            </div>
          )
        }
      </Fragment>
    )
  }
}

Patent.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  openStructureAccordion: PropTypes.bool,
  list: PropTypes.object,
  type: PropTypes.string
}

Patent.defaultProps = {
  openStructureAccordion: false,
  type: 'patent',
  list: {},
}

export default Patent
