import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { stringify } from 'query-string'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { trendsAction } from '../Trends/logic'
import { Loader } from '../../../common/components'
import LineGraphFilter from './LineGraphFilter'
import { parseHashUrl } from '../../../common/utils'

class TrendsGraph extends Component {
  componentDidMount () {
    const q = parseHashUrl(window)
    this.props.trendsAction({
      params: stringify({
        dataset: q.tab
      }),
      data: {
        query: this.props.value,
      }
    })
  }
  componentWillReceiveProps (nextProps) {
    if (JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value)) {
      const q = parseHashUrl(window)
      this.props.trendsAction({
        params: stringify({
          dataset: q.tab
        }),
        data: {
          query: nextProps.value,
        }
      })
    }
  }
  render () {
    return (
      <Loader width={this.props.width} height={this.props.height} loading={this.props.trends.loading} error={this.props.trends.error} >
        <LineGraphFilter data={this.props.trends.data} labelX='' labelY='' height={this.props.height} width={this.props.width} index={1} message='No trends found' />
      </Loader>
    )
  }
}

const mapStateToProps = state => ({
  trends: state.trends,
  groups: state.groups
})

TrendsGraph.propTypes = {
  trendsAction: PropTypes.func.isRequired,
  trends: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
TrendsGraph.defaultProps = {
  height: 130,
  width: 270
}

export default (connect(mapStateToProps, { trendsAction })(TrendsGraph))
