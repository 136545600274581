import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ClickOutside } from 'reusable-react-components'
import ToolTip from 'react-portal-tooltip'
import { withRouter } from 'react-router-dom'
import { style } from '../../../common/utils'
import TrendsGraph from '../../container/TrendsGraph'
import { CloseOnEscape } from '../../../common/components'

class ProductListItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isTooltipActive: false
    }
    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
    this.addToSearch = this.addToSearch.bind(this)
    this.excludeFromSearch = this.excludeFromSearch.bind(this)
    this.updateSearchQuery = this.updateSearchQuery.bind(this)
  }

  showTooltip () {
    this.setState({ isTooltipActive: true })
  }

  hideTooltip () {
    this.setState({ isTooltipActive: false })
  }

  addToSearch () {
    this.updateSearchQuery(this.props.value)
  }

  excludeFromSearch () {
    this.updateSearchQuery(`not ${this.props.value}`)
  }

  updateSearchQuery (newTerm) {
    this.props.onClickSearch(newTerm)
  }

  render () {
    return (
      <div className='list-item' ref={(element) => { this.element = element }} onClick={this.showTooltip} role='presentation' >
        {this.props.name}
        <CloseOnEscape onEscape={this.hideTooltip}>
          <ToolTip style={style} active={this.state.isTooltipActive} position='top' arrow='center' parent={this.state.isTooltipActive && this.element}>
            <ClickOutside onClickOutside={() => this.hideTooltip()} >
              <div className='tooltip-container'>
                <div>{this.props.name}</div>
                <TrendsGraph value={this.props.value} />
                <div className='search-filter-container'>
                  <p role='presentation' onClick={this.addToSearch} className='filter-element'>Add to search | </p>
                  <p role='presentation' onClick={this.excludeFromSearch} className='filter-element'>Exclude from search</p>
                </div>
              </div>
            </ClickOutside>
          </ToolTip>
        </CloseOnEscape>
      </div>
    )
  }
}

ProductListItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClickSearch: PropTypes.func
}

ProductListItem.defaultProps = {
  onClickSearch: null
}

export default withRouter(ProductListItem)
