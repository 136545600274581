import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { stringify } from 'query-string'
import { connect } from 'react-redux'
import { modalCloseAction } from '../Modal/logic'
import { loginAction } from '../Login/logic'

class RequestInvite extends Component {
  constructor (props) {
    super(props)
    if (sso) {
      sso.config({
        base_url: process.env.sso.baseUrl,
        client_id: process.env.sso.clientId,
        window_type: 'iframe',
        response_type: 'code',
        divId: 'ssoRequest',
        lang: this.props.lang,
        cssUrl: `${process.env.APPS.app.redirectUrl}landingImages/sso.css`
      })
    }
  }
  componentDidMount () {
    sso.requestUserInvite({ scope: process.env.sso.scope }, (status) => {
      if (status.type && status.type === 'ssoLogin') {
        if (status.code) {
          this.props.loginAction({
            params: stringify({
              code: status.code,
              app: 'facelift'
            })
          })
        } else {
          this.props.modalCloseAction()
        }
      } else if (status.type && status.type === 'ssoRequestInvite') {
        if (status.data && status.data.is_tour_period && status.data.email) {
          this.props.loginAction({
            url: 'v0/auth/triallogin',
            params: stringify({
              email: status.data.email,
              app: 'facelift'
            })
          })
        } else {
          this.props.modalCloseAction()
        }
      } else {
        this.props.modalCloseAction()
      }
    })
  }
  render () {
    const styles = {
      width: '100%',
      height: this.props.height
    }
    return (
      <div id='ssoRequest' style={styles} />
    )
  }
}

RequestInvite.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  modalCloseAction: PropTypes.func.isRequired,
  loginAction: PropTypes.func.isRequired,
  lang: PropTypes.string,
}
RequestInvite.defaultProps = {
  height: 'calc(100vh - 60px)',
  lang: 'en'
}

const mapStateToProps = state => ({
  lang: state.language.lang,
})

export default withRouter(connect(mapStateToProps, { modalCloseAction, loginAction })(RequestInvite))
