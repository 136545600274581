export default {
  en: {
    lastUpdated: '2019-05-31T10:18:40.940Z',
    version: '1.1',
    'Header.discover': 'Discover',
    'Header.myWorkspace': 'My Workspace',
    'Header.dashboards': 'Dashboards',
    'Header.marketplace': 'Marketplace',
    "Header.FAQ's": "FAQ's",
    'Header.Profile': 'Profile',
    'Header.AccountSettings': 'Account Settings',
    'Header.Subscriptions': 'Subscriptions',
    'Header.Logout': 'Logout',
    'Footer.allRights': '- All Rights Reserved.',
    'Btn.Cancel': 'Cancel',
    'Btn.clearAll': 'Clear All',
    'Btn.Download': 'Download',
    'Search.hello': 'Hello',
    'Search.goodToSee': 'Good to see you here!',
    'Search.searchHereFor': 'Search here for indications, interventions, genes, researchers',
    'Search.typeBefore': 'Type @ before a name to search for a researcher',
    'Search.advanceSearch': 'Advanced Search',
    'Search.SEARCH': 'SEARCH',
    'Search.Search': 'Search',
    'Cluster.SaveAsCluster': 'Save as Cluster',
    'Cluster.Context': 'Context',
    'Cluster.ClusterName': 'Cluster Name',
    'Cluster.EmailFrequency': 'Email Frequency',
    'Get Email on any new content': 'Get Email on any new content',
    Weekly: 'Weekly',
    Daily: 'Daily',
    'Raw data result': 'Raw data result',
    'SearchAdvance.backToBasicSearch': 'Back to Basic Search',
    'SearchAdvance.findArticle': 'Find articles with...',
    'Indication(s)': 'Indication(s)',
    'Intervention(s)': 'Intervention(s)',
    'Protein(s)': 'Protein(s)',
    'Gene(s)': 'Gene(s)',
    'Author(s)': 'Author(s)',
    Others: 'Others',
    Literature: 'Literature',
    KOLs: 'KOLs',
    Published: 'Published',
    Publications: 'Publications',
    'Clinical Trials': 'Clinical Trials',
    Congresses: 'Congresses',
    'Theses & Dissertations': 'Theses & Dissertations',
    Patents: 'Patents',
    'News & Press Releases': 'News & Press Releases',
    'Treatment Guidelines': 'Treatment Guidelines',
    Grants: 'Grants',
    Unpublished: 'Unpublished',
    'Sort By': 'Sort By',
    Recency: 'Recency',
    Relevance: 'Relevance',
    'Related Concepts': 'Related Concepts',
    By: 'By',
    Correlation: 'Correlation',
    Frequency: 'Frequency',
    Reset: 'Reset',
    Apply: 'Apply',
    Filter: 'Filter',
    'Biomedical Entity Type': 'Biomedical Entity Type',
    'Study/Experiment Type': 'Study/Experiment Type',
    Species: 'Species',
    From: 'From',
    To: 'To',
    Publication: 'Publication',
    Trends: 'Trends',
    '(for last 10 years)': '(for last 10 years)',
    'Clinical Trial': 'Clinical Trial',
    Congress: 'Congress',
    Patent: 'Patent',
    'News & Press Release': 'News & Press Release',
    Grant: 'Grant',
    Sponsorship: 'Sponsorship',
    Country: 'Country',
    'Data Source': 'Data Source',
    Institution: 'Institution',
    'Download Results': 'Download Results',
    'Number of Records': 'Number of Records',
    'File Format': 'File Format',
    'Downloading...': 'Downloading...',
    'Read more': 'Read more',
    'Read less': 'Read less',
    'Back to list': 'Back to list',
    Authors: 'Authors',
    FINGERPRINTS: 'FINGERPRINTS',
    Abstract: 'Abstract',
    More: 'More',
    'Show less': 'Show less',
    PMID: 'PMID',
    DOI: 'DOI',
    'Thought Leaders': 'Thought Leaders',
    'Commercial KOLs': 'Commercial KOLs',
    'Scientific KOLs': 'Scientific KOLs',
    Speakers: 'Speakers',
    Resources: 'Resources',
    'Score Equalizer': 'Score Equalizer',
    'Total Score': 'Total Score',
    'Must be 100%': 'Must be 100%',
    Save: 'Save',
    Guidelines: 'Guidelines',
    Societies: 'Societies',
    'Top Hospital KOLs': 'Top Hospital KOLs',
    'Regulatory Bodies': 'Regulatory Bodies',
    HTA: 'HTA',
    Advocacy: 'Advocacy',
    'Select Country': 'Select Country',
    LOGIN: 'LOGIN',
    'Life Sciences': 'Life Sciences',
    'Research Simplified': 'Research Simplified',
    'We know you': "We know you don't have an interest about every research out there, but the ones that",
    'matter to you': 'matter to you. Now its curated, analyzed and arranged on a',
    platter: 'platter',
    '35M+': '35M+',
    '500K+': '500K+',
    'Clinical trials': 'Clinical trials',
    '850K+': '850K+',
    'Congress articles': 'Congress articles',
    '127K+': '127K+',
    'Drug profiles': 'Drug profiles',
    '1M+': '1M+',
    'Also latest lifescience news': 'Also latest life sciences news, regulatory announcements, analytical engine & much more.',
    'ROLLING OUT INVITES - GET ONE NOW': 'ROLLING OUT INVITES - GET ONE NOW',
    img: 'img',
    'Asset classes': 'Asset classes',
    'Get access to wide range': 'Get access to wide range of life sciences related data universe like publications, clinical trials, congresses, theses, patents, news, press releases, grants and treatment guidelines.',
    'Search crumbs': 'Search crumbs',
    'A unique feature': 'A unique feature which helps the user in iterating over the query and build the correct context until desired results are obtained.',
    'Concept based search': 'Concept based search',
    'Our innovative and intuitive': 'Our innovative and intuitive semantic search facility understands biomedical concepts using our proprietary Ontology database. You will get relevant, in-context results for your search queries. Using our advanced search features you can opt for your search results according to a particular class of concepts like indication, intervention, gene or protein.',
    'Detailed insights': 'Detailed insights',
    'You can deep dive': 'You can deep dive and take your research to the next level, through our different analysis layers and modules. Get intervention views, gene views, researcher profiles etc. for your search query and make an informed decision.',
    Discovery: 'Discovery',
    'Our AI based engine': 'Our AI based engine collect, clean and categorize various asset classes and gives you a comprehensive result for your search query, including related concepts as well. This helps you in making informed decisions.',
    Annotations: 'Annotations',
    'Get the flexibility of adding': 'Get the flexibility of adding comments to any document of your interest and quickly recall it for future reference.',
    'Cluster updates': 'Cluster updates',
    'Get real-time updated': 'Get real-time updated notifications on ‘topics of interest’ by creating your workspace (research clusters). View and manage the latest updates on your research clusters from where you left last time.',
    'Customizable dashboards': 'Customizable dashboards',
    'You can create your own': 'You can create your own graphs and visualizations by selecting your own parameters. You may select different types of graphs from the pre-populated widgets library and can export them as well.',
    Marketplace: 'Marketplace',
    'You will have access': 'You will have access to unpublished/failed experiments data as well. You may use this platform to sell or buy experimental data. Powered by blockchain technology our platform ensures the ownership of experiments published through it.',
    'A PRODUCT BY INNOPLEXUS': 'A PRODUCT BY INNOPLEXUS',
    'Innoplexus is structuring': 'Innoplexus is structuring the world"s information to make AI easier. Innoplexus offers Data as a Service (DaaS) and Continuous Analytics as a Service (CAaS) products, leveraging Artificial Intelligence, proprietary algorithms and patent pending technologies to help global Life Sciences organisations with access to relevant data, intelligence & intuitive insights, across pre-clinical, clinical, regulatory and commercial stages of a drug.',
    'We automate the collection': 'We automate the collection and curation of data using technologies such as natural language processing, network analysis, ontologies, computer vision and entity normalisation. Our clients are Fortune 500 companies including some of the world’s largest pharmaceutical companies.',
    'Drug Discovery': 'Drug Discovery',
    Clinical: 'Clinical',
    'Header.Frequently asked questions': 'Frequently asked questions',
    'Header.More about you': 'More about you',
    'Header.Check / Update your settings': 'Check / Update your settings',
    'Header.Check / Update your subscriptions': 'Check / Update your subscriptions',
    'Header.See you next time!': 'See you next time!',
    'Header.Context based intelligent search': 'Context based intelligent search across varied data sources',
    'Header.Your history, saved searches': 'Your history, saved searches, and more',
    'Header.Deep dive with various analysis modules': 'Deep dive with various analysis modules',
    'Header.Sell and buy unpublished data': 'Sell and buy unpublished data',
    'Discover.Language': 'Language',
    'Reset all Filters': 'Reset all Filters',
    'Gene.About': 'About',
    'Gene.Official Full Name': 'Official Full Name',
    'Gene.Gene type': 'Gene type',
    'Gene.Organism': 'Organism',
    'Gene.Also known as': 'Also known as',
    'Gene.Primary Source': 'Primary Source',
    'Gene.Location': 'Location',
    'Gene.Chromosome': 'Chromosome',
    'Gene.Start': 'Start',
    'Gene.End': 'End',
    'Gene.Size': 'Size',
    'Gene.Orientation': 'Orientation',
    'Gene.Score': 'Score',
    'Gene.Disorder': 'Disorder',
    'Gene.Metadata of all': 'Metadata of all associated drugs for selected gene',
    Name: 'Name',
    Indication: 'Indication',
    Sponsors: 'Sponsors',
    Total: 'Total',
    'SL pairs number limit': 'SL pairs number limit',
    'REGULATORY DESIGNATIONS': 'REGULATORY DESIGNATIONS',
    'Header.Customer Support': 'Customer Support',
    Analytics: 'Analytics',
    Custom: 'Custom',
    'Author.AFFILIATIONS': 'AFFILIATIONS',
    'Author.KEY RESEARCH AREAS': 'KEY RESEARCH AREAS',
    'Author.Data unavailable': 'Data unavailable',
    'Author.ACTIVITY TREND': 'ACTIVITY TREND',
    'Author.RESEARCH ACTIVITY': 'RESEARCH ACTIVITY',
    'Author.Select Option': 'Select Option',
    'Author.Select': 'Select',
    'Author.Contribution': 'Contribution',
    'Author.Global view': 'Global view',
    'Author.List view': 'List view',
    'Author.Number of connections': 'Number of connections',
    'Author.Affiliation unavailable': 'Affiliation unavailable',
    'Author.Country unavailable': 'Country unavailable',
    'Preclinical.Originator': 'Originator',
    'Preclinical.Synonyms': 'Synonyms',
    'Preclinical.Preclinical Name': 'Preclinical Name',
    'Preclinical.Intervention : Preclinical Stage': 'Intervention : Preclinical Stage',
    'Preclinical.Select': 'Select',
    Molecule: 'Molecule',
    'Related Articles': 'Related Articles',
    About: 'About',
    'Research Activity': 'Research Activity',
    Network: 'Network',
    'Profile.Delete Activity History': 'Delete Activity History',
    'Profile.This action will clear': 'This action will clear all your activity history on Ontosight including your past searches, bookmarks, workspace and annotations. Your bookmarks and workspace are only visible to you and help enhance your experience on the platform.',
    'Profile.Delete history': 'Delete history',
    'Profile.Delete Ontosight Account': 'Delete Ontosight Account',
    'Profile.Deleting your account': 'Deleting your account is permanent and irreversible. This will erase all your personal data associated with your account.',
    'Profile.Delete Account': 'Delete Account',
    'Profile.Personal Details': 'Personal Details',
    'Profile.First Name': 'First Name',
    'Profile.Last Name': 'Last Name',
    'Profile.Organization': 'Organization',
    'Profile.Contact Details': 'Contact Details',
    'Profile.Email': 'Email',
    'Profile.Are you sure, you want to delete all': 'Are you sure, you want to delete all your activity history on Ontosight including your past searches, bookmarks, workspace and annotations ?',
    'Profile.Delete': 'Delete',
    'Profile.Cancel': 'Cancel',
    'Profile.It may take few days for your data': 'It may take few days for your data to get deleted. You will lose access to your account immediately and all your associated data will be erased permenently.',
    'Profile.Are you sure, you want to close your account ?': 'Are you sure, you want to close your account ?',
    'Profile.Edit': 'Edit',
    'Account Settings': 'Account Settings',
    Profile: 'Profile',
    'Identification & Approval': 'Identification & Approval',
    'Mechanism & Classes': 'Mechanism & Classes',
    Indications: 'Indications',
    'Competing/Related Drugs': 'Competing/Related Drugs',
    'Trial Landscape': 'Trial Landscape',
    'Regulatory.Latest regulatory Updates': 'Latest regulatory Updates',
    'Regulatory.Guidelines': 'Guidelines',
    'Regulatory.Disease and drug guidelines': 'Disease and drug guidelines',
    'Regulatory.Please select the required fields.': 'Please select the required fields.',
    'Regulatory.Select Interventions': 'Select Interventions',
    'Regulatory.No. of guidelines': 'No. of guidelines',
    'Regulatory.Interventions': 'Interventions',
    'Regulatory.Data unavailable': 'Data unavailable',
    'Regulatory.Drug launches across targets': 'Drug launches across targets',
    'Regulatory.Status across regulatory bodies': 'Status across regulatory bodies',
    'Regulatory.From FDA and EMA': 'From FDA and EMA',
    'Regulatory.Status': 'Status',
    'Clarivate.Highest Phase': 'Highest Phase',
    'Clarivate.Back to search': 'Back to search',
    'Clarivate.No data returned from clarivate API': 'No data returned from clarivate API',
    'Clarivate.REGULATORY DESIGNATIONS': 'REGULATORY DESIGNATIONS',
    'Clarivate.Search by designation': 'Search by designation',
    'Clarivate.DEVELOPMENT PROFILE': 'DEVELOPMENT PROFILE',
    'Clarivate.DEVELOPMENT STATUS': 'DEVELOPMENT STATUS',
    'Clarivate.Type': 'Type',
    'Clarivate.Search Type': 'Search Type',
    'Clarivate.Select Type': 'Select Type',
    'Clarivate.Select Indication': 'Select Indication',
    'Clarivate.Search Indication': 'Search Indication',
    'Clarivate.Indication': 'Indication',
    'Clarivate.Status': 'Status',
    'Clarivate.Search Status': 'Search Status',
    'Clarivate.Select Status': 'Select Status',
    'Clarivate.Reset all Filters': 'Reset all Filters',
    'Clarivate.Search by company': 'Search by company',
    'Clarivate.SALES & FORECAST': 'SALES & FORECAST',
    'Clarivate.DEALS': 'DEALS',
    'Clarivate.Search by title': 'Search by title',
    'Clarivate.EXPERT REVIEW': 'EXPERT REVIEW',
    'Clarivate.SWOT ANALYSIS': 'SWOT ANALYSIS',
    'Clarivate.PATENT FAMILIES': 'PATENT FAMILIES',
    'Clarivate.SOURCES': 'SOURCES',
    'Clarivate.Sort by Date': 'Sort by Date',
    'Clarivate.Citations added (ID)': 'Citations added (ID)',
    'Clarivate.Citations removed (ID)': 'Citations removed (ID)',
    'ClinicalTrials.Trial ID': 'Trial ID',
    'ClinicalTrials.Trial Snapshot': 'Trial Snapshot',
    'ClinicalTrials.Abstract': 'Abstract',
    'ClinicalTrials.Description': 'Description',
    'ClinicalTrials.Phase': 'Phase',
    'ClinicalTrials.Enrollment Goal': 'Enrollment Goal',
    'ClinicalTrials.Study Dates': 'Study Dates',
    'ClinicalTrials.Condition(s)': 'Condition(s)',
    'ClinicalTrials.Study Intervention(s)': 'Study Intervention(s)',
    'ClinicalTrials.Sponsor': 'Sponsor',
    'ClinicalTrials.Type': 'Type',
    'ClinicalTrials.Design': 'Design',
    'ClinicalTrials.Primary Outcome Measure': 'Primary Outcome Measure',
    'ClinicalTrials.Time Frame': 'Time Frame',
    'ClinicalTrials.Measure': 'Measure',
    'ClinicalTrials.Secondary Outcome Measure': 'Secondary Outcome Measure',
    'ClinicalTrials.Arms and Active Intervention': 'Arms and Active Intervention',
    'ClinicalTrials.Accepts Healthy Volunteers': 'Accepts Healthy Volunteers',
    'ClinicalTrials.Genders Eligible for Study': 'Genders Eligible for Study',
    'ClinicalTrials.Lower age limit for Study': 'Lower age limit for Study',
    'ClinicalTrials.Upper age limit for Study': 'Upper age limit for Study',
    'ClinicalTrials.General': 'General',
    'ClinicalTrials.Inclusion Criteria': 'Inclusion Criteria',
    'ClinicalTrials.Exclusion Criteria': 'Exclusion Criteria',
    'ClinicalTrials.Locations': 'Locations',
    'ClinicalTrials.Contacts': 'Contacts',
    'ClinicalTrials.Sponsors': 'Sponsors',
    'ClinicalTrials.Principal Investigator': 'Principal Investigator',
    'ClinicalTrials.Collaborators': 'Collaborators',
    'ClinicalTrials.Back': 'Back',
    'ClinicalTrials.Measure Description': 'Measure Description',
    'ClinicalTrials.Population Description': 'Population Description',
    'ClinicalTrials.Reporting Groups': 'Reporting Groups',
    'ClinicalTrials.Measured Values': 'Measured Values',
    'ClinicalTrials.Related Clinical Trials': 'Related Clinical Trials',
    'Research Clusters': 'Research Clusters',
    'Search History': 'Search History',
    'My Favourites': 'My Favourites',
    'My Bibliography': 'My Bibliography',
    'Workspace.SEARCH': 'SEARCH',
    'Workspace.Cluster Name': 'Cluster Name',
    'Workspace.Settings': 'Settings',
    'Workspace.Email Frequency': 'Email Frequency',
    'Workspace.Delete': 'Delete',
    'Workspace.Cancel': 'Cancel',
    'Workspace.Save': 'Save',
    'Workspace.Cluster name': 'Cluster name',
    'Workspace.No updates available': 'No updates available',
    'Workspace.Select Option': 'Select Option',
    'Workspace.Search results': 'Search results',
    'Workspace.Repeat this search': 'Repeat this search',
    'Workspace.Search History': 'Search History',
    'Workspace.No search history recorded': 'No search history recorded',
    'Workspace.No clusters created': "No cluster's created",
    'Workspace.Show only new updates': 'Show only new updates',
    'Workspace.No folder has been created': 'No folder has been created',
    'Workspace.Delete Potli': 'Delete',
    'Workspace.No favourites found': 'No favourites found',
    'Workspace.Select': 'Select',
    'Workspace.list items avilable at the top': 'list items available at the top',
    'Workspace.No my bibliographys created': "No bibliography's created",
    'Workspace.Delete My Bibliography': 'Delete',
    'Workspace.No bibliographys found': 'No bibliographys found',
    'Custom.No widgets available': 'No widgets available',
    'Custom.My Dashboard': 'My Dashboard',
    'Custom.Add Widget': 'Add Widget',
    'Custom.Top filters': 'Top filters',
    'Custom.Widget name': 'Widget name',
    'Custom.1. Widget name': '1. Widget name',
    'Custom.2. Define context': '2. Define context',
    'Custom.3. Select asset class': '3. Select asset class',
    'Custom.Select dataset': 'Select dataset',
    'Custom.Cancel': 'Cancel',
    'Custom.Save Widget': 'Save Widget',
    'Custom.Select graph': 'Select graph',
    'Custom.No dimensions available': 'No dimensions available',
    'Custom.X-axis': 'X-axis',
    'Custom.Dimension': 'Dimension',
    'Custom.Y-axis': 'Y-axis',
    'Custom.Time Range': 'Time Range',
    'Custom.Complete the above steps to see a preview of your widget.': 'Complete the above steps to see a preview of your widget.',
    'Custom.No data available': 'No data available',
    'Custom.5. Define dimension': '5. Define dimension',
    'Custom.Select': 'Select',
    'Custom.Search': 'Search',
    'Custom.No graphs available': 'No graphs available',
    'Custom.Query': 'Query',
    'Competitive.Search here for indications, interventions, genes, researchers': 'Search here for indications, interventions, genes, researchers',
    'Competitive.Time Range': 'Time Range',
    'Competitive.Name': 'Name',
    'Competitive.Regulatory body': 'Regulatory body',
    'Competitive.Company': 'Company',
    'Competitive.Indication': 'Indication',
    'Competitive.Phase of Development': 'Phase of Development',
    'Competitive.Metadata for all related marketed drugs for selected parameters': 'Metadata for all related marketed drugs for selected parameters',
    'Competitive.Clinical Trials': 'Clinical Trials',
    'Competitive.Show more': 'Show more',
    'Competitive.Back': 'Back',
    'Competitive.Details for': 'Details for',
    'Competitive.Type @ before a name to search for a researcher': 'Type @ before a name to search for a researcher',
    'Competitive.Search': 'Search',
    'Competitive.Did you mean': 'Did you mean',
    'Competitive.SEARCH': 'SEARCH',
    'Competitive.Search...': 'Search...',
    'Competitive.You typed': 'You typed',
    'Competitive.Concept': 'Concept',
    'Competitive.Search drug....': 'Search drug....',
    'Intervention.Dosage & Route of Administration': 'Dosage & Route of Administration',
    'Intervention.Related Publications on drug’s pharmacology': 'Related Publications on drug’s pharmacology',
    'Intervention.Back': 'Back',
    'Back to Basic Search': 'Back to Basic Search',
    'Find articles with...': 'Find articles with...',
    'Clear All': 'Clear All',
    Concept: 'Concept',
    'You typed': 'You typed',
    Download: 'Download',
    Cancel: 'Cancel',
    Search: 'Search',
    'Asset Type': 'Asset Type',
    'KOL Category Distribution': 'KOL Category Distribution',
    'Influence Map': 'Influence Map',
    'KOL Ranking': 'KOL Ranking',
    'TIER 1': 'TIER 1',
    'Top KOL': 'Top KOL',
    'Back to search results': 'Back to search results',
    'Drugs of': 'Drugs of',
    'Click on any drug to add it to your search query': 'Click on any drug to add it to your search query',
    'View details': 'View details',
    'Purchase Document': 'Purchase Document',
    'Select license type': 'Select license type',
    'per license': 'per license',
    'REQUEST PENDING': 'REQUEST PENDING',
    'We’ll notify you when the status of your request changes': 'We’ll notify you when the status of your request changes',
    'Request decline': 'Request decline',
    'BUY NOW': 'BUY NOW',
    'REQUEST TO BUY': 'REQUEST TO BUY',
    'Based on our AI based valuation engine, the license is fairly priced.': 'Based on our AI based valuation engine, the license is fairly priced.',
    'license to similar documents costs around': 'license to similar documents costs around',
    'Please note that this information is only suggestive and determining the final selling price for a submission is the prerogative of its author.': 'Please note that this information is only suggestive and determining the final selling price for a submission is the prerogative of its author.',
    Showing: 'Showing',
    Back: 'Back',
    'results for': 'results for',
    Context: 'Context',
    'Cluster Name': 'Cluster Name',
    'Email Frequency': 'Email Frequency',
    0.95: '0.95',
    0.05: '0.05',
    'Clinical Trails': 'Clinical Trials',
    0.1: '0.1',
    Guidlines: 'Guidelines',
    0.2: '0.2',
    'Type @ before a name to search for a researcher': 'Type @ before a name to search for a researcher',
    SEARCH: 'SEARCH',
    'Search term appearance prioritization': 'Search term appearance prioritization',
    'Summary Details for': 'Summary Details for',
    ApprovalDate: 'Approval Date',
    'list items available at the top': 'list items available at the top',
    'Recent Events': 'Recent Events',
    'Your browser is not supported, please upgrade!!!': 'Your browser is not supported, please upgrade!!!',
    'There seems to be an issue, please allow cookies and try again!!!': 'There seems to be an issue, please allow cookies and try again!!!',
    'By continuing to use our site, you agree to our cookie policy': 'By continuing to use our site, you agree to our cookie policy',
    404: '404',
    Feedback: 'Feedback',
    Dismiss: 'Dismiss',
    'Did you mean': 'Did you mean',
    'Page not found': 'Page not found',
    'The page you are looking for does not exist. Check the URL or go to the homepage.': 'The page you are looking for does not exist. Check the URL or go to the homepage.',
    'Go To Home': 'Go To Home',
    'Oops! There has been an issue. Re-try in some time.': 'Oops! Something went wrong, Please try again.',
    'Loading...': 'Loading...',
    'Data unavailable': 'Data unavailable',
    'You discovered a Premium Feature': 'You discovered a Premium Feature',
    'You are trying to access a feature that is not included in Ontosight': 'You are trying to access a feature that is not included in Ontosight',
    TM: 'TM',
    'Academic.': 'Academic.',
    'Accelerate your research and increase your efficiency with additional features': 'Accelerate your research and increase your efficiency with additional features',
    'News & Press Releases data': 'News & Press Releases data',
    'Treatment Guidelines data': 'Treatment Guidelines data',
    'Focused analysis on multiple fronts and customised dashboards': 'Focused analysis on multiple fronts and customised dashboards',
    'Detailed insights into clinical trials and interventions': 'Detailed insights into clinical trials and interventions',
    'Create new bibliography': 'Create new bibliography',
    'Add to Bibliography': 'Add to Bibliography',
    'Remove from all': 'Remove from all',
    'Create new folder': 'Create new folder',
    'Add to My Favourites': 'Add to My Favourites',
    Notifications: 'Notifications',
    'Therapeutic Area': 'Therapeutic Area',
    'Time Range': 'Time Range',
    'Guidelines comparison': 'Guidelines comparison',
    'More list items are available at the top': 'More list items are available at the top',
    'Innoplexus AG': 'Innoplexus AG',
    'All Rights Reserved.': 'All Rights Reserved.',
    'Reset to default': 'Reset to default',
    'Reset to last save': 'Reset to last save',
    'Enrollment Goal': 'Enrollment Goal',
    'Study Dates': 'Study Dates',
    Phase: 'Phase',
    'Condition(s)': 'Condition(s)',
    'Study Intervention(s)': 'Study Intervention(s)',
    Sponsor: 'Sponsor',
    'Grant ID': 'Grant ID',
    Source: 'Source',
    'Abstract/Overview': 'Abstract/Overview',
    Claims: 'Claims',
    Citations: 'Citations',
    'School/Department': 'School/Department',
    'Source Type': 'Source Type',
    'Date of Publication': 'Date of Publication',
    Committee: 'Committee',
    'Protocol used': 'Protocol used',
    'Experiment Details': 'Experiment Details',
    'Type of experiment': 'Type of experiment',
    'Materials used': 'Materials used',
    'Stage Relevance': 'Stage Relevance',
    Department: 'Department',
    'Drug Action Type': 'Drug Action Type',
    Instruments: 'Instruments',
    'Research Field': 'Research Field',
    Condition: 'Condition',
    Intervention: 'Intervention',
    'Highest Phase': 'Highest Phase',
    'Active ingredients': 'Active ingredients',
    'Regulatory Body': 'Regulatory Body',
    'View drug Details': 'View drug Details',
    'Drug ID': 'Drug ID',
    'Last Modified': 'Last Modified',
    'Primary Indications': 'Primary Indications',
    'Grant Type': 'Grant Type',
    'Principal Investigator': 'Principal Investigator',
    GUIDELINE: 'GUIDELINE',
    ASSIGNEE: 'ASSIGNEE',
    'PATENT ID': 'PATENT ID',
    'PRIORITY DATE': 'PRIORITY DATE',
    'Has full text': 'Has full text',
    to: 'to',
    'Cited by': 'Cited by',
    'CITED BY': 'CITED BY',
    'Show annotations': 'Show annotations',
    Deals: 'Deals',
    'Regulatory Updates': 'Regulatory Updates',
    'Search...': 'Search...',
    'Medical Affairs.board.title': 'Medical Affairs',
    'Medical Affairs.board.desc': 'Get insights around real-time updated data for clinical trials, guidelines and label revisions around your indication and interventions of interest.',
    'Medical Affairs.board.subtitle': 'Real-time insights for medical affairs personnel',
    'Regulatory Updates.board.title': 'Regulatory Updates',
    'Regulatory Updates.board.desc': 'Get real-time and relevant updates around regulatory submission of a drug from major regulatory bodies around your indication of interest.',
    'Enterprise Data': 'Enterprise Data',
    '\nInvestigational Drugs': '\nInvestigational Drugs',
    'Regulatory Updates.board.subtitle': 'Relevant updates for regulatory information of drug',
    'Sentiment Watch.board.title': 'Sentiment Watch',
    'Sentiment Watch.board.desc': 'Track real-time buzz around sponsors, SOV across drugs of interest, sentiment momentum, hot topics and weak signals from clinical or digital media.',
    'Sentiment Watch.board.subtitle': 'Track sentiments from digital and scientific media around drugs of interest',
    'Guideline Watch.board.title': 'Guideline Watch',
    'Guideline Watch.board.desc': 'Get insights and relevant updates around treatment guidelines published for your indication of interest.',
    'Guideline Watch.board.subtitle': 'Relevant updates from treatment guidelines around an indication',
    Summary: 'Summary',
    'My Dashboard.board.title': 'My Dashboard',
    Ontology: 'Ontology',
    'My Dashboard.board.desc': 'Select your own data points to generate widgets to get customized insights around your topics of interest.',
    'My Dashboard.board.subtitle': 'Define your own widgets',
    'Associated Pathways': 'Associated Pathways',
    Phenotypes: 'Phenotypes',
    'Competitive Landscape.board.title': 'Competitive Landscape',
    'Associated Drugs': 'Associated Drugs',
    'Synthetically Lethal Pairs': 'Synthetically Lethal Pairs',
    'Molecular Functions': 'Molecular Functions',
    'Cellular Components': 'Cellular Components',
    'Competitive Landscape.board.desc': 'Get detailed analysis of competitor interventions in clinical development with respect to its publications, clinical trials and regulatory approvals',
    'Biological Processes': 'Biological Processes',
    'Reactome Pathways': 'Reactome Pathways',
    'Competitive Landscape.board.subtitle': 'Real-time intelligence on competitors around drug targets, genes, proteins, drugs for each therapeutic area',
    'Kegg Pathways': 'Kegg Pathways',
    View: 'View',
    'No access': 'No access',
    'Regulatory UpdateTrends (for last 10 years)': 'Regulatory Update Trends (for last 10 years)',
    'distribution of top associated drugs': 'distribution of top associated drugs for the query within selected time interval',
    'Years of': 'Years of',
    'Start year of clinical trials': 'Start year of clinical trials',
    'Distribution of': 'Distribution of',
    'for top associated drugs': 'for top associated drugs',
    'Please select atleast of one intervention': 'Please select atleast of one intervention',
    'Entity network on': 'Entity network on',
    'Relationship network of entities based on': 'Relationship network of entities based on',
    'for the given gene within selected time interval': 'for the given gene within selected time interval',
    Success: 'Success',
    Error: 'Error',
    Info: 'Info',
    Warning: 'Warning',
    '400.message': 'Oops! Something went wrong, Please try again.',
    '401.message': 'Your current session has expired.',
    '403.message': "You don't have required permissions, please contact admin",
    'login.message': 'Login Successful!',
    'deleteAccount.message': 'Your account has been deleted successfully',
    'potliCreate.message': 'Folder created successfully',
    'potliDelete.message': 'Folder deleted successfully',
    'bibliographyCreate.message': 'Bibliography Folder created successfully',
    'bibliographyDelete.message': 'Bibliography Folder deleted successfully',
    'saveCluster.message': 'Cluster has been created successfully. Go to "MY WORKSPACE" to view your saved cluster',
    'editCluster.message': 'Cluster has been updated successfully',
    'deleteCluster.message': 'Cluster has been deleted successfully',
    'deleteData.message': 'All user activities have been deleted successfully',
    'article.message': 'Thank you for your feedback',
    'bookmark.message': 'Bookmark has been created successfully',
    'editBookmark.message': 'Bookmark has been updated successfully',
    'All associated drugs': 'All associated drugs',
    'No notification available': 'No notification available',
    'deleteBookmark.message': 'Bookmark has been removed successfully',
    'bibliography.message': 'Bibliography has been created successfully',
    'editBibliography.message': 'Bibliography has been updated successfully',
    'deleteBibliography.message': 'Bibliography has been removed successfully',
    'logout.message': 'Logged out successfully',
    'You trial period has been expired': 'Your trial period has expired, Please click on the registration link received in your inbox to continue.',
    'Top Trends': 'Top Trends',
    'Top Indications': 'Top Indications',
    'Top Drugs': 'Top Drugs',
    'Top Drug Targets': 'Top Drug Targets',
    'Top Affiliations': 'Top Affiliations',
    'Top Journals': 'Top Journals',
    'Top Authors': 'Top Authors',
    'Clinical trial distribution (Phase)': 'Clinical trial distribution (Phase)',
    'Clinical trial distribution (Status)': 'Clinical trial distribution (Status)',
    'Geographical distribution for Clinical trials (Phase)': 'Geographical distribution for Clinical trials (Phase)',
    'Top Sponsors': 'Top Sponsors',
    'Select TA': 'Select TA',
    'Custom Sort': 'Custom Sort',
    ALL: 'ALL',
    FDA: 'FDA',
    ANMAT: 'ANMAT',
    EMA: 'EMA',
    ANVISA: 'ANVISA',
    CHILE: 'CHILE',
    'HEALTH CANADA': 'HEALTH CANADA',
    OTHERS: 'OTHERS',
    'DRUGS LAUNCHED': 'DRUGS LAUNCHED',
    ALERTS: 'ALERTS',
    'DAC REPORTS': 'DAC REPORTS',
    'NEWS FEEDS': 'NEWS FEEDS',
    'Study Details': 'Study Details',
    Eligibility: 'Eligibility',
    Channel: 'Channel',
    Results: 'Results',
    'Mentions in Other Sources': 'Mentions in Other Sources',
    PERSONAL: 'PERSONAL',
    'INN NAME / ACTIVE INGREDIENT': 'INN NAME / ACTIVE INGREDIENT',
    'BIOSIMILAR PRODUCTS': 'BIOSIMILAR PRODUCTS',
    'APPROVED LABELLED PRODUCTS': 'APPROVED LABELLED PRODUCTS',
    'NEW MOLECULAR ENTITY': 'NEW MOLECULAR ENTITY',
    'BREAKTHROUGH APPROVAL': 'BREAKTHROUGH APPROVAL',
    'ACCELERATED APPROVAL': 'ACCELERATED APPROVAL',
    'PRIORITY REVIEW STATUS': 'PRIORITY REVIEW STATUS',
    'ORPHAN STATUS': 'ORPHAN STATUS',
    'ON FAST TRACK': 'ON FAST TRACK',
    'MECHANISM OF ACTION': 'MECHANISM OF ACTION',
    'DRUG TARGET': 'DRUG TARGET',
    'DRUG CLASS': 'DRUG CLASS',
    'TARGETED INDICATIONS FOR HIGHEST PHASE OF DEVELOPMENT': 'TARGETED INDICATIONS FOR HIGHEST PHASE OF DEVELOPMENT',
    'OTHER INDICATIONS UNDER TRIAL': 'OTHER INDICATIONS UNDER TRIAL',
    'Active Comparator': 'Active Comparator',
    Description: 'Description',
    Drug: 'Drug',
    'Abstract Unavailable': 'Abstract Unavailable',
    'Intervention - Marketed': 'Intervention - Marketed',
    'Intervention - Clinical phase': 'Intervention - Clinical phase',
    'Intervention - Preclinical': 'Intervention - Preclinical',
    Gene: 'Gene',
    Protein: 'Protein',
    Author: 'Authors',
    'Biological Pathway': 'Biological Pathway',
    Taxonomy: 'Taxonomy',
    'Cell Line': 'Cell Line',
    'Medical Procedure': 'Medical Procedure',
    Device: 'Device',
    Technology: 'Technology',
    Toxicity: 'Toxicity',
    'Adverse Reaction': 'Adverse Reaction',
    MoA: 'MoA',
    'Lab Reagent': 'Lab Reagent',
    'Lab device': 'Lab device',
    Chemical: 'Chemical',
    'Search Indication': 'Search Indication',
    'Search Intervention - Marketed': 'Search Intervention - Marketed',
    'Search Intervention - Clinical phase': 'Search Intervention - Clinical phase',
    'Search Intervention - Preclinical': 'Search Intervention - Preclinical',
    'Search Gene': 'Search Gene',
    'Search Protein': 'Search Protein',
    'Search Author': 'Search Author',
    'Search Biological Pathway': 'Search Biological Pathway',
    'Search Taxonomy': 'Search Taxonomy',
    'Search Cell Line': 'Search Cell Line',
    'Search Medical Procedure': 'Search Medical Procedure',
    'Search Device': 'Search Device',
    'Search Technology': 'Search Technology',
    'Search Toxicity': 'Search Toxicity',
    'Search Adverse Reaction': 'Search Adverse Reaction',
    'Search MoA': 'Search MoA',
    'Search Lab Reagent': 'Search Lab Reagent',
    'Search Lab device': 'Search Lab device',
    'Search Chemical': 'Search Chemical',
    Citation: 'Citation',
    General: 'General',
    Development: 'Development',
    Financial: 'Financial',
    Analysis: 'Analysis',
    'Patent Families': 'Patent Families',
    Sources: 'Sources',
    'Change History': 'Change History',
    'Publication Trends': 'Publication Trends',
    'Biomedical Entities': 'Biomedical Entities',
    'Academic Access': 'Academic Access',
    'Experience the': 'Experience the',
    'Life Sciences AI.': 'Life Sciences AI.',
    'Now.': 'Now.',
    'Utilisez le courrier électronique de votre université pour demander une invitation.': 'ACCESS NOW',
    'We are committed to help researchers worldwide': 'We are committed to help researchers worldwide in their quest to solve the challenges in Life Sciences.  We have launched our Ontosight Academic Access Program which offers free access of Ontosight Academic version to all researchers and students, affiliated with any university worldwide.',
    'Use your University email for requesting invite.': 'Use your University email for requesting invite.',
    '35M': '35M',
    Theses: 'Theses',
    '0.5M': '0.5M',
    '2M': '2M',
    '36K': '36K',
    'Drug Profiles': 'Drug Profiles',
    '40K': '40K',
    'Genes Profiles': 'Genes Profiles',
    'Why Ontosight': 'Why Ontosight',
    Academic: 'Academic',
    'Search using Life Science ontology': 'Search using Life Science ontology',
    'Our ontology understands life science concepts and terms.': 'Our ontology understands life science concepts and terms. It is able to understand that acronyms are related to various concepts and can distinguish between two concepts that share the same acronym such as EGFR.',
    'Single platform': 'Single platform',
    'Academic consolidates your search by connecting data from thousands* of life science databases. Ontosight': 'Academic consolidates your search by connecting data from thousands* of life science databases. Ontosight',
    'Academic actively crawls and aggregates over 97% of the life science public domain.': 'Academic actively crawls and aggregates over 97% of the life science public domain.',
    'Updated in real-time': 'Updated in real-time',
    'New data is assimilated into our data ocean immediately after publication. Set alerts for specific search clusters to stay on top of your unique research area.': 'New data is assimilated into our data ocean immediately after publication. Set alerts for specific search clusters to stay on top of your unique research area.',
    'Find the cutting-edge': 'Find the cutting-edge',
    'Academic can help you navigate fast-moving research frontiers. Ontosight': 'Academic can help you navigate fast-moving research frontiers. Ontosight',
    'Academic encompasses a massive volume of life science congresses and theses, ensuring that you have access to the most nascent research being done in your field.': 'Academic encompasses a massive volume of life science congresses and theses, ensuring that you have access to the most nascent research being done in your field.',
    'A PRODUCT BY': 'A PRODUCT BY',
    "Innoplexus is structuring the world's information": "Innoplexus is structuring the world's information to make AI easier. Innoplexus offers Data as a Service (DaaS) and Continuous Analytics as a Service (CAaS) products, leveraging Artificial Intelligence, proprietary algorithms and patent pending technologies to help global Life Sciences organisations with access to relevant data, intelligence & intuitive insights, across pre-clinical, clinical, regulatory and commercial stages of a drug.",
    'We automate the collection and curation of data using technologies such as natural language processing': 'We automate the collection and curation of data using technologies such as natural language processing, network analysis, ontologies, computer vision and entity normalisation. Our clients are Fortune 500 companies including some of the world’s largest pharmaceutical companies.',
    'VISIT WEBSITE': 'VISIT WEBSITE',
    'Phrase match': 'Phrase match',
    'Has mentions In': 'Has mentions In',
    'No folders available': 'No folders available',
    'Start year': 'Start Year',
    'End Year': 'End Year',
    number_of_records: 'Number of records',
    file_format: 'File Format',
    Select: 'Select',
    equalizer: 'equalizer',
    'Select Language': 'Select Language',
    MOA: 'MOA',
    'Search Equalizer': 'Search Equalizer',
    'Source Link': 'Source Link',
    'View Details': 'View Details',
    demo: 'Demo',
    Bibliography: 'Bibliography',
    Bookmark: 'Bookmark',
    'Select Indications': 'Select Indications',
    filter: 'filter',
    'Related drugs': 'Related drugs',
    'Widget Preview': 'Widget Preview',
    'Zoom In': 'Zoom In',
    'Zoom Out': 'Zoom Out',
    'Select Societies': 'Select Societies',
    'Select Interventions': 'Select Interventions',
    'Select Geography': 'Select Geography',
    'Select application Number': 'Select application Number',
    'Bibliography Export': 'Bibliography Export',
    'Back to home': 'Back to home'
  },
  de: {
    lastUpdated: '2019-05-31T10:18:40.940Z',
    version: '1.1',
    'Header.discover': 'Entdecken',
    'Header.myWorkspace': 'Mein Arbeitsbereich',
    'Header.dashboards': 'Dashboards',
    'Header.marketplace': 'Marktplatz',
    "Header.FAQ's": "FAQ's",
    'Header.Profile': 'Profil',
    'Header.AccountSettings': 'Kontoeinstellungen',
    'Header.Subscriptions': 'Abonnements',
    'Header.Logout': 'Logout',
    'Footer.allRights': '- Alle Rechte vorbehalten.',
    'Btn.Cancel': 'Stornieren',
    'Btn.clearAll': 'Alles löschen',
    'Btn.Download': 'Download',
    'Search.hello': 'Hallo',
    'Search.goodToSee': 'Schön Sie hier zu sehen!',
    'Search.searchHereFor': 'Suchen Sie hier nach Indikationen, Interventionen, Genen, Forschern oder beliebigen Stichwörtern',
    'Search.typeBefore': 'Geben Sie vor einem Namen @ ein, um nach einem Forscher zu suchen',
    'Search.advanceSearch': 'Erweiterte Suche',
    'Search.SEARCH': 'SUCHE',
    'Search.Search': 'Suche',
    'Cluster.SaveAsCluster': 'Speichern Sie als Cluster',
    'Cluster.Context': 'Kontext',
    'Cluster.ClusterName': 'Clustername',
    'Cluster.EmailFrequency': 'Email Häufigkeit',
    'Get Email on any new content': 'Erhalten Sie E-Mails zu neuen Inhalten',
    Weekly: 'Wöchentlich',
    Daily: 'Täglich',
    'Raw data result': 'Rohdatenergebnis',
    'SearchAdvance.backToBasicSearch': 'Zurück zur einfachen Suche',
    'SearchAdvance.findArticle': 'Artikel finden mit ...',
    'Indication(s)': 'Anzeige (n)',
    'Intervention(s)': 'Intervention (en)',
    'Protein(s)': 'Protein (e)',
    'Gene(s)': 'Gen (e)',
    'Author(s)': 'Autor (en)',
    Others: 'Andere',
    Literature: 'Literatur',
    KOLs: 'KOLs',
    Published: 'Veröffentlicht',
    Publications: 'Veröffentlichungen',
    'Clinical Trials': 'Klinische Studien',
    Congresses: 'Kongresse',
    'Theses & Dissertations': 'Abschlussarbeiten & Dissertationen',
    Patents: 'Patente',
    'News & Press Releases': 'News & Pressemitteilungen',
    'Treatment Guidelines': 'Behandlungsrichtlinien',
    Grants: 'Zuschüsse',
    Unpublished: 'Nicht veröffentlicht',
    'Sort By': 'Sortiere nach',
    Recency: 'Aktualität',
    Relevance: 'Relevanz',
    'Related Concepts': 'Verwandte Konzepte',
    By: 'Durch',
    Correlation: 'Korrelation',
    Frequency: 'Frequenz',
    Reset: 'Zurücksetzen',
    Apply: 'Anwenden',
    Filter: 'Filter',
    'Biomedical Entity Type': 'Biomedizinischer Entitätstyp',
    'Study/Experiment Type': 'Studien- / Versuchstyp',
    Species: 'Spezies',
    From: 'Von',
    To: 'Zu',
    Publication: 'Veröffentlichung',
    Trends: 'Trends',
    '(for last 10 years)': '(für die letzten 10 Jahre)',
    'Clinical Trial': 'Klinische Studie',
    Congress: 'Kongress',
    Patent: 'Patent',
    'News & Press Release': 'News & Pressemitteilung',
    Grant: 'Förderung',
    Sponsorship: 'Sponsoring',
    Country: 'Land',
    'Data Source': 'Datenquelle',
    Institution: 'Institution',
    'Download Results': 'Ergebnisse herunterladen',
    'Number of Records': 'Anzahl der Datensätze',
    'File Format': 'Dateiformat',
    'Downloading...': 'Wird heruntergeladen...',
    'Read more': 'Weiterlesen',
    'Read less': 'Weniger lesen',
    'Back to list': 'Zurück zur Liste',
    Authors: 'Autoren',
    FINGERPRINTS: 'FINGERPRINTS',
    Abstract: 'Abstract',
    More: 'Mehr',
    'Show less': 'Weniger anzeigen',
    PMID: 'PMID',
    DOI: 'DOI',
    'Thought Leaders': 'Meinungsführer',
    'Commercial KOLs': 'Kommerzielle KOLs',
    'Scientific KOLs': 'Wissenschaftliche KOLs',
    Speakers: 'Sprecher',
    Resources: 'Ressourcen',
    'Score Equalizer': 'Score Equalizer',
    'Total Score': 'Gesamtpunktzahl',
    'Must be 100%': 'Muss 100% ergeben',
    Save: 'sparen',
    Guidelines: 'Richtlinien',
    Societies: 'Gesellschaften',
    'Top Hospital KOLs': 'Top Krankenhaus KOLs',
    'Regulatory Bodies': 'Regulatorische Behörden',
    HTA: 'HTA',
    Advocacy: 'Advocacy',
    'Select Country': 'Land auswählen',
    LOGIN: 'LOGIN',
    'Life Sciences': 'Biowissenschaften',
    'Research Simplified': 'Forschung vereinfacht',
    'We know you': 'Wir wissen, dass Sie sich nicht für jede Forschung interessieren, sondern für die, die Ihnen wichtig sind.',
    'matter to you': 'Jetzt wird es kuratiert, analysiert und auf einer ',
    platter: 'Platte angeordnet',
    '35M+': '35M +',
    '500K+': '500 K +',
    'Clinical trials': 'Klinische Versuche',
    '850K+': '850 K +',
    'Congress articles': 'Kongressartikel',
    '127K+': '127K +',
    'Drug profiles': 'Medikamentenprofile',
    '1M+': '1M +',
    'Also latest lifescience news': 'Auch die neuesten Life Sciences News, regulatorische Updates, analytische Engine & vieles mehr.',
    'ROLLING OUT INVITES - GET ONE NOW': 'ROLLING OUT INVITES - GET ONE NOW',
    img: 'img',
    'Asset classes': 'Asset-Klassen',
    'Get access to wide range': 'Erhalten Sie Zugriff auf ein breites Spektrum an Daten aus den Biowissenschaften, z. B. Publikationen, klinische Studien, Kongresse, Abschlussarbeiten, Patente, Nachrichten, Pressemitteilungen, Stipendien und Behandlungsrichtlinien.',
    'Search crumbs': 'Search crumbs',
    'A unique feature': 'Eine einzigartige Funktion, die dem Benutzer dabei hilft, die Abfrage zu durchlaufen und den richtigen Kontext zu erstellen, bis die gewünschten Ergebnisse erzielt werden.',
    'Concept based search': 'Konzeptbasierte Suche',
    'Our innovative and intuitive': 'Unsere innovative und intuitive semantische Suchfunktion kann biomedizinische Konzepte mithilfe unserer Ontologie-Datenbank verstehen. Sie erhalten relevante Ergebnisse im Kontext für Ihre Suchanfragen. Mit unseren erweiterten Suchfunktionen können Sie Ihre Suchergebnisse nach einer bestimmten Kategorie von Konzepten wie Indikation, Intervention, Gen oder Protein auswählen.',
    'Detailed insights': 'Detaillierte Einblicke',
    'You can deep dive': 'Durch unsere verschiedenen Analyseebenen und -module können Sie tief tauchen und Ihre Forschung auf die nächste Stufe bringen. Holen Sie sich Interventionsansichten, Gen-Ansichten, Forscherprofile usw. für Ihre Suchanfrage und treffen Sie eine fundierte Entscheidung.',
    Discovery: 'Entdeckung',
    'Our AI based engine': 'Unsere KI-basierte Engine sammelt, bereinigt und kategorisiert verschiedene Asset-Klassen und liefert Ihnen ein umfassendes Ergebnis für Ihre Suchabfrage, einschließlich verwandter Konzepte. Dies hilft Ihnen, fundierte Entscheidungen zu treffen.',
    Annotations: 'Anmerkungen',
    'Get the flexibility of adding': 'Erhalten Sie die Flexibilität, Kommentare zu jedem Dokument hinzuzufügen, das Sie interessiert, und rufen Sie es zur späteren Verwendung schnell auf.',
    'Cluster updates': 'Cluster-Updates',
    'Get real-time updated': 'Erhalten Sie in Echtzeit aktualisierte Benachrichtigungen zu interessanten Themen, indem Sie Ihren eigenen Arbeitsbereich erstellen (Forschungscluster). Sehen und managen Sie die neuesten Aktualisierungen zu Ihren Forschungsclustern seit Ihrem letzen Abruf. ',
    'Customizable dashboards': 'Individualisierbare Dashboards',
    'You can create your own': 'Sie können Ihre eigenen Grafiken und Visualisierungen erstellen, indem Sie Ihre eigenen Parameter auswählen. Sie können verschiedene Diagrammtypen aus der vorgefüllten Widgets-Bibliothek auswählen und auch exportieren.',
    Marketplace: 'Marktplatz',
    'You will have access': 'Sie haben auch Zugriff auf nicht veröffentlichte / fehlgeschlagene Versuchsdaten. Sie können diese Plattform verwenden, um experimentelle Daten zu verkaufen oder zu kaufen. Durch die Blockchain-Technologie wird das Eigentum der von Ihnen veröffentlichten Experimente sichergestellt. ',
    'A PRODUCT BY INNOPLEXUS': 'EIN PRODUKT VON INNOPLEXUS',
    'Innoplexus is structuring': 'Innoplexus strukturiert die weltweiten Informationen um es der KI einfacher zu machen. Innoplexus bietet Data as a Service (DaaS) und Continuous Analytics as a Service (CAaS) Produkte an. Dabei werden künstliche Intelligenz, proprietäre Algorithmen und zum Patent angemeldete Technologien eingesetzt, um globale Life Sciences-Organisationen zu unterstützen Zugriff auf relevante Daten, Intelligenz und intuitive Erkenntnisse über präklinische, klinische, regulatorische und kommerzielle Stadien eines Arzneimittels zu erhalten.',
    'We automate the collection': 'Wir automatisieren das Sammeln und Kurieren von Daten mithilfe von Technologien wie natürliche Sprachverarbeitung, Netzwerkanalyse, Ontologien, Computer Vision und Normalisierung von Entitäten. Unsere Kunden sind Fortune-500-Unternehmen, darunter einige der weltweit größten Pharmaunternehmen.',
    'Drug Discovery': 'Drug Discovery',
    Clinical: 'Klinisch',
    'Header.Frequently asked questions': 'Häufig gestellte Fragen',
    'Header.More about you': 'Mehr über Sie',
    'Header.Check / Update your settings': 'Überprüfen / Aktualisieren Sie Ihre Einstellungen',
    'Header.Check / Update your subscriptions': 'Überprüfen / Aktualisieren Sie Ihre Abonnements',
    'Header.See you next time!': 'Bis zum nächsten Mal!',
    'Header.Context based intelligent search': 'Kontextbasierte intelligente Suche über verschiedene Datenquellen',
    'Header.Your history, saved searches': 'Ihr Verlauf, gespeicherte Suchen und mehr',
    'Header.Deep dive with various analysis modules': 'Deep Dive mit verschiedenen Analysemodulen',
    'Header.Sell and buy unpublished data': 'Verkaufen und kaufen Sie unveröffentlichte Daten',
    'Discover.Language': 'Sprache',
    'Reset all Filters': 'Alle Filter zurücksetzen',
    'Gene.About': 'Über',
    'Gene.Official Full Name': 'Offizieller vollständiger Name',
    'Gene.Gene type': 'Gen-Typ',
    'Gene.Organism': 'Organismus',
    'Gene.Also known as': 'Auch bekannt als',
    'Gene.Primary Source': 'Primäre Quelle',
    'Gene.Location': 'Ort',
    'Gene.Chromosome': 'Chromosom',
    'Gene.Start': 'Start',
    'Gene.End': 'Ende',
    'Gene.Size': 'Größe',
    'Gene.Orientation': 'Orientierung',
    'Gene.Score': 'Score',
    'Gene.Disorder': 'Erkrankung',
    'Gene.Metadata of all': 'Metadaten aller assoziierten Arzneimittel für das ausgewählte Gen',
    Name: 'Name',
    Indication: 'Indikation',
    Sponsors: 'Sponsoren',
    Total: 'Gesamt',
    'SL pairs number limit': 'SL-Paarnummernlimit',
    'REGULATORY DESIGNATIONS': 'REGULIERUNGSBEZEICHNUNGEN',
    'Header.Customer Support': 'Kundendienst',
    Analytics: 'Analytics',
    Custom: 'Custom',
    'Author.AFFILIATIONS': 'ZUGEHÖRIGKEIT',
    'Author.KEY RESEARCH AREAS': 'WICHTIGE FORSCHUNGSBEREICHE',
    'Author.Data unavailable': 'Daten nicht verfügbar',
    'Author.ACTIVITY TREND': 'AKTIVITÄTENTREND',
    'Author.RESEARCH ACTIVITY': 'FORSCHUNGSAKTIVITÄT',
    'Author.Select Option': 'Wähle Sie eine Option',
    'Author.Select': 'Wählen',
    'Author.Contribution': 'Beitrag',
    'Author.Global view': 'Globale Ansicht',
    'Author.List view': 'Listenansicht',
    'Author.Number of connections': 'Anzahl der Verbindungen',
    'Author.Affiliation unavailable': 'Zugehörigkeit nicht verfügbar',
    'Author.Country unavailable': 'Land nicht verfügbar',
    'Preclinical.Originator': 'Urheber',
    'Preclinical.Synonyms': 'Synonyme',
    'Preclinical.Preclinical Name': 'Präklinischer Name',
    'Preclinical.Intervention : Preclinical Stage': 'Intervention: Präklinische Phase',
    'Preclinical.Select': 'Wählen',
    Molecule: 'Molekül',
    'Related Articles': 'In Verbindung stehende Artikel',
    About: 'Über',
    'Research Activity': 'Forschungsaktivität',
    Network: 'Netzwerk',
    'Profile.Delete Activity History': 'Aktivitätsverlauf löschen',
    'Profile.This action will clear': 'Mit dieser Aktion werden alle Ihre Aktivitäten in Ontosight gelöscht, einschließlich früherer Suchanfragen, Lesezeichen, Workspace und Anmerkungen. Ihre Lesezeichen und Ihr Workspace sind nur für Sie sichtbar und tragen dazu bei, Ihr Erlebnis auf der Plattform zu verbessern.',
    'Profile.Delete history': 'Verlauf löschen',
    'Profile.Delete Ontosight Account': 'Ontosight-Konto löschen',
    'Profile.Deleting your account': 'Das Löschen Ihres Kontos ist dauerhaft und kann nicht rückgängig gemacht werden. Dadurch werden alle persönlichen Daten Ihres Kontos gelöscht.',
    'Profile.Delete Account': 'Konto löschen',
    'Profile.Personal Details': 'Persönliche Details',
    'Profile.First Name': 'Vorname',
    'Profile.Last Name': 'Nachname',
    'Profile.Organization': 'Organisation',
    'Profile.Contact Details': 'Kontaktdetails',
    'Profile.Email': 'Email',
    'Profile.Are you sure, you want to delete all': 'Sind Sie sicher, dass Sie Ihren gesamten Aktivitätsverlauf in Ontosight löschen möchten, einschließlich früherer Suchen, Lesezeichen, Workspace und Anmerkungen?',
    'Profile.Delete': 'Löschen',
    'Profile.Cancel': 'Abbrechen',
    'Profile.It may take few days for your data': 'Es kann einige Tage dauern, bis Ihre Daten gelöscht werden. Sie verlieren sofort den Zugriff auf Ihr Konto, und alle Ihre zugehörigen Daten werden permanent gelöscht.',
    'Profile.Are you sure, you want to close your account ?': 'Sind Sie sicher, dass Sie Ihr Konto schließen möchten?',
    'Profile.Edit': 'Bearbeiten',
    'Account Settings': 'Kontoeinstellungen',
    Profile: 'Profil',
    'Identification & Approval': 'Identifikation & Zulassung',
    'Mechanism & Classes': 'Mechanismus & Klassen',
    Indications: 'Indikationen',
    'Competing/Related Drugs': 'Konkurrierende / verwandte Arzneimittel',
    'Trial Landscape': 'Studienlandschaft',
    'Regulatory.Latest regulatory Updates': 'Neueste regulatorische Updates',
    'Regulatory.Guidelines': 'Guideline',
    'Regulatory.Disease and drug guidelines': 'Richtlinien zu Krankheiten und Arzneimittel',
    'Regulatory.Please select the required fields.': 'Bitte wählen Sie die erforderlichen Felder aus.',
    'Regulatory.Select Interventions': 'Wählen Sie Interventionen aus',
    'Regulatory.No. of guidelines': 'Anzahl der Richtlinien',
    'Regulatory.Interventions': 'Interventionen',
    'Regulatory.Data unavailable': 'Daten nicht verfügbar',
    'Regulatory.Drug launches across targets': 'Arzneimittellaunzierungen über Targets hinweg',
    'Regulatory.Status across regulatory bodies': 'Status bei Regulierungsbehörden',
    'Regulatory.From FDA and EMA': 'Von der FDA und EMA',
    'Regulatory.Status': 'Status',
    'Clarivate.Highest Phase': 'Höchste Phase',
    'Clarivate.Back to search': 'Zurück zur Suche',
    'Clarivate.No data returned from clarivate API': 'Keine Daten von der Clarivate-API zurückgegeben',
    'Clarivate.REGULATORY DESIGNATIONS': 'REGULIERUNGSBEZEICHNUNGEN',
    'Clarivate.Search by designation': 'Suche nach Bezeichnung',
    'Clarivate.DEVELOPMENT PROFILE': 'ENTWICKLUNGSPROFIL',
    'Clarivate.DEVELOPMENT STATUS': 'ENTWICKLUNGSSTATUS',
    'Clarivate.Type': 'Typ',
    'Clarivate.Search Type': 'Siche Typ',
    'Clarivate.Select Type': 'Typ auswählen',
    'Clarivate.Select Indication': 'Indikation auswählen',
    'Clarivate.Search Indication': 'Suche Indikation',
    'Clarivate.Indication': 'Indikation',
    'Clarivate.Status': 'Status',
    'Clarivate.Search Status': 'Suche Status',
    'Clarivate.Select Status': 'Status auswählen',
    'Clarivate.Reset all Filters': 'Alle Filter zurücksetzen',
    'Clarivate.Search by company': 'Suche nach Unternehmen',
    'Clarivate.SALES & FORECAST': 'SALES & PROGNOSE',
    'Clarivate.DEALS': 'DEALS',
    'Clarivate.Search by title': 'Suche nach Titel',
    'Clarivate.EXPERT REVIEW': 'EXPERTENBERICHT',
    'Clarivate.SWOT ANALYSIS': 'SWOT-ANALYSE',
    'Clarivate.PATENT FAMILIES': 'PATENTFAMILIEN',
    'Clarivate.SOURCES': 'QUELLEN',
    'Clarivate.Sort by Date': 'Nach Datum sortieren',
    'Clarivate.Citations added (ID)': 'Zitationen hinzugefügt (ID)',
    'Clarivate.Citations removed (ID)': 'Zitationen entfernt (ID)',
    'ClinicalTrials.Trial ID': 'Studien-ID',
    'ClinicalTrials.Trial Snapshot': 'Studien Snapshot',
    'ClinicalTrials.Abstract': 'Abstract',
    'ClinicalTrials.Description': 'Beschreibung',
    'ClinicalTrials.Phase': 'Phase',
    'ClinicalTrials.Enrollment Goal': 'Rekrutierungsziel',
    'ClinicalTrials.Study Dates': 'Studientermine',
    'ClinicalTrials.Condition(s)': 'Bedingung(en)',
    'ClinicalTrials.Study Intervention(s)': 'Studienintervention(en)',
    'ClinicalTrials.Sponsor': 'Sponsor',
    'ClinicalTrials.Type': 'Typ',
    'ClinicalTrials.Design': 'Design',
    'ClinicalTrials.Primary Outcome Measure': 'Primäre Ergebnismessung',
    'ClinicalTrials.Time Frame': 'Zeitrahmen',
    'ClinicalTrials.Measure': 'Messen',
    'ClinicalTrials.Secondary Outcome Measure': 'Sekundäre Ergebnismessung',
    'ClinicalTrials.Arms and Active Intervention': 'Arme und aktive Intervention',
    'ClinicalTrials.Accepts Healthy Volunteers': 'Akzeptiert gesunde Freiwillige',
    'ClinicalTrials.Genders Eligible for Study': 'Für ein Studium geeignete Geschlechter',
    'ClinicalTrials.Lower age limit for Study': 'Untere Altersgrenze für die Studie',
    'ClinicalTrials.Upper age limit for Study': 'Obere Altersgrenze für die Studie',
    'ClinicalTrials.General': 'Allgemeines',
    'ClinicalTrials.Inclusion Criteria': 'Einschlusskriterien',
    'ClinicalTrials.Exclusion Criteria': 'Ausschlusskriterien',
    'ClinicalTrials.Locations': 'Standorte',
    'ClinicalTrials.Contacts': 'Kontakte',
    'ClinicalTrials.Sponsors': 'Sponsoren',
    'ClinicalTrials.Principal Investigator': 'Leitender Prüfer',
    'ClinicalTrials.Collaborators': 'Mitarbeiter',
    'ClinicalTrials.Back': 'Zurück',
    'ClinicalTrials.Measure Description': 'Maßnahme Beschreibung',
    'ClinicalTrials.Population Description': 'Bevölkerungsbeschreibung',
    'ClinicalTrials.Reporting Groups': 'Berichtsgruppen',
    'ClinicalTrials.Measured Values': 'Messwerte',
    'ClinicalTrials.Related Clinical Trials': 'Verwandte klinische Studien',
    'Research Clusters': 'Forschungscluster',
    'Search History': 'Suchverlauf',
    'My Favourites': 'Mein Favoriten',
    'My Bibliography': 'Meine Bibliographie',
    'Workspace.SEARCH': 'SUCHE',
    'Workspace.Cluster Name': 'Clustername',
    'Workspace.Settings': 'Einstellungen',
    'Workspace.Email Frequency': 'E-Mail-Häufigkeit',
    'Workspace.Delete': 'Löschen',
    'Workspace.Cancel': 'Abbrechen',
    'Workspace.Save': 'Speichern',
    'Workspace.Cluster name': 'Clustername',
    'Workspace.No updates available': 'Keine Updates verfügbar',
    'Workspace.Select Option': 'Option auswählen',
    'Workspace.Search results': 'Suchergebnisse',
    'Workspace.Repeat this search': 'Suche wiederholen',
    'Workspace.Search History': 'Suchverlauf',
    'Workspace.No search history recorded': 'Keine Suchhistorie aufgezeichnet',
    'Workspace.No clusters created': 'Keine Cluster erstellt',
    'Workspace.Show only new updates': 'Nur neue Updates anzeigen',
    'Workspace.No folder has been created': 'Kein Ordner ist angelegt',
    'Workspace.Delete Potli': 'Löschen',
    'Workspace.No favourites found': 'Keine Favoriten gefunden',
    'Workspace.Select': 'wählen',
    'Workspace.list items avilable at the top': 'Listen Sie Items die oben verfügbar sind',
    'Workspace.No my bibliographys created': 'Es wurden keine Bibliographien erstellt.',
    'Workspace.Delete My Bibliography': 'Löschen',
    'Workspace.No bibliographys found': 'Keine Bibliographien gefunden',
    'Custom.No widgets available': 'Keine Widgets verfügbar',
    'Custom.My Dashboard': 'Mein Dashboard',
    'Custom.Add Widget': 'Widget hinzufügen',
    'Custom.Top filters': 'Top-Filter',
    'Custom.Widget name': 'Widget-Name',
    'Custom.1. Widget name': '1. Name des Widgets',
    'Custom.2. Define context': '2. Definieren Sie den Kontext',
    'Custom.3. Select asset class': '3. Wählen Sie die Assetklasse aus',
    'Custom.Select dataset': 'Datensatz auswählen',
    'Custom.Cancel': 'Abbrechen',
    'Custom.Save Widget': 'Widget speichern',
    'Custom.Select graph': 'Diagramm auswählen',
    'Custom.No dimensions available': 'Keine Dimensionen verfügbar',
    'Custom.X-axis': 'X-Achse',
    'Custom.Dimension': 'Dimension',
    'Custom.Y-axis': 'Y-Achse',
    'Custom.Time Range': 'Zeitspanne',
    'Custom.Complete the above steps to see a preview of your widget.': 'Führen Sie die obigen Schritte aus, um eine Vorschau Ihres Widgets zu sehen.',
    'Custom.No data available': 'Keine Daten verfügbar',
    'Custom.5. Define dimension': '5. Dimension definieren',
    'Custom.Select': 'Wählen',
    'Custom.Search': 'Suche',
    'Custom.No graphs available': 'Keine Graphen verfügbar',
    'Custom.Query': 'Abfrage',
    'Competitive.Search here for indications, interventions, genes, researchers': 'Suchen Sie hier nach Indikationen, Interventionen, Genen, Forschern oder anderen Stichwörtern, um eine Wettbewerbslandschaft zu erhalten',
    'Competitive.Time Range': 'Zeitspanne',
    'Competitive.Name': 'Name',
    'Competitive.Regulatory body': 'Regulatorische Behörde',
    'Competitive.Company': 'Unternehmen',
    'Competitive.Indication': 'Indikation',
    'Competitive.Phase of Development': 'Entwicklungsphase',
    'Competitive.Metadata for all related marketed drugs for selected parameters': 'Metadaten für alle verwandten Arzneimittel für ausgewählte Parameter',
    'Competitive.Clinical Trials': 'Klinische Studien',
    'Competitive.Show more': 'Merh anzeigen',
    'Competitive.Back': 'Zurück',
    'Competitive.Details for': 'Details für',
    'Competitive.Type @ before a name to search for a researcher': 'Geben Sie vor einem Namen @ ein, um nach einem Forscher zu suchen',
    'Competitive.Search': 'Suche',
    'Competitive.Did you mean': 'Meinten Sie',
    'Competitive.SEARCH': 'SUCHE',
    'Competitive.Search...': 'Suche...',
    'Competitive.You typed': 'Du hast geschrieben',
    'Competitive.Concept': 'Konzept',
    'Competitive.Search drug....': 'Suche Arzneimittel...',
    'Intervention.Dosage & Route of Administration': 'Dosierung & Verabreichungsweg',
    'Intervention.Related Publications on drug’s pharmacology': 'Verwandte Veröffentlichungen zur Pharmakologie des Arzneimittels',
    'Intervention.Back': 'Zurück',
    'Back to Basic Search': 'Zurück zur einfachen Suche',
    'Find articles with...': 'Artikel finden mit ...',
    'Clear All': 'Alles löschen',
    Concept: 'Konzept',
    'You typed': 'Du hast geschrieben',
    Download: 'Herunterladen',
    Cancel: 'Abbrechen',
    Search: 'Suche',
    'Asset Type': 'Asset-Typ',
    'KOL Category Distribution': 'KOL-Kategorienverteilung',
    'Influence Map': 'Einflusskarte',
    'KOL Ranking': 'KOL-Rangliste',
    'TIER 1': 'TIER 1',
    'Top KOL': 'Top KOL',
    'Back to search results': 'Zurück zu den Suchergebnissen',
    'Drugs of': 'Arzneimitteln von',
    'Click on any drug to add it to your search query': 'Klicken Sie auf ein Arzneimittel, um es Ihrer Suche hinzuzufügen',
    'View details': 'Details anzeigen',
    'Purchase Document': 'Kaufbeleg',
    'Select license type': 'Wählen Sie den Lizenztyp aus',
    'per license': 'pro Lizenz',
    'REQUEST PENDING': 'ANFRAGE AUSSTEHEND',
    'We’ll notify you when the status of your request changes': 'Wir benachrichtigen Sie, wenn sich der Status Ihrer Anfrage ändert',
    'Request decline': 'Anfrage ablehnen',
    'BUY NOW': 'JETZT KAUFEN',
    'REQUEST TO BUY': 'KAUFANTRAG STELLEN',
    'Based on our AI based valuation engine, the license is fairly priced.': 'Durch unsere AI-basierten Bewertungs-Engine ist der Lizenzpreis fair.',
    'license to similar documents costs around': 'Lizenzen für ähnliche Dokumente kosten ungefähr',
    'Please note that this information is only suggestive and determining the final selling price for a submission is the prerogative of its author.': 'Bitte beachten Sie, dass diese Information lediglich eine Empfehlung ist und der endgültige Verkaufspreis von den Autoren festgelegt wird.',
    Showing: 'Anzeigen',
    Back: 'Zurück',
    'results for': 'Ergebnisse für',
    Context: 'Kontext',
    'Cluster Name': 'Clustername',
    'Email Frequency': 'E-Mail-Häufigkeit',
    0.95: '0.95',
    0.05: '0.05',
    'Clinical Trails': 'Klinische Studien',
    0.1: '0.1',
    Guidlines: 'Guidelines',
    0.2: '0.2',
    'Type @ before a name to search for a researcher': 'Geben Sie vor einem Namen @ ein, um nach einem Forscher zu suchen',
    SEARCH: 'SUCHE',
    'Search term appearance prioritization': 'Priorisierung der Suchbegriff-Suche',
    'Summary Details for': 'Detailzusammenfassung für',
    ApprovalDate: 'Zulassungsdatum',
    'list items available at the top': 'Listen Sie oben angezeigte Items',
    'Recent Events': 'Kürzliche Events',
    'Your browser is not supported, please upgrade!!!': 'Ihr Browser wird nicht unterstützt. Bitte upgraden Sie Ihren Browser!!!',
    'There seems to be an issue, please allow cookies and try again!!!': 'Es scheint ein Problem zu geben, bitte erlauben Sie Cookies und versuchen Sie es erneut !!!',
    'By continuing to use our site, you agree to our cookie policy': 'Durch die weitere Nutzung unserer Website stimmen Sie unseren Cookie-Richtlinien zu',
    404: '404',
    Feedback: 'Feedback',
    Dismiss: 'Verwerfen',
    'Did you mean': 'Meinten Sie',
    'Page not found': 'Seite nicht gefunden',
    'The page you are looking for does not exist. Check the URL or go to the homepage.': 'Die von Ihnen gesuchte Seite existiert nicht. Überprüfen Sie die URL oder gehen Sie zur Startseite.',
    'Go To Home': 'Zur Startseite',
    'Oops! There has been an issue. Re-try in some time.': 'Hoppla! Etwas ist schief gelaufen, bitte versuchen Sie es noch einmal.',
    'Loading...': 'Wird geladen...',
    'Data unavailable': 'Daten nicht verfügbar',
    'You discovered a Premium Feature': 'Sie haben eine Premium-Funktion entdeckt',
    'You are trying to access a feature that is not included in Ontosight': 'Sie versuchen, auf eine Funktion zuzugreifen, die nicht in Ontosight enthalten ist',
    TM: 'TM',
    'Academic.': 'Akademisch.',
    'Accelerate your research and increase your efficiency with additional features': 'Beschleunigen Sie Ihre Forschung und steigern Sie Ihre Effizienz mit zusätzlichen Funktionen',
    'News & Press Releases data': 'News & Pressemitteilungen',
    'Treatment Guidelines data': 'Daten zur Behandlungsrichtlinie',
    'Focused analysis on multiple fronts and customised dashboards': 'Fokussierte Analyse an mehreren Fronten und angepassten Dashboards',
    'Detailed insights into clinical trials and interventions': 'Detaillierte Einblicke in klinische Studien und Interventionen',
    'Create new bibliography': 'Neue Bibliographie erstellen',
    'Add to Bibliography': 'Zur Bibliographie hinzufügen',
    'Remove from all': 'Von allen entfernen',
    'Create new folder': 'Neues ordner erstellen',
    'Add to My Favourites': 'Zu meinen Favoriten hinzufügen',
    Notifications: 'Benachrichtigungen',
    'Therapeutic Area': 'Therapeutischer Bereich',
    'Time Range': 'Zeitspanne',
    'Guidelines comparison': 'Richtlinienvergleich',
    'More list items are available at the top': 'Weitere Listenelemente finden Sie oben',
    'Innoplexus AG': 'Innoplexus AG',
    'All Rights Reserved.': 'Alle Rechte vorbehalten.',
    'Reset to default': 'Zurücksetzen',
    'Reset to last save': 'Zurücksetzen zum letzten Speicherpunkt',
    'Enrollment Goal': 'Rekrutierungsziel',
    'Study Dates': 'Studientermine',
    Phase: 'Phase',
    'Condition(s)': 'Bedingung(en)',
    'Study Intervention(s)': 'Studienintervention(en)',
    Sponsor: 'Sponsor',
    'Grant ID': 'Fördermittel ID',
    Source: 'Quelle',
    'Abstract/Overview': 'Abstract/Überblick',
    Claims: 'Ansprüche',
    Citations: 'Zitate',
    'School/Department': 'Schule / Abteilung',
    'Source Type': 'Quellentyp',
    'Date of Publication': 'Veröffentlichungsdatum',
    Committee: 'Ausschuss',
    'Protocol used': 'Verwendetes Protokoll',
    'Experiment Details': 'Details zum Experiment',
    'Type of experiment': 'Art des Experiments',
    'Materials used': 'Verwendete Materialien',
    'Stage Relevance': 'Stage Relevanz',
    Department: 'Abteilung',
    'Drug Action Type': 'Art der Arzneimittelwirkung',
    Instruments: 'Instrumente',
    'Research Field': 'Forschungsfeld',
    Condition: 'Bedingung',
    Intervention: 'Intervention',
    'Highest Phase': 'Höchste Phase',
    'Active ingredients': 'Aktiver Inhaltsstoff',
    'Regulatory Body': 'Regulatorische Behörde',
    'View drug Details': 'Arzneimitteldetails anzeigen',
    'Drug ID': 'Arzneimittel-ID',
    'Last Modified': 'Zuletzt bearbeitet',
    'Primary Indications': 'Primäre Indikationen',
    'Grant Type': 'Art des Fördermittels',
    'Principal Investigator': 'Leitender Prüfer',
    GUIDELINE: 'RICHTLINIE',
    ASSIGNEE: 'ASSIGNEE',
    'PATENT ID': 'PATENT ID',
    'PRIORITY DATE': 'PRIORITÄTSDATUM',
    'Has full text': 'Hat Volltext',
    to: 'zu',
    'Cited by': 'Zitiert von',
    'CITED BY': 'ZITIERT VON',
    'Show annotations': 'Anmerkungen anzeigen',
    Deals: 'Deals',
    'Regulatory Updates': 'Regulatorische Updates',
    'Search...': 'Suche...',
    'Medical Affairs.board.title': 'Medical Affairs',
    'Medical Affairs.board.desc': 'Erhalten Sie Einblicke in Echtzeit-Daten für klinische Studien, Richtlinien und Label-Revisionen in Bezug auf Ihre Indikation und Interventionen von Interesse.',
    'Medical Affairs.board.subtitle': 'Echtzeit-Einblicke für Medical Affairs Personal',
    'Regulatory Updates.board.title': 'Regulatorische Updates',
    'Regulatory Updates.board.desc': 'Erhalten Sie relevante Echtzeit-Updates rund um die Zulassungseinreichung eines Medikaments von wichtigen Zulassungsbehörden rund um Ihren Interessenbereich.',
    'Enterprise Data': 'Unternehmensdaten',
    '\nInvestigational Drugs': 'Investigational Drugs',
    'Regulatory Updates.board.subtitle': 'Relevante Aktualisierungen für regulatorische Informationen zu Arzneimitteln',
    'Sentiment Watch.board.title': 'Sentiment Watch',
    'Sentiment Watch.board.desc': 'Verfolgen Sie in Echtzeit den Buzz um Sponsoren, SOV von Arzneimitteln, Stimmungsschwankungen, Hot Topics und Weak Signals aus klinischen oder digitalen Medien.',
    'Sentiment Watch.board.subtitle': 'Erfassen Sie das Sentiment digitaler und wissenschaftlicher Medien rund um Medikamente von Interesse',
    'Guideline Watch.board.title': 'Guideline Watch',
    'Guideline Watch.board.desc': 'Erhalten Sie Einblicke und relevante Updates zu den Behandlungsrichtlinien, die für die Krankheit von Interesse veröffentlicht wurden.',
    'Guideline Watch.board.subtitle': 'Relevante Updates aus den Behandlungsrichtlinien um eine Indikation',
    Summary: 'Zusammenfassung',
    'My Dashboard.board.title': 'My Dashboard',
    Ontology: 'Ontologie',
    'My Dashboard.board.desc': 'Wählen Sie Ihre eigenen Datenpunkte aus, um Widgets zu generieren und individuelle Einblicke zu Ihren Themen zu erhalten.',
    'My Dashboard.board.subtitle': 'Definieren Sie Ihre eigenen Widgets',
    'Associated Pathways': 'Zugehörige Pathways',
    Phenotypes: 'Phänotypen',
    'Competitive Landscape.board.title': 'Wettbewerbslandschaft',
    'Associated Drugs': 'Assoziierte Arzneimittel',
    'Synthetically Lethal Pairs': 'Synthetisch tödliche Paare',
    'Molecular Functions': 'Molekulare Funktionen',
    'Cellular Components': 'Zelluläre Komponenten',
    'Competitive Landscape.board.desc': 'Erhalten Sie eine detaillierte Analyse der Interventionen von Mitbewerbern in der klinischen Entwicklung in Bezug auf Veröffentlichungen, klinische Studien und behördliche Zulassungen',
    'Biological Processes': 'Biologische Prozesse',
    'Reactome Pathways': 'Reaktionswege',
    'Competitive Landscape.board.subtitle': 'Echtzeitinformationen zu Konkurrenten rund um Medikamententargets, Gene, Proteine   und Arzneimittel für jeden therapeutischen Bereich',
    'Kegg Pathways': 'Kegg-Pfade',
    View: 'Ansicht',
    'No access': 'Kein Zugang',
    'Regulatory UpdateTrends (for last 10 years)': 'Trends für regulatorische Updates (für die letzten 10 Jahre)',
    'distribution of top associated drugs': 'Verteilung der Top-assoziierten Medikamente für die Abfrage innerhalb der ausgewählten Zeitspanne',
    'Years of': 'Jahre von',
    'Start year of clinical trials': 'Startjahr der klinischen Studien',
    'Distribution of': 'Verteilung von',
    'for top associated drugs': 'für Top-assoziierte Arzneimittel',
    'Please select atleast of one intervention': 'Bitte wählen Sie mindestens eine Intervention aus',
    'Entity network on': 'Entity-Netzwerk an',
    'Relationship network of entities based on': 'Beziehungsnetzwerk von Entitäten basierend auf',
    'for the given gene within selected time interval': 'für das gegebene Gen innerhalb des ausgewählten Zeitintervalls',
    Success: 'Erfolg',
    Error: 'Error',
    Info: 'Info',
    Warning: 'Warnung',
    '400.message': 'Hoppla! Etwas ist schief gelaufen, bitte versuchen Sie es noch einmal.',
    '401.message': 'Ihre aktuelle Sitzung ist abgelaufen.',
    '403.message': 'Du hast keine erforderlichen Berechtigungen, bitte kontaktiere den Administrator.',
    'login.message': 'Anmeldung erfolgreich!',
    'deleteAccount.message': 'Dein Konto wurde erfolgreich gelöscht.',
    'potliCreate.message': 'Ordner wurde erfolgreich erstellt.',
    'potliDelete.message': 'Ordner wurde erfolgreich gelöscht.',
    'bibliographyCreate.message': 'Bibliographie-Ordner wurde erfolgreich erstellt',
    'bibliographyDelete.message': 'Bibliographie-Ordner wurde erfolgreich gelöscht.',
    'saveCluster.message': 'Cluster wurde erfolgreich angelegt. Gehen Sie zu "MEIN ARBEITSBEREICH", um Ihren gespeicherten Cluster anzuzeigen.',
    'editCluster.message': 'Cluster wurde erfolgreich aktualisiert',
    'deleteCluster.message': 'Cluster wurde erfolgreich gelöscht',
    'deleteData.message': 'Alle Benutzeraktivitäten wurden erfolgreich gelöscht.',
    'article.message': 'Vielen Dank für Ihr Feedback.',
    'bookmark.message': 'Lesezeichen wurde erfolgreich erstellt.',
    'editBookmark.message': 'Lesezeichen wurde erfolgreich aktualisiert.',
    'All associated drugs': 'Alle assoziierten Arzneimittel',
    'No notification available': 'Keine Benachrichtigung verfügbar',
    'deleteBookmark.message': 'Lesezeichen wurde erfolgreich entfernt.',
    'bibliography.message': 'Die Bibliographie wurde erfolgreich erstellt.',
    'editBibliography.message': 'Die Bibliographie wurde erfolgreich aktualisiert.',
    'deleteBibliography.message': 'Bibliographie wurde erfolgreich entfernt',
    'logout.message': 'Erfolgreich abgemeldet',
    'You trial period has been expired': 'Ihre Testperiode ist abgelaufen, Bitte klicken Sie auf den Registrierungslink in Ihrem Posteingang, um fortzufahren.',
    'Top Trends': 'Top Trends',
    'Top Indications': 'Top Indikationen',
    'Top Drugs': 'Top-Arzneimittel',
    'Top Drug Targets': 'Top Arzneimitteltargets',
    'Top Affiliations': 'Top-Mitgliedschaften',
    'Top Journals': 'Top-Journals',
    'Top Authors': 'Top-Autoren',
    'Clinical trial distribution (Phase)': 'Verteilung der klinischen Studien (Phase)',
    'Clinical trial distribution (Status)': 'Verteilung der klinischen Studien (Status)',
    'Geographical distribution for Clinical trials (Phase)': 'Geographische Verteilung für klinische Studien (Phase)',
    'Top Sponsors': 'Top-Sponsoren',
    'Select TA': 'TA auswählen',
    'Custom Sort': 'Benutzerdefinierte Sortierung',
    ALL: 'ALLES',
    FDA: 'FDA',
    ANMAT: 'ANMAT',
    EMA: 'EMA',
    ANVISA: 'ANVISA',
    CHILE: 'CHILE',
    'HEALTH CANADA': 'HEALTH CANADA',
    OTHERS: 'ANDERE',
    'DRUGS LAUNCHED': 'LAUNZIERTE ARZNEIMITTEL',
    ALERTS: 'WARNHINWEISE',
    'DAC REPORTS': 'DAC-BERICHTE',
    'NEWS FEEDS': 'NEWS FEEDS',
    'Study Details': 'Studiendetails',
    Eligibility: 'Zulassungskriterien',
    Channel: 'Kanal',
    Results: 'Ergebnisse',
    'Mentions in Other Sources': 'Erwähnungen in anderen Quellen',
    PERSONAL: 'PERSÖNLICH',
    'INN NAME / ACTIVE INGREDIENT': 'INN-NAME / AKTIVER WIRKSTOFF',
    'BIOSIMILAR PRODUCTS': 'BIOSIMILARE PRODUKTE',
    'APPROVED LABELLED PRODUCTS': 'ZUGELASSENE PRODUKTE',
    'NEW MOLECULAR ENTITY': 'NEW MOLECULAR ENTITY',
    'BREAKTHROUGH APPROVAL': 'BREAKTHROUGH APPROVAL',
    'ACCELERATED APPROVAL': 'ACCELERATED APPROVAL',
    'PRIORITY REVIEW STATUS': 'PRIORITY REVIEW STATUS',
    'ORPHAN STATUS': 'ORPHAN STATUS',
    'ON FAST TRACK': 'ON FAST TRACK',
    'MECHANISM OF ACTION': 'WIRKMECHANISMUS',
    'DRUG TARGET': 'ARZNEIMITTELTARGET',
    'DRUG CLASS': 'ARZNEIMITTELKLASSE',
    'TARGETED INDICATIONS FOR HIGHEST PHASE OF DEVELOPMENT': 'Gezielte Indikationen für höchste Entwicklungsphase',
    'OTHER INDICATIONS UNDER TRIAL': 'ANDERE INDIKATIONEN IN KLINISCHEN STUDIEN',
    'Active Comparator': 'Aktiver Komparator',
    Description: 'Beschreibung',
    Drug: 'Arzneimittel',
    'Abstract Unavailable': 'Abtract nicht verfügbar',
    'Intervention - Marketed': 'Intervention - Vermarktet',
    'Intervention - Clinical phase': 'Intervention - klinische Phase',
    'Intervention - Preclinical': 'Intervention - präklinisch',
    Gene: 'Gen',
    Protein: 'Eiweiß',
    Author: 'Autoren',
    'Biological Pathway': 'Biologischer Pathway',
    Taxonomy: 'Taxonomie',
    'Cell Line': 'Zelllinie',
    'Medical Procedure': 'Medizinische Prozedur',
    Device: 'Gerät',
    Technology: 'Technologie',
    Toxicity: 'Toxizität',
    'Adverse Reaction': 'Nebenwirkung',
    MoA: 'MoA',
    'Lab Reagent': 'Laborreagenz',
    'Lab device': 'Laborgerät',
    Chemical: 'Chemisch',
    'Search Indication': 'Suche Indikation',
    'Search Intervention - Marketed': 'Suche Intervention - Vermarktet',
    'Search Intervention - Clinical phase': 'Suche Intervention - klinische Phase',
    'Search Intervention - Preclinical': 'Suche Intervention - präklinisch',
    'Search Gene': 'Suche Gene',
    'Search Protein': 'Suche Protein',
    'Search Author': 'Suche Autor',
    'Search Biological Pathway': 'Suche biologischen Pathway',
    'Search Taxonomy': 'Suche Taxonomie',
    'Search Cell Line': 'Suche Zelllinie',
    'Search Medical Procedure': 'Suche Medizinisches Verfahren',
    'Search Device': 'Gerät suchen',
    'Search Technology': 'Suche Technologie',
    'Search Toxicity': 'Suche Toxizität',
    'Search Adverse Reaction': 'Suche Nebenwirkung',
    'Search MoA': 'Suche MoA',
    'Search Lab Reagent': 'Suche Laborreagenz',
    'Search Lab device': 'Suche Laborgerät',
    'Search Chemical': 'Suche Chemikalie',
    Citation: 'Zitat',
    General: 'Allgemeines',
    Development: 'Entwicklung',
    Financial: 'Finanziell',
    Analysis: 'Analyse',
    'Patent Families': 'Patentfamilien',
    Sources: 'Quellen',
    'Change History': 'Änderungshistorie',
    'Publication Trends': 'Publikationstrends',
    'Biomedical Entities': 'Biomedizinische Einheiten',
    'Academic Access': 'Akademischer Zugang',
    'Experience the': 'Erleben Sie die',
    'Life Sciences AI.': 'Life Sciences AI.',
    'Now.': 'Jetzt.',
    'Utilisez le courrier électronique de votre université pour demander une invitation.': 'JETZT ZUGREIFEN',
    'We are committed to help researchers worldwide': 'Wir sind bestrebt, Forschern auf der ganzen Welt dabei zu helfen, die Herausforderungen in den Biowissenschaften zu lösen. Wir haben unser Ontosight Academic Access-Programm gestartet, das allen Forschern und Studenten, die mit einer Universität weltweit in Verbindung stehen, freien Zugang zur Ontosight Academic-Version bietet.',
    'Use your University email for requesting invite.': 'Verwenden Sie die E-Mail-Adresse Ihrer Universität, um eine Einladung anzufordern.',
    '35M': '35M',
    Theses: 'Thesen',
    '0.5M': '0.5M',
    '2M': '2M',
    '36K': '36K',
    'Drug Profiles': 'Arzneimittelprofile',
    '40K': '40K',
    'Genes Profiles': 'Gene Profile',
    'Why Ontosight': 'Warum Ontosight?',
    Academic: 'Akademisch',
    'Search using Life Science ontology': 'Suche mit Life Science Ontologie',
    'Our ontology understands life science concepts and terms.': 'Unsere Ontologie versteht Life-Science-Konzepte und -Begriffe. Es ist verständlich, dass Akronyme mit verschiedenen Konzepten zusammenhängen und zwischen zwei Konzepten unterscheiden können, die dasselbe Akronym wie EGFR verwenden.',
    'Single platform': 'Einzelplattform',
    'Academic consolidates your search by connecting data from thousands* of life science databases. Ontosight': 'Academic konsolidiert Ihre Suche, indem es Daten aus Tausenden * von Life-Science-Datenbanken verbindet. Nachtsicht',
    'Academic actively crawls and aggregates over 97% of the life science public domain.': 'Academic crawlt und aggregiert aktiv über 97% des öffentlichen Bereichs der Biowissenschaften.',
    'Updated in real-time': 'In Echtzeit aktualisiert',
    'New data is assimilated into our data ocean immediately after publication. Set alerts for specific search clusters to stay on top of your unique research area.': 'Neue Daten werden unmittelbar nach der Veröffentlichung in unseren Datenozean aufgenommen. Legen Sie Warnungen für bestimmte Suchcluster fest, um den Überblick über Ihr spezielles Forschungsgebiet zu behalten.',
    'Find the cutting-edge': 'Finde die Schneide',
    'Academic can help you navigate fast-moving research frontiers. Ontosight': 'Academic kann Ihnen dabei helfen, schnelllebige Forschungsgrenzen zu überwinden. Nachtsicht',
    'Academic encompasses a massive volume of life science congresses and theses, ensuring that you have access to the most nascent research being done in your field.': 'Das akademische Umfeld umfasst eine Vielzahl von Life-Science-Kongressen und -Thesen, um sicherzustellen, dass Sie Zugang zu den aktuellsten Forschungsarbeiten auf Ihrem Gebiet haben.',
    'A PRODUCT BY': 'EIN PRODUKT VON',
    "Innoplexus is structuring the world's information": 'Innoplexus strukturiert die Informationen der Welt, um die KI zu vereinfachen. Innoplexus bietet DaaS (Data as a Service) - und CAaS (Continuous Analytics as a Service) -Produkte. Dabei werden künstliche Intelligenz, proprietäre Algorithmen und zum Patent angemeldete Technologien eingesetzt, um globalen Life Sciences-Organisationen den Zugriff auf relevante Daten, Informationen und intuitive Einblicke zu ermöglichen. klinische, klinische, regulatorische und kommerzielle Stadien eines Arzneimittels.',
    'We automate the collection and curation of data using technologies such as natural language processing': 'Wir automatisieren die Erfassung und Kuratierung von Daten mithilfe von Technologien wie natürlicher Sprachverarbeitung, Netzwerkanalyse, Ontologien, Computer Vision und Entitätsnormalisierung. Unsere Kunden sind Fortune 500-Unternehmen, darunter einige der weltweit größten Pharmaunternehmen.',
    'VISIT WEBSITE': 'BESUCHE DIE WEBSITE',
    'Phrase match': 'Phrase passen',
    'Has mentions In': 'Hat Erwähnungen in',
    'No folders available': 'Keine Ordner vorhanden',
    'Start year': 'Startjahr',
    'End Year': 'Ende des Jahres',
    number_of_records: 'Anzahl der Datensätze',
    file_format: 'Datei Format',
    Select: 'Wählen',
    equalizer: 'Equalizer',
    'Select Language': 'Sprache auswählen',
    MOA: 'MOA',
    'Search Equalizer': 'Suche Equalizer',
    'Source Link': 'Quelllink',
    'View Details': 'Details anzeigen',
    demo: 'Demo',
    Bibliography: 'Literaturverzeichnis',
    Bookmark: 'Lesezeichen',
    'Select Indications': 'Wählen Sie Indikationen',
    filter: 'Filter',
    'Related drugs': 'Verwandte Medikamente',
    'Widget Preview': 'Widget-Vorschau',
    'Zoom In': 'Hineinzoomen',
    'Zoom Out': 'Rauszoomen',
    'Select Societies': 'Gesellschaften auswählen',
    'Select Interventions': 'Wählen Sie Interventionen',
    'Select Geography': 'Wählen Sie Geografie',
    'Select application Number': 'Anwendungsnummer auswählen',
    'Bibliography Export': 'Bibliography Export',
    'Back to home': 'Zurück zur Startseite'
  },
  fr: {
    lastUpdated: '2019-05-31T10:18:40.940Z',
    version: '1.1',
    'Header.discover': 'Découvrir',
    'Header.myWorkspace': 'Mon espace de travail',
    'Header.dashboards': 'Tableaux de bord',
    'Header.marketplace': 'Marketplace',
    "Header.FAQ's": "FAQ's",
    'Header.Profile': 'Profil',
    'Header.AccountSettings': 'Paramètres du compte',
    'Header.Subscriptions': 'Abonnements',
    'Header.Logout': 'Déconnexion',
    'Footer.allRights': '- Tous droits réservés',
    'Btn.Cancel': 'Annuler',
    'Btn.clearAll': 'Tout effacer',
    'Btn.Download': 'Télécharger',
    'Search.hello': 'Bonjour',
    'Search.goodToSee': "C'est bon de vous voir ici !",
    'Search.searchHereFor': 'Recherchez ici des indications, des interventions, des gènes, des chercheurs ou des mots-clés',
    'Search.typeBefore': 'Tapez @ avant un nom pour trouver un chercheur',
    'Search.advanceSearch': 'Recherche Avancée',
    'Search.SEARCH': 'CHERCHEZ',
    'Search.Search': 'Cherchez',
    'Cluster.SaveAsCluster': 'Enregistrer sous Cluster',
    'Cluster.Context': 'Contexte',
    'Cluster.ClusterName': 'Nom du cluster',
    'Cluster.EmailFrequency': "Fréquence d'Email",
    'Get Email on any new content': 'Recevez un email sur tout nouveau contenu',
    Weekly: 'Hebdomadaire',
    Daily: 'Quotidiennement',
    'Raw data result': 'Résultat de données brutes',
    'SearchAdvance.backToBasicSearch': 'Retour à la recherche simple',
    'SearchAdvance.findArticle': 'Trouver des articles avec ...',
    'Indication(s)': 'Indication(s)',
    'Intervention(s)': 'Intervention(s)',
    'Protein(s)': 'Protéine(s)',
    'Gene(s)': 'Gène(s)',
    'Author(s)': 'Auteurs)',
    Others: 'Autres',
    Literature: 'Littérature',
    KOLs: 'KOL',
    Published: 'Publié',
    Publications: 'Publications',
    'Clinical Trials': 'Essais cliniques',
    Congresses: 'Congrès',
    'Theses & Dissertations': 'Thèses & Mémoires',
    Patents: 'Brevets',
    'News & Press Releases': 'Actualités & Communiqués de presse',
    'Treatment Guidelines': 'Directives de traitement',
    Grants: 'Subventions',
    Unpublished: 'Non publié',
    'Sort By': 'Triez par',
    Recency: 'Les plus récents',
    Relevance: 'Pertinence',
    'Related Concepts': 'Concepts associés',
    By: 'Par',
    Correlation: 'Corrélation',
    Frequency: 'Fréquence',
    Reset: 'Réinitialiser',
    Apply: 'Appliquer',
    Filter: 'Filtrer',
    'Biomedical Entity Type': "Type d'entité biomédicale",
    'Study/Experiment Type': "Type d'étude / d'expérience",
    Species: 'Espèces',
    From: 'De',
    To: 'À',
    Publication: 'Publication',
    Trends: 'Les tendances',
    '(for last 10 years)': '(depuis 10 ans)',
    'Clinical Trial': 'Essai clinique',
    Congress: 'Congrès',
    Patent: 'Brevet',
    'News & Press Release': 'Actualités & Communiqués de presse',
    Grant: 'Subvention',
    Sponsorship: 'Parrainage',
    Country: 'Pays',
    'Data Source': 'Source de données',
    Institution: 'Institution',
    'Download Results': 'Télécharger les résultats',
    'Number of Records': "Nombre d'enregistrements",
    'File Format': 'Format de fichier',
    'Downloading...': 'Téléchargement...',
    'Read more': 'Lire la suite',
    'Read less': 'Lire moins',
    'Back to list': 'Retour à la liste',
    Authors: 'Auteurs',
    FINGERPRINTS: 'EMPREINTES',
    Abstract: 'Extrait',
    More: 'Plus',
    'Show less': 'Montrer moins',
    PMID: 'PMID',
    DOI: 'DOI',
    'Thought Leaders': "Leaders d'opinion",
    'Commercial KOLs': 'KOL commerciaux',
    'Scientific KOLs': 'KOL scientifiques',
    Speakers: 'Conférenciés',
    Resources: 'Ressources',
    'Score Equalizer': 'Score égaliseur',
    'Total Score': 'Score total',
    'Must be 100%': 'Doit être 100%',
    Save: 'Sauvegarder',
    Guidelines: 'Directives',
    Societies: 'Sociétés',
    'Top Hospital KOLs': 'Meilleurs hôpitaux KOL',
    'Regulatory Bodies': 'Organismes de réglementation',
    HTA: 'HTA',
    Advocacy: 'Plaidoyer',
    'Select Country': 'Choisissez le pays',
    LOGIN: "S'IDENTIFIER",
    'Life Sciences': 'Sciences de la vie',
    'Research Simplified': 'Recherche simplifiée',
    'We know you': 'Ici, vous pouvez trouver les recherches qui vous incombent. Elles sont organisées, analysées et arrangées sur un plateau.',
    'matter to you': 'Maintenant, il est organisé, analysé et organisé sur un',
    platter: 'plateau',
    '35M+': '35M +',
    '500K+': '500K +',
    'Clinical trials': 'Essais cliniques',
    '850K+': '850K +',
    'Congress articles': 'Articles de Congrès',
    '127K+': '127K +',
    'Drug profiles': 'Profils de médicaments',
    '1M+': '1M +',
    'Also latest lifescience news': "Dernières actualités de LifeSciences, annonces réglementaires, moteur d'analyse, etc.",
    'ROLLING OUT INVITES - GET ONE NOW': 'ROLLING OUT INVITES - GET ONE NOW',
    img: 'img',
    'Asset classes': "Classes d'actifs",
    'Get access to wide range': 'Accédez à un large éventail de données LifeSciences, tels que publications, essais cliniques, congrès, thèses, brevets, actualités, communiqués de presse, subventions et directives de traitement.',
    'Search crumbs': 'Recherche de miettes',
    'A unique feature': "Une fonctionnalité unique qui aide l'utilisateur à parcourir la requête et à créer le contexte approprié jusqu'à l'obtention des résultats souhaités.",
    'Concept based search': 'Recherche par concept',
    'Our innovative and intuitive': "Notre fonction de recherche sémantique innovante et intuitive comprend les concepts biomédicaux à l'aide de notre base de données propriétaire Ontology. Vous obtiendrez des résultats pertinents et contextuels pour vos requêtes de recherche. En utilisant nos fonctionnalités de recherche avancée, vous pouvez choisir vos résultats de recherche en fonction d'une classe de concepts particulière, telle que indication, intervention, gène ou protéine.",
    'Detailed insights': 'Informations détaillées',
    'You can deep dive': "Vous pouvez approfondir vos recherches grâce à nos différentes couches d'analyses et modules. Obtenez des vues d'interventions, de gènes, de profils de chercheurs, etc. pour votre requête et prenez une décision en connaissance de cause.",
    Discovery: 'Découverte',
    'Our AI based engine': "Notre IA collecte, nettoie et classe différentes catégories d'actifs et vous donne un résultat complet pour votre requête, incluant les concepts associés. Cela vous aide à prendre des décisions en connaissance de cause.\n",
    Annotations: 'Annotations',
    'Get the flexibility of adding': "Ajouter des commentaires à n'importe quel document qui vous intéresse et retrouver le rapidement.",
    'Cluster updates': 'Mises à jour du cluster',
    'Get real-time updated': "Obtenez des notifications mises à jour en temps réel sur des \"sujets d'intérêt\" en créant votre espace de travail (clusters de recherche). Affichez et gérez les dernières mises à jour de vos clusters de recherche depuis votre dernière connexion.",
    'Customizable dashboards': 'Tableaux de bord personnalisables',
    'You can create your own': 'Vous pouvez créer vos propres graphiques et visualisations en sélectionnant vos propres paramètres. Vous pouvez sélectionner différents types de graphiques dans la bibliothèque de widgets préremplie et également les exporter.',
    Marketplace: 'Marché',
    'You will have access': "Vous pouvez également accéder aux données d'expérimentations non publiées / ayant échouées. Vous pouvez utiliser cette plate-forme pour vendre ou acheter des données d'expérimentations. Notre technologie blockchain sur la plate-forme assure la propriété des expérimentations publiées.",
    'A PRODUCT BY INNOPLEXUS': 'UN PRODUIT INNOPLEXUS',
    'Innoplexus is structuring': "Innoplexus structure les informations du monde pour faciliter l'Intelligence Artificielle. Innoplexus propose les données comme service (DaaS) ainsi que des solutions d'analyses continues (CAaS), utilisant l'Intelligence Artificielle, et des algorithmes propriétaires ainsi que des brevets technologiques pour accompagner les organisations LifeSciences avec un accès aux données pertinentes, avec des informations intelligentes et intuitives, à toutes les étapes précliniques, cliniques, règlementaires et commerciales d'un médicament.",
    'We automate the collection': "Nous automatisons la collecte et la conservation des données à l'aide de technologies telles que le traitement du langage naturel, l'analyse de réseau, les ontologies, la vision par ordinateur et la normalisation d'entités. Nos clients sont des sociétés Fortune 500, y compris certaines des plus grandes sociétés pharmaceutiques du monde.",
    'Drug Discovery': 'Découverte de médicament',
    Clinical: 'Clinique',
    'Header.Frequently asked questions': 'Questions fréquemment posées',
    'Header.More about you': 'Plus à votre sujet',
    'Header.Check / Update your settings': 'Vérifier / mettre à jour vos paramètres',
    'Header.Check / Update your subscriptions': 'Vérifier / mettre à jour vos abonnements',
    'Header.See you next time!': 'A bientôt !',
    'Header.Context based intelligent search': 'Recherche intelligente basée sur le contexte dans diverses sources de données',
    'Header.Your history, saved searches': 'Votre historique, vos recherches sauvegardées, etc.',
    'Header.Deep dive with various analysis modules': "Recherche approfondie grâce aux modules d'analyses",
    'Header.Sell and buy unpublished data': 'Vendre et acheter des données non publiées',
    'Discover.Language': 'Langage',
    'Reset all Filters': 'Réinitialiser tous les filtres',
    'Gene.About': 'Sur',
    'Gene.Official Full Name': 'Nom complet officiel',
    'Gene.Gene type': 'Type de gène',
    'Gene.Organism': 'Organisme',
    'Gene.Also known as': 'Aussi connu sous le nom',
    'Gene.Primary Source': 'Source principale',
    'Gene.Location': 'Localisation',
    'Gene.Chromosome': 'Chromosome',
    'Gene.Start': 'Début',
    'Gene.End': 'Fin',
    'Gene.Size': 'Taille',
    'Gene.Orientation': 'Orientation',
    'Gene.Score': 'Score',
    'Gene.Disorder': 'Désordre',
    'Gene.Metadata of all': 'Métadonnées de tous les médicaments associés pour le gène sélectionné',
    Name: 'Prénom',
    Indication: 'Indication',
    Sponsors: 'Sponsors',
    Total: 'Total',
    'SL pairs number limit': 'Nombre limite de paires SL',
    'REGULATORY DESIGNATIONS': 'DÉSIGNATIONS RÉGLEMENTAIRES',
    'Header.Customer Support': 'Service client',
    Analytics: 'Analytique',
    Custom: 'Personnaliser',
    'Author.AFFILIATIONS': 'AFFILIATIONS',
    'Author.KEY RESEARCH AREAS': 'DOMAINES DE RECHERCHE CLÉS',
    'Author.Data unavailable': 'Données non disponibles',
    'Author.ACTIVITY TREND': 'TENDANCE DES ACTIVITÉS',
    'Author.RESEARCH ACTIVITY': 'ACTIVITE DE RECHERCHE',
    'Author.Select Option': 'Sélectionnez une option',
    'Author.Select': 'Sélectionner',
    'Author.Contribution': 'Contribution',
    'Author.Global view': 'Vue globale',
    'Author.List view': 'Vue liste',
    'Author.Number of connections': 'Nombre de connexions',
    'Author.Affiliation unavailable': 'Affiliation indisponible',
    'Author.Country unavailable': 'Pays indisponible',
    'Preclinical.Originator': 'Auteur',
    'Preclinical.Synonyms': 'Synonymes',
    'Preclinical.Preclinical Name': 'Nom préclinique',
    'Preclinical.Intervention : Preclinical Stage': 'Intervention: Stade Préclinique',
    'Preclinical.Select': 'Sélectionner',
    Molecule: 'Molécule',
    'Related Articles': 'Articles Liés',
    About: 'Sur',
    'Research Activity': 'Activité de recherche',
    Network: 'Réseau',
    'Profile.Delete Activity History': "Supprimer l'historique d'activité",
    'Profile.This action will clear': "Cette action effacera tout l'historique de vos activités sur Ontosight, y compris vos recherches antérieures, vos favoris, vos espaces de travail et vos annotations. Vos favoris et votre espace de travail ne sont visibles que par vous et contribuent à améliorer votre expérience sur la plate-forme.",
    'Profile.Delete history': "Effacer l'historique",
    'Profile.Delete Ontosight Account': 'Supprimer le compte Ontosight',
    'Profile.Deleting your account': 'La suppression de votre compte est permanente et irréversible. Cela effacera toutes vos données personnelles associées à votre compte.',
    'Profile.Delete Account': 'Supprimer le compte',
    'Profile.Personal Details': 'Détails personnels',
    'Profile.First Name': 'Prénom',
    'Profile.Last Name': 'Nom de famille',
    'Profile.Organization': 'Organisation',
    'Profile.Contact Details': 'Détails du contact',
    'Profile.Email': 'Email',
    'Profile.Are you sure, you want to delete all': "Êtes-vous sûr de vouloir supprimer tout l'historique de vos activités sur Ontosight, y compris vos recherches antérieures, vos favoris, vos espaces de travail et vos annotations?",
    'Profile.Delete': 'Effacer',
    'Profile.Cancel': 'Annuler',
    'Profile.It may take few days for your data': "La suppression de vos données peut prendre quelques jours. Vous perdrez immédiatement l'accès à votre compte et toutes vos données associées seront effacées en permanence.",
    'Profile.Are you sure, you want to close your account ?': 'Êtes-vous sûr de vouloir fermer votre compte?',
    'Profile.Edit': 'Modifier',
    'Account Settings': 'Paramètres du compte',
    Profile: 'Profil',
    'Identification & Approval': 'Identification & Approbation',
    'Mechanism & Classes': 'Mécanisme & Classes',
    Indications: 'Indications',
    'Competing/Related Drugs': 'Drogues concurrentes / connexes',
    'Trial Landscape': "Vue d'ensemble des Essais",
    'Regulatory.Latest regulatory Updates': 'Dernières mises à jour réglementaires',
    'Regulatory.Guidelines': 'Lignes directrices',
    'Regulatory.Disease and drug guidelines': 'Directives sur les maladies et les médicaments',
    'Regulatory.Please select the required fields.': 'Veuillez sélectionner les champs obligatoires.',
    'Regulatory.Select Interventions': 'Sélectionnez des interventions',
    'Regulatory.No. of guidelines': 'Nombre de directives',
    'Regulatory.Interventions': 'Interventions',
    'Regulatory.Data unavailable': 'Données non disponibles',
    'Regulatory.Drug launches across targets': 'Lancement de médicaments sur plusieurs cibles',
    'Regulatory.Status across regulatory bodies': 'Statut auprès des organismes de réglementation',
    'Regulatory.From FDA and EMA': 'De FDA et EMA',
    'Regulatory.Status': 'Statut',
    'Clarivate.Highest Phase': 'Phase la plus élevée',
    'Clarivate.Back to search': 'Retour à la recherche',
    'Clarivate.No data returned from clarivate API': "Aucune donnée renvoyée par l'API clarivate",
    'Clarivate.REGULATORY DESIGNATIONS': 'DÉSIGNATIONS RÉGLEMENTAIRES',
    'Clarivate.Search by designation': 'Recherche par désignation',
    'Clarivate.DEVELOPMENT PROFILE': 'PROFIL DE DÉVELOPPEMENT',
    'Clarivate.DEVELOPMENT STATUS': 'ÉTAT DE DÉVELOPPEMENT',
    'Clarivate.Type': 'Type',
    'Clarivate.Search Type': 'Type de recherche',
    'Clarivate.Select Type': 'Sélectionner le genre',
    'Clarivate.Select Indication': 'Sélectionnez une indication',
    'Clarivate.Search Indication': 'Indication de recherche',
    'Clarivate.Indication': 'Indication',
    'Clarivate.Status': 'Statut',
    'Clarivate.Search Status': 'Statut de recherche',
    'Clarivate.Select Status': 'Sélectionnez le statut',
    'Clarivate.Reset all Filters': 'Réinitialiser tous les filtres',
    'Clarivate.Search by company': 'Recherche par entreprise',
    'Clarivate.SALES & FORECAST': 'PRÉVISIONS DE VENTES',
    'Clarivate.DEALS': 'OFFRES',
    'Clarivate.Search by title': 'Recherche par titre',
    'Clarivate.EXPERT REVIEW': 'REVISION EXPERT',
    'Clarivate.SWOT ANALYSIS': 'ANALYSE SWOT',
    'Clarivate.PATENT FAMILIES': 'FAMILLES DE BREVETS',
    'Clarivate.SOURCES': 'SOURCES',
    'Clarivate.Sort by Date': 'Trier par date',
    'Clarivate.Citations added (ID)': 'Citations ajoutées (ID)',
    'Clarivate.Citations removed (ID)': 'Citations supprimées (ID)',
    'ClinicalTrials.Trial ID': "ID d'essai",
    'ClinicalTrials.Trial Snapshot': "Aperçu d'Essai",
    'ClinicalTrials.Abstract': 'Résumé',
    'ClinicalTrials.Description': 'Description',
    'ClinicalTrials.Phase': 'Phase',
    'ClinicalTrials.Enrollment Goal': "Objectif d'inscription",
    'ClinicalTrials.Study Dates': "Dates d'étude",
    'ClinicalTrials.Condition(s)': 'Condition(s)',
    'ClinicalTrials.Study Intervention(s)': "Étude d'intervention",
    'ClinicalTrials.Sponsor': 'Sponsoriser',
    'ClinicalTrials.Type': 'Type',
    'ClinicalTrials.Design': 'Conception',
    'ClinicalTrials.Primary Outcome Measure': 'Mesure de résultat primaire',
    'ClinicalTrials.Time Frame': 'Période',
    'ClinicalTrials.Measure': 'Mesure',
    'ClinicalTrials.Secondary Outcome Measure': 'Mesure de résultat secondaire',
    'ClinicalTrials.Arms and Active Intervention': 'Armes et intervention active',
    'ClinicalTrials.Accepts Healthy Volunteers': 'Accepte des volontaires sains',
    'ClinicalTrials.Genders Eligible for Study': 'Genre éligible pour étude',
    'ClinicalTrials.Lower age limit for Study': "Limite d'âge inférieure pour l'étude",
    'ClinicalTrials.Upper age limit for Study': "Limite d'âge supérieure pour l'étude",
    'ClinicalTrials.General': 'Général',
    'ClinicalTrials.Inclusion Criteria': "Critère d'intégration",
    'ClinicalTrials.Exclusion Criteria': "Critère d'exclusion",
    'ClinicalTrials.Locations': 'Localisation',
    'ClinicalTrials.Contacts': 'Contacts',
    'ClinicalTrials.Sponsors': 'Sponsors',
    'ClinicalTrials.Principal Investigator': 'Chercheur principal',
    'ClinicalTrials.Collaborators': 'Collaborateurs',
    'ClinicalTrials.Back': 'Retour',
    'ClinicalTrials.Measure Description': 'Description de la mesure',
    'ClinicalTrials.Population Description': 'Description de la population',
    'ClinicalTrials.Reporting Groups': 'Groupes de rapport',
    'ClinicalTrials.Measured Values': 'Valeurs mesurées',
    'ClinicalTrials.Related Clinical Trials': 'Essais cliniques connexes',
    'Research Clusters': 'Pôles de recherche',
    'Search History': 'Historique des recherches',
    'My Favourites': 'Mon Favoris',
    'My Bibliography': 'Ma bibliographie',
    'Workspace.SEARCH': 'CHERCHER',
    'Workspace.Cluster Name': 'Nom du cluster',
    'Workspace.Settings': 'Réglages',
    'Workspace.Email Frequency': "Fréquence d'e-mail",
    'Workspace.Delete': 'Effacer',
    'Workspace.Cancel': 'Annuler',
    'Workspace.Save': 'Sauvegarder',
    'Workspace.Cluster name': 'Nom du cluster',
    'Workspace.No updates available': 'Aucune mise à jour disponible',
    'Workspace.Select Option': 'Sélectionnez une option',
    'Workspace.Search results': 'Résultats de la recherche',
    'Workspace.Repeat this search': 'Répétez cette recherche',
    'Workspace.Search History': 'Historique des recherches',
    'Workspace.No search history recorded': 'Aucun historique de recherche enregistré',
    'Workspace.No clusters created': 'Aucun cluster créé',
    'Workspace.Show only new updates': 'Afficher uniquement les nouvelles mises à jour',
    'Workspace.No folder has been created': 'Aucun dossier créé',
    'Workspace.Delete Potli': 'Effacer',
    'Workspace.No favourites found': 'Aucun favori trouvé',
    'Workspace.Select': 'Sélectionner',
    'Workspace.list items avilable at the top': 'liste des éléments disponibles en haut',
    'Workspace.No my bibliographys created': "Aucune bibliographie n'a été créée",
    'Workspace.Delete My Bibliography': 'Effacer',
    'Workspace.No bibliographys found': 'Aucune bibliographie trouvée',
    'Custom.No widgets available': 'Aucun widget disponible',
    'Custom.My Dashboard': 'Mon tableau de bord',
    'Custom.Add Widget': 'Ajouter un widget',
    'Custom.Top filters': 'Top filtres',
    'Custom.Widget name': 'Nom du widget',
    'Custom.1. Widget name': '1. Nom du widget',
    'Custom.2. Define context': '2. Définir le contexte',
    'Custom.3. Select asset class': "3. Sélectionnez la classe d'actif",
    'Custom.Select dataset': 'Sélectionner un jeu de données',
    'Custom.Cancel': 'Annuler',
    'Custom.Save Widget': 'Enregistrer le widget',
    'Custom.Select graph': 'Sélectionner un graphique',
    'Custom.No dimensions available': 'Aucune dimension disponible',
    'Custom.X-axis': "L'axe X",
    'Custom.Dimension': 'Dimension',
    'Custom.Y-axis': 'Y-axe',
    'Custom.Time Range': 'Intervalle de temps',
    'Custom.Complete the above steps to see a preview of your widget.': 'Effectuez les étapes ci-dessus pour voir un aperçu de votre widget.',
    'Custom.No data available': 'Aucune donnée disponible',
    'Custom.5. Define dimension': '5. Définir la dimension',
    'Custom.Select': 'Sélectionner',
    'Custom.Search': 'Chercher',
    'Custom.No graphs available': 'Pas de graphique disponible',
    'Custom.Query': 'Question',
    'Competitive.Search here for indications, interventions, genes, researchers': 'Cherchez ici des indications, des interventions, des gènes, des chercheurs ou tout autre mot-clé pour obtenir un paysage compétitif',
    'Competitive.Time Range': 'Intervalle de temps',
    'Competitive.Name': 'Nom',
    'Competitive.Regulatory body': 'Organisme de réglementation',
    'Competitive.Company': 'Entreprise',
    'Competitive.Indication': 'Indication',
    'Competitive.Phase of Development': 'Phase de développement',
    'Competitive.Metadata for all related marketed drugs for selected parameters': 'Métadonnées pour tous les médicaments commercialisés liés pour certains paramètres',
    'Competitive.Clinical Trials': 'Essais cliniques',
    'Competitive.Show more': 'Montrer plus',
    'Competitive.Back': 'Retour',
    'Competitive.Details for': 'Détails pour',
    'Competitive.Type @ before a name to search for a researcher': 'Tapez @ avant un nom pour trouver un chercheur',
    'Competitive.Search': 'Chercher',
    'Competitive.Did you mean': 'Voulez-vous dire',
    'Competitive.SEARCH': 'CHERCHER',
    'Competitive.Search...': 'Chercher...',
    'Competitive.You typed': 'Vous avez tapé',
    'Competitive.Concept': 'Concept',
    'Competitive.Search drug....': 'Recherche de drogue ....',
    'Intervention.Dosage & Route of Administration': "Posologie et voie d'administration",
    'Intervention.Related Publications on drug’s pharmacology': 'Publications connexes sur la pharmacologie du médicament',
    'Intervention.Back': 'Retour',
    'Back to Basic Search': 'Retour à la recherche simple',
    'Find articles with...': 'Trouver des articles avec ...',
    'Clear All': 'Tout effacer',
    Concept: 'Concept',
    'You typed': 'Vous avez tapé',
    Download: 'Télécharger',
    Cancel: 'Annuler',
    Search: 'Chercher',
    'Asset Type': "Type d'actif",
    'KOL Category Distribution': 'Distribution par catégorie KOL',
    'Influence Map': "Carte d'influence",
    'KOL Ranking': 'Classement KOL',
    'TIER 1': 'NIVEAU 1',
    'Top KOL': 'Top KOL',
    'Back to search results': 'Retour aux résultats de recherche',
    'Drugs of': 'Médicaments de',
    'Click on any drug to add it to your search query': "Cliquez sur n'importe quel médicament pour l'ajouter à votre requête de recherche",
    'View details': 'Voir les détails',
    'Purchase Document': 'Facture',
    'Select license type': 'Sélectionnez le type de licence',
    'per license': 'par licence',
    'REQUEST PENDING': 'DEMANDE EN ATTENTE',
    'We’ll notify you when the status of your request changes': 'Nous vous informerons lorsque le statut de votre demande changera',
    'Request decline': 'Demande refusée',
    'BUY NOW': 'ACHETER MAINTENANT',
    'REQUEST TO BUY': "DEMANDE D'ACHAT",
    'Based on our AI based valuation engine, the license is fairly priced.': "Basé sur notre moteur d'évaluation basé sur l'IA, le prix de la licence est raisonnable.",
    'license to similar documents costs around': 'La licence de documents similaires coûte environ',
    'Please note that this information is only suggestive and determining the final selling price for a submission is the prerogative of its author.': "Veuillez noter que ces informations sont à titre indicatif et que le prix de vente final est déterminé par l'auteur.",
    Showing: 'Montrant',
    Back: 'Retour',
    'results for': 'résultats pour',
    Context: 'Contexte',
    'Cluster Name': 'Nom du cluster',
    'Email Frequency': "Fréquence d'e-mail",
    0.95: '0.95',
    0.05: '0.05',
    'Clinical Trails': 'Essais Cliniques',
    0.1: '0.1',
    Guidlines: 'Directives',
    0.2: '0.2',
    'Type @ before a name to search for a researcher': 'Tapez @ avec un nom pour trouver un chercheur',
    SEARCH: 'CHERCHER',
    'Search term appearance prioritization': "Priorité de l'apparence des termes de recherche",
    'Summary Details for': 'Résumé Détails pour',
    ApprovalDate: "Date d'approbation",
    'list items available at the top': 'liste des éléments disponibles en haut',
    'Recent Events': 'Événements récents',
    'Your browser is not supported, please upgrade!!!': "Votre navigateur n'est pas supporté, veuillez le mettre à jour",
    'There seems to be an issue, please allow cookies and try again!!!': "Il semble y avoir un problème, merci d'autoriser les cookies et de réessayer.",
    'By continuing to use our site, you agree to our cookie policy': 'En continuant à utiliser notre site, vous acceptez notre politique en matière de cookies.',
    404: '404',
    Feedback: 'Feedback',
    Dismiss: 'Rejeter',
    'Did you mean': 'Vouliez-vous dire',
    'Page not found': 'Page non trouvée',
    'The page you are looking for does not exist. Check the URL or go to the homepage.': "La page que vous recherchez n'existe pas. Vérifiez l'URL ou allez à la page d'accueil.",
    'Go To Home': "Aller à la page d'accueil",
    'Oops! There has been an issue. Re-try in some time.': "Oups ! Quelque chose s'est mal passé. Essayez encore.",
    'Loading...': 'Chargement...',
    'Data unavailable': 'Données non disponibles',
    'You discovered a Premium Feature': 'Vous avez découvert une fonctionnalité Premium',
    'You are trying to access a feature that is not included in Ontosight': "Vous essayez d'accéder à une fonctionnalité qui n'est pas incluse dans Ontosight",
    TM: 'TM',
    'Academic.': 'Académique.',
    'Accelerate your research and increase your efficiency with additional features': 'Accélérez vos recherches et augmentez votre efficacité avec des fonctionnalités supplémentaires',
    'News & Press Releases data': 'Actualités et communiqués de presse',
    'Treatment Guidelines data': 'Traitement de données de directives',
    'Focused analysis on multiple fronts and customised dashboards': 'Analyse ciblée sur plusieurs fronts et tableaux de bord personnalisés',
    'Detailed insights into clinical trials and interventions': 'Informations détaillées sur les essais cliniques et les interventions',
    'Create new bibliography': 'Créer une nouvelle bibliographie',
    'Add to Bibliography': 'Ajouter à la bibliographie',
    'Remove from all': 'Tout supprimer',
    'Create new folder': 'Créer un nouveau dossier',
    'Add to My Favourites': 'Ajouter à mes favoris',
    Notifications: 'Les notifications',
    'Therapeutic Area': 'Domaine thérapeutique',
    'Time Range': 'Périodicité',
    'Guidelines comparison': 'Comparaison des directives',
    'More list items are available at the top': "Plus d'éléments de la liste sont disponibles en haut",
    'Innoplexus AG': 'Innoplexus AG',
    'All Rights Reserved.': 'Tous droits réservés',
    'Reset to default': 'Réinitialiser aux valeurs par défaut',
    'Reset to last save': 'Réinitialiser à la dernière sauvegarde',
    'Enrollment Goal': "Objectif d'inscription",
    'Study Dates': "Dates d'étude",
    Phase: 'Phase',
    'Condition(s)': 'Condition(s)',
    'Study Intervention(s)': "Étude d'intervention",
    Sponsor: 'Parrainer',
    'Grant ID': 'ID de subvention',
    Source: 'Source',
    'Abstract/Overview': 'Extrait',
    Claims: 'Réclamations',
    Citations: 'Citations',
    'School/Department': 'École / département',
    'Source Type': 'Type de Source',
    'Date of Publication': 'Date de publication',
    Committee: 'Comité',
    'Protocol used': 'Protocole utilisé',
    'Experiment Details': "Détails de l'expérience",
    'Type of experiment': "Type d'expérience",
    'Materials used': 'Matériaux utilisés',
    'Stage Relevance': "Pertinence de l'étape",
    Department: 'Département',
    'Drug Action Type': "Type d'action antidrogue",
    Instruments: 'Instruments',
    'Research Field': 'Domaine de recherche',
    Condition: 'État',
    Intervention: 'Intervention',
    'Highest Phase': 'Phase la plus élevée',
    'Active ingredients': 'Ingrédients actifs',
    'Regulatory Body': 'Organisme de réglementation',
    'View drug Details': 'Voir les détails du médicament',
    'Drug ID': 'ID du médicament',
    'Last Modified': 'Dernière modification',
    'Primary Indications': 'Indications primaires',
    'Grant Type': 'Type de subvention',
    'Principal Investigator': 'Chercheur principal',
    GUIDELINE: 'LIGNE DIRECTRICE',
    ASSIGNEE: 'CESSIONNAIRE',
    'PATENT ID': 'ID DE BREVET',
    'PRIORITY DATE': 'DATE DE PRIORITÉ',
    'Has full text': 'Texte intégral',
    to: 'à',
    'Cited by': 'Cité par',
    'CITED BY': 'CITÉ PAR',
    'Show annotations': 'Afficher les annotations',
    Deals: 'Offres',
    'Regulatory Updates': 'Mises à jour réglementaires',
    'Search...': 'Chercher...',
    'Medical Affairs.board.title': 'Affaires Médicales',
    'Medical Affairs.board.desc': "Obtenez des informations sur les mises à jour en temps réel pour les essais cliniques, les directives et les révision d'étiquettes concernant les indications et les interventions qui vous intéressent.",
    'Medical Affairs.board.subtitle': 'Informations en temps réel pour le personnel des affaires médicales',
    'Regulatory Updates.board.title': 'Mises à jour réglementaires',
    'Regulatory Updates.board.desc': "Obtenez des mises à jour pertinentes en temps réel sur la soumission réglementaire d'un médicament par les principaux organismes de réglementation autour de votre indication d'intérêt.",
    'Enterprise Data': "Données d'entreprise",
    '\nInvestigational Drugs': '\nDrogues expérimentales ',
    'Regulatory Updates.board.subtitle': 'Mises à jour pertinentes des informations réglementaires sur les médicaments.',
    'Sentiment Watch.board.title': 'Sentiment Watch',
    'Sentiment Watch.board.desc': "Suivez les buzz en temps réel autour des sponsors, des émissions de SOV parmi les drogues d'intérêt, de la montée de sentiment, des sujets d'actualité et des signaux faibles des médias cliniques ou numériques.",
    'Sentiment Watch.board.subtitle': "Suivez les sentiments des médias numériques et scientifiques sur les drogues d'intérêt",
    'Guideline Watch.board.title': 'Veille Directive',
    'Guideline Watch.board.desc': 'Obtenez des informations et des mises à jour pertinentes sur les directives de traitement publiées pour les indications qui vous intéressent.',
    'Guideline Watch.board.subtitle': "Mises à jour pertinentes des directives de traitement autour d'une indication",
    Summary: 'Résumé',
    'My Dashboard.board.title': 'Mon tableau de bord',
    Ontology: 'Ontologie',
    'My Dashboard.board.desc': "Sélectionnez vos propres points de données pour générer des widgets afin d'obtenir des informations personnalisées sur vos sujets d'intérêt.",
    'My Dashboard.board.subtitle': 'Définissez vos propres widgets',
    'Associated Pathways': 'Pathways associés',
    Phenotypes: 'Phénotypes',
    'Competitive Landscape.board.title': 'Contexte concurrentiel',
    'Associated Drugs': 'Drogues Associées',
    'Synthetically Lethal Pairs': 'Paires synthétiquement létales',
    'Molecular Functions': 'Fonctions moléculaires',
    'Cellular Components': 'Composants cellulaires',
    'Competitive Landscape.board.desc': 'Obtenez une analyse détaillée des interventions des concurrents en développement clinique en ce qui concerne ses publications, ses essais cliniques et ses approbations réglementaires',
    'Biological Processes': 'Processus biologiques',
    'Reactome Pathways': 'Pathways réactifs',
    'Competitive Landscape.board.subtitle': 'Intelligence en temps réel des concurrents sur les cibles de médicaments, gènes, protéines, médicaments pour chaque domaine thérapeutique',
    'Kegg Pathways': 'Kegg Pathways',
    View: 'Vue',
    'No access': "Pas d'accès",
    'Regulatory UpdateTrends (for last 10 years)': 'Tendances en matière de mise à jour de la réglementation (pour les 10 dernières années)',
    'distribution of top associated drugs': "distribution des principaux médicaments associés à la requête dans l'intervalle de temps sélectionné",
    'Years of': 'Années de',
    'Start year of clinical trials': 'Année de démarrage des essais cliniques',
    'Distribution of': 'Distribution de',
    'for top associated drugs': 'pour les médicaments les plus associés',
    'Please select atleast of one intervention': 'Veuillez sélectionner au moins une intervention',
    'Entity network on': "Réseau d'entités sur",
    'Relationship network of entities based on': "Réseau relationnel d'entités basé sur",
    'for the given gene within selected time interval': "pour le gène donné dans l'intervalle de temps sélectionné",
    Success: 'Succès',
    Error: 'Erreur',
    Info: 'Info',
    Warning: 'Attention',
    '400.message': "Oups ! Quelque chose s'est mal passé. Essayez encore.",
    '401.message': 'Votre session en cours a expiré.',
    '403.message': "Vous n'avez pas les permissions requises, veuillez contacter l'administrateur",
    'login.message': 'Connexion Succès !',
    'deleteAccount.message': 'Votre compte a été supprimé avec succès',
    'potliCreate.message': 'dossier a été créé avec succès',
    'potliDelete.message': 'Dossier a été supprimé avec succès',
    'bibliographyCreate.message': 'Le dossier bibliographique a été créé avec succès',
    'bibliographyDelete.message': 'Le dossier de bibliographie a été supprimé avec succès.',
    'saveCluster.message': 'Cluster a été créé avec succès. Allez dans "MON ESPACE DE TRAVAIL" pour voir votre cluster sauvegardé',
    'editCluster.message': 'Le Cluster a été mis à jour avec succès',
    'deleteCluster.message': 'Le cluster a été supprimé avec succès',
    'deleteData.message': "Toutes les activités de l'utilisateur ont été supprimées avec succès.",
    'article.message': 'Merci pour vos commentaires',
    'bookmark.message': 'Le signet a été créé avec succès',
    'editBookmark.message': 'Le signet a été mis à jour avec succès.',
    'All associated drugs': 'Tous les médicaments associés',
    'No notification available': 'Aucune notification disponible',
    'deleteBookmark.message': 'Le signet a été supprimé avec succès',
    'bibliography.message': 'La bibliographie a été créée avec succès',
    'editBibliography.message': 'La bibliographie a été mise à jour avec succès',
    'deleteBibliography.message': 'La bibliographie a été supprimée avec succès',
    'logout.message': 'Se déconnecter avec succès',
    'You trial period has been expired': "Votre période d'essai a expiré, veuillez cliquer sur le lien d'inscription reçu dans votre boîte de réception pour continuer.",
    'Top Trends': 'Top Tendances',
    'Top Indications': 'Top Indications',
    'Top Drugs': 'Top Drogues',
    'Top Drug Targets': 'Principales cibles de médicaments',
    'Top Affiliations': 'Top Affiliations',
    'Top Journals': 'Top Journaux',
    'Top Authors': 'Top auteurs',
    'Clinical trial distribution (Phase)': 'Répartition des essais cliniques (phase)',
    'Clinical trial distribution (Status)': 'Répartition des essais cliniques (statut)',
    'Geographical distribution for Clinical trials (Phase)': 'Répartition géographique des essais cliniques (phase)',
    'Top Sponsors': 'Meilleurs sponsors',
    'Select TA': 'Sélectionnez TA',
    'Custom Sort': 'Tri personnalisé',
    ALL: 'TOUT',
    FDA: 'FDA',
    ANMAT: 'ANMAT',
    EMA: 'EMA',
    ANVISA: 'ANVISA',
    CHILE: 'CHILI',
    'HEALTH CANADA': 'Santé Canada',
    OTHERS: 'AUTRES',
    'DRUGS LAUNCHED': 'MEDICAMENTS LANCEES',
    ALERTS: 'ALERTES',
    'DAC REPORTS': 'RAPPORTS DU CAD',
    'NEWS FEEDS': "FIL D'ACTUALITÉ",
    'Study Details': "Détails de l'étude",
    Eligibility: 'Admissibilité',
    Channel: 'Canal',
    Results: 'Résultats',
    'Mentions in Other Sources': "Mentions dans d'autres sources",
    PERSONAL: 'PERSONNEL',
    'INN NAME / ACTIVE INGREDIENT': 'INN NOM / INGRÉDIENT ACTIF',
    'BIOSIMILAR PRODUCTS': 'PRODUITS BIOSIMILAIRES',
    'APPROVED LABELLED PRODUCTS': 'PRODUITS HOMOLOGUÉS',
    'NEW MOLECULAR ENTITY': 'NOUVELLE ENTITÉ MOLÉCULAIRE',
    'BREAKTHROUGH APPROVAL': 'APPROBATION DE RUPTURE',
    'ACCELERATED APPROVAL': 'APPROBATION ACCÉLÉRÉE',
    'PRIORITY REVIEW STATUS': "ÉTAT D'EXAMEN DES PRIORITÉS",
    'ORPHAN STATUS': "STATUT D'ORPHANE",
    'ON FAST TRACK': 'SUR LA PISTE RAPIDE',
    'MECHANISM OF ACTION': "MECANISME D'ACTION",
    'DRUG TARGET': 'CIBLE DE DROGUE',
    'DRUG CLASS': 'Classe de drogue',
    'TARGETED INDICATIONS FOR HIGHEST PHASE OF DEVELOPMENT': 'INDICATIONS CIBLÉES POUR LA PLUS HAUTE PHASE DE DÉVELOPPEMENT',
    'OTHER INDICATIONS UNDER TRIAL': "AUTRES INDICATIONS EN COURS D'ESSAI",
    'Active Comparator': 'Comparateur actif',
    Description: 'Description',
    Drug: 'Médicament',
    'Abstract Unavailable': 'Résumé non disponible',
    'Intervention - Marketed': 'Intervention - Commercialisée',
    'Intervention - Clinical phase': 'Intervention - phase clinique',
    'Intervention - Preclinical': 'Intervention - Préclinique',
    Gene: 'Gène',
    Protein: 'Protéine',
    Author: 'Auteurs',
    'Biological Pathway': 'Voie biologique',
    Taxonomy: 'Taxonomie',
    'Cell Line': 'Ligne cellulaire',
    'Medical Procedure': 'Procédure médicale',
    Device: 'Dispositif',
    Technology: 'Technologie',
    Toxicity: 'Toxicité',
    'Adverse Reaction': 'Réaction indésirable',
    MoA: 'MoA',
    'Lab Reagent': 'Réactif de laboratoire',
    'Lab device': 'Appareil de laboratoire',
    Chemical: 'Chimique',
    'Search Indication': 'Indication de recherche',
    'Search Intervention - Marketed': 'Intervention de recherche - Commercialisée',
    'Search Intervention - Clinical phase': 'Recherche Intervention - Phase clinique',
    'Search Intervention - Preclinical': 'Intervention de Recherche - Préclinique',
    'Search Gene': 'Rechercher un gène',
    'Search Protein': 'Rechercher des protéines',
    'Search Author': 'Rechercher auteur',
    'Search Biological Pathway': 'Recherche voie biologique',
    'Search Taxonomy': 'Rechercher une taxonomie',
    'Search Cell Line': 'Ligne de recherche',
    'Search Medical Procedure': 'Procédure médicale de recherche',
    'Search Device': 'Dispositif de recherche',
    'Search Technology': 'Technologie de recherche',
    'Search Toxicity': 'Recherche de toxicité',
    'Search Adverse Reaction': 'Rechercher des réactions indésirables',
    'Search MoA': 'Recherche MoA',
    'Search Lab Reagent': 'Réactif de laboratoire de recherche',
    'Search Lab device': 'Appareil de recherche',
    'Search Chemical': 'Rechercher un produit chimique',
    Citation: 'Citation',
    General: 'Général',
    Development: 'Développement',
    Financial: 'Financier',
    Analysis: 'Analyse',
    'Patent Families': 'Familles de brevets',
    Sources: 'Sources',
    'Change History': "Changer l'historique",
    'Publication Trends': 'Tendances de publication',
    'Biomedical Entities': 'Entités biomédicales',
    'Academic Access': 'Accès académique',
    'Experience the': 'Découvrez les',
    'Life Sciences AI.': 'LifeSciences AI',
    'Now.': 'À présent.',
    'Utilisez le courrier électronique de votre université pour demander une invitation.': 'ACCEDEZ MAINTENANT',
    'We are committed to help researchers worldwide': "Nous nous engageons à aider les chercheurs du monde entier dans leur quête de solutions aux défis des LifeSciences. Nous avons lancé notre programme d'accès académique Ontosight gratuit pour tous les chercheurs et étudiants, affiliés à toutes les universités du monde.",
    'Use your University email for requesting invite.': 'Utilisez votre email universitaire pour demander un accès.',
    '35M': '35M',
    Theses: 'Thèses',
    '0.5M': '0.5M',
    '2M': '2M',
    '36K': '36K',
    'Drug Profiles': 'Profils de médicament',
    '40K': '40K',
    'Genes Profiles': 'Profils de gènes',
    'Why Ontosight': 'Pourquoi Ontosight',
    Academic: 'Académique',
    'Search using Life Science ontology': "Recherche à l'aide de l'ontologie des sciences de la vie",
    'Our ontology understands life science concepts and terms.': 'Notre ontologie comprend les concepts et les termes LifeSciences. Elle est capable de comprendre que les acronymes sont liés à divers concepts et peut distinguer deux concepts qui partagent la même acronyme, tel que EGFR.',
    'Single platform': 'Plateforme unique',
    'Academic consolidates your search by connecting data from thousands* of life science databases. Ontosight': 'Academic consolide votre recherche en connectant des données provenant de milliers * de bases de données des sciences de la vie. Ontosight',
    'Academic actively crawls and aggregates over 97% of the life science public domain.': 'Les universitaires explorent et agrègent activement plus de 97% du domaine public des sciences de la vie.',
    'Updated in real-time': 'Mis à jour en temps réel',
    'New data is assimilated into our data ocean immediately after publication. Set alerts for specific search clusters to stay on top of your unique research area.': 'Les nouvelles données sont assimilées dans notre océan de données immédiatement après leur publication. Définissez des alertes pour des groupes de recherche spécifiques afin de rester informé dans votre domaine de recherche unique.',
    'Find the cutting-edge': 'Trouver les dernières innovations',
    'Academic can help you navigate fast-moving research frontiers. Ontosight': 'Les universitaires peuvent vous aider à naviguer dans les frontières de la recherche en évolution rapide. Ontosight',
    'Academic encompasses a massive volume of life science congresses and theses, ensuring that you have access to the most nascent research being done in your field.': "Les universités englobent un nombre considérable de congrès et de thèses sur les LifeSciences, vous permettant d'avoir accès aux recherches les plus récentes dans votre domaine.",
    'A PRODUCT BY': 'UN PRODUIT DE',
    "Innoplexus is structuring the world's information": "Innoplexus est en train de structurer l'information du monde pour faciliter l'IA. Innoplexus propose des produits de données en tant que service (DaaS) et des analyses continues en tant que service (CAaS), exploitant l'intelligence artificielle, des algorithmes exclusifs et des technologies en attente de brevet pour aider les organisations mondiales du secteur des sciences de la vie à accéder aux données pertinentes, à l'intelligence et aux intuitions clinique, clinique, réglementaire et commercial d’un médicament.",
    'We automate the collection and curation of data using technologies such as natural language processing': "Nous automatisons la collecte et la conservation des données à l'aide de technologies telles que le traitement du langage naturel, l'analyse de réseau, les ontologies, la vision par ordinateur et la normalisation d'entités. Nos clients sont des sociétés Fortune 500, y compris certaines des plus grandes sociétés pharmaceutiques du monde.",
    'VISIT WEBSITE': 'VISITEZ LE SITE WEB',
    'Phrase match': 'Phrase correspondante',
    'Has mentions In': 'A des mentions dans',
    'No folders available': 'Aucun dossier disponible',
    'Start year': 'Année de début',
    'End Year': "Fin d'année",
    number_of_records: "Nombre d'enregistrements",
    file_format: 'Format de fichier',
    Select: 'Sélectionner',
    equalizer: 'égaliseur',
    'Select Language': 'Choisir la langue',
    MOA: 'MOA',
    'Search Equalizer': 'Rechercher égaliseur',
    'Source Link': 'Lien source',
    'View Details': 'Voir les détails',
    demo: 'démo',
    Bibliography: 'Bibliographie',
    Bookmark: 'Signet',
    'Select Indications': 'Sélectionnez des indications',
    filter: 'filtre',
    'Related drugs': 'Médicaments liés',
    'Widget Preview': 'Aperçu du widget',
    'Zoom In': 'Agrandir',
    'Zoom Out': 'Dézoomer',
    'Select Societies': 'Sélectionner des sociétés',
    'Select Interventions': 'Sélectionnez des interventions',
    'Select Geography': 'Sélectionnez la géographie',
    'Select application Number': "Sélectionnez le numéro de l'application",
    'Bibliography Export': 'Bibliography Export',
    'Back to home': 'Retour à la maison'
  }
}
