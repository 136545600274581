import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { uniqueFromArray } from '../../../common/utils'

const DidYouMean = ({ suggestions, onMeanClick }) => {
  const showSuggestions = uniqueFromArray(suggestions).slice(0, 5).map((item, i) => (
    <div className='links-didyoumeans' role='presentation' key={i} onClick={() => onMeanClick(item)} >{item}</div>
  ))
  return (
    <div className='search-help'>
      <div className='search-help-text'><FormattedMessage id="Did you mean" defaultMessage="Did you mean" />: </div>
      {showSuggestions}
    </div>
  )
}

DidYouMean.propTypes = {
  onMeanClick: PropTypes.func.isRequired,
  suggestions: PropTypes.array
}

DidYouMean.defaultProps = {
  suggestions: []
}

export default DidYouMean
