import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import html2canvas from 'html2canvas'

class Highlighter extends Component {
  constructor() {
    super();
    this.state = {
      paths: [[]],
      isDrawing: false,
      top: 0,
      scrollHeight: 0,
      left: 0,
      lightRects: [[]],
      darkRects: [[]],
      option: ''
    };
  }

  componentWillMount() {
    this.capture();
  }

  componentDidMount = () => {
    const node = (this.canvas);
    const rect = node.getBoundingClientRect();
    const { left, top } = rect;
    this.setState({ top, left });
  }

  handleScroll = () => {
    const container = document.querySelector('.highlighter-container')
    const h = container.scrollTop
    this.setState({ scrollHeight: h });
  }

  handleMouseDown = (e) => {
    if (!this.state.isDrawing) {
      const lastX = e.pageX - this.state.left;
      const lastY = (e.pageY - this.state.top) + this.state.scrollHeight;
      this.setState({
        lastX,
        lastY,
        // width: 0,
        // height: 0,
        paths: [].concat(this.state.paths, [[]]),
        lightRects: [].concat(this.state.lightRects, [[]]),
        darkRects: [].concat(this.state.darkRects, [[]])
      });
    }
    this.setState({ isDrawing: true });
  }

  handleMouseMove = (e) => {
    if (this.state.isDrawing) {
      const x = e.pageX - this.state.left;
      const y = (e.pageY - this.state.top) + this.state.scrollHeight;
      const width = x - this.state.lastX;
      const height = y - this.state.lastY;

      if (this.state.option === 'highlight') {
        const lightRects = this.state.lightRects.slice(0);
        const activePathLight = lightRects[lightRects.length - 1];
        activePathLight.push({
          lastX: this.state.lastX,
          lastY: this.state.lastY,
          width,
          height
        });
        this.setState({ lightRects });
      }
      if (this.state.option === 'hide') {
        const darkRects = this.state.darkRects.slice(0);
        const activePathDark = darkRects[darkRects.length - 1];
        activePathDark.push({
          lastX: this.state.lastX,
          lastY: this.state.lastY,
          width,
          height
        });
        this.setState({ darkRects });
      }
      if (this.state.option === 'line') {
        const paths = this.state.paths.slice(0);
        const activePath = paths[paths.length - 1];
        activePath.push({ x, y });
        this.setState({ paths });
      }
    }
  }

  handleMouseUp = () => {
    if (this.state.isDrawing) {
      this.setState({ isDrawing: false });
    }
  }

  capture = () => {
    html2canvas(document.getElementById('app'), {
      scale: 5
    }).then((canvas) => {
      const img = canvas.toDataURL('image/jpeg')
      this.setState({ img });
    });
  }

  undo() {
    if (this.state.option === 'highlight') {
      const temp = this.state.lightRects
      temp.pop()
      this.setState({ lightRects: temp });
    }
    if (this.state.option === 'hide') {
      const temp = this.state.darkRects
      temp.pop()
      this.setState({ darkRects: temp });
    }
  }

  download = () => {
    html2canvas(document.querySelector('.highlighter-canvas')).then((canvas) => {
      const downlodImg = canvas.toDataURL('image/jpeg')
      this.setState({ downlodImg });
    });
    setTimeout(() => {
      const download = document.getElementById('download');
      download.setAttribute('href', this.state.downlodImg);
    }, 1000);
  }

  render() {
    const paths = this.state.paths.map((_points) => {
      let path = '';
      const points = _points.slice(0);
      if (points.length > 0) {
        path = `M ${points[0].x} ${points[0].y}`;
        let p1;
        let p2;
        let end;
        for (let i = 1; i < points.length - 2; i += 2) {
          p1 = points[i];
          p2 = points[i + 1];
          end = points[i + 2];
          path += ` C ${p1.x} ${p1.y}, ${p2.x} ${p2.y}, ${end.x} ${end.y}`;
        }
      }
      return path;
    }).filter(p => p !== '');
    return (
      <div className='highlighter-container' onScroll={this.handleScroll} style={{ height: '550px', overflow: 'auto' }}>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '1em' }}>
          {/* <button onClick={() => this.setState({ option: 'line' })} className='btn btn-primary'>Draw</button> */}
          <button onClick={() => this.setState({ option: 'highlight' })} className='btn btn-primary'>Highlight</button>
          <button onClick={() => this.setState({ option: 'hide' })} className='btn btn-primary'>Hide</button>
          <button onClick={() => this.undo()} className='btn btn-primary'>Undo</button>
          <a id="download" download="highlight.jpeg" style={{ marginLeft: '0.8em' }} >
            <button onClick={this.download} className='btn btn-primary'>Download</button>
          </a>
        </div>
        <div className='highlighter-canvas'>
          <svg
            style={{ cursor: 'crosshair' }}
            width={1100}
            height={1000}
            ref={(ref) => { this.canvas = ref }}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onMouseMove={this.handleMouseMove}
          >
            <image x={0} y={0} xlinkHref={this.state.img} height={1000} width={1100} />
            {
              paths.map(path => (<path
                key={path}
                stroke="black"
                strokeWidth={2}
                d={path}
                fill="none"
              />))
            }
            {
              this.state.lightRects.map((rect) => {
                const lastX = rect[rect.length - 1] ? rect[rect.length - 1].lastX : 0
                const lastY = rect[rect.length - 1] ? rect[rect.length - 1].lastY : 0
                const width = rect[rect.length - 1] ? rect[rect.length - 1].width : 0
                const height = rect[rect.length - 1] ? rect[rect.length - 1].height : 0
                return (
                  <rect x={lastX} y={lastY} width={width} height={height} stroke="black" fill="transparent" strokeWidth="3" />
                )
              })
            }
            {
              this.state.darkRects.map((rect) => {
                const lastX = rect[rect.length - 1] ? rect[rect.length - 1].lastX : 0
                const lastY = rect[rect.length - 1] ? rect[rect.length - 1].lastY : 0
                const width = rect[rect.length - 1] ? rect[rect.length - 1].width : 0
                const height = rect[rect.length - 1] ? rect[rect.length - 1].height : 0
                return (
                  <rect x={lastX} y={lastY} width={width} height={height} stroke="black" fill="black" strokeWidth="3" />
                )
              })
            }
          </svg>
        </div>
      </div >
    );
  }
}

export default withRouter(Highlighter)
