import React, { Fragment } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
// import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
// import { ListIcons } from '../'
// import Congresses from "./congresses";

const ThreeDIntegration = props => (
  <Fragment>
    { props.flag &&
      <div className='card-actions'>
        <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Unpublished' />
      </div>
    }
    {/* <div className="three-d-card-img-block">
       <img className="img" alt='' src='src/assets/images/slide.png' />
    </div> */}
    <div className='card-details'>
      <div className="card-meta">
        <div className="date">
          {
            props.data.experiment_completion_date
            &&
            moment(props.data.experiment_completion_date).format('D MMM YYYY')
          }
        </div>
      </div>
      <h3 className='card-title break-word'>
        {props.data.name}
      </h3>
      <div className='card-text'>
        {/* {
          typeof (props.data.first_author) !== 'undefined'
          && props.data.first_author !== null
          &&
          <span>
            {props.data.first_author.first_name} {props.data.first_author.last_name});
          </span>
        }
        {
          typeof (props.data.second_author) !== 'undefined'
          && props.data.second_author !== null
          &&
          <span>
            {props.data.second_author.first_name} {props.data.second_author.last_name};
          </span>
        }
        {
          typeof (props.data.third_author) !== 'undefined'
          && props.data.third_author !== null
          &&
          <span>
            {props.data.third_author.first_name} {props.data.third_author.last_name}
          </span>
        } */}

        {props.data.first_author}{props.data.second_author && `, ${props.data.second_author}`}
        {props.data.third_author && `, ${props.data.third_author}`}
        {
          props.data.other_authors ? props.data.other_authors.map(author => (
            `, ${author}`
          )) : '-'
        }
      </div>
    </div>
  </Fragment>
);

ThreeDIntegration.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  flag: PropTypes.bool
};

ThreeDIntegration.defaultProps = {
  flag: true
};

export default ThreeDIntegration;
