import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
// import { square, circle, triangle, star } from '../../utils/index'

// const color = ['#ffc8cc', '#fead93', '#94f1e8', '#ff7c77', '#3aafa8', '#f1e078', '#a0a3ed']
const border = ['#e3898f', '#de744f', '#59c7bd', '#ce625d', '#288c86', '#c0b45a', '#8081bf']
class ScatterPlotDiscrete extends Component {
  constructor (props) {
    super(props)
    this.marginLeft = 240
    this.marginRight = 50
    this.marginTop = 50
    this.marginBottom = this.props.labelX ? 100 : 50
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
  }
  componentWillMount () {
    this.x = d3.scaleBand()
    this.r = d3.scaleLinear()
    this.y = d3.scaleBand()

    let xDom = []
    const yDom = []
    let rDom = []
    Object.keys(this.props.data).forEach((element) => {
      Object.keys(this.props.data[element]).forEach((el) => {
        xDom = xDom.concat(this.props.data[element][el].map(d => d.year))
        rDom = rDom.concat(this.props.data[element][el].map(d => d.count))
        yDom.push(el)
      })
    })
    xDom.sort()
    this.x.domain(xDom.map(d => Number(d)))
      .range([0, this.width])
    this.y.domain(yDom.map(d => d))
      .range([this.height, 0])
    const radiusRange = Math.min(this.y.bandwidth(), this.x.bandwidth())
    // const lowerRadiusRange = (radiusRange / 2) < 4 ? Math.max(1, (radiusRange / 2 - 2))
    this.r.domain(d3.extent(rDom))
      .range([4, Math.min(30, (radiusRange / 2))])
  }
  componentDidMount () {
    this.renderAxis()
  }
  renderAxis () {
    const xnode = '#xaxis'
    const xaxis = d3.axisBottom(this.x)
    d3.select(xnode).call(xaxis)
    const ynode = '#yaxis'
    const yaxis = d3.axisLeft(this.y).tickSizeOuter(-this.width)
    d3.select(ynode).call(yaxis)
  }
  renderShapes (i, el, xcategory, count) {
    // if (this.props.markers) {
    //   if (this.props.markers[i] === 'triangle') {
    //     return (
    //       Parser(triangle(this.x(xcategory) + (this.x.bandwidth() / 2), this.y(el) + (this.y.bandwidth() / 2), this.props.color[i], 0.8, 'scatter-circle'))
    //     )
    //   } else if (this.props.markers[i] === 'square') {
    //     return (
    //       Parser(square(this.x(xcategory) + (this.x.bandwidth() / 2), this.y(el) + (this.y.bandwidth() / 2), 10, this.props.color[i], 0.8, 'scatter-circle'))
    //     )
    //   } else if (this.props.markers[i] === 'circle') {
    //     return (
    //       Parser(circle(this.x(xcategory) + (this.x.bandwidth() / 2), this.y(el) + (this.y.bandwidth() / 2), 5, this.props.color[i], 0.8, 'scatter-circle'))
    //     )
    //   }
    //   return null
    // }
    return (
      <circle
        key={i}
        cx={this.x(xcategory) + (this.x.bandwidth() / 2)}
        cy={this.y(el) + (this.y.bandwidth() / 2)}
        fill={border[i]}
        r={this.r(count)}
      />
    )
  }
  renderGraph () {
    return Object.keys(this.props.data).map((element, i) => (
      Object.keys(this.props.data[element]).map(el => (
        this.props.data[element][el].map(d => (
          this.renderShapes(i, el, d.year, d.count)
        ))
      ))
    ))
  }
  renderLabelX () {
    return (
      <text
        transform={`translate(${this.width / 2}, ${this.height + (this.marginBottom / 2)})`}
        textAnchor='middle'
        className='graph-font-family capitalize-me'
      >
        {this.props.labelX}
      </text>
    )
  }
  renderLabelY () {
    return (
      <text
        transform={`translate(${-this.marginLeft / 1.2}, ${this.height / 2})rotate(-90)`}
        textAnchor='middle'
        className='graph-font-family-bold capitalize-me'
      >
        {this.props.labelY}
      </text>
    )
  }
  render () {
    return (
      <g
        transform={`translate(${this.marginLeft}, ${this.marginTop})`}
      >
        <g id='xaxis' className='x axis' transform={`translate(0, ${this.height})`} />
        <g id='yaxis' className='y axis small-font' />
        { this.renderGraph() }
        { this.props.labelX ? this.renderLabelX() : null}
        { this.props.labelY ? this.renderLabelY() : null}
      </g>
    )
  }
}

ScatterPlotDiscrete.propTypes = {
  labelX: PropTypes.string.isRequired,
  labelY: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.shape({
    xDom: PropTypes.string.isRequired,
    rDom: PropTypes.string.isRequired,
    yDom: PropTypes.string.isRequired
  })
}

ScatterPlotDiscrete.defaultProps = {
  data: {
    xDom: '',
    rDom: '',
    yDom: ''
  }
}

export default ScatterPlotDiscrete
