import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { modalCloseAction } from '../Modal/logic'

class WileyModal extends Component {
  componentDidMount () {
    console.log()
  }
  render() {
    return (
      <Fragment>
        <div className='modal-header'>
          <div className='modal-title'><FormattedMessage id="Wiley.header.text" defaultMessage="Access Online Wiley Full Text Library" /></div>
        </div>
        <div className='modal-body'>
          <p className='bold' ><FormattedMessage id="Wiley.body.text" defaultMessage="Get access to full text version of the article." /></p>
          <ul className='margin-medium-top' >
            <li style={{ listStyleType: 'disc' }} className='margin-lg-left margin-medium-top' >
              <div>Already a member of Online Wiley Full Text Library ?</div>
              <a rel="noopener" target='_balnk' href='https://onlinelibrary.wiley.com/action/showLogin?uri=%2Flibrary-info&sid=ontosight' className='link margin-small-top' >Log in with my credentials</a>
            </li>
            <li style={{ listStyleType: 'disc' }} className='margin-lg-left margin-medium-top' >
              <div>Not a member of Online Wiley Full Text Library ?</div>
              <a rel="noopener" target='_balnk' href='https://onlinelibrary.wiley.com/action/registration?sid=ontosight' className='link margin-small-top' >Sign up for wiley online library</a>
            </li>
          </ul>
        </div>
        <div className='modal-footer' >
          <button className='btn btn-default delete-btn' onClick={this.props.modalCloseAction}><FormattedMessage id="Profile.Cancel" defaultMessage="Cancel" /></button>
        </div>
      </Fragment>
    );
  }
}

WileyModal.propTypes = {
  modalCloseAction: PropTypes.func.isRequired,
}

export default connect(null, { modalCloseAction })(WileyModal)
