import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Switch from './switch'


class Toggle extends Component {
  toggleSwitch = () => {
    this.props.toggle()
  }
  render () {
    return (
      <div className='toggle'>
        <Switch
          onClick={this.toggleSwitch}
          on={this.props.switched}
          LabelOffText={this.props.LabelOffText}
          LabelOnText={this.props.LabelOnText}
        />
      </div>
    )
  }
}

Toggle.propTypes = {
  switched: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  LabelOffText: PropTypes.string,
  LabelOnText: PropTypes.string,
}

Toggle.defaultProps = {
  LabelOffText: '',
  LabelOnText: ''
}

export default withRouter(Toggle)
