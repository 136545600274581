import React, { Component } from 'react'
import { getAllPermissions } from '../../../common/utils'

const AlternateView = () => (<div> You Dont have permission </div>)

const Access = (allowedPermissions = []) => BaseComponent =>
  class Permissions extends Component {
    constructor (props) {
      super(props)
      const all = getAllPermissions()
      this.state = {
        allowedPermissions,
        all: all && all.length ? all : []
      }
      this.checkPermission = this.checkPermission.bind(this)
    }
    checkPermission () {
      let access = true
      for (let i = 0; i < allowedPermissions.length; i += 1) {
        access = access && (this.state.all.indexOf(allowedPermissions[i]) > -1)
      }
      return access
    }
    render () {
      if (this.checkPermission()) {
        return <BaseComponent {...this.props} />
      }
      return <AlternateView />
    }
  }

export default Access
