import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { stringify } from 'query-string'
import { apiCallFetch, checkPermission } from '../../utils'
import { createBookmarkSuccessAction, deleteBookmarkSuccessAction } from './logic'
import { statusError } from '../Status/logic'
import { modalAction } from '../Modal/logic'

class Bookmark extends Component {
  onToggle(e, flag) {
    e.stopPropagation()
    if (flag) {
      this.props.modalAction({
        type: 'create_bookmark',
        open: true,
        dialog: true,
        size: 'small',
        data: {
          id: this.props.id,
          dataset: this.props.type,
          group: this.props.group
        }
      })
    } else {
      this.props.modalAction({
        type: 'delete_bookmark',
        open: true,
        dialog: true,
        size: 'small',
        data: {
          id: this.props.id,
          dataset: this.props.type,
          group: this.props.group
        }
      })
    }
  }
  deleteBookmark(query) {
    const url = `${process.env.faceliftApiUrl}v1/bookmark/delete/?${stringify(query)}`;
    const params = apiCallFetch('DELETE', true)

    fetch(url, params)
      .then(res => res.json())
      .catch(e => this.props.statusError(e))
      .then(res => this.props.deleteBookmarkSuccessAction({ id: res.doc_id, status: false }))
  }
  createBookmark(query) {
    const url = `${process.env.faceliftApiUrl}v1/bookmark/createBookmark`;
    const params = apiCallFetch('POST', true, query)

    fetch(url, params)
      .then(res => res.json())
      .catch(e => this.props.statusError(e))
      .then(res => this.props.createBookmarkSuccessAction({ id: res.doc_id, status: true }))
  }
  render() {
    const { intl } = this.props
    if (checkPermission('myworkspace_permission')) {
      return (
        <div className='icons is-clickable hand' >
          <i title={intl.formatMessage({ id: 'Favourite', defaultMessage: 'Favourite' })} role='presentation' data-status={this.props.flag} onClick={e => this.onToggle(e, !this.props.flag)} className={`star${!this.props.flag ? '-active' : ''}`} />
        </div>
      )
    }
    return null
  }
}

Bookmark.propTypes = {
  flag: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  createBookmarkSuccessAction: PropTypes.func.isRequired,
  deleteBookmarkSuccessAction: PropTypes.func.isRequired,
  statusError: PropTypes.func.isRequired,
  modalAction: PropTypes.func.isRequired,
  group: PropTypes.string,
  intl: PropTypes.object.isRequired
}

Bookmark.defaultProps = {
  group: 'Published'
}

export default connect(null, {
  createBookmarkSuccessAction, deleteBookmarkSuccessAction, statusError, modalAction
})(injectIntl(Bookmark))
