import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { connect } from 'react-redux'
import { getRedirectUrl, checkPermission, setAnalytics } from '../../utils'
import { ReadMore } from '../../components/index'
import { modalAction } from '../Modal/logic'
import Mixpanel from '../../mixpanel'


const ClickableAuthors = (props) => {
  const generateParams = (item) => {
    const query = parse(window.location.hash)
    const hash = parse(window.location.search)
    const noBack = query.noBack || hash.noBack
    const appLabel = query.appLabel || hash.appLabel
    return ({
      doc: item.author_id,
      q: `@${item.author_name.replace(/ /g, '~')}::${item.author_id}`,
      type: 'Author',
      class: 'author',
      id: item.author_id,
      ...(noBack ? { backApp: 'Abstract' } : {}),
      ...(appLabel ? { appLabel } : {})
    })
  }
  const openAuthor = (e, item) => {
    e.stopPropagation();
    if (checkPermission('ontosight_academics_user')) {
      props.modalAction({
        type: 'premium',
        open: true,
        dialog: true,
        size: 'large'
      })
      Mixpanel.track('noPermissionEvent', {
        action: 'No Permission',
        url: getRedirectUrl('author', generateParams(item), {}, false),
        permission: 'no permission',
        view: 'list author click'
      })
      setAnalytics('noPermissionTrigger', 'noPermission', JSON.stringify({
        url: getRedirectUrl('author', generateParams(item), {}, false),
        permission: 'no permission',
        view: 'list author click'
      }))
    } else {
      const url = getRedirectUrl('author', generateParams(item), {}, false)
      window.open(url)
    }
  }
  const renderAuthors = () => props.data.Authors.map((item, i) => (
    item.author_id ? (
      <a rel="noopener" role='presentation' className='author-name' onClick={e => openAuthor(e, item)} target='_blank' key={i} >{item.author_name}{i !== props.data.Authors.length - 1 ? ', ' : '.'}</a>
    ) : (
      <span className='author-name' key={i} >{item.author_name}{i !== props.data.Authors.length - 1 ? ', ' : '.'}</span>
    )))
  return (
    <Fragment>
      <ReadMore limit={props.limit}>
        {renderAuthors()}
      </ReadMore>
    </Fragment>
  )
}

ClickableAuthors.propTypes = {
  data: PropTypes.object.isRequired,
  limit: PropTypes.number,
  modalAction: PropTypes.func.isRequired,
}

ClickableAuthors.defaultProps = {
  limit: 50
}

export default connect(null, { modalAction })(ClickableAuthors)
