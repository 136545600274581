import { checkPermission } from '../../utils'

export const academicPermissionText = [
  'Clinical Trials detailed view',
  'Advanced Search',
  'Abstract Annotations',
]

export const permissionsText = {
  myworkspace_permission: 'My Workspace',
  discover_permission: 'Discover',
  // summary_dashboard: 'Analytics Dashboard',
  home_deals_list: 'Deals Data',
  home_congresses_list: 'Congresses Data',
  home_theses_list: 'Theses & Dissertations Data',
  home_treatment_guideline_list: 'Treatment Guidelines Data',
  home_publications_list: 'Publications Data',
  home_clinical_trials_list: 'Clinical Trials Data',
  home_patents_list: 'Patents Data',
  home_grants_list: 'Grants Data',
  home_news_and_press_releases_list: 'News & Press Releases Data',
  home_export_list: 'Download list',
  home_springer_publications_list: 'Springer Publications Partner Data',
  clincaltrials_analytics_view: 'Clinical Trials Analytics Data',
  publications_analytics_view: 'Publications Analytics Data',
  home_wiley_congresses_list: 'Wiley Congresses Partner Data',
  home_ema_regulatory_guidance_drugs_list: 'EMA Drugs Specific Guidances Data',
  home_regulatory_guidance_general_list: 'FDA General Guidances Data',
  home_regulatory_guidance_drugs_list: 'FDA Drugs Specific Guidances Data',
  home_labels_list: 'Labels/Package Insert Data',
  home_regulatory_decision_list: 'Regulatory Decision Data',
  home_advisory_committee_list: 'Advisory Committee Data',
  home_regulatory_review_list: 'Regulatory Review Data',
  home_wiley_publications_list: 'Wiley Publications Partner Data',
  home_expression_data_list: 'Expression Data',
  aaoca_clarivate_data: 'Investigational Drugs Data',
  congresses_analytics_view: 'Congresses Analytics Data',
  patent_analytics_view: 'Patents Analytics Data',
  publications_w_analytics_view: 'Wiley Publications Analytics Partner Data',
  search_summary_biomedical_entities: 'Search Summary Biomedical Entities',
  search_summary_authors: 'Search Summary Authors',
  // reviewed_publications_toggle: 'Reviewed/Unreviewed Publications toggle',
  // search_equializer: 'Custom sorting',
  citation_index: 'Publication Citation Index',
  hospital_view: 'Hospital Detailed View',
  company_view: 'Company Detailed View',
  intervention_view: 'Intervention Detailed view',
  protein_view: 'Protein Detailed view',
  gene_view: 'Gene Detailed view',
  author_view: 'Author Detailed view',
  preclinical_view: 'Pre Clinical Detailed view',
}

export const getPermissionText = () => Object.keys(permissionsText).filter(item => !checkPermission(item))
