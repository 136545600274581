export function getMonth (timeStamp) {
  return (timeStamp !== null && typeof timeStamp !== 'undefined') ? new Date(timeStamp).getMonth() : new Date().getMonth()
}
export function getYear (timeStamp) {
  return (timeStamp !== null && typeof timeStamp !== 'undefined') ? new Date(timeStamp).getFullYear() : new Date().getFullYear()
}
export function getDate (timeStamp) {
  return (timeStamp !== null && typeof timeStamp !== 'undefined') ? new Date(timeStamp).getDate() : new Date().getDate()
}
export function getNoOfDays (timeStamp) {
  return new Date(getYear(timeStamp), getMonth(timeStamp) + 1, 0).getDate()
}
export function getNoOfDaysSpecificMonthYear (year, month) {
  return new Date(year, month + 1, 0).getDate()
}
export function getEmptySpace (timeStamp) {
  return new Date(getYear(timeStamp), getMonth(timeStamp), 1).getDay()
}
export function getTimeStamp (...value) {
  return value ? new Date(...value).getTime() : new Date().getTime()
}
export function getMinMaxValue (type, maxFlag, active) {
  if (type === 'date' && active !== null) {
    const min = getTimeStamp(getYear(active), getMonth(active), getDate(active))
    const max = getTimeStamp(getYear(active), getMonth(active), getDate(active), 23, 59, 59)
    return maxFlag ? max : min
  } else if (type === 'month' && active !== null) {
    const min = getTimeStamp(getYear(active), getMonth(active), 1)
    const max = getTimeStamp(getYear(active), getMonth(active), getNoOfDays(active), 23, 59, 59)
    return maxFlag ? max : min
  } else if (type === 'year' && active !== null) {
    const min = getTimeStamp(getYear(active), 0, 1)
    const max = getTimeStamp(getYear(active), 11, 31, 23, 59, 59)
    return maxFlag ? max : min
  }
  return active
}
export function checkActive (type, after, active, year, month, date) {
  const min = getMinMaxValue(type, false, active)
  const monthCheck = typeof (month) !== 'undefined' && month !== null ? month : after ? 11 : 0
  const dateCheck = typeof (date) !== 'undefined' && date !== null ? date : after ? new Date(year, monthCheck + 1, 0).getDate() : 1
  const actual = getTimeStamp(year, monthCheck, dateCheck)
  const max = getMinMaxValue(type, true, active)
  return (min <= actual) && (actual <= max)
}
export function checkDisable (type, after, disable, year, month, date) {
  if (typeof (disable) !== 'undefined' && disable !== null) {
    const value = getMinMaxValue(type, after, disable)
    const monthCheck = typeof (month) !== 'undefined' && month !== null ? month : after ? 11 : 0
    const dateCheck = typeof (date) !== 'undefined' && date !== null ? date : after ? new Date(year, monthCheck + 1, 0).getDate() : 1
    const actual = getMinMaxValue(type, after, getTimeStamp(year, monthCheck, dateCheck))
    return after ? actual > value : actual < value
  }
  return false
}
export function formatDate (value, format = { day: '2-digit', month: 'short', year: 'numeric' }) {
  return Intl.DateTimeFormat('en-US', format).format(new Date(value))
}

const futureYear = new Date().getFullYear() + 10

export const disableBeforeDate = (type = 'year', date = '1900-1-1') => getMinMaxValue(type, false, new Date(date).getTime())
export const disableAfterDate = (type = 'year', date = `${futureYear}-1-1`) => getMinMaxValue(type, true, new Date(date).getTime())

const now = (type = 'date') => getMinMaxValue(type, true, getTimeStamp())
const startDate = (value, type = 'date') => getMinMaxValue(type, false, getTimeStamp(getYear(now(type)) - value, getMonth(now(type)), getDate(now(type))))

export const customOptions = (type = 'date', all = false) => [
  ...(all ? [{
    name: 'All',
    endDate: null,
    startDate: null
  }] : []),
  {
    name: 'Last 10 years',
    endDate: now(type),
    startDate: startDate(10, type)
  }, {
    name: 'Last 5 years',
    endDate: now(type),
    startDate: startDate(5, type)
  }, {
    name: 'Last 2 years',
    endDate: now(type),
    startDate: startDate(2, type)
  }, {
    name: 'Last 1 year',
    endDate: now(type),
    startDate: startDate(1, type)
  }
]
