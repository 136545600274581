import React from 'react';
// import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Purchase from '../../../app/container/Purchase/index'


const UnPublished = props => (
  <div className='abstract-preclinical-trials'>
    <div className='card-body-content'>
      <div className='sub-title'>
        <FormattedMessage id="Experiment Details" defaultMessage="Experiment Details" />
      </div>
      <div className='sub-text'>
        {props.data.summary}
      </div>
    </div>
    <div className='card-body-content'>
      {
        props.data.protocol_type.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Protocol used" defaultMessage="Protocol used" />:</span>
            {
              props.data.protocol_type.map((protocolType, index) => (
                <span key={index} className='category-details'>{protocolType.name}{props.data.protocol_type.length > index + 1 && ', '} </span>
              ))
            }
          </div>
      }
      {/*  <div className='sub-title category-body'>
        <span className='category-type'>Protocol used:</span>
        {
          props.data.protocol_type.map((protocolType, index) => (
            <span key={index} className='category-details'>{protocolType.name}{index > 0 && ','} </span>
          ))
        }
      </div>
      <div className='sub-title category-body'>
        <span className='category-type'>Type of experiment:</span>
        <span className='category-details'>{props.data.experiment_type[0].name}</span>
      </div> */}

      {
        props.data.experiment_type.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Type of experiment" defaultMessage="Type of experiment" />:</span>
            {
              props.data.experiment_type.map((material, index) => (
                <span key={index} className='category-details'>{material.name}{props.data.experiment_type.length > index + 1 && ', '} </span>
              ))
            }
          </div>
      }

      {
        props.data.consumables.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Materials used" defaultMessage="Materials used" />:</span>
            {
              props.data.consumables.map((material, index) => (
                <span key={index} className='category-details'>{material.name}{props.data.consumables.length > index + 1 && ', '} </span>
              ))
            }
          </div>
      }


      {
        props.data.experiment_type.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Stage Relevance" defaultMessage="Stage Relevance" />:</span>
            {
              props.data.experiment_type.map((material, index) => (
                <span key={index} className='category-details'>{material.group_type}{props.data.experiment_type.length > index + 1 && ', '} </span>
              ))
            }
          </div>
      }
      {/* <div className='sub-title category-body'>
        <span className='category-type'>Stage Relevance:</span>
        <span className='category-details'> {props.data.experiment_type[0].group_type}</span>
      </div> */}

      {
        props.data.department.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Department" defaultMessage="Department" />:</span>
            {
              props.data.department.map((material, index) => (
                <span key={index} className='category-details'>{material.name}{props.data.department.length > index + 1 && ', '} </span>
              ))
            }
          </div>
      }
      {
        props.data.drug_action_type.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Drug Action Type" defaultMessage="Drug Action Type" />:</span>
            {
              props.data.drug_action_type.map((material, index) => (
                <span key={index} className='category-details'>{material.name}{props.data.drug_action_type.length > index + 1 && ', '} </span>
              ))
            }
          </div>
      }

      {
        props.data.instruments.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Instruments" defaultMessage="Instruments" /></span>
            <span className='category-details'>
              { props.data.instruments.map((material, index) => (
                <span>{material.name} {props.data.instruments.length > index + 1 && ', '}</span>
                ))
              }
            </span>
          </div>
      }

      {
        props.data.research_field.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Research Field" defaultMessage="Research Field" />:</span>
            {
              props.data.research_field.map((material, index) => (
                <span key={index} className='category-details'>{material.name}{props.data.research_field.length > index + 1 && ', '} </span>
              ))
            }
          </div>
      }

      {
        props.data.species.length > 0
        &&
          <div className='sub-title category-body'>
            <span className='category-type'><FormattedMessage id="Species" defaultMessage="Species" />:</span>
            {
              props.data.species.map((material, index) => (
                <span key={index} className='category-details'>{material.name} {props.data.species.length > index + 1 && ', '} </span>
              ))
            }
          </div>
      }
    </div>
    <Purchase data={props.data} />
  </div>
);

UnPublished.propTypes = {
  data: PropTypes.object.isRequired
};

export default UnPublished;
