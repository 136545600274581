import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl';
import { ClickOutside } from 'reusable-react-components'
import Calendar from './calendar'
import { getMinMaxValue, formatDate } from './utils'
import CustomDropdown from './custom'

const setCustom = (type, customObject, startDate, endDate) => {
  const custom = {
    name: 'Custom',
    startDate,
    endDate
  }
  const customOptions = [
    ...customObject,
    custom
  ]
  let active = {
    name: 'All',
    startDate: null,
    endDate: null
  }
  if (startDate && endDate) {
    active = customOptions.find(item => getMinMaxValue('date', false, item.startDate) === startDate && getMinMaxValue('date', true, item.endDate) === endDate)
  }
  return {
    customOptions,
    active
  }
}

class DatePicker extends Component {
  constructor (props) {
    super(props)
    const { startDate = null, endDate = null } = this.props
    // const startDate = this.props.startDate ? getMinMaxValue(this.props.type, false, this.props.startDate) : null
    // const endDate = this.props.endDate ? getMinMaxValue(this.props.type, true, this.props.endDate) : null
    const custom = setCustom(this.props.type, this.props.customOptions, startDate, endDate)
    this.state = {
      open: this.props.open,
      startDate,
      endDate: startDate && endDate ? endDate >= startDate ? endDate : startDate : null,
      disableAfterDate: this.props.disableAfterDate,
      disableBeforeDate: this.props.disableDependsOnStart ? (startDate || this.props.disableBeforeDate) : this.props.disableBeforeDate,
      ...custom
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.startDate !== this.props.startDate || nextProps.endDate !== this.props.endDate) {
      // const startDate = getMinMaxValue(nextProps.type, false, nextProps.startDate)
      // const endDate = getMinMaxValue(nextProps.type, true, nextProps.endDate)
      const { startDate = null, endDate = null } = nextProps
      const custom = setCustom(nextProps.type, nextProps.customOptions, startDate, endDate)
      this.setState({
        startDate,
        endDate: endDate >= startDate ? endDate : startDate,
        disableAfterDate: nextProps.disableAfterDate,
        disableBeforeDate: nextProps.disableDependsOnStart ? startDate || nextProps.disableBeforeDate : nextProps.disableBeforeDate,
        ...custom
      })
    }
  }
  outsideClick = () => {
    const { startDate = null, endDate = null } = this.props
    // const startDate = getMinMaxValue(this.props.type, false, this.props.startDate)
    // const endDate = getMinMaxValue(this.props.type, true, this.props.endDate)
    const custom = setCustom(this.props.type, this.props.customOptions, startDate, endDate)
    this.setState({
      ...custom,
      open: false,
      startDate,
      endDate: endDate >= startDate ? endDate : startDate,
      disableAfterDate: this.props.disableAfterDate,
      disableBeforeDate: this.props.disableDependsOnStart ? startDate : this.props.disableBeforeDate
    })
  }
  onChange = (value, key, submit, type = this.props.type) => {
    this.setState({
      [key]: value,
      ...(key === 'startDate' ? {
        disableBeforeDate: this.props.disableDependsOnStart ? value || this.props.disableBeforeDate : this.state.disableBeforeDate,
        endDate: value > getMinMaxValue(type, false, this.state.endDate) ? getMinMaxValue(type, true, value) : this.state.endDate
      } : {})
    }, () => {
      const custom = setCustom(type, this.props.customOptions, this.state.startDate, this.state.endDate)
      this.setState(custom, () => {
        if (submit) {
          this.onSubmit(type)
        }
      })
    })
  }
  onSubmit = (type = this.props.type) => {
    this.setState({
      open: false
    }, () => {
      if (this.props.onSubmit) {
        this.props.onSubmit(getMinMaxValue(type, false, this.state.startDate), getMinMaxValue(type, true, this.state.endDate), 'submit')
      }
    })
  }
  onReset = () => {
    this.setState({
      open: false
    }, () => {
      if (this.props.onSubmit) {
        this.props.onSubmit(null, null, 'reset')
      }
    })
  }
  onCustomClick = (item) => {
    this.onChange(item.startDate, 'startDate', false, 'date')
    this.onChange(item.endDate, 'endDate', true, 'date')
  }
  renderBox () {
    const format = {
      year: { year: 'numeric' },
      month: { month: 'short', year: 'numeric' },
      date: { day: '2-digit', month: 'short', year: 'numeric' }
    }
    return (
      <button style={{ ...this.props.boxStyle }} data-test='datepicker-dropdown-box' title={this.props.startDate && this.props.endDate ? `${formatDate(this.props.startDate, format[this.props.type])} - ${formatDate(this.props.endDate, format[this.props.type])}` : `${formatDate(this.props.disableBeforeDate, format[this.props.type])} - ${formatDate(this.props.disableAfterDate, format[this.props.type])}`} onClick={() => this.setState({ open: !this.state.open })} className='s-date-picker-box' >
        {this.props.startDate && this.props.endDate ? `${formatDate(this.props.startDate, format[this.props.type])} - ${formatDate(this.props.endDate, format[this.props.type])}` : 'All'}
      </button>
    )
  }
  render() {
    const { intl } = this.props
    return (
      <div className={this.props.className} >
        {this.props.label && <div data-test='datepicker-dropdown-label' style={{ height: 25 }} className='filter-blk-label vcenter' ><div>{this.props.label}</div>{this.props.required && <div className='required-red'>*</div>}</div>}
        <ClickOutside onClickOutside={e => this.outsideClick(e)}>
          <div className="s-date-picker">
            {this.renderBox()}
            {this.state.open && (
              <div data-test='datepicker-dropdown-content' className={`s-date-picker-container pos-${this.props.position} ${this.props.type}`} >
                <div className='s-date-picker-container-header' >
                  {this.state.customOptions.length > 1 && <CustomDropdown active={this.state.active} onCustomClick={this.onCustomClick} options={this.state.customOptions} />}
                </div>
                <div data-test='datepicker-calendar' className='s-date-picker-content' >
                  <Calendar id='startDate' disableAfterDate={this.state.disableAfterDate} disableBeforeDate={this.props.disableBeforeDate} date={this.state.startDate} title={intl.formatMessage({ id: 'Start Date', defaultMessage: 'Start Date' })} type={this.props.type} onChange={value => this.onChange(value, 'startDate')} />
                  <Calendar id='endDate' disableAfterDate={this.state.disableAfterDate} disableBeforeDate={this.state.disableBeforeDate} date={this.state.endDate} title={intl.formatMessage({ id: 'End Date', defaultMessage: 'End Date' })} type={this.props.type} onChange={value => this.onChange(value, 'endDate')} endRange />
                </div>
                <div className='s-date-picker-container-footer' >
                  <button onClick={this.outsideClick} className='s-footer-btn cancel' >Cancel</button>
                  {this.props.reset && <button onClick={this.onReset} className='s-footer-btn calenderreset' ><FormattedMessage id="Reset" defaultMessage="Reset" /></button>}
                  <button disabled={(!this.state.startDate || !this.state.endDate)} onClick={this.onSubmit} className='s-footer-btn apply' ><FormattedMessage id="Apply" defaultMessage="Apply" /></button>
                </div>
              </div>
            )}
          </div>
        </ClickOutside>
      </div>
    );
  }
}

DatePicker.propTypes = {
  open: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  onSubmit: PropTypes.func,
  disableAfterDate: PropTypes.number,
  disableBeforeDate: PropTypes.number,
  disableDependsOnStart: PropTypes.bool,
  customOptions: PropTypes.array,
  position: PropTypes.string,
  intl: PropTypes.object.isRequired,
  required: PropTypes.bool,
  reset: PropTypes.bool,
  className: PropTypes.string,
  boxStyle: PropTypes.object,
}

DatePicker.defaultProps = {
  open: false,
  label: '',
  type: 'date',
  startDate: null,
  endDate: null,
  onSubmit: () => null,
  disableAfterDate: null,
  disableBeforeDate: null,
  disableDependsOnStart: false,
  customOptions: [],
  position: 'left',
  required: true,
  reset: false,
  className: 'padding-medium-right',
  boxStyle: {}
}

export default injectIntl(DatePicker);
