import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
export const AUTO_COMPLETE = 'AUTO_COMPLETE'
export const AUTO_COMPLETE_SUCCESS = 'AUTO_COMPLETE_SUCCESS'
export const AUTO_COMPLETE_FAILURE = 'AUTO_COMPLETE_FAILURE'
export const AUTO_COMPLETE_CANCEL = 'AUTO_COMPLETE_CANCEL'

export const ADD_SEARCH_TERM = 'ADD_SEARCH_TERM'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

// Auto Complete cancel action
export const autoCompleteCancelAction = () => ({
  type: AUTO_COMPLETE_CANCEL
})

// Auto Complete action
export const autoCompleteAction = payload => ({
  type: AUTO_COMPLETE,
  payload
})

// Auto Complete Success action
export const autoCompleteSuccess = payload => ({
  type: AUTO_COMPLETE_SUCCESS,
  payload
})

// Add search term action
export const addSearchTermaction = payload => ({
  type: ADD_SEARCH_TERM,
  payload
})

// Auto Complete epic
export const autoCompleteEpic = action$ => action$
  .ofType(AUTO_COMPLETE)
  .debounceTime(500)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/autocomplete?${action.payload}`, 'GET'))
    .map(response => autoCompleteSuccess(response))
    .takeUntil(action$.ofType(AUTO_COMPLETE_CANCEL))
    .catch(error => Observable.of({
      type: AUTO_COMPLETE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Auth reducer updates both autoComplete and logout
export function autoCompleteReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case AUTO_COMPLETE: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case AUTO_COMPLETE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case AUTO_COMPLETE_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function addSearchTermReducer (state = {}, action = null) {
  if (action.type === ADD_SEARCH_TERM) {
    return action.payload
  }
  return state
}


// FORCE REFRESH
export const ALLOW_FORCE_REFRESH = 'ALLOW_FORCE_REFRESH'
export const BLOCK_FORCE_REFRESH = 'BLOCK_FORCE_REFRESH'
export const FORCE_REFRESH_INITIAL_STATE = {
  allowForceRefresh: false
}

export const allowForceRefreshAction = () => ({
  type: ALLOW_FORCE_REFRESH
})

export const blockForceRefreshAction = () => ({
  type: BLOCK_FORCE_REFRESH
})

export function forceRefreshReducer (state = FORCE_REFRESH_INITIAL_STATE, action = null) {
  switch (action.type) {
    case ALLOW_FORCE_REFRESH: {
      return {
        ...state,
        allowForceRefresh: true,
      }
    }
    case BLOCK_FORCE_REFRESH: {
      return {
        ...state,
        allowForceRefresh: false,
      }
    }
    default:
      return state
  }
}


// Create Search History
// Constants
export const CREATE_HISTORY = 'CREATE_HISTORY'
export const CREATE_HISTORY_SUCCESS = 'CREATE_HISTORY_SUCCESS'
export const CREATE_HISTORY_FAILURE = 'CREATE_HISTORY_FAILURE'

export const createHistoryAction = payload => ({
  type: CREATE_HISTORY,
  payload
})

export const createHistorySuccess = payload => ({
  type: CREATE_HISTORY_SUCCESS,
  payload
})

export const createHistoryEpic = action$ => action$
  .ofType(CREATE_HISTORY)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/workspace/createsearchhistory`, 'POST', true, action.payload))
    .map(response => createHistorySuccess(response))
    .catch(error => Observable.of({
      type: CREATE_HISTORY_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function createHistoryReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case CREATE_HISTORY: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case CREATE_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case CREATE_HISTORY_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
