import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { parse, stringify } from 'query-string'
import { FormattedMessage } from 'react-intl';
// import { sidOpenable } from '../../constant';

const ClickableDOI = (props) => {
  const { DOI } = props.data
  let link = ''
  if (props.data && props.data['DOI Link']) {
    const ref = props.data['DOI Link'].match(/^https?:/) ? props.data['DOI Link'] : `http://${props.data['DOI Link']}`
    // let params = sidOpenable.indexOf(props.type) > -1 ? {
    //   sid: 'ontosight'
    // } : {}
    let params = {
      sid: 'ontosight'
    }
    const split = ref.split('?')
    if (split.length > 1) {
      params = {
        ...params,
        ...(parse(split[1]))
      }
    }
    link = `${split[0]}?${stringify(params)}`
  }
  const renderDOI = () => (
    <Fragment>
      {
        DOI && DOI !== 'not found' ?
          <Fragment>
            <div data-test='doi-content' className='doi'>
              <div className='sub-title'><FormattedMessage id="DOI" defaultMessage="DOI" /></div>
              {link ?
                <a rel="noopener" data-test='doi-link' className='sub-text' target='_blank' href={link}>{props.data.DOI} </a> :
                <div data-test='doi-text' className='sub-text'> {props.data.DOI} </div>
              }
            </div>
          </Fragment> : null
      }
    </Fragment >
  )
  return (
    <Fragment>
      {renderDOI()}
    </Fragment>
  )
}

ClickableDOI.propTypes = {
  data: PropTypes.object.isRequired,
  // type: PropTypes.string,
}

ClickableDOI.defaultProps = {
  // type: ''
}

export default ClickableDOI
