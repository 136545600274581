import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { getEmptySpace, getMonth, getYear, getNoOfDays, getTimeStamp, getDate, getMinMaxValue, checkActive, checkDisable, getNoOfDaysSpecificMonthYear } from './utils'

const Days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const Month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

class Calendar extends Component {
  constructor (props) {
    super(props)
    const timeStamp = getMinMaxValue(this.props.type, this.props.endRange, this.props.date)
    this.state = {
      month: getMonth(timeStamp),
      year: getYear(timeStamp),
      date: getDate(timeStamp),
      type: this.props.type,
      active: timeStamp,
      noOfDays: getNoOfDays(timeStamp),
      empty: getEmptySpace(timeStamp)
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.date !== this.props.date) {
      const timeStamp = getMinMaxValue(nextProps.type, nextProps.endRange, nextProps.date)
      this.setState({
        month: getMonth(timeStamp),
        year: getYear(timeStamp),
        date: getDate(timeStamp),
        type: this.props.type,
        active: timeStamp,
        noOfDays: getNoOfDays(timeStamp),
        empty: getEmptySpace(timeStamp)
      })
    }
  }
  onArrowLeftClick = () => {
    if (this.state.type === 'date') {
      this.setState({
        month: this.state.month === 0 ? 11 : this.state.month - 1,
        year: this.state.month === 0 ? this.state.year - 1 : this.state.year,
      }, () => this.getCalendarDates())
    } else if (this.state.type === 'month') {
      this.setState({
        year: this.state.year - 1
      })
    } else if (this.state.type === 'year') {
      const year = (parseInt(this.state.year / 20, 10) * 20)
      this.setState({
        year: year - 20
      })
    }
  }
  onArrowRightClick = () => {
    if (this.state.type === 'date') {
      this.setState({
        month: this.state.month === 11 ? 0 : this.state.month + 1,
        year: this.state.month === 11 ? this.state.year + 1 : this.state.year
      }, () => this.getCalendarDates())
    } else if (this.state.type === 'month') {
      this.setState({
        year: this.state.year + 1
      })
    } else if (this.state.type === 'year') {
      const year = (parseInt(this.state.year / 20, 10) * 20)
      this.setState({
        year: year + 20
      })
    }
  }
  onDateChange = (type, value) => {
    this.setState({
      [type]: value,
    }, () => this.restrictCheck())
  }
  setActive = () => {
    const days = this.state.date > getNoOfDaysSpecificMonthYear(this.state.year, this.state.month) ? getNoOfDaysSpecificMonthYear(this.state.year, this.state.month) : this.state.date
    this.setState({
      active: getMinMaxValue(this.props.type, this.props.endRange, getTimeStamp(this.state.year, this.state.month, days))
    }, () => this.props.onChange(this.state.active))
  }
  getCalendarDates () {
    this.setState({
      noOfDays: getNoOfDays(`${this.state.year}/${this.state.month + 1}/15`),
      empty: getEmptySpace(`${this.state.year}/${this.state.month + 1}/15`)
    })
  }
  restrictCheck () {
    if (this.state.type === 'month' && this.props.type === 'date') {
      this.setState({
        type: 'date',
        noOfDays: getNoOfDays(`${this.state.year}/${this.state.month + 1}/15`),
        empty: getEmptySpace(`${this.state.year}/${this.state.month + 1}/15`)
      })
    } else if (this.state.type === 'year' && (this.props.type === 'date' || this.props.type === 'month')) {
      this.setState({
        type: 'month'
      })
    }
    if (this.state.type === this.props.type) {
      this.setActive()
    }
  }
  onButtonContentClick = () => {
    if (this.state.type === 'date') {
      this.setState({
        type: 'month'
      })
    } else if (this.state.type === 'month') {
      this.setState({
        type: 'year'
      })
    }
  }
  renderButtonContent () {
    if (this.state.type === 'date') {
      return <Fragment>{Month[this.state.month]} {this.state.year}</Fragment>
    } else if (this.state.type === 'month') {
      return <Fragment>{this.state.year}</Fragment>
    } else if (this.state.type === 'year') {
      const year = (parseInt(this.state.year / 20, 10) * 20)
      return <Fragment>{year}-{year + 19}</Fragment>
    }
    return null
  }
  renderContent () {
    if (this.state.type === 'date') {
      return (
        <Fragment>
          <div className='calendar-wrap' >
            {Days.map((day, i) => <div className='calendar-days' key={i} >{day}</div>)}
          </div>
          <div className='calendar-wrap' >
            {Array(this.state.empty).fill('').map((day, i) => <div className='calendar-days' key={i} >{day}</div>)}
            {
              Array(this.state.noOfDays).fill('').map((day, i) => {
                const disabled = checkDisable('date', false, this.props.disableBeforeDate, this.state.year, this.state.month, i + 1) || checkDisable('date', true, this.props.disableAfterDate, this.state.year, this.state.month, i + 1)
                return (
                  <button data-test='calendar-dates-button' disabled={disabled} onClick={() => this.onDateChange('date', i + 1)} className={`calendar-date-button ${checkActive(this.state.type, this.props.endRange, this.state.active, this.state.year, this.state.month, i + 1) ? 'active' : ''}`} key={i} >{i + 1}</button>
                )
              })
            }
          </div>
        </Fragment>
      )
    } else if (this.state.type === 'month') {
      return (
        <div className='calendar-wrap padding-small-top' >
          {
            Month.map((month, i) => {
              const disabled = checkDisable('month', false, this.props.disableBeforeDate, this.state.year, i) || checkDisable('month', true, this.props.disableAfterDate, this.state.year, i)
              return (
                <button data-test='calendar-months-button' disabled={disabled} key={i} onClick={() => this.onDateChange('month', i)} className={`calendar-month-button ${checkActive('month', this.props.endRange, this.state.active, this.state.year, i) ? 'active' : ''}`} >{month}</button>
              )
            })
          }
        </div>
      )
    } else if (this.state.type === 'year') {
      const years = (parseInt(this.state.year / 20, 10) * 20)
      return (
        <div className='calendar-wrap padding-small-top' >
          {
            Array(20).fill('').map((year, i) => {
              const disabled = checkDisable('year', false, this.props.disableBeforeDate, years + i) || checkDisable('year', true, this.props.disableAfterDate, years + i)
              return (<button data-test='calendar-years-button' disabled={disabled} key={i} onClick={() => this.onDateChange('year', years + i)} className={`calendar-year-button ${checkActive('year', this.props.endRange, this.state.active, years + i) ? 'active' : ''}`} >{years + i}</button>)
            })
          }
        </div>
      )
    }
    return null
  }
  render() {
    return (
      <div data-test='calendar' className='s-date-picker-calendar-container' >
        {this.props.title && <div data-test='calendar-title' className='s-date-picker-calendar-container-header' >{this.props.title}</div>}
        <div className='s-date-picker-calendar-container-body' >
          <div data-test='calendar-control-panel' className='s-date-picker-controls-container' >
            <button data-test='calendar-left-arrow' onClick={this.onArrowLeftClick} className='s-date-picker-control-btn arrow-button' >
              <div className='arrow-left' />
            </button>
            <button data-test='calendar-control-display-button' disabled={this.state.type === 'year'} onClick={this.onButtonContentClick} className='s-date-picker-control-btn content-button'>
              {this.renderButtonContent()}
            </button>
            <button data-test='calendar-right-arrow' onClick={this.onArrowRightClick} className='s-date-picker-control-btn arrow-button' >
              <div className='arrow-right' />
            </button>
          </div>
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

Calendar.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  endRange: PropTypes.bool,
  onChange: PropTypes.func,
  date: PropTypes.number,
  disableBeforeDate: PropTypes.number,
  disableAfterDate: PropTypes.number,
}
Calendar.defaultProps = {
  title: '',
  type: 'date',
  endRange: false,
  onChange: () => null,
  date: new Date().getTime(),
  disableBeforeDate: null,
  disableAfterDate: null
}

export default Calendar
