// import React, { Fragment } from 'react'
// import PropTypes from 'prop-types'
// import { FormattedMessage } from 'react-intl';
// import ReactHtmlParser from 'react-html-parser'
// import Bookmark from '../../container/Bookmark'
// import { ListIcons, AssetTag, AnnotationIcon } from '..'
// import ReadMore from '../ReadMore';
// import { generateStringArray } from '../../utils'
// import ShowMore from '../ShowMore'

// const PublicationWileyList = (props) => {
//   const renderAuthors = () => props.data.Authors.map((item, i) => (
//     <span className='author-name' key={i} >{item.author_name}{i !== props.data.Authors.length - 1 ? ', ' : '.'}</span>
//   ))
//   const renderTags = () => {
//     let tags = []
//     if (props.data.Tags.length) {
//       tags = generateStringArray(props.data.Tags, 'name')
//     }
//     return tags.length && (<ShowMore data={[{ value: tags }]} />)
//   }

//   return (
//     <Fragment>
//       { props.flag &&
//         <div className='card-actions'>
//           <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Published' />
//           <AnnotationIcon data={props.data} />
//         </div>
//       }
//       <div className='card-details'>
//         <div className='card-meta'>

//           <AssetTag asset={props.data.type} />
//           {props.data['Journal Title'] ? <div className='card-meta-title text-elips'>{props.data['Journal Title']}</div> : null}
//           {props.data['Publication Date'] ? <div className='date'>{props.data['Publication Date']}</div> : null}
//           {props.data['Full Text Link'] ? <a className='is-clickable handicon padding-small-left' onClick={e => e.stopPropagation()} target='_balnk' href={props.data['Full Text Link']}><FormattedMessage id="Has full text" defaultMessage="Has full text" /></a> : null}
//         </div>
//         {props.data['Article Title']
//           ? (
//             <h3 className='card-title'>
//               {ReactHtmlParser(props.data['Article Title'])}
//             </h3>
//         ) : null}
//         {!props.showMore && props.data.Authors.length > 0 && props.data.Authors.map(author => author.author_name).join('').length > 0 && (
//           <div className='card-text' >
//             <ReadMore limit={3}>
//               {renderAuthors()}
//             </ReadMore>
//           </div>
//         )}
//         {props.showMore
//         ? (
//           <Fragment>
//             {props.data.Authors.length > 0 && props.data.Authors.map(author => author.author_name).join('').length > 0 && (
//             <div className='card-body-content' >
//               <div className='sub-title'><FormattedMessage id="Authors" defaultMessage="Authors" />:</div>
//                 {renderAuthors()}
//             </div>)}
//             {props.data.Tags.length > 0 && (
//             <div className='card-body-content' >
//               <div className='sub-title'><FormattedMessage id="Ontology Tags" defaultMessage="Ontology Tags" />:</div>
//               <div className='tag-list-wrap'>{renderTags()}</div>
//             </div>)}
//           </Fragment>
//         ) : null }
//       </div>
//       {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
//     </Fragment>
//   )
// }

// PublicationWileyList.propTypes = {
//   type: PropTypes.string.isRequired,
//   data: PropTypes.object.isRequired,
//   flag: PropTypes.bool,
//   showMore: PropTypes.bool,
//   likeParams: PropTypes.object,
//   takeFeedback: PropTypes.bool,
//   overlay: PropTypes.bool,
// }

// PublicationWileyList.defaultProps = {
//   flag: true,
//   showMore: false,
//   likeParams: {},
//   takeFeedback: true,
//   overlay: true
// }

// export default PublicationWileyList

import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { parse, stringify } from 'query-string'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import Bibliography from '../../container/Bibliography'
import { ClickableAuthors, ListIcons, AssetTag, AnnotationIcon } from '../'
import { generateStringArray, checkPermission, setAnalytics, parseHashUrl } from '../../utils'
import ShowMore from '../ShowMore'
import { modalAction } from '../../container/Modal/logic'
import { sidOpenable } from '../../constant';
import Mixpanel from '../../mixpanel'

const PublicationWileyList = (props) => {
  const renderTags = () => {
    let tags = []
    if (props.data.Tags && props.data.Tags.length) {
      tags = generateStringArray(props.data.Tags, 'name')
    }
    return tags.length && (<ShowMore data={[{ value: tags }]} />)
  }
  let link = ''
  if (props.data && props.data['Source Link']) {
    const ref = props.data['Source Link'].match(/^https?:/) ? props.data['Source Link'] : `http://${props.data['Source Link']}`
    let params = sidOpenable.indexOf(props.data.type) > -1 ? {
      sid: 'ontosight'
    } : {}
    const split = ref.split('?')
    if (split.length > 1) {
      params = {
        ...params,
        ...(parse(split[1]) || '')
      }
    }
    link = `${split[0]}?${stringify(params)}`
  }
  // const style = {
  //   top: 3,
  //   right: 15,
  //   zIndex: 200,
  //   position: 'absolute'
  // }
  // const onClick = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setAnalytics('citationClickedTrigger', 'citationClicked', JSON.stringify({
  //     dataset: 'publications',
  //     id: props.data.doc_id,
  //   }))
  //   props.openCard();
  // }
  const openFullText = (e, sourceUrl) => {
    e.preventDefault();
    e.stopPropagation();
    // if (false) {
    //   props.modalAction({
    //     type: 'wiley',
    //     open: true,
    //     dialog: true,
    //     size: 'small'
    //   })
    // } else {
    Mixpanel.track('wileyFulltextClickEvent', {
      action: 'wiley Full text Click',
      link,
      sourceUrl,
      type: 'Analytics',
      page: process.env.backLabel
    })
    setAnalytics('wileyFulltextClickTrigger', 'wileyFulltextClick', JSON.stringify({ link: sourceUrl, type: 'publications_w', ...(process.env.current === 'app' && parseHashUrl(window).summaryView === '1' ? { page: 'Analytics' } : { page: process.env.backLabel }) }))
    window.open(sourceUrl, '_blank')
    // }
  }
  return (
    <Fragment>
      {/* {!!props.data['Citation count'] && !props.showMore && checkPermission('citation_index') && (
        <a role='presentation' onClick={e => onClick(e)} style={style} className='card-text text-link' ><FormattedMessage id="Cited by" defaultMessage="Cited by" /> - {props.data['Citation count']}</a>
      )} */}
      {props.flag &&
        <div className='card-actions'>
          <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Partner Data' />
          <div className='margin-lg-top' >
            <Bibliography flag={props.data.bibliography || false} type={props.type} id={props.data.doc_id} group='Partner Data' />
          </div>
          {!checkPermission('ontosight_academics_user') && <AnnotationIcon data={props.data} />}
        </div>
      }
      <div className='card-details'>
        <div className='card-meta'>
          <AssetTag asset={props.data.type} />
          {props.data['Journal Title'] ? <div data-test='wiley-title' className='card-meta-title text-elips'>{props.data['Journal Title']}</div> : null}
          {props.data['Publication Date'] ? <div data-test='pub-date' className='date'>{props.data['Publication Date']}</div> : null}
          {link && <a role='presentation' className='is-clickable handicon padding-small-left' onClick={e => openFullText(e, link)} ><FormattedMessage id="Has full text" defaultMessage="Has full text" /></a>}
        </div>
        {props.data['Article Title']
          ? (
            <h3 data-test='article-title' className='card-title'>
              {ReactHtmlParser(props.data['Article Title'])}
            </h3>
          ) : null}
        {!props.showMore && props.data.Authors && props.data.Authors.length > 0 && props.data.Authors.map(author => author.author_name).join('').length > 0 && (
          <div className='card-text' >
            <ClickableAuthors {...props} limit={3} />
          </div>
        )}
        {props.showMore
          ? (
            <Fragment>
              {props.data.Authors && props.data.Authors.length > 0 && props.data.Authors.map(author => author.author_name).join('').length > 0 && (
                <div className='card-body-content' >
                  <div className='sub-title'><FormattedMessage id="Authors" defaultMessage="Authors" />:</div>
                  <ClickableAuthors {...props} />
                </div>)}
              {props.data.Tags && props.data.Tags.length > 0 && (
                <div className='card-body-content' >
                  <div className='sub-title'><FormattedMessage id="Ontology Tags" defaultMessage="Ontology Tags" />:</div>
                  <div className='tag-list-wrap'>{renderTags()}</div>
                </div>)}
            </Fragment>
          ) : null}
      </div>
      {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
      {/* {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={false} />} */}
    </Fragment>
  )
}

PublicationWileyList.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  flag: PropTypes.bool,
  showMore: PropTypes.bool,
  likeParams: PropTypes.object,
  takeFeedback: PropTypes.bool,
  overlay: PropTypes.bool,
  openCard: PropTypes.func,
  modalAction: PropTypes.func.isRequired,
}

PublicationWileyList.defaultProps = {
  flag: true,
  showMore: false,
  likeParams: {},
  takeFeedback: true,
  overlay: true,
  openCard: null
}

export default withRouter(connect(null, { modalAction })(PublicationWileyList))
