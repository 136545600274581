import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

const INITIAL_STATE_OBJECT = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

// CREATE BIBLIOGRAPHY FOLDERS
const CREATE_BIBLIOGRAPHY_FOLDER = 'CREATE_BIBLIOGRAPHY_FOLDER'
export const CREATE_BIBLIOGRAPHY_FOLDER_SUCCESS = 'CREATE_BIBLIOGRAPHY_FOLDER_SUCCESS'
const CREATE_BIBLIOGRAPHY_FOLDER_FAILURE = 'CREATE_BIBLIOGRAPHY_FOLDER_FAILURE'

// DELETE BIBLIOGRAPHY FOLDERS
const DELETE_BIBLIOGRAPHY_FOLDER = 'DELETE_BIBLIOGRAPHY_FOLDER'
export const DELETE_BIBLIOGRAPHY_FOLDER_SUCCESS = 'DELETE_BIBLIOGRAPHY_FOLDER_SUCCESS'
const DELETE_BIBLIOGRAPHY_FOLDER_FAILURE = 'DELETE_BIBLIOGRAPHY_FOLDER_FAILURE'

// BIBLIOGRAPHY FOLDERS FETCH START
const BIBLIOGRAPHY_FOLDERS = 'BIBLIOGRAPHY_FOLDERS'
export const BIBLIOGRAPHY_FOLDERS_SUCCESS = 'BIBLIOGRAPHY_FOLDERS_SUCCESS'
const BIBLIOGRAPHY_FOLDERS_FAILURE = 'BIBLIOGRAPHY_FOLDERS_FAILURE'

// CREATE BIBLIOGRAPHY
const CREATE_BIBLIOGRAPHY = 'CREATE_BIBLIOGRAPHY'
export const CREATE_BIBLIOGRAPHY_SUCCESS = 'CREATE_BIBLIOGRAPHY_SUCCESS'
const CREATE_BIBLIOGRAPHY_FAILURE = 'CREATE_BIBLIOGRAPHY_FAILURE'

// EDIT BIBLIOGRAPHY
const EDIT_BIBLIOGRAPHY = 'EDIT_BIBLIOGRAPHY'
export const EDIT_BIBLIOGRAPHY_SUCCESS = 'EDIT_BIBLIOGRAPHY_SUCCESS'
const EDIT_BIBLIOGRAPHY_FAILURE = 'EDIT_BIBLIOGRAPHY_FAILURE'

// EDIT BIBLIOGRAPHY
const DELETE_BIBLIOGRAPHY = 'DELETE_BIBLIOGRAPHY'
export const DELETE_BIBLIOGRAPHY_SUCCESS = 'DELETE_BIBLIOGRAPHY_SUCCESS'
const DELETE_BIBLIOGRAPHY_FAILURE = 'DELETE_BIBLIOGRAPHY_FAILURE'

export const createBibliographyFolderAction = payload => ({
  type: CREATE_BIBLIOGRAPHY_FOLDER,
  payload
})

export const createBibliographyFolderSuccess = payload => ({
  type: CREATE_BIBLIOGRAPHY_FOLDER_SUCCESS,
  payload
})

export const deleteBibliographyFolderAction = payload => ({
  type: DELETE_BIBLIOGRAPHY_FOLDER,
  payload
})

export const deleteBibliographyFolderSuccess = payload => ({
  type: DELETE_BIBLIOGRAPHY_FOLDER_SUCCESS,
  payload
})

export const bibliographyFolderFetchAction = payload => ({
  type: BIBLIOGRAPHY_FOLDERS,
  payload
})

export const bibliographyFolderFetchSuccess = payload => ({
  type: BIBLIOGRAPHY_FOLDERS_SUCCESS,
  payload
})

export const createBibliographyAction = payload => ({
  type: CREATE_BIBLIOGRAPHY,
  payload
})

export const createBibliographySuccess = payload => ({
  type: CREATE_BIBLIOGRAPHY_SUCCESS,
  payload
})

export const editBibliographyAction = payload => ({
  type: EDIT_BIBLIOGRAPHY,
  payload
})

export const editBibliographySuccess = payload => ({
  type: EDIT_BIBLIOGRAPHY_SUCCESS,
  payload
})

export const deleteBibliographyAction = payload => ({
  type: DELETE_BIBLIOGRAPHY,
  payload
})

export const deleteBibliographySuccess = payload => ({
  type: DELETE_BIBLIOGRAPHY_SUCCESS,
  payload
})

export const createBibliographyFolderEpic = action$ => action$
  .ofType(CREATE_BIBLIOGRAPHY_FOLDER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bibliography/createbibliographyfolder`, 'POST', true, action.payload))
    .map(response => createBibliographyFolderSuccess(response))
    .catch(error => Observable.of({
      type: CREATE_BIBLIOGRAPHY_FOLDER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const deleteBibliographyFolderEpic = action$ => action$
  .ofType(DELETE_BIBLIOGRAPHY_FOLDER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bibliography/delete?${action.payload}`, 'DELETE'))
    .map(response => deleteBibliographyFolderSuccess(response))
    .catch(error => Observable.of({
      type: DELETE_BIBLIOGRAPHY_FOLDER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const bibliographyFolderFetchEpic = action$ => action$
  .ofType(BIBLIOGRAPHY_FOLDERS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bibliography/getFolder/?${action.payload ? action.payload : ''}`, 'GET'))
    .map(response => bibliographyFolderFetchSuccess(response))
    .catch(error => Observable.of({
      type: BIBLIOGRAPHY_FOLDERS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const createBibliographyEpic = action$ => action$
  .ofType(CREATE_BIBLIOGRAPHY)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bibliography/createBibliography`, 'POST', true, action.payload))
    .map(response => createBibliographySuccess(response))
    .catch(error => Observable.of({
      type: CREATE_BIBLIOGRAPHY_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const editBibliographyEpic = action$ => action$
  .ofType(EDIT_BIBLIOGRAPHY)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bibliography/modifyBibliography`, 'POST', true, action.payload))
    .map(response => editBibliographySuccess(response))
    .catch(error => Observable.of({
      type: EDIT_BIBLIOGRAPHY_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const deleteBibliographyEpic = action$ => action$
  .ofType(DELETE_BIBLIOGRAPHY)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bibliography/modifyBibliography`, 'POST', true, action.payload))
    .map(response => deleteBibliographySuccess(response))
    .catch(error => Observable.of({
      type: DELETE_BIBLIOGRAPHY_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function createBibliographyFolderReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case CREATE_BIBLIOGRAPHY_FOLDER: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case CREATE_BIBLIOGRAPHY_FOLDER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case CREATE_BIBLIOGRAPHY_FOLDER_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function deleteBibliographyFolderReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case DELETE_BIBLIOGRAPHY_FOLDER: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_BIBLIOGRAPHY_FOLDER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_BIBLIOGRAPHY_FOLDER_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}


export function bibliographyFolderFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case BIBLIOGRAPHY_FOLDERS: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case BIBLIOGRAPHY_FOLDERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case BIBLIOGRAPHY_FOLDERS_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function createBibliographyReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case CREATE_BIBLIOGRAPHY: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case CREATE_BIBLIOGRAPHY_SUCCESS: {
      return {
        ...state,
        data: {
          id: action.payload.response.data.doc_id,
          status: true
        },
        loading: false,
        error: false,
        flag: true
      }
    }
    case CREATE_BIBLIOGRAPHY_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function editBibliographyReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case EDIT_BIBLIOGRAPHY: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case EDIT_BIBLIOGRAPHY_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case EDIT_BIBLIOGRAPHY_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function deleteBibliographyReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case DELETE_BIBLIOGRAPHY: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_BIBLIOGRAPHY_SUCCESS: {
      return {
        ...state,
        data: {
          id: action.payload.response.data.doc_id,
          status: false
        },
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_BIBLIOGRAPHY_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
