import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import { filter } from 'rxjs/operators';
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const LIST_V1 = 'LIST_V1'
export const LIST_V1_SUCCESS = 'LIST_V1_SUCCESS'
export const LIST_V1_FAILURE = 'LIST_V1_FAILURE'

export const LIST_V1_TOP = 'LIST_V1_TOP'
export const LIST_V1_TOP_SUCCESS = 'LIST_V1_TOP_SUCCESS'
export const LIST_V1_TOP_FAILURE = 'LIST_V1_TOP_FAILURE'

export const LIST_V1_BOTTOM = 'LIST_V1_BOTTOM'
export const LIST_V1_BOTTOM_SUCCESS = 'LIST_V1_BOTTOM_SUCCESS'
export const LIST_V1_BOTTOM_FAILURE = 'LIST_V1_BOTTOM_FAILURE'

// Delete this function once api has been changed in getbookmark
const tempMod = (data) => {
  if (data.length > 0 && data[0].date) {
    const list = []
    data.forEach((item) => {
      list.push(...item.data)
    })
    return list
  }
  return data
}

export const listV1FetchAction = (payload, url, from, listType, id, apiType) => ({
  type: LIST_V1,
  payload,
  url,
  from,
  listType,
  id,
  apiType
})

export const listV1FetchCancel = id => ({
  type: `${id}_cancel`,
  id
})

export const listV1TopFetchCancel = id => ({
  type: `${id}_top_cancel`,
  id
})

export const listV1BottomFetchCancel = id => ({
  type: `${id}_bottom_cancel`,
  id
})

export const listV1FetchSuccess = (payload, id) => ({
  type: LIST_V1_SUCCESS,
  payload,
  id
})

export const listV1BottomFetchAction = (payload, url, from, listType, id, apiType) => ({
  type: LIST_V1_BOTTOM,
  payload,
  url,
  from,
  listType,
  id,
  apiType
})

export const listV1BottomFetchSuccess = (payload, id) => ({
  type: LIST_V1_BOTTOM_SUCCESS,
  payload,
  id
})

export const listV1TopFetchAction = (payload, url, from, listType, id, apiType) => ({
  type: LIST_V1_TOP,
  payload,
  url,
  from,
  listType,
  id,
  apiType
})

export const listV1TopFetchSuccess = (payload, id) => ({
  type: LIST_V1_TOP_SUCCESS,
  payload,
  id
})

export const listV1FetchEpic = action$ => action$
  .ofType(LIST_V1)
  .mergeMap(action => staticAjax(apiCall(`${action.url}?${action.payload.params}`, action.apiType || 'POST', true, action.payload.data))
    .map(response => listV1FetchSuccess(response, action.id))
    .takeUntil(action$.pipe(filter(act => `${act.id}_cancel` === act.type && action.id === act.id)))
    .catch(error => Observable.of({
      type: LIST_V1_FAILURE,
      payload: error,
      id: action.id
    }, {
      type: ERROR,
      payload: error
    })))

export const listV1BottomFetchEpic = action$ => action$
  .ofType(LIST_V1_BOTTOM)
  .mergeMap(action => staticAjax(apiCall(`${action.url}?${action.payload.params}`, action.apiType || 'POST', true, action.payload.data))
    .map(response => listV1BottomFetchSuccess(response, action.id))
    .takeUntil(action$.pipe(filter(act => `${act.id}_bottom_cancel` === act.type && action.id === act.id)))
    .catch(error => Observable.of({
      type: LIST_V1_BOTTOM_FAILURE,
      payload: error,
      id: action.id
    }, {
      type: ERROR,
      payload: error
    })))

export const listV1TopFetchEpic = action$ => action$
  .ofType(LIST_V1_TOP)
  .mergeMap(action => staticAjax(apiCall(`${action.url}?${action.payload.params}`, action.apiType || 'POST', true, action.payload.data))
    .map(response => listV1TopFetchSuccess(response, action.id))
    .takeUntil(action$.pipe(filter(act => `${act.id}_top_cancel` === act.type && action.id === act.id)))
    .catch(error => Observable.of({
      type: LIST_V1_TOP_FAILURE,
      payload: error,
      id: action.id
    }, {
      type: ERROR,
      payload: error
    })))

export function listV1FetchReducer (state = {}, action = null) {
  switch (action.type) {
    case LIST_V1: {
      return {
        ...state,
        [action.id]: {
          data: [],
          loading: true,
          error: false,
          flag: false,
          total: 0,
          from: action.from,
          listType: action.listType
        }
      }
    }
    case LIST_V1_SUCCESS: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: Array.isArray(action.payload.response.data) ? tempMod(action.payload.response.data.map(item => ({ ...item, from: state.from }))) : tempMod(action.payload.response.data.documents.map(item => ({ ...item, type: 'drug-list-new', from: state.from }))),
          loading: false,
          error: false,
          flag: true,
          total: action.payload.response.totalcount || action.payload.response.total || action.payload.response.data.total,
        }
      }
    }
    case LIST_V1_FAILURE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: [],
          loading: false,
          error: true,
          flag: false,
          total: 0
        }
      }
    }
    default:
      return state
  }
}

export function listV1BottomFetchReducer (state = {}, action = null) {
  switch (action.type) {
    case LIST_V1_BOTTOM: {
      return {
        ...state,
        [action.id]: {
          data: [],
          loading: true,
          error: false,
          flag: false,
          total: 0,
          from: action.from,
          listType: action.listType
        }
      }
    }
    case LIST_V1_BOTTOM_SUCCESS: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: Array.isArray(action.payload.response.data) ? tempMod(action.payload.response.data.map(item => ({ ...item, from: state.from }))) : tempMod(action.payload.response.data.documents.map(item => ({ ...item, type: 'drug-list-new', from: state.from }))),
          loading: false,
          error: false,
          flag: true,
        }
      }
    }
    case LIST_V1_BOTTOM_FAILURE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: [],
          loading: false,
          error: true,
          flag: false,
        }
      }
    }
    default:
      return state
  }
}

export function listV1TopFetchReducer (state = {}, action = null) {
  switch (action.type) {
    case LIST_V1_TOP: {
      return {
        ...state,
        [action.id]: {
          data: [],
          loading: true,
          error: false,
          flag: false,
          total: 0,
          from: action.from,
          listType: action.listType
        }
      }
    }
    case LIST_V1_TOP_SUCCESS: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: tempMod(action.payload.response.data.map(item => ({ ...item, from: state.from }))),
          loading: false,
          error: false,
          flag: true,
        }
      }
    }
    case LIST_V1_TOP_FAILURE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: [],
          loading: false,
          error: true,
          flag: false,
        }
      }
    }
    default:
      return state
  }
}
