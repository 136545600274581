import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const DELETE_HISTORY = 'DELETE_HISTORY'
export const DELETE_HISTORY_SUCCESS = 'DELETE_HISTORY_SUCCESS'
export const DELETE_HISTORY_FAILURE = 'DELETE_HISTORY_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const deleteHistoryAction = payload => ({
  type: DELETE_HISTORY,
  payload
})

export const deleteHistorySuccess = payload => ({
  type: DELETE_HISTORY_SUCCESS,
  payload
})

export const deleteHistoryEpic = action$ => action$
  .ofType(DELETE_HISTORY)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/workspace/deletesearchhistory?${action.payload}`, 'DELETE'))
    .map(response => deleteHistorySuccess(response))
    .catch(error => Observable.of({
      type: DELETE_HISTORY_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function deleteHistoryReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case DELETE_HISTORY: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_HISTORY_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
