import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

const INITIAL_COUNT = {
  data: 0,
  loading: false,
  error: false,
  flag: false
}

export const NOTIFICATION = 'NOTIFICATION'
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_FAILURE = 'NOTIFICATION_FAILURE'

export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT'
export const NOTIFICATION_COUNT_SUCCESS = 'NOTIFICATION_COUNT_SUCCESS'
export const NOTIFICATION_COUNT_FAILURE = 'NOTIFICATION_COUNT_FAILURE'

// Notification action
export const notificationAction = payload => ({
  type: NOTIFICATION,
  payload
})

// Notification Success action
export const notificationSuccess = payload => ({
  type: NOTIFICATION_SUCCESS,
  payload
})

// Notification count action
export const notificationCountAction = payload => ({
  type: NOTIFICATION_COUNT,
  payload
})

// Notification count Success action
export const notificationCountSuccess = payload => ({
  type: NOTIFICATION_COUNT_SUCCESS,
  payload
})

// Notification epic
export const notificationEpic = action$ => action$
  .ofType(NOTIFICATION)
  .mergeMap(() => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/3d/users/notifications/`, 'GET'))
    .map(response => notificationSuccess(response))
    .catch(error => Observable.of({
      type: NOTIFICATION_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Notification count epic
export const notificationCountEpic = action$ => action$
  .ofType(NOTIFICATION_COUNT)
  .mergeMap(() => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/3d/users/notifications/count/`, 'GET'))
    .map(response => notificationCountSuccess(response))
    .catch(error => Observable.of({
      type: NOTIFICATION_COUNT_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Notification Reducer
export function notificationReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case NOTIFICATION: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case NOTIFICATION_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// Notification Reducer
export function notificationCountReducer (state = INITIAL_COUNT, action = null) {
  switch (action.type) {
    case NOTIFICATION_COUNT: {
      return {
        ...state,
        data: 0,
        loading: true,
        error: false,
        flag: false
      }
    }
    case NOTIFICATION_COUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.notification_count,
        loading: false,
        error: false,
        flag: true
      }
    }
    case NOTIFICATION_COUNT_FAILURE: {
      return {
        ...state,
        data: 0,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
