import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ListIcons, AssetTag, AnnotationIcon } from '../'
import { checkPermission } from '../../utils';


const SocialMedia = props => (
  <Fragment>
    { props.flag &&
      <div className='card-actions'>
        <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Published' />
        {!checkPermission('ontosight_academics_user') && <AnnotationIcon data={props.data} />}
      </div>
    }
    <div className='card-details'>
      <div className='card-meta'>

        <AssetTag asset={props.data.type} />
        <div className='card-meta-title text-elips'>{props.data['Data Source']}</div>
        <div className='date'>{props.data.Date}</div>
      </div>
      <h3 className='card-title'>
        {ReactHtmlParser(props.data.Title)}
      </h3>
    </div>
    {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
  </Fragment>
)

SocialMedia.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  likeParams: PropTypes.object,
  takeFeedback: PropTypes.bool,
  overlay: PropTypes.bool,
}

SocialMedia.defaultProps = {
  flag: true,
  likeParams: {},
  takeFeedback: true,
  overlay: true
}

export default SocialMedia
