import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

const INITIAL_ABSTRACT_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}
export const ABSTRACT = 'ABSTRACT'
export const ABSTRACT_SUCCESS = 'ABSTRACT_SUCCESS'
export const ABSTRACT_FAILURE = 'ABSTRACT_FAILURE'

export const ABSTRACT_CLEAR = 'ABSTRACT_CLEAR'

// Fetch Abstract action
export const fetchAbstractClearAction = payload => ({
  type: ABSTRACT_CLEAR,
  payload
})

// Fetch Abstract action
export const fetchAbstractAction = payload => ({
  type: ABSTRACT,
  payload
})

// Fetch Abstract Success action
export const fetchAbstractSuccess = payload => ({
  type: ABSTRACT_SUCCESS,
  payload
})

// Fetch Abstract epic
export const fetchAbstractEpic = action$ => action$
  .ofType(ABSTRACT)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/details/summary/?${action.payload}`, 'GET'))
    .map(response => fetchAbstractSuccess(response))
    .catch(error => Observable.of({
      type: ABSTRACT_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// fetch Abstract Reducer
export function fetchAbstractReducer (state = INITIAL_ABSTRACT_STATE, action = null) {
  switch (action.type) {
    case ABSTRACT_CLEAR: {
      return {
        ...state,
        data: {},
        loading: false,
        error: false,
        flag: false
      }
    }
    case ABSTRACT: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case ABSTRACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case ABSTRACT_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
