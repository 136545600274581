import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage } from 'react-intl';

const Grants = (props) => {
  const abstract = props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
  return (
    <Fragment>
      {props.data.Abstract && (
      <div className='card-body-content'>
        <div className='sub-title' ><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
        <div data-test='grants-abstarct' id='annotation-highlight-1'>{ReactHtmlParser(abstract)}</div>
      </div>
      )}

      {props.data['Grant ID'] && (
      <div className='card-body-content'>
        <div className='sub-title'><FormattedMessage id="Grant ID" defaultMessage="Grant ID" /></div>
        <div data-test='grants-id' className='sub-text'>{props.data['Grant ID']} </div>
      </div>
      )}

      {props.data['Data Source'] && (
      <div className='card-body-content'>
        <div className='sub-title'><FormattedMessage id="Source" defaultMessage="Source" /></div>
        <div data-test='grants-source' className='sub-text'>{props.data['Data Source']}</div>
      </div>
      )}
    </Fragment>
  )
}

Grants.propTypes = {
  data: PropTypes.object.isRequired
}

export default Grants
