import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { ClickOutside } from 'reusable-react-components';
import PropTypes from 'prop-types'

class NestedCheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    const { All, ...filterdObj } = this.props.options;
    const countries = this.getNewCountries(this.props.active, filterdObj);
    this.state = {
      search: '',
      filterdObj,
      toggleHide: [],
      countries,
      open: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { All, ...filterdObj } = nextProps.options;
    if (JSON.stringify(nextProps.options) !== JSON.stringify(this.props.options) && nextProps.options) {
      this.setState({
        filterdObj
      })
    }
    if (JSON.stringify(this.props.active.sort()) !== JSON.stringify(nextProps.active.sort())) {
      const newCountries = this.getNewCountries(nextProps.active, filterdObj)
      this.setState({
        countries: newCountries,
      })
    }
  }

  getNewCountries = (active, options) => {
    const newCountries = {

    };
    const keysOfOptions = Object.keys(options);
    active.forEach((country) => {
      Object.values(options).forEach((countriesList, index) => {
        if (countriesList.includes(country)) {
          if (newCountries[keysOfOptions[index]]) {
            newCountries[keysOfOptions[index]].push(country);
          } else {
            newCountries[keysOfOptions[index]] = [country]
          }
        }
      })
    })
    return newCountries;
  }

  getSelectedContinents = () => {
    const selected = Object.entries(this.state.filterdObj).map((el) => {
      let selectedContinent = false;
      el[1].forEach((country) => {
        if (this.props.active.includes(country)) {
          selectedContinent = true
        }
      })
      if (selectedContinent) { return el[0]; }
      return 0;
    })
    return selected.filter(el => el);
  }
  onSearch = (event) => {
    event.preventDefault();
    const search = event.target.value;
    const newFilterContinentList = {};
    Object.keys(this.props.options).forEach((continent) => {
      let countryList = JSON.parse(JSON.stringify(this.props.options[continent]))
      countryList = countryList.filter((country) => {
        if (country === 'Europe') {
          return 'Entire Europe'.toLowerCase().includes(search.toLowerCase())
        }
        return (country.toLowerCase().includes(search.toLowerCase()))
      });
      if (countryList.length > 0) {
        newFilterContinentList[continent] = countryList;
      }
    });
    const { All, ...filterdObj } = newFilterContinentList;
    this.setState(() => ({
      search,
      filterdObj
    }))
  };
  clearSearch = () => {
    const { All, ...filterdObj } = this.props.options;
    this.setState(() => ({
      search: '',
      filterdObj
    }));
  };

  toggleHide = (continent) => {
    const toggleArray = this.state.toggleHide;
    if (this.state.toggleHide.indexOf(continent) > -1) {
      toggleArray.splice(this.state.toggleHide.indexOf(continent), 1);
    } else {
      toggleArray.push(continent);
    }
    this.setState(() => ({
      toggleHide: toggleArray
    }));
  };

  isInderminate = (continent) => {
    const elem = document.getElementById(`${this.props.id}-${continent}`);
    if (this.state.countries[continent] && elem) {
      elem.indeterminate =
      !!(this.state.countries[continent].length &&
        this.props.options[continent].length !==
        this.state.countries[continent].length);
    } else if (elem) {
      elem.indeterminate = this.props.options[continent].length === 1 && this.state.filterdObj[continent] && this.state.filterdObj[continent].length !== 1;
    }
  };

  onCountrySelect = (e, continent, country) => {
    if (e.target.checked) {
      this.setState((prevState) => {
        if (prevState.countries[continent]) {
          const newCountries = { ...prevState.countries }
          newCountries[continent] = [...prevState.countries[continent], country];
          return { countries: newCountries };
        }
        const obj = {
          countries: prevState.countries,
        };
        obj.countries[continent] = [];
        obj.countries[continent].push(country);
        return obj;
      }, () => this.isInderminate(continent))
    } else {
      this.setState((prevState) => {
        const prevSta = [...prevState.countries[continent]];
        prevSta.splice(prevSta.indexOf(country), 1);
        const newCountries = { ...prevState.countries }
        newCountries[continent] = prevSta;
        return { countries: newCountries };
      }, () => this.isInderminate(continent))
    }
  };
  onContinentSelect = (e, continent) => {
    const contryList = this.props.options[continent];
    const newCountries = { ...this.state.countries }
    newCountries[continent] = e.target.checked ? contryList : [];
    this.setState({
      countries: newCountries
    })
  };


  outsideClick = () => {
    let activeElements = [];
    Object.values(this.state.countries).forEach((el) => {
      activeElements = activeElements.concat(el);
    });
    let options = [];
    Object.values(this.props.options).forEach((el) => {
      options = options.concat(el);
    });
    if (activeElements.length >= this.props.minSelect && (this.props.maxSelect >= activeElements.length || this.props.maxSelect === -1)) {
      const { open } = this.state;
      this.setState({
        open: false,
        error: false
      }, () => {
        if (open && JSON.stringify(this.props.active) !== JSON.stringify(activeElements.sort())) {
          if (this.props.onSubmit) {
            this.props.onSubmit(activeElements, this.state.type)
          }
        }
      })
    } else {
      let n = ''
      if (this.props.minSelect > options.length) {
        this.setState({
          open: false,
          error: false
        })
        n = 'lessOptions'
      } else if (!(this.props.maxSelect >= activeElements.length || this.props.maxSelect === -1)) {
        n = 'maxSelect'
      } else if (activeElements.length < this.props.minSelect) {
        n = 'minSelect'
      }
      if (this.state.open && this.props.notify) {
        this.setState({
          error: true,
          open: false,
        }, () => this.props.notify(n))
      }
    }
  };


  renderContinent = (continent, countryList) => {
    if (continent === 'All') {
      return null
    }
    if (countryList.includes('Europe')) {
      countryList.splice(countryList.indexOf('Europe'), 1);
      countryList.push('Europe');
    }

    const countries = countryList.map((country, index) => (
      <div className="padding-lg-left">
        <li key={index} className="reusable-checkbox-item">
          <input
            type="checkbox"
            key={`${this.props.id}-${country}-${index}`}
            id={`${this.props.id}-${country}-${index}`}
            checked={this.state.countries[continent] && this.state.countries[continent].includes(country)}
            onChange={e => this.onCountrySelect(e, continent, country)}
          />
          <label htmlFor={`${this.props.id}-${country}-${index}`} className="label">
            {country === 'Europe' ? <span>Entire Europe</span> : <span>{country}</span>}
          </label>
        </li>
      </div>
    ));

    return (
      <div>
        <ul>
          <li
            role="presentation"
            key={continent}
            className="reusable-checkbox-item"
            onClick={() => this.toggleHide(continent)}
          >
            <input
              type="checkbox"
              key={continent}
              id={`${this.props.id}-${continent}`}
              checked={this.state.countries[continent] && this.state.countries[continent].length}
              onChange={e => this.onContinentSelect(e, continent)}
            />
            <label htmlFor={`${this.props.id}-${continent}`} className="label label-continent">
              <span>{continent}</span>
            </label>
            {!this.props.hideBuckets && continent !== 'International' && <span role="presentation" className="arrow-aligment">{this.state.toggleHide.indexOf(continent) > -1 ? <span className="checkbox-up-arrow" /> : <span className="checkbox-down-arrow" />}</span>}
          </li>
          {
            !this.props.hideBuckets && continent !== 'International' && this.state.toggleHide.indexOf(continent) <= -1 && <div className="countries-bg">{countries}</div>
          }
        </ul>
      </div>
    );
  };

  renderBox () {
    let showingList = JSON.parse(JSON.stringify(this.props.active));
    if (showingList.includes('Europe')) {
      showingList.splice(showingList.indexOf('Europe'), 1, 'Entire Europe')
    }
    if (this.props.hideBuckets) {
      showingList = this.getSelectedContinents();
    }
    const { intl } = this.props
    return (
      <button title={this.props.hoverTitle ? intl.formatMessage({ id: this.props.hoverTitle || 'abc', defaultMessage: this.props.hoverTitle }) : ''} onClick={() => this.setState({ open: true })} className={`msd-box ${this.state.error ? 'msd-error' : ''}`} disabled={this.props.disabled}>
        {showingList.length === 0 ? (
          <span>
            {this.props.title}
          </span>
        ) : (
          <div title={`${showingList[0]}${showingList.length > 1 ? ` +${showingList.length - 1}` : ''}`} className='disp-flex' >
            <div className='text-elips' >{showingList[0]}</div>
            <div>
              {showingList.length > 1 && ` +${showingList.length - 1}`}
            </div>
          </div>
        )}
      </button>
    )
  }

  updateIndeterminate = () => {
    window.requestAnimationFrame(() => {
      Object.keys(this.state.filterdObj).forEach(el => this.isInderminate(el));
    })
  }
  render() {
    this.updateIndeterminate();
    const height = 200;
    return (
      <Fragment>
        {this.props.label && <div style={{ height: 25 }} className={`filter-blk-label vcenter ${this.state.error ? 'msd-error' : ''}`} ><div>{this.props.label}</div>{this.props.minSelect > 0 && <div className='required-red'>*</div>}</div>}
        <ClickOutside onClickOutside={e => this.outsideClick(e)}>
          {this.state.open ?
            <Fragment>
              {this.props.search ? (
                <div className='msd-search-container'>
                  <input type="text" onClick={() => this.setState({ open: true })} className="msd-search" value={this.state.search} autoFocus placeholder="Search..." onChange={this.onSearch} />
                  <div role='presentation' className='msd-search-icon' />
                </div>
              ) : (
                <Fragment>
                  {this.renderBox()}
                </Fragment>
              )
              }
              {this.state.open &&
                <div className="msd">
                  <div style={{ height }} className='msd-container' >
                    <div className='msd-search-content scrollbar' >
                      <ul className='reusable-checkbox scrollbar'>
                        {
                      [].concat(Object.values(this.state.filterdObj)).length ?
                      Object.keys(this.state.filterdObj)
                      .map((continent, index) => (
                        <div key={`continent-${index}`}>
                          {this.renderContinent(continent, this.state.filterdObj[continent])}
                        </div>)) : <div className="msd-search-content center">No Search Found</div>
                    }
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </Fragment> : this.renderBox()}
        </ClickOutside>
      </Fragment>
    )
  }
}


NestedCheckboxGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  minSelect: PropTypes.number,
  maxSelect: PropTypes.number,
  active: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  notify: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  hideBuckets: PropTypes.bool,
  search: PropTypes.bool,
  hoverTitle: PropTypes.string,
  intl: PropTypes.object.isRequired,
}

NestedCheckboxGroup.defaultProps = {
  label: '',
  minSelect: 0,
  maxSelect: -1,
  onSubmit: null,
  notify: null,
  type: 'All',
  title: 'Select',
  disabled: false,
  hideBuckets: false,
  search: true,
  hoverTitle: ''
}
export default injectIntl(NestedCheckboxGroup);

