import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage } from 'react-intl';

const SocialMedia = (props) => {
  if (!props.data.Abstract) {
    return null;
  }
  const abstract = props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
  return (
    <div className='card-body-content'>
      <div className='sub-title'><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
      <div id='annotation-highlight-1'>{ReactHtmlParser(abstract)}</div>
    </div>
  )
}

SocialMedia.propTypes = {
  data: PropTypes.object.isRequired
}

export default SocialMedia
