import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const TRENDS = 'TRENDS'
export const TRENDS_SUCCESS = 'TRENDS_SUCCESS'
export const TRENDS_FAILURE = 'TRENDS_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const trendsAction = payload => ({
  type: TRENDS,
  payload
})

export const trendsSuccess = payload => ({
  type: TRENDS_SUCCESS,
  payload
})

export const trendsEpic = action$ => action$
  .ofType(TRENDS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/trends?${action.payload.params}`, 'POST', true, action.payload.data))
    .map(response => trendsSuccess(response))
    .catch(error => Observable.of({
      type: TRENDS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// trends config reducer
export function trendsReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case TRENDS: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case TRENDS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data.map(item => ({ year: item.key_as_string, value: item.doc_count })),
        loading: false,
        error: false,
        flag: true
      }
    }
    case TRENDS_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
