import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import ToolTip from 'react-portal-tooltip'

class SearchCrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropDownOpen: false
    }
    this.updateFilter = this.updateFilter.bind(this);
  }
  updateFilter(filter) {
    this.setState({
      isDropDownOpen: false
    }, () => (this.props.item.filter !== filter)
    && this.props.updateSearchCrumbFilter(this.props.listId, filter))
  }
  renderDropDown() {
    return (
      <div className='filter'>
        <div
          className='filter-selected'
          role='presentation'
          onClick={() => this.setState({ isDropDownOpen: true })}
        >
          {this.props.item.filter}
          <div className='down-arrow' />
        </div>
        {
          this.state.isDropDownOpen &&
          <div className='filter-dropdown'>
            <div className="filter-item" role='presentation' onClick={() => this.updateFilter('and')}>and</div>
            <div className="filter-item" role='presentation' onClick={() => this.updateFilter('not')}>not</div>
          </div>
        }
      </div>
    )
  }
  render() {
    return (
      <div className={`search-crumb ${this.props.item.filter}`}>
        { this.props.listId !== 0 && this.renderDropDown() }
        <div className='search-term'>
          <div className='search-term-value' >{this.props.item.display}</div>
          <button className='search-term-delete' onClick={() => this.props.removeSearchCrumb(this.props.listId)}>
            <div className='delete-button' />
          </button>
        </div>
      </div>
    )
  }
}

SearchCrumb.propTypes = {
  removeSearchCrumb: PropTypes.func.isRequired,
  updateSearchCrumbFilter: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  listId: PropTypes.number.isRequired
}

export default SearchCrumb
