import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { feature } from 'topojson-client'
import ToolTip from 'react-portal-tooltip'
import * as d3 from 'd3'
import * as worldData from './combined2.json'
import { style } from '../../utils/index'
import { OutsideClick, CloseOnEscape } from '../'

class WorldMap extends Component {
  constructor() {
    super()
    this.state = {
      worldData: [],
      isTooltipActive: false,
      id: '',
      countryName: '',
      name: ''
    }
    this.width = 1000
    this.height = 350
    this.hideTooltip = this.hideTooltip.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }
  componentWillMount () {
    this.tmpjson = []
    this.domain = []
    this.colorPalette = ['#fff3cc', '#ffe17f', '#ffc400', '#E9F8DA', '#c8f19f', '#92e33f', '#e5ddfd', '#beaaf9', '#7d56f4'];
    Object.keys(this.props.data).forEach((d) => {
      if (d !== 'No Country') {
        this.tmpjson.push(this.props.data[d])
      }
    })
    if (this.tmpjson.length === 1) {
      this.tmpjson.unshift(0);
    }
    this.tmpjson.sort((a, b) => (d3.ascending(a, b)))
    // Find the min/max counts
    this.min = Math.min(...this.tmpjson);
    this.max = Math.max(...this.tmpjson);
    if (this.tmpjson.length) {
      const len = this.colorPalette.length
      for (let i = 0; i < len; i += 1) {
        this.domain.push(Math.round(this.max / ((len - 1) * i)));
      }
    }
    if (this.tmpjson.length) {
      const tmpjsonSet = new Set(this.tmpjson)
      let domainArr = [...tmpjsonSet].sort((a, b) => a - b)
      const max = 100
      if (domainArr.length < this.colorPalette.length) {
        const maxpresent = Math.max(max, d3.max(domainArr))
        domainArr = Array.from({ length: 9 }, (v, i) => (
          Math.round((i + 1) * (maxpresent / this.colorPalette.length))
        ))
      }
      const lengthBucket = domainArr.length;
      const percentile = (Math.ceil(100 / this.colorPalette.length)) / 100;
      this.domain = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < this.colorPalette.length; i += 1) {
        const index = parseInt(Math.floor((percentile * (i + 1)) * lengthBucket), 10) - 1
        this.domain[i] = domainArr[index] !== undefined ? domainArr[index] + 1 : domainArr[domainArr.length - 1] + 1
      }
    }
    this.props.setDomain(this.domain)
    this.color = d3.scaleThreshold()
      .domain(this.domain)
      .range(this.colorPalette)
  }
  componentDidMount() {
    this.setState({
      worldData: feature(worldData, worldData.objects.countries).features
    })
  }
  setName (value) {
    let country = ''
    Object.keys(this.props.data).forEach((item) => {
      if (item.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        country = item
      }
    })
    this.setState({
      name: country
    })
  }
  projection = () => (
    d3.geoMercator()
      .scale(95)
      .translate([this.width / 2, 550 / 2])
  )
  calculate = (country) => {
    let color = '#ffffff'
    Object.keys(this.props.data).forEach((item) => {
      if (item.toLowerCase().indexOf(country.properties.name.toLowerCase()) !== -1) {
        color = this.color(this.props.data[item]) || '#ffffff'
      }
    })
    return color;
  }
  showTooltip = (e, id, countryName) => {
    Object.keys(this.props.data).forEach((item) => {
      if (item.toLowerCase().indexOf(countryName.toLowerCase()) !== -1) {
        const data = this.props.data[item] || 0
        if (data) {
          this.setState({
            isTooltipActive: true,
            id,
            countryName: item
          })
        }
      }
    })
  }
  hideTooltip = () => {
    this.setState({
      isTooltipActive: false
    })
  }
  renderTooltipContent = () => {
    if (this.props.worldData[this.state.countryName]) {
      return this.props.worldData[this.state.countryName].map((d, i) => (
        <div
          key={i}
          onClick={() => this.props.clicked(d)}
          onKeyPress={() => this.props.clicked(d)}
          role='button'
          tabIndex={0}
          className='item hand'
        >
          { d.name }
        </div>
      ))
    }
    return null
  }
  render() {
    const a = this.props.data[this.state.name] || 0
    console.log(a, this.state.name)
    return (
      <svg width={this.width} height={this.height} viewBox='0 0 800 400'>
        <g className='countries'>
          {
            this.state.worldData.map((d, i) => (
              <path
                key={`path-${i}`}
                id={`path-${i}`}
                d={d3.geoPath().projection(this.projection())(d)}
                className={a ? 'country hand' : 'country'}
                fill={this.calculate(d)}
                stroke='black'
                strokeWidth={0.5}
                onMouseEnter={() => this.setName(d.properties.name)}
                onClick={e => this.showTooltip(e, `path-${i}`, d.properties.name)}
              />
            ))
          }
        </g>
        <CloseOnEscape onEscape={this.hideTooltip}>
          <OutsideClick onClickOutside={() => this.hideTooltip()} >
            <ToolTip tooltipTimeout={500} style={style} active={this.state.isTooltipActive} position='right' parent={`#${this.state.id}`}>
              <div className='world-map-tooltip'>
                <div className='title'>
                  { this.state.countryName } ({this.props.worldData[this.state.countryName] ? this.props.worldData[this.state.countryName].length : 0})
                </div>
                <div className='list scrollbar'>
                  { this.renderTooltipContent() }
                </div>
              </div>
            </ToolTip>
          </OutsideClick>
        </CloseOnEscape>
      </svg>
    )
  }
}

WorldMap.propTypes = {
  data: PropTypes.object.isRequired,
  worldData: PropTypes.object.isRequired,
  clicked: PropTypes.func,
  setDomain: PropTypes.func.isRequired
}

WorldMap.defaultProps = {
  clicked: () => null
}

export default WorldMap
