import React, { Component } from 'react'
import PropTypes from 'prop-types'

const LoaderComponent = () => (
  <div className='reusable-loader relative' >
    <div className='spinner'>
      <div className='bounce1' />
      <div className='bounce2' />
      <div className='bounce3' />
    </div>
  </div>
)

class LazyLoadOnDivList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loadTop: true,
      loadBottom: true
    }
    this.onScroll = this.onScroll.bind(this)
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.loadDataTop !== this.props.loadDataTop && nextProps.loadDataTop) {
      this.setState({
        loadTop: true
      })
    }
    if (nextProps.loadDataBottom !== this.props.loadDataBottom && nextProps.loadDataBottom) {
      this.setState({
        loadBottom: true
      })
    }
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll () {
    const { scrollHeight, scrollTop, clientHeight } = document.getElementById(this.props.id)
    if (scrollHeight - scrollTop - 50 <= clientHeight && this.props.loadBottom) {
      if (this.state.loadBottom) {
        this.props.loadBottomRows()
      }
      this.setState({
        loadBottom: false
      })
    }
    if (scrollTop === 0 && this.props.loadTop) {
      if (this.state.loadTop) {
        this.props.loadTopRows()
      }
      this.setState({
        loadTop: false
      })
    }
  }

  render () {
    return (
      <div className='scrollbar' id={this.props.id} onScroll={this.onScroll} style={{ height: this.props.height, overflowY: 'auto' }} >
        {!this.state.loadTop ? this.props.loader : null}
        {this.props.children}
        {!this.state.loadBottom ? this.props.loader : null}
      </div>
    )
  }
}

LazyLoadOnDivList.propTypes = {
  id: PropTypes.string.isRequired,
  loadBottomRows: PropTypes.func.isRequired,
  loadTopRows: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  loader: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loadTop: PropTypes.bool,
  loadBottom: PropTypes.bool,
  loadDataTop: PropTypes.bool,
  loadDataBottom: PropTypes.bool
}

LazyLoadOnDivList.defaultProps = {
  children: null,
  footerHeight: 0,
  loader: <LoaderComponent />,
  height: 300,
  loadTop: false,
  loadBottom: false,
  loadDataTop: false,
  loadDataBottom: false
}

export default LazyLoadOnDivList
