import { getRedirectUrl, checkPermission } from '../../common/utils'

const url = process.env.dashboard ? process.env.dashboardUrl : getRedirectUrl('app', {}, {}, false)
const urlWorkspace = getRedirectUrl('workspace', {}, {}, false)

export const Apps = [
  {
    name: 'Discover',
    link: url,
    active: !process.env.dashboard,
    permission: true
  },
  {
    name: 'Influence',
    link: 'http://192.168.8.180:5002/#!/intro?alreadyLoggedIn=true',
    active: false,
    permission: true
  },
  {
    name: 'Xplore',
    link: '',
    active: false,
    permission: true
  },
  {
    name: 'Integrate',
    link: '',
    active: false,
    permission: true
  },
  {
    name: 'Dashboards',
    link: `${url}boards`,
    active: process.env.dashboard,
    permission: 'dashboard_permission'
  }
]

const rest = ['workspace']

export const DiscoverLinks = (/* pathname */) => (process.env.dashboard ?
  [
    {
      name: 'My Workspace',
      link: `${urlWorkspace}?fromDashboard=true`,
      active: rest.indexOf(process.env.current) !== -1,
      permission: checkPermission('myworkspace_permission') && checkPermission('competitive_landscape_jnj'),
      // permission: (checkPermission('myworkspace_permission') || (getCookie('trialUser'))),
      // academic: getCookie('trialUser') || checkPermission('ontosight_academics_user'),
      academic: false,
      id: 'Header.myWorkspace'
    }
    // {
    //   name: 'Medical Affairs',
    //   link: `${url}medical`,
    //   active: false,
    //   permission: checkPermission('loadMedicalDashboard'),
    //   academic: '',
    //   id: 'ma-dash'
    // },
    // {
    //   name: 'Regulatory Updates',
    //   link: `${url}regulatory`,
    //   active: false,
    //   permission: checkPermission('loadRegulatoryDashboard'),
    //   academic: '',
    //   id: 'ru-dash'
    // },
    // {
    //   name: 'Sentiment Watch',
    //   link: `${url}sentiment?alreadyLoggedIn=true`,
    //   active: false,
    //   permission: checkPermission('loadSentimentDashboard'),
    //   academic: '',
    //   id: 'sw-dash'
    // },
    // {
    //   name: 'Guideline Watch',
    //   link: `${url}guidelinewatch`,
    //   active: false,
    //   permission: checkPermission('loadGuidelinesWatchDashboard'),
    //   academic: '',
    //   id: 'gw-dash'
    // },
    // {
    //   name: 'My Dashboard',
    //   link: `${url}custom`,
    //   active: false,
    //   permission: checkPermission('loadCustomDashboard'),
    //   academic: '',
    //   id: 'md-dash'
    // },
    // {
    //   name: 'Competitive Landscape',
    //   link: `${url}competitive`,
    //   active: pathname.includes('competitive'),
    //   permission: checkPermission('load_competitive_landscape'),
    //   academic: '',
    //   id: 'cl-dash'
    // }
  ] : [
    // {
    //   name: 'Search',
    //   link: `${url}search`,
    //   active: rest.indexOf(process.env.current) === -1,
    //   permission: checkPermission('discover_permission'),
    //   academic: '',
    //   id: 'Header.search'
    // },
    {
      name: 'My Workspace',
      link: `${urlWorkspace}`,
      active: rest.indexOf(process.env.current) !== -1,
      permission: checkPermission('myworkspace_permission'),
      // permission: (checkPermission('myworkspace_permission') || (getCookie('trialUser'))),
      // academic: getCookie('trialUser') || checkPermission('ontosight_academics_user'),
      academic: false,
      id: 'Header.myWorkspace'
    },
    // {
    //   name: 'Marketplace',
    //   link: '/marketplace',
    //   active: false,
    //   permission: checkPermission('marketplace_permission'),
    //   academic: checkPermission('ontosight_academics_user'),
    //   id: 'Header.marketplace'
    // }
  ])
