import React from 'react'
import { FormattedMessage } from 'react-intl';
import { checkPermission } from '../../utils';
import { academicPermissionText, getPermissionText, permissionsText } from './constant'

const Premium = () => (
  <div className='modal-premium'>
    <div className="modal-header">
      <h3 className="modal-title text-center"> <span className="premium-icon" /><FormattedMessage id="You discovered a Premium Feature" defaultMessage="You discovered a Premium Feature" /></h3>
    </div>
    <div className="modal-body">
      <div className="txt">
        <p className="text-center">You are trying to access a feature that is not included in your Ontosight Discover <sup>®</sup> subscription</p>
        <p><FormattedMessage id="Accelerate your research and increase your efficiency with additional features" defaultMessage="Accelerate your research and increase your efficiency with additional features" />:</p>
        <ul className="feature-list scrollbar">
          {getPermissionText().map((item, i) => (
            <li key={i} >
              <span className="tick-premium" />
              {permissionsText[item]}
            </li>
          ))}
          {checkPermission('ontosight_academics_user') && academicPermissionText.map((item, i) => (
            <li key={i} >
              <span className="tick-premium" />
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="btn-wrap text-center">
        <a className="btn btn-primary primary btn-rounded" href="https://www.innoplexus.com/index.php/buy-now/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" /> </a>
      </div>
    </div>
  </div>
)

export default Premium
