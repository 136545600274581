import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { loginAction } from './logic'
import { removeCookies, getCookie } from '../../utils'

class Login extends Component {
  constructor (props) {
    super(props)
    if (sso) {
      sso.config({
        base_url: process.env.sso.baseUrl,
        client_id: process.env.sso.clientId,
        window_type: 'iframe',
        response_type: 'code',
        divId: 'ssoLogin',
        lang: this.props.lang
      })
    }
  }
  componentDidMount () {
    const { alreadyLoggedIn, hashCode, code } = parse(this.props.location.search)
    if (sso) {
      if (hashCode) {
        const { email } = parse(this.props.location.search)
        const dim = {
          window_type: 'iframe',
          showImage: false,
          scope: process.env.sso.scope,
          notCancellable: false,
          response_type: 'code',
          divId: 'ssoLogin',
          hashCode,
          emailId: email || null,
          removeSession: true,
          lang: this.props.lang
        }
        if (parse(this.props.location.search).request_type === 'reset_password') {
          sso.resetPassword(dim)
        } else {
          sso.registerUser(dim, (status) => {
            removeCookies(true)
            localStorage.clear()
            if (status.callback_url.split('code=')[1]) {
              this.props.loginAction({
                params: stringify({
                  code: status.callback_url.split('code=')[1],
                  app: this.props.app
                })
              })
            }
          })
        }
      } else if (alreadyLoggedIn === 'true') {
        removeCookies(true)
        sso.login({ scope: process.env.sso.scope, nosession: true, lang: this.props.lang }, (status) => {
          this.props.loginAction({
            params: stringify({
              code: status.code,
              app: this.props.app
            })
          })
        })
      } else if (code) {
        this.props.loginAction({
          params: stringify({
            code,
            app: this.props.app
          })
        })
      } else if (getCookie('accessToken')) {
        this.props.history.replace(`${process.env.redirectRoute}?${stringify({ ...parse(this.props.location.search) })}`)
      } else {
        const dimen = {
          window_type: 'iframe',
          showImage: false,
          scope: process.env.sso.scope,
          notCancellable: false,
          response_type: 'code',
          divId: 'ssoLogin',
          lang: this.props.lang
        }
        sso.login(dimen, (status) => {
          this.props.loginAction({
            params: stringify({
              code: status.code,
              app: this.props.app
            })
          })
        })
      }
    }
  }
  render () {
    const styles = {
      width: '100%',
      height: this.props.height,
      ...this.props.style
    }
    return (
      <div id='ssoLogin' style={styles} />
    )
  }
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loginAction: PropTypes.func.isRequired,
  app: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lang: PropTypes.string,
  style: PropTypes.object,
}
Login.defaultProps = {
  app: 'facelift',
  height: 'calc(100vh - 60px)',
  lang: 'en',
  style: {}
}

const mapStateToProps = state => ({
  auth: state.auth,
  lang: state.language.lang,
})

export default withRouter(connect(mapStateToProps, { loginAction })(Login))
