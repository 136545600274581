import React from 'react'
import PropTypes from 'prop-types'

/* Below function pass legend category to parent Legends component */
const onClick = (props) => {
  if (props.clickable) {
    props.onClick(props.category)
  }
}

const LegendItem = props => (
  <div style={{ opacity: (props.disabledLegends && props.disabledLegends.indexOf(props.category) !== -1) || props.value === 0 ? '0.4' : '1' }} role='presentation' onClick={() => onClick(props)} className={`legends-item ${props.className}`}>
    <div style={{ backgroundColor: props.color, borderRadius: props.borderRadius ? props.borderRadius : '10px' }} className='legend-bullet' />
    <span className='legend-text'> {props.category}{props.value[props.category]} </span>
  </div>
)

LegendItem.propTypes = {
  color: PropTypes.string,
  borderRadius: PropTypes.number,
  value: PropTypes.number,
  category: PropTypes.string,
  className: PropTypes.string,
  disabledLegends: PropTypes.array
}

LegendItem.defaultProps = {
  color: '',
  borderRadius: 10,
  category: '',
  className: '',
  disabledLegends: [],
  value: 1
}

export default LegendItem
