import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false,
  total: 0
}
const RAWDATA_COUNT = 'RAWDATA_COUNT'
const RAWDATA_COUNT_SUCCESS = 'RAWDATA_COUNT_SUCCESS'
const RAWDATA_COUNT_FAILURE = 'RAWDATA_COUNT_FAILURE'

const RAWDATA_LIST = 'RAWDATA_LIST'
const RAWDATA_LIST_SUCCESS = 'RAWDATA_LIST_SUCCESS'
const RAWDATA_LIST_MORE = 'RAWDATA_LIST_MORE'
const RAWDATA_LIST_MORE_SUCCESS = 'RAWDATA_LIST_MORE_SUCCESS'
const RAWDATA_LIST_FAILURE = 'RAWDATA_LIST_FAILURE'

const RAWDATA_INNER = 'RAWDATA_INNER'
const RAWDATA_INNER_SUCCESS = 'RAWDATA_INNER_SUCCESS'
const RAWDATA_INNER_FAILURE = 'RAWDATA_INNER_FAILURE'
const RAWDATA_INNER_MORE = 'RAWDATA_INNER_MORE'
const RAWDATA_INNER_MORE_SUCCESS = 'RAWDATA_INNER_MORE_SUCCESS'

export const rawdataCountAction = payload => ({
  type: RAWDATA_COUNT,
  payload
})

const rawdataCountSuccess = payload => ({
  type: RAWDATA_COUNT_SUCCESS,
  payload
})

export const rawdataListAction = payload => ({
  type: RAWDATA_LIST,
  payload
})

const rawdataListSuccess = payload => ({
  type: RAWDATA_LIST_SUCCESS,
  payload
})

export const rawdataListMoreAction = payload => ({
  type: RAWDATA_LIST_MORE,
  payload
})

const rawdataListMoreSuccess = payload => ({
  type: RAWDATA_LIST_MORE_SUCCESS,
  payload
})

export const rawdataInnerAction = payload => ({
  type: RAWDATA_INNER,
  payload
})

export const rawdataInnerMoreAction = payload => ({
  type: RAWDATA_INNER_MORE,
  payload
})

const rawdataInnerMoreSuccess = payload => ({
  type: RAWDATA_INNER_MORE_SUCCESS,
  payload
})

const rawdataInnerSuccess = payload => ({
  type: RAWDATA_INNER_SUCCESS,
  payload
})

export const rawdataCountEpic = action$ => action$
  .ofType(RAWDATA_COUNT)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/rawsearch/count?${action.payload}`, 'GET'))
    .map(response => rawdataCountSuccess(response))
    .catch(error => Observable.of({
      type: RAWDATA_COUNT_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const rawdataListEpic = action$ => action$
  .ofType(RAWDATA_LIST)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/rawsearch?${action.payload}`, 'GET'))
    .map(response => rawdataListSuccess(response))
    .catch(error => Observable.of({
      type: RAWDATA_LIST_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const rawdataListMoreEpic = action$ => action$
  .ofType(RAWDATA_LIST_MORE)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/rawsearch?${action.payload}`, 'GET'))
    .map(response => rawdataListMoreSuccess(response))
    .catch(error => Observable.of({
      type: RAWDATA_LIST_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const rawdataInnerEpic = action$ => action$
  .ofType(RAWDATA_INNER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/innerresult?${action.payload}`, 'GET'))
    .map(response => rawdataInnerSuccess(response))
    .catch(error => Observable.of({
      type: RAWDATA_INNER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const rawdataInnerMoreEpic = action$ => action$
  .ofType(RAWDATA_INNER_MORE)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/innerresult?${action.payload}`, 'GET'))
    .map(response => rawdataInnerMoreSuccess(response))
    .catch(error => Observable.of({
      type: RAWDATA_INNER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function rawdataCountReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case RAWDATA_COUNT: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case RAWDATA_COUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case RAWDATA_COUNT_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function rawdataListReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case RAWDATA_LIST: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false,
        total: 0
      }
    }
    case RAWDATA_LIST_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true,
        total: action.payload.response.total
      }
    }
    case RAWDATA_LIST_MORE_SUCCESS: {
      return {
        ...state,
        data: [...state.data, ...action.payload.response.data],
        loading: false,
        error: false,
        flag: true,
        total: action.payload.response.total
      }
    }
    case RAWDATA_LIST_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false,
        total: 0
      }
    }
    default:
      return state
  }
}

export function rawdataInnerReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case RAWDATA_INNER: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false,
        total: 0
      }
    }
    case RAWDATA_INNER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true,
        total: action.payload.response.total
      }
    }
    case RAWDATA_INNER_MORE_SUCCESS: {
      return {
        ...state,
        data: [...state.data, ...action.payload.response.data],
        loading: false,
        error: false,
        flag: true,
        total: action.payload.response.total
      }
    }
    case RAWDATA_INNER_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false,
        total: 0
      }
    }
    default:
      return state
  }
}
