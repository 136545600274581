import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ListIcons, ReadMore, AssetTag, AnnotationIcon } from '../'
import { generateStringArray, checkPermission } from '../../utils'
import ShowMore from '../ShowMore'

const Patent = (props) => {
  const renderAssignee = () => props.data['Standard Assignee'].join(', ')
  // const renderTags = () => props.data.Tags.map((item, i) => (
  //   <span key={i} className='tag' >{item.name}</span>
  // ))

  const style = {
    top: 3,
    right: 15,
    zIndex: 200,
    position: 'absolute'
  }

  const onClick = (e, structure) => {
    e.preventDefault();
    e.stopPropagation();
    props.openCard(structure);
  }

  const renderTags = () => {
    let tags = []
    if (props.data.Tags && props.data.Tags.length) {
      tags = generateStringArray(props.data.Tags, 'name')
    }
    return tags.length && (<ShowMore data={[{ value: tags }]} />)
  }
  return (
    <Fragment>
      {checkPermission('molecule_structure') && !props.showMore && props.data['Molecule Counts'] && (
        <a role='presentation' onClick={e => onClick(e, true)} style={style} className='card-text text-link' ><FormattedMessage id="Molecule Structures" defaultMessage="Molecule Structures" /> - {props.data['Molecule Counts']}</a>
      )}
      {props.flag &&
        <div className='card-actions'>
          <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Published' />
          {!checkPermission('ontosight_academics_user') && <AnnotationIcon data={props.data} />}
        </div>
      }
      <div className='card-details'>
        <div className='card-meta'>

          <AssetTag asset={props.data.type} />
          <div className='card-meta-title text-elips'>{props.data['Patent Office']}</div>
          <div className='date'>{props.data['Publication Date']}</div>
        </div>
        <h3 className='card-title'>
          {props.data['Patent ID'] && <span className='patent-id'>{`${props.data['Patent ID']} | `}</span>}
          <span data-test='patent-title'>{ReactHtmlParser(props.data.Title)}</span>
        </h3>
        {!props.showMore && props.data['Standard Assignee'] && (props.data['Standard Assignee'].length > 0) &&
          <div className='card-text'>
            <div className='card-subtext'>
              <div className='sub-title'><FormattedMessage id="ASSIGNEE" defaultMessage="ASSIGNEE" />:&nbsp;</div>
              <div>
                <ReadMore limit={3}>
                  {renderAssignee()}
                </ReadMore>
              </div>
            </div>
          </div>
        }
        {props.showMore
        ? (
          <Fragment>
            {props.data['Standard Assignee'] && props.data['Standard Assignee'].length > 0 ?
            (
              <div className='card-text' >
                <h3 className='sub-title'><FormattedMessage id="ASSIGNEE" defaultMessage="ASSIGNEE" /></h3>
                {renderAssignee()}
              </div>
            ) : null}
            {props.data['Patent ID'] && (
              <div className='card-text' >
                <h3 className='sub-title'><FormattedMessage id="PATENT ID" defaultMessage="PATENT ID" /></h3>
                <div>{props.data['Patent ID']}</div>
              </div>
            )}
            {props.data['Priority Date'] && (
              <div className='card-text' >
                <h3 className='sub-title'><FormattedMessage id="PRIORITY DATE" defaultMessage="PRIORITY DATE" /></h3>
                <div>{props.data['Priority Date']}</div>
              </div>
            )}
            {props.data.Tags && props.data.Tags.length > 0 && props.showMore && (
            <div className='card-body-content' >
              <div className='sub-title'><FormattedMessage id="Ontology Tags" defaultMessage="Ontology Tags" />:</div>
              <div className='tag-list-wrap'>{renderTags()}</div>
            </div>)}
          </Fragment>
        ) : null }
      </div>
      {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
    </Fragment>
  )
}

Patent.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  likeParams: PropTypes.object,
  showMore: PropTypes.bool,
  takeFeedback: PropTypes.bool,
  overlay: PropTypes.bool,
  openCard: PropTypes.func,
}

Patent.defaultProps = {
  flag: true,
  showMore: false,
  likeParams: {},
  takeFeedback: true,
  overlay: true,
  openCard: null
}

export default Patent
