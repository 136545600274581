import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { stringify, parse } from 'query-string'
import { logoutAction } from '../Login/logic'
import { getCookie, getRedirectUrl, ucFirst, setAnalytics, checkPermission, checkBrowserSupport, parseHashUrl } from '../../utils'
import { setDataLang, LangSet } from '../../lang/logic'
import { SimpleDropDown } from '../../components'
import { drawerAction } from '../Drawer/logic'
// import { notificationCountAction } from '../Notification/logic'
import { modalAction } from '../Modal/logic'
import CustomerSupport from '../CustomerSupport'
import { DiscoverLinks } from '../constants'
import { getApps, getParticularApp } from '../utils'
import CountDown from '../CountDown'
import Mixpanel from '../../mixpanel'

const open = {
  type: 'notification',
  open: true
}

const close = {
  type: '',
  open: false
}

const backUrlCheck = url => [
  `/${process.env.current}/notifications`, `/${process.env.current}/subscriptions`, '/guidelinewatch/details', '/guidelinewatch/comparison'
].indexOf(url)

const renderApps = (props, fromdash) => {
  if (getCookie('accessToken')) {
    let appName = 'Discover'
    if (process.env.dashboard) {
      appName = ''
      let { appLabel = '' } = process.env
      appLabel = parse(window.location.search).appLabel || parseHashUrl(props).appLabel || appLabel
      const apps = getParticularApp('Dashboards')
      if (appLabel) {
        apps.forEach((app) => {
          if (appLabel === app.name) {
            appName = app.name
          }
        });
      }
    }

    if (fromdash === 'true' && checkPermission('competitive_landscape_jnj')) {
      appName = 'Competitive Landscape';
    }

    const Apps = getApps()
    return Apps.map((item, i) => {
      const childApps = getParticularApp(item.name)
      return (
        <li key={i} className={`left-nav-group-item ${(item.name === 'Dashboards' && checkPermission('competitive_landscape_jnj') && fromdash === 'true') ? 'active' : item.name === 'Search & Explore' && !process.env.dashboard && !checkPermission('competitive_landscape_jnj') ? 'active' : item.name === 'Dashboards' && process.env.dashboard ? 'active' : ''} unified-apps-dropdown`}>
          <a {...(childApps && childApps.length && childApps.length === 1 ? { href: childApps[0][childApps[0].status] } : {})} className='nav-link-item' >
            {item.name}
          </a>
          {childApps && !!childApps.length && childApps.length > 1 && (
            <div className='unified-apps-dropdown-content' >
              {childApps.map((app, j) => (
                <a className={`unified-apps-dropdown-content-link ${app.name === appName ? 'active' : ''}`} key={`${i}-${j}-app-dropdown`} href={app[app.status]} >{app.name}</a>
              ))}
            </div>
          )}
        </li>
      )
    })
  }
  return null
}

class UnifiedHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: this.props.lang,
      langTitle: this.props.lang === 'de' ? 'Deutsch' : (this.props.lang === 'fr' ? 'French' : 'English')
    }
    this.isBrowserSupported = checkBrowserSupport()
  }
  // componentDidMount() {
  //   if (getCookie('accessToken') && ((checkPermission('marketplace_permission') && !checkPermission('ontosight_academics_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)))) {
  //     this.props.notificationCountAction()
  //   }
  // }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.lang !== nextProps.lang) {
      this.setState({
        langTitle: nextProps.lang === 'de' ? 'Deutsch' : (nextProps.lang === 'fr' ? 'French' : 'English')
      })
    }
  };

  onLangSelection = (lang) => {
    let langCode;
    let langTitle;
    switch (lang) {
      case 'Deutsch': {
        langCode = 'de';
        langTitle = 'Deutsch';
        break;
      }
      case 'French': {
        langCode = 'fr';
        langTitle = 'French';
        break;
      }
      default: {
        langCode = 'en';
        langTitle = 'English'
      }
    }
    if (langCode !== this.state.lang) {
      this.setState(() => ({
        lang: langCode,
        langTitle
      }));
      this.props.LangSet(langCode);
      if (langCode !== this.props.dataLang) {
        this.props.setDataLang(langCode);
      }
    }
  };

  onNotificationClick() {
    if (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)) {
      const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
      const url = getRedirectUrl(process.env.current, {}, {}, false)
      const params = stringify({
        ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
        backApp: process.env.backLabel
      })
      window.location = `${url}/notifications?${params}`
    } else {
      this.props.drawerAction(this.props.drawer.open ? close : open)
      // if (this.props.drawer.open) {
      //   this.props.notificationCountAction()
      // }
    }
  }

  onSubAppsClick(url, permission) {
    if (permission) {
      this.props.modalAction({
        type: 'premium',
        open: true,
        dialog: true,
        size: 'large'
      })
      Mixpanel.track('noPermissionEvent', {
        action: 'No Permission',
        url,
        permission: 'no permission',
        view: 'discover sub app click'
      })
      setAnalytics('noPermissionTrigger', 'noPermission', JSON.stringify({
        url,
        permission: 'no permission',
        view: 'discover sub app click'
      }))
    } else {
      window.location.href = url;
    }
  }

  onSolutionSelection = (value) => {
    window.location.href = `${process.env.mainAppUrl}/${value.toLowerCase()}`
  }

  openModal(invite = false) {
    if (invite) {
      Mixpanel.track('rollingOutInvitesClickedEvent', {
        action: 'rolling Out Invites Clicked'
      })
      setAnalytics('rollingOutInvitesClickedTrigger');
      this.props.modalAction({
        type: 'request_invite',
        open: true,
        dialog: true,
        size: 'small'
      })
    } else {
      this.props.modalAction({
        type: 'login',
        open: true,
        dialog: true,
        size: 'medium'
      })
    }
  }
  renderLocalApps() {
    if (window.location.pathname === '/abstract') {
      return null
    }
    return DiscoverLinks(this.props.location.pathname).map((item, i) => {
      if (item.permission) {
        return (
          <li key={i} className={`left-nav-group-item ${item.active ? 'active' : ''}`}>
            <a role='presentation' onClick={() => this.onSubAppsClick(item.link, item.academic)} className='nav-link-item' >
              {item.academic && <span className='card-premium padding-sm-right' />}
              <FormattedMessage id={item.id} defaultMessage={item.name} />
            </a>
          </li>
        )
      }
      return null
    })
  }
  renderLanguage() {
    const { intl } = this.props
    const languagesOption = ['english', 'Deutsch', 'French'];
    if ((getCookie('accessToken') && checkPermission('language_localization')) || !getCookie('accessToken')) {
      return (
        <li className='language-item right-nav-group-item capitalize-me'>
          <SimpleDropDown
            DropdownClass='has-arrow'
            options={languagesOption}
            title={intl.formatMessage({ id: this.state.langTitle || 'abc', defaultMessage: this.state.langTitle })}
            onSelect={index => this.onLangSelection(languagesOption[index])}
          />
        </li>
      )
    }
    return null
  }
  renderAcademic() {
    const { intl } = this.props
    if (!getCookie('accessToken')) {
      return (
        <li className='right-nav-group-item'>
          <a title={intl.formatMessage({ id: 'Academic access', defaultMessage: 'Academic access' })} style={{ color: '#ffffff' }} href='/academic'><FormattedMessage id="Academic Access" defaultMessage="Academic Access" /></a>
        </li>
      )
    }
    return null
  }
  renderNotification() {
    if (getCookie('accessToken') && process.env.dashboard !== 1 && ((checkPermission('marketplace_permission') && !checkPermission('ontosight_academics_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)))) {
      const count = process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)
        ? this.props.notification.count.unread_count
        : this.props.count.data
      const { intl } = this.props
      return (
        <li className='right-nav-group-item notification-drawer'>
          <a title={intl.formatMessage({ id: 'Notifications', defaultMessage: 'Notifications' })} role='presentation' className='notification-icon-wrap' onClick={() => this.onNotificationClick()} >
            <i className='notification-icon' />
            {count > 0 && <span className='badge-count'>{count}</span>}
          </a>
        </li>
      )
    }
    return null
  }
  renderProfile() {
    if (getCookie('accessToken')) {
      const { intl } = this.props;
      const userOptions = [
        {
          name: <FormattedMessage id="Header.FAQ's" defaultMessage="FAQ's" />,
          title: intl.formatMessage({ id: 'Header.Frequently asked questions', defaultMessage: 'Frequently asked questions' }),
          cb: () => {
            Mixpanel.track('pageFromWhereFaqsClicked', {
              action: 'Page From Where Faqs Clicked',
              pathname: this.props.location.pathname
            })
            setAnalytics('pageFromWhereFaqsClickedTrigger', 'pageFromWhereFaqsClicked', this.props.location.pathname);
            window.location = `${process.env.mainAppUrl}/faq`
          }
        },
        {
          name: <FormattedMessage id="Header.Profile" defaultMessage="Profile" />,
          title: intl.formatMessage({ id: 'Header.More about you', defaultMessage: 'More about you' }),
          cb: () => { window.location = `${process.env.mainAppUrl}/profile` },
          condition: !getCookie('trialUser')
        },
        {
          name: <FormattedMessage id="Header.AccountSettings" defaultMessage="Account Settings" />,
          title: intl.formatMessage({ id: 'Header.Check / Update your settings', defaultMessage: 'Check / Update your settings' }),
          cb: () => { window.location = `${process.env.mainAppUrl}/settings` },
          condition: !getCookie('trialUser')
        },
        {
          name: <FormattedMessage id="Header.Subscriptions" defaultMessage="Subscriptions" />,
          cb: () => {
            const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
            const url = getRedirectUrl(process.env.current, {}, {}, false)
            const params = stringify({
              ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
              backApp: process.env.backLabel
            })
            window.location = `${url}/subscriptions?${params}`
          },
          title: intl.formatMessage({ id: 'Header.Check / Update your subscriptions', defaultMessage: 'Check / Update your subscriptions' }),
          condition: !!process.env.standaloneNotifications && !!checkPermission(`${process.env.current}_notification_permission`) && !getCookie('trialUser'),
        },
        // { name: 'On Boarding', cb: () => { window.location = `${getRedirectUrl('app', {}, {}, false)}marketplace/#/home/onboarding/affiliations/` } },
        {
          name: <FormattedMessage id="Header.Logout" defaultMessage="Logout" />,
          title: intl.formatMessage({ id: 'Header.See you next time!', defaultMessage: 'See you next time!' }),
          cb: () => {
            Mixpanel.track('logoutEvent', {
              action: 'logout',
              userName: getCookie('userName')
            })
            setAnalytics('logoutTrigger', 'logout', getCookie('userName'));
            this.props.logoutAction(stringify({ app: this.props.app, trialUser: !!getCookie('trialUser') }))
          }
        }
      ]
      const filteredUserOptions = userOptions.filter(opt => !('condition' in opt) || opt.condition)
      const profileIcon = `<div title='${ucFirst(getCookie('name') || 'Guest')}' class='disp-flex vcenter' ><div class='user-img'>
      <img class='user-face' src='/src/assets/images/svg/users.svg' alt='Profile' />
      </div><div class='nav-item text-elips'>${ucFirst(getCookie('name') || 'Guest')}</div></div>`
      return (
        <li className='user-item' >
          <SimpleDropDown
            DropdownClass='has-arrow'
            options={filteredUserOptions}
            title={profileIcon}
            onSelect={(index) => { filteredUserOptions[index].cb() }}
          />
        </li>
      )
    }
    return null
  }
  renderLogin() {
    if (!getCookie('accessToken')) {
      return (
        <li role='presentation' onClick={() => this.openModal()} className='right-nav-group-item'>
          <FormattedMessage id="LOGIN" defaultMessage="LOGIN" />
        </li>
      )
    }
    return null
  }
  renderAccessNow() {
    const style = {
      margin: '0 10px',
      cursor: 'pointer',
      fontSize: 16,
      color: '#2d2b2b9c'
    }
    if (!getCookie('accessToken')) {
      return (
        <li style={style} role='presentation' onClick={() => this.openModal(true)} className='right-nav-group-item'>
          Access Now
        </li>
      )
    }
    return null
  }
  renderSolution() {
    const Options = [...(this.props.location.pathname === '/academic' ? ['Discover'] : []), 'Influence', 'Explore', 'Dashboards', 'Integrate', 'Daas'];
    return (
      <div className='solutions-item right-nav-group-item'>
        <SimpleDropDown
          // DropdownClass='has-arrow'
          options={Options}
          title='Solutions'
          onSelect={index => this.onSolutionSelection(Options[index])}
        />
      </div>
    )
  }
  renderHeaderLogout() {
    const query = parse(window.location.hash).alreadyLoggedIn
    const hash = parse(window.location.search).alreadyLoggedIn
    const style = {
      ...!getCookie('accessToken') && (query === 'true' || hash === 'true') ? { display: 'none' } : {}
    }
    return (
      <div style={style} className='unified-header-logged-out unified-header-top-container'>
        <ul className='left-nav-group'>
          <li className='nav-logo-wrap' >
            <a href={process.env.mainAppUrl} className='logo' >
              {/* {checkPermission('ontosight_academics_user') ? <i className='icon icon-logo-iplexus-academics' /> : <i className='icon icon-logo-iplexus' />} */}
              <i className='icon icon-logo-iplexus' />
            </a>
          </li>
          {/* {renderTrialApps(this.props)} */}
          {renderApps(this.props)}
        </ul>
        <div className='menu-landing'>
          <div className='dropdown'>
            {this.renderSolution()}
          </div>
          <div className='dropdown'>
            <div className='dropbtn'>
              <CustomerSupport />
            </div>
          </div>
          {/* <div className='try-ad-btn'>
            <a href='/academic' className='ad'>Try Discover Academics</a>
          </div> */}
        </div>
        <ul className='right-nav-group'>
          {this.renderAccessNow()}
        </ul>
      </div>
    )
  }
  renderHeaderLogin() {
    const fromdash = parse(window.location.search).fromDashboard
    const query = parse(window.location.hash).alreadyLoggedIn
    const hash = parse(window.location.search).alreadyLoggedIn
    const style = {
      ...!getCookie('accessToken') && (query === 'true' || hash === 'true') ? { display: 'none' } : {}
    }
    const permission = checkPermission('discover_permission') || checkPermission('myworkspace_permission') /* || checkPermission('marketplace_permission') */ || process.env.dashboard
    let appName = 'Discover'
    let appUrl = '/?alreadyLoggedin=true'
    if (checkPermission('ontosight_academics_user')) {
      appName = 'Discover Academics'
    } else if (process.env.dashboard) {
      appName = 'Dashboards'
      let appLabel = parse(window.location.search).appLabel || parseHashUrl(this.props).appLabel
      const discoverDashboardApps = ['competitive', 'competitivev1']
      if (process.env.current && discoverDashboardApps.indexOf(process.env.current) > -1) {
        appLabel = 'Competitive Landscape'
      }
      const apps = getParticularApp('Dashboards')
      if (appLabel) {
        apps.forEach((app) => {
          if (appLabel === app.name) {
            appName = app.name
            appUrl = app.active
          }
        });
      }
    } else if (fromdash === 'true' && checkPermission('competitive_landscape_jnj')) {
      const appLabel = 'Competitive Landscape';
      const apps = getParticularApp('Dashboards')
      apps.forEach((app) => {
        if (appLabel === app.name) {
          appName = app.name
          appUrl = app.active
        }
      });
    } else {
      appName = 'Discover'
    }
    const logoUrl = process.env.dashboard ? appUrl : `${process.env.standalone ? getRedirectUrl(process.env.current, { alreadyLoggedIn: true }, null, false) : getRedirectUrl('app', { alreadyLoggedIn: true }, null, false)}`
    const localAppsLength = DiscoverLinks().length
    return (
      <Fragment>
        <div style={style} className='unified-header-top-container'>
          <ul className='left-nav-group'>
            <li className='nav-logo-wrap' >
              <a href={logoUrl} className='logo' >
                {/* {checkPermission('ontosight_academics_user') ? <i className='icon icon-logo-iplexus-academics' /> : <i className='icon icon-logo-iplexus' />} */}
                <i className='icon icon-logo-iplexus' />
              </a>
            </li>
            {renderApps(this.props, fromdash)}
            {/* {renderTrialApps(this.props)} */}
          </ul>
          <ul className='right-nav-group'>
            <li className='right-nav-group-item'>
              <CustomerSupport />
            </li>
            {this.renderAcademic()}
            {this.renderProfile()}
            {this.renderLogin()}
          </ul>
        </div>
        {getCookie('accessToken') && permission && this.isBrowserSupported &&
          <div className='unified-header-bottom-container'>
            <ul className='left-nav-group'>
              <a href={appUrl} className='product-title'>
                {appName}
              </a>
              {!!localAppsLength && <span className='product-sep' />}
              {this.renderLocalApps()}
            </ul>
            <ul className='right-nav-group'>
              {getCookie('trialUser') && (
                <li className='right-nav-group-item' >
                  <CountDown />
                </li>
              )}
              {this.renderLanguage()}
              {checkPermission('competitive_landscape_jnj') ? null : this.renderNotification()}
            </ul>
          </div>
        }
      </Fragment>
    )
  }
  render() {
    if (getCookie('accessToken')) {
      const remove = document.getElementById('header-container')
      if (remove) {
        remove.style.display = 'block'
      }
    }
    return (
      <header id='header-container' className='unified-header'>
        {getCookie('accessToken') ? this.renderHeaderLogin() : this.renderHeaderLogout()}
      </header>
    )
  }
}

UnifiedHeader.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  drawerAction: PropTypes.func.isRequired,
  drawer: PropTypes.object.isRequired,
  // notificationCountAction: PropTypes.func.isRequired,
  count: PropTypes.object.isRequired,
  modalAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  notification: PropTypes.object,
  app: PropTypes.string,
  LangSet: PropTypes.func.isRequired,
  setDataLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  dataLang: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
}
UnifiedHeader.defaultProps = {
  notification: {},
  app: 'facelift'
}

const mapStateToProps = state => ({
  drawer: state.drawer,
  count: state.count,
  notification: state.notificationList,
  lang: state.language.lang,
  dataLang: state.language.dataLang
})

// export default withRouter(connect(mapStateToProps, {
//   logoutAction, drawerAction, notificationCountAction, modalAction, setDataLang, LangSet
// })(injectIntl(UnifiedHeader)))

export default withRouter(connect(mapStateToProps, {
  logoutAction, drawerAction, modalAction, setDataLang, LangSet
})(injectIntl(UnifiedHeader)))
