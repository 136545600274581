import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'
import { GROUP_FETCH_CANCEL, GROUP_UPDATE_FETCH_CANCEL, GROUP_FETCH, GROUP_UPDATE_FETCH } from '../Groups/logic'

export const FILTER_CONFIG = 'FILTER_CONFIG'
export const FILTER_CONFIG_CANCEL = 'FILTER_CONFIG_CANCEL'
export const FILTER_CONFIG_SUCCESS = 'FILTER_CONFIG_SUCCESS'
export const FILTER_CONFIG_FAILURE = 'FILTER_CONFIG_FAILURE'

export const FILTER = 'FILTER'
export const FILTER_SUCCESS = 'FILTER_SUCCESS'
export const FILTER_FAILURE = 'FILTER_FAILURE'

export const FILTER_SEARCH = 'FILTER_SEARCH'
export const FILTER_SEARCH_SUCCESS = 'FILTER_SEARCH_SUCCESS'
export const FILTER_SEARCH_FAILURE = 'FILTER_SEARCH_FAILURE'

export const FILTER_MANUAL_DATA = 'FILTER_MANUAL_DATA'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const INITIAL_STATE_FILTER = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const filterConfigAction = payload => ({
  type: FILTER_CONFIG,
  payload
})

export const filterConfigCancelAction = payload => ({
  type: FILTER_CONFIG_CANCEL,
  payload
})

export const filterConfigSuccess = payload => ({
  type: FILTER_CONFIG_SUCCESS,
  payload
})

export const filterAction = payload => ({
  type: FILTER,
  payload
})

export const filterSuccess = payload => ({
  type: FILTER_SUCCESS,
  payload
})

export const filterSearchAction = payload => ({
  type: FILTER_SEARCH,
  payload
})

export const filterSearchSuccess = payload => ({
  type: FILTER_SEARCH_SUCCESS,
  payload
})

export const filterManualData = payload => ({
  type: FILTER_MANUAL_DATA,
  payload
})

export const filterConfigEpic = action$ => action$
  .ofType(FILTER_CONFIG)
  .debounceTime(1000)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/filters?${action.payload}`, 'GET', true))
    .map(response => filterConfigSuccess(response))
    .takeUntil(action$.ofType(GROUP_FETCH, GROUP_UPDATE_FETCH, GROUP_FETCH_CANCEL, GROUP_UPDATE_FETCH_CANCEL, FILTER_CONFIG_CANCEL, FILTER_CONFIG_CANCEL))
    .catch(error => Observable.of({
      type: FILTER_CONFIG_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const filterEpic = action$ => action$
  .ofType(FILTER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/filters/?${action.payload.params}`, 'POST', true, action.payload.data))
    .map(response => filterSuccess(response))
    .takeUntil(action$.ofType(GROUP_FETCH, GROUP_UPDATE_FETCH, GROUP_FETCH_CANCEL, GROUP_UPDATE_FETCH_CANCEL, FILTER_CONFIG_CANCEL))
    .catch(error => Observable.of({
      type: FILTER_FAILURE,
      payload: error
    })))

export const filterSearchEpic = action$ => action$
  .ofType(FILTER_SEARCH)
  .debounceTime(500)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/filters/?${action.payload.params}`, 'POST', true, action.payload.data))
    .map(response => filterSearchSuccess(response))
    .catch(error => Observable.of({
      type: FILTER_SEARCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// filter config reducer
export function filterConfigReducer(state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case FILTER_CONFIG: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case FILTER_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FILTER_CONFIG_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// filter reducer
export function filterReducer(state = INITIAL_STATE_FILTER, action = null) {
  switch (action.type) {
    case FILTER_CONFIG: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case FILTER_MANUAL_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.type]: {
            data: action.payload.data,
            loading: false,
            error: false,
            flag: false
          }
        },
        loading: false,
        error: false,
        flag: false
      }
    }
    case FILTER: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.type]: {
            data: [],
            loading: true,
            error: false,
            flag: false
          }
        },
        loading: true,
        error: false,
        flag: false
      }
    }
    case FILTER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.response.data.name]: {
            data: action.payload.response.data.values,
            loading: false,
            error: false,
            flag: true
          }
        },
        loading: false,
        error: false,
        flag: true
      }
    }
    case FILTER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// filter reducer
export function filterSearchReducer(state = INITIAL_STATE_FILTER, action = null) {
  switch (action.type) {
    case FILTER_SEARCH: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.type]: []
        },
        loading: true,
        error: false,
        flag: false
      }
    }
    case FILTER_SEARCH_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.response.data.name]: action.payload.response.data.values
        },
        loading: false,
        error: false,
        flag: true
      }
    }
    case FILTER_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
