import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
export const ADVANCED_SEARCH_AUTO_COMPLETE = 'ADVANCED_SEARCH_AUTO_COMPLETE'
export const ADVANCED_SEARCH_AUTO_COMPLETE_SUCCESS = 'ADVANCED_SEARCH_AUTO_COMPLETE_SUCCESS'
export const ADVANCED_SEARCH_AUTO_COMPLETE_FAILURE = 'ADVANCED_SEARCH_AUTO_COMPLETE_FAILURE'
export const ADVANCED_SEARCH_AUTO_COMPLETE_CANCEL = 'ADVANCED_SEARCH_AUTO_COMPLETE_CANCEL'

const ADD_SEARCH_TERM = 'ADD_SEARCH_TERM'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const advancedSearchAutoCompleteCancelAction = () => ({
  type: ADVANCED_SEARCH_AUTO_COMPLETE_CANCEL
})

export const advancedSearchAutoCompleteAction = payload => ({
  type: ADVANCED_SEARCH_AUTO_COMPLETE,
  payload
})

export const advancedSearchAutoCompleteSuccess = payload => ({
  type: ADVANCED_SEARCH_AUTO_COMPLETE_SUCCESS,
  payload
})

export const addSearchTermAction = payload => ({
  type: ADD_SEARCH_TERM,
  payload
})

export const advancedSearchAutoCompleteEpic = action$ => action$
  .ofType(ADVANCED_SEARCH_AUTO_COMPLETE)
  .debounceTime(500)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/autocomplete?${action.payload}`, 'GET'))
    .map(response => advancedSearchAutoCompleteSuccess(response))
    .takeUntil(action$.ofType(ADVANCED_SEARCH_AUTO_COMPLETE_CANCEL))
    .catch(error => Observable.of({
      type: ADVANCED_SEARCH_AUTO_COMPLETE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function advancedSearchAutoCompleteReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case ADVANCED_SEARCH_AUTO_COMPLETE: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case ADVANCED_SEARCH_AUTO_COMPLETE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case ADVANCED_SEARCH_AUTO_COMPLETE_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

