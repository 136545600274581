import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import Bookmark from '../../container/Bookmark';
import { ListIcons, AssetTag, AnnotationIcon } from '../';
import { generateStringArray, checkPermission } from '../../utils';
import ShowMore from '../ShowMore';
import ClickableAuthors from '../../container/ClickableAuthors';

const Thesis = (props) => {
  // const renderTags = () => props.data.Tags.map((item, i) => (
  //   <span key={i} className='tag' >{item.name}</span>
  // ))
  const renderTags = () => {
    let tags = [];
    if (props.data.Tags && props.data.Tags.length) {
      tags = generateStringArray(props.data.Tags, 'name');
    }
    return tags.length && <ShowMore data={[{ value: tags }]} />;
  };

  return (
    <Fragment>
      {props.flag && (
        <div className="card-actions">
          <Bookmark
            flag={props.data.bookmark}
            type={props.type}
            id={props.data.doc_id}
            group="Published"
          />
          {!checkPermission('ontosight_academics_user') && <AnnotationIcon data={props.data} />}
        </div>
      )}
      <div className="card-details">
        <div className="card-meta">
          <AssetTag asset={props.data.type} />
          <div className="card-meta-title text-elips">
            {props.data.Publisher}
          </div>
          <div className="date">{props.data.Date}</div>
        </div>
        <h3 className="card-title">{ReactHtmlParser(props.data.Title)}</h3>
        {!props.showMore &&
          props.data.Authors &&
          props.data.Authors.length > 0 &&
          props.data.Authors.map(author => author.author_name).join('').length >
            0 && (
            <div className="card-text">
              <ClickableAuthors {...props} limit={3} />
            </div>
          )}
        {props.showMore ? (
          <Fragment>
            {props.data.Authors && props.data.Authors.length > 0 &&
              props.data.Authors.map(author => author.author_name).join('')
                .length > 0 && (
                <div className="card-body-content">
                  <div className="sub-title"><FormattedMessage id="Authors" defaultMessage="Authors" />:</div>
                  <ClickableAuthors {...props} />
                </div>
              )}
            {props.data.Tags && props.data.Tags.length > 0 && (
              <div className="card-body-content">
                <div className="sub-title"><FormattedMessage id="Ontology Tags" defaultMessage="Ontology Tags" />:</div>
                <div className="tag-list-wrap">{renderTags()}</div>
              </div>
            )}
          </Fragment>
        ) : null}
      </div>
      {props.overlay && (
        <ListIcons
          {...props}
          data={props.data}
          likeParams={props.likeParams}
          takeFeedback={props.takeFeedback}
        />
      )}
    </Fragment>
  );
};

Thesis.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  likeParams: PropTypes.object,
  showMore: PropTypes.bool,
  takeFeedback: PropTypes.bool,
  overlay: PropTypes.bool
};

Thesis.defaultProps = {
  flag: true,
  showMore: false,
  likeParams: {},
  takeFeedback: true,
  overlay: true
};

export default Thesis;
