import React from 'react'
import PropTypes from 'prop-types'

const AnnotationIcon = (props) => {
  if (props.data.annotation) {
    return (
      <div className='margin-medium-top' >
        <a title='Annotation' className='annotation-icon' />
      </div>
    )
  }
  return null
}

AnnotationIcon.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AnnotationIcon
