import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

// const color = [
//   '#629dd1',
//   '#2d3d66',
//   '#7f8fa9',
//   '#9c8fa0',
//   '#2a5f8e',
//   '#59a2ae',
//   '#297fd5',
//   '#4a65ac',
// ]

class Sunburst extends Component {
  constructor (props) {
    super(props)
    this.state = {
      nodes: null
    }
    this.radius = (Math.min(this.props.width, this.props.height) / 2) - 10
    this.x = d3.scaleLinear()
      .range([0, 2 * Math.PI])
    this.y = d3.scaleSqrt()
      .range([0, this.radius])
    this.color = d3.scaleOrdinal(d3.schemeCategory20)
    this.partition = d3.partition().size([2 * Math.PI, this.radius * this.radius]);
  }
  componentDidMount () {
    this.root = d3.hierarchy(this.props.data)
    this.root.sum(d => d.size).sort((a, b) => b.value - a.value);
    const nodes = this.partition(this.root).descendants()
    this.totalSize = 0
    nodes.forEach((d) => {
      this.totalSize += d.value
      if (d.depth === 1) {
        d.data.id = d.data.name.replace(/[/\s/!@#\\$%\\^\\&*\\)\\(+=._,:;-]/g, '')
      } else if (d.depth === 2) {
        d.data.id = `${d.parent.data.name.replace(/[/\s/!@#\\$%\\^\\&*\\)\\(+=._,:;-]/g, '')}_${d.data.name.replace(/[/\s/!@#\\$%\\^\\&*\\)\\(+=._,:;-]/g, '')}`
      } else if (d.depth === 3) {
        d.data.id = `${d.parent.parent.data.name.replace(/[/\s/!@#\\$%\\^\\&*\\)\\(+=._,:;-]/g, '')}_${d.parent.data.name.replace(/[/\s/!@#\\$%\\^\\&*\\)\\(+=._,:;-]/g, '')}_${d.data.name.replace(/[/\s/!@#\\$%\\^\\&*\\)\\(+=._,:;-]/g, '')}`
      }
    })
    this.setState({
      nodes
    })
  }
  calcParent = (node) => {
    let node1 = node
    while (node1.depth > 0 && node1.parent !== null) {
      node1 = node1.parent
    }
    return node1
  }
  calcColorParentName = (node) => {
    let node2 = node
    while (node2.depth > 1 && node2.parent !== null) {
      node2 = node2.parent
    }
    return node2
  }
  mouseover = (d) => {
    d3.selectAll(`.sunburstpath${this.props.id}`)
      .style('opacity', 0.3);
    const sequenceArray = d.ancestors().reverse();
    sequenceArray.shift();
    this.state.nodes.filter(el => sequenceArray.indexOf(el) > -1).forEach((e) => {
      d3.select(`#sunburst_${this.props.id}_${e.data.id}`)
        .style('opacity', 1)
    })
    const percentage = (100 * (d.value / this.totalSize)).toPrecision(3);
    let percentageString = `${percentage}%`;
    if (percentage < 0.1) {
      percentageString = '< 0.1%'
    }
    d3.select(`#text_sunburst_${this.props.id}`)
      .append('tspan')
      .text(d.data.name)
    d3.select(`#text_sunburst_${this.props.id}`)
      .append('tspan')
      .attr('y', '20')
      .attr('x', '2')
      .text(percentageString)
  }
  mouseleave = () => {
    d3.selectAll(`.sunburstpath${this.props.id}`)
      .style('opacity', 1)
    d3.select(`#text_sunburst_${this.props.id}`)
      .text('')
  }
  renderGraph () {
    const arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .innerRadius(d => Math.sqrt(d.y0))
      .outerRadius(d => Math.sqrt(d.y1))
    // const repdata = []
    if (this.state.nodes !== undefined && this.state.nodes !== null) {
      return this.state.nodes.map((d, i) => {
        const colorparentName = this.calcColorParentName(d)
        if (i === 0) {
          return null
        }
        return (
          <path
            d={arc(d)}
            key={i}
            fill={this.color(colorparentName.data.name)}
            fillRule='evenodd'
            className={`sunburstpath${this.props.id}`}
            opacity='1'
            id={`sunburst_${this.props.id}_${d.data.id}`}
            onMouseOver={() => this.mouseover(d)}
            onFocus={console.log()}
            onMouseLeave={() => this.mouseleave(d)}
          />
        )
      })
    }
    return null
  }
  renderBackgroundCircle () {
    return (
      <circle
        r={this.radius}
        opacity={0}
      />
    )
  }
  renderText () {
    return (
      <text
        textAnchor='middle'
        id={`text_sunburst_${this.props.id}`}
      />
    )
  }
  render () {
    return (
      <g transform={`translate(${this.props.width / 2}, ${this.props.height / 2})`}>
        { this.renderBackgroundCircle() }
        { this.renderGraph() }
        { this.renderText() }
      </g>
    )
  }
}

Sunburst.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })
}

Sunburst.defaultProps = {
  data: {}
}

export default Sunburst
