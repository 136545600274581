import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { SizeMe } from 'react-sizeme'
import { Loader } from '../../../common/components';

class Molecule extends Component {
  constructor (props) {
    super(props)
    this.state = {
      status: ''
    }
  }
  componentDidMount () {
    this.loadFile()
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.pdbId !== this.props.pdbId && nextProps.pdbId) {
      this.loadFile(nextProps)
    }
  }
  loadFile = (props = this.props) => {
    if (props.pdbId && props.id) {
      setTimeout(() => {
        const doc = document.getElementById(props.id)
        if (doc) {
          const canvas = doc.querySelector('canvas')
          if (canvas) {
            canvas.remove()
          }
        }
        const stage = new NGL.Stage(props.id, { backgroundColor: 'white' })
        this.setState({
          status: 'loading'
        }, () => {
          stage.loadFile(`rcsb://${props.pdbId}`).then((component) => {
            this.setState({
              status: ''
            }, () => {
              component.addRepresentation('cartoon', {
                sele: 'polymer',
                name: 'polymer',
              })
              component.addRepresentation('ball+stick', {
                name: 'ligand',
                visible: true,
                sele: 'not ( polymer or water or ion )',
              })
              component.autoView();
            })
          }).catch(() => {
            this.setState({
              status: 'error'
            })
          });
        })
      }, 500);
    }
  }
  render() {
    return (
      <div style={{ width: '100%', height: '100%' }} className='relative' >
        {this.props.title && (
          <div className='modal-header'>
            <div className='modal-title'>PDB ID: {this.props.title}</div>
          </div>
        )}
        <SizeMe monitorHeight>
          {({ size }) => {
            const style = {
              width: size.width,
              height: size.height || 400,
              ...this.props.style
            }
            const loaderStyle = {
              ...(this.state.status === '' ? { display: 'none' } : {}),
              position: 'absolute',
              zIndex: 30
            }
            return (
              <div className='relative' >
                <Loader style={loaderStyle} height={size.height || 400} loading={this.state.status === 'loading'} error={false} noData={this.state.status === 'error'} />
                <div id={this.props.id} style={style} />
              </div>
            )
          }}
        </SizeMe>
      </div>
    );
  }
}

Molecule.propTypes = {
  pdbId: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
}

Molecule.defaultProps = {
  pdbId: '',
  id: 'viewport',
  style: {},
  title: ''
}

export default Molecule;
