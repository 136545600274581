import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

const GRAPH_DATA = 'GRAPH_DATA'
const GRAPH_DATA_SUCCESS = 'GRAPH_DATA_SUCCESS'
const GRAPH_DATA_FAILURE = 'GRAPH_DATA_FAILURE'

const GRAPH_FILTER_REDUCER_REGISTER = 'GRAPH_FILTER_REDUCER_REGISTER'

const INITIAL_STATE = {
}
const INITIAL_SETUP = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

//  action
export const graphFilterRegisterAction = (id, payload) => ({
  type: GRAPH_FILTER_REDUCER_REGISTER,
  payload,
  id
})


export const getGraphDataAction = (id, payload) => ({
  type: GRAPH_DATA,
  payload,
  id
})

export const getGraphDataSuccess = (payload, id) => ({
  type: GRAPH_DATA_SUCCESS,
  payload,
  id
})

// epic
export const graphDataEpic = action$ => action$
  .ofType(GRAPH_DATA)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/summaryDashboard/getGraphData?${action.payload.params}`, 'POST', true, action.payload.query))
    .map(response => getGraphDataSuccess(response, action.id))
    .catch(error => Observable.of({
      type: GRAPH_DATA_FAILURE,
      payload: error,
      id: action.id
    }, {
      type: ERROR,
      payload: error
    })))

export function graphDataFiltersReducer (state = INITIAL_STATE, action = null) {
  // console.log(action.type, action.payload)
  switch (action.type) {
    case GRAPH_FILTER_REDUCER_REGISTER: {
      return {
        ...state,
        [action.id]: { ...INITIAL_SETUP }
      }
    }
    case GRAPH_DATA: {
      return {
        ...state,
        [action.id]: {
          data: [],
          loading: true,
          error: false,
          flag: false
        }
      }
    }
    case GRAPH_DATA_SUCCESS: {
      return {
        ...state,
        [action.id]: {
          data: action.payload.response.data,
          loading: false,
          error: false,
          flag: true
        }
      }
    }
    case GRAPH_DATA_FAILURE: {
      return {
        ...state,
        [action.id]: {
          data: [],
          loading: false,
          error: true,
          flag: false,
        }
      }
    }
    default:
      return state
  }
}
