
import React, { Component } from 'react'
import Axios from 'axios'

const Localization = () => BaseComponent =>
  class Permissions extends Component {
    constructor (props) {
      super(props)
      this.state = {
        messages: null
      }
    }
    componentDidMount () {
      this.loadLocalization()
    }
    loadLocalization = () => {
      Axios.get(`https://s3.amazonaws.com/ontosight-dependency-data/localization_${process.env.type === 'production' ? 'prod' : 'dev'}.json`).then((res) => {
        this.setState({
          messages: res.data,
        })
      })
    }
    render () {
      return (
        <BaseComponent messages={this.state.messages} {...this.props} />
      )
    }
  }
export default Localization
