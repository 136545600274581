import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage } from 'react-intl';

const Thesis = (props) => {
  const renderCommittee = () => props.data.Committee.map((item, i) => (
    <span key={i} >{item.normalize_name}{item.author_type ? `(${item.author_type})` : ''}{i !== props.data.Committee.length - 1 ? ', ' : '.'}</span>
  ))
  const abstract = props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
  return (
    <Fragment>
      {props.data.Abstract && (
      <div className='card-body-content'>
        <div className='sub-title'><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
        <div id='annotation-highlight-1' >{ReactHtmlParser(abstract)}</div>
      </div>)}
      <div className='card-body-content'>
        {props.data.Publisher && (<div><b><FormattedMessage id="School/Department" defaultMessage="School/Department" /></b>: {props.data.Publisher}</div>)}
        {props.data['Thesis Type'].length > 0 && (<div><b><FormattedMessage id="Source Type" defaultMessage="Source Type" /></b>: { props.data['Thesis Type'].join(',') }</div>)}
        {props.data.Date && (<div><b><FormattedMessage id="Date of Publication" defaultMessage="Date of Publication" /></b>: { props.data.Date }</div>)}
        {props.data.Committee.length > 0 && (<div><b><FormattedMessage id="Committee" defaultMessage="Committee" /></b>: {renderCommittee()}</div>)}
      </div>
    </Fragment>
  )
}

Thesis.propTypes = {
  data: PropTypes.object.isRequired
}

export default Thesis
