import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

const Others = props => (
  <Fragment>
    <div className='card-details'>
      <div className='card-meta'>
        <div className='date'>{props.data.Committee}</div>
      </div>
      <h3 className='card-title'>
        {ReactHtmlParser(props.data.Title)}
      </h3>
    </div>
  </Fragment>
)

Others.propTypes = {
  data: PropTypes.object.isRequired,
}

Others.defaultProps = {
}

export default Others
