import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

export const TABS_NAVIGATION = 'TABS_NAVIGATION'
export const TABS_NAVIGATION_SUCCESS = 'TABS_NAVIGATION_SUCCESS'
export const TABS_NAVIGATION_FAILURE = 'TABS_NAVIGATION_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const tabsAction = payload => ({
  type: TABS_NAVIGATION,
  payload
})

export const tabsNavigationSuccess = payload => ({
  type: TABS_NAVIGATION_SUCCESS,
  payload
})

export const tabsNavigationEpic = action$ => action$
  .ofType(TABS_NAVIGATION)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/navigation/tablist?${action.payload}`, 'GET', true))
    .map(response => tabsNavigationSuccess(response))
    .catch(error => Observable.of({
      type: TABS_NAVIGATION_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function tabsNavigationReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case TABS_NAVIGATION: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case TABS_NAVIGATION_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case TABS_NAVIGATION_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
