import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
// import { Molecule } from 'openchemlib'
import { FormattedMessage } from 'react-intl';
import { AssetTag } from '../'

// const Kekule = require('kekule');

const style = {
  padding: '10px 0px 10px 20px',
  borderBottom: 'none'
}

class Molecules extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  // componentDidMount() {
  //   const { data } = this.props
  //   const molfile = Molecule.fromSmiles(data.smile).toMolfile()
  //   const mol = Kekule.IO.loadFormatData(molfile, 'mol')
  //   const chemViewer = new Kekule.ChemWidget.Viewer(document.getElementById(data.molecule_id));
  //   chemViewer.setChemObj(mol)
  // }
  render() {
    const { data } = this.props
    return (
      <Fragment>
        <div className='card-details'>
          <div className='table'>
            <div className='table-body'>
              <div role='presentation' className='table-row table-tab-list' style={style}>
                <div className='table-cell'>
                  <div data-test='mol-title' className='molecule-title bold'><FormattedMessage id="Molecule Name" defaultMessage="Molecule Name" /></div>
                  <div className='card-subtext'>
                    <AssetTag asset={data.type} />
                    {data.molecule_names && Array.isArray(data.molecule_names) ? <span className='value'>{`${data.molecule_names.join()}`}</span> : '-'}
                  </div>
                </div>
                <div className='table-cell' style={{ wordBreak: 'break-all' }}>
                  <div className='molecule-title'><FormattedMessage id="Molecule Smile" defaultMessage="Molecule Smile" /></div>
                  <div className='card-subtext'>
                    {data.smile ? <div data-test='smile-value' className='value'>{data.smile}</div> : '-'}
                  </div>
                </div>
                <div className='table-cell'>
                  <div className='molecule-title'><FormattedMessage id="Molecular Weight" defaultMessage="Molecular Weight" /></div>
                  <div className='card-subtext'>
                    {data.molecular_weight ? <div data-test='weight-value' className='value'>{data.molecular_weight}</div> : '-'}
                  </div>
                </div>
              </div>
              <div role='presentation' className='table-row table-tab-list' style={style}>
                <div className='table-cell'>
                  <div className='molecule-title'><FormattedMessage id="Molecular Formula" defaultMessage="Molecular Formula" /></div>
                  <div className='card-subtext'>
                    {data.formula ? <span data-test='formula-value'>{data.formula}</span> : '-'}
                  </div>
                </div>
                <div className='table-cell' style={{ wordBreak: 'break-all' }}>
                  <div className='molecule-title'><FormattedMessage id="Sortable Formula" defaultMessage="Sortable Formula" /></div>
                  <div className='card-subtext'>
                    {data.sortable_formula ? <div data-test='sortable-value' className='value'>{data.sortable_formula}</div> : '-'}
                  </div>
                </div>
                <div className='table-cell'>
                  <div className='molecule-title'><FormattedMessage id="Atom Count" defaultMessage="Atom Count" /></div>
                  <div className='card-subtext'>
                    {data.atom_count ? <div data-test='atom-value' className='value'>{data.atom_count}</div> : '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}


Molecules.propTypes = {
  data: PropTypes.object.isRequired,
  // type: PropTypes.string.isRequired,
}

Molecules.defaultProps = {
}
export default Molecules
