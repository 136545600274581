import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

const INITIAL_STATE_OBJECT = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

// CREATE BOOKMARK FOLDERS
const CREATE_FOLDER = 'CREATE_FOLDER'
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS'
const CREATE_FOLDER_FAILURE = 'CREATE_FOLDER_FAILURE'

// DELETE BOOKMARK FOLDERS
const DELETE_FOLDER = 'DELETE_FOLDER'
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS'
const DELETE_FOLDER_FAILURE = 'DELETE_FOLDER_FAILURE'

// BOOKMARK FOLDERS FETCH START
const BOOKMARK_FOLDERS = 'BOOKMARK_FOLDERS'
export const BOOKMARK_FOLDERS_SUCCESS = 'BOOKMARK_FOLDERS_SUCCESS'
const BOOKMARK_FOLDERS_FAILURE = 'BOOKMARK_FOLDERS_FAILURE'

// CREATE BOOKMARK
const CREATE_BOOKMARK = 'CREATE_BOOKMARK'
export const CREATE_BOOKMARK_SUCCESS = 'CREATE_BOOKMARK_SUCCESS'
const CREATE_BOOKMARK_FAILURE = 'CREATE_BOOKMARK_FAILURE'

// EDIT BOOKMARK
const EDIT_BOOKMARK = 'EDIT_BOOKMARK'
export const EDIT_BOOKMARK_SUCCESS = 'EDIT_BOOKMARK_SUCCESS'
const EDIT_BOOKMARK_FAILURE = 'EDIT_BOOKMARK_FAILURE'

// EDIT BOOKMARK
const DELETE_BOOKMARK = 'DELETE_BOOKMARK'
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS'
const DELETE_BOOKMARK_FAILURE = 'DELETE_BOOKMARK_FAILURE'

export const createFolderAction = payload => ({
  type: CREATE_FOLDER,
  payload
})

export const createFolderSuccess = payload => ({
  type: CREATE_FOLDER_SUCCESS,
  payload
})

export const deleteFolderAction = payload => ({
  type: DELETE_FOLDER,
  payload
})

export const deleteFolderSuccess = payload => ({
  type: DELETE_FOLDER_SUCCESS,
  payload
})

export const bookmarkFolderFetchAction = payload => ({
  type: BOOKMARK_FOLDERS,
  payload
})

export const bookmarkFolderFetchSuccess = payload => ({
  type: BOOKMARK_FOLDERS_SUCCESS,
  payload
})

export const createBookmarkAction = payload => ({
  type: CREATE_BOOKMARK,
  payload
})

export const createBookmarkSuccess = payload => ({
  type: CREATE_BOOKMARK_SUCCESS,
  payload
})

export const editBookmarkAction = payload => ({
  type: EDIT_BOOKMARK,
  payload
})

export const editBookmarkSuccess = payload => ({
  type: EDIT_BOOKMARK_SUCCESS,
  payload
})

export const deleteBookmarkAction = payload => ({
  type: DELETE_BOOKMARK,
  payload
})

export const deleteBookmarkSuccess = payload => ({
  type: DELETE_BOOKMARK_SUCCESS,
  payload
})

export const createFolderEpic = action$ => action$
  .ofType(CREATE_FOLDER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bookmark/createbookmarkfolder`, 'POST', true, action.payload))
    .map(response => createFolderSuccess(response))
    .catch(error => Observable.of({
      type: CREATE_FOLDER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const deleteFolderEpic = action$ => action$
  .ofType(DELETE_FOLDER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bookmark/delete?${action.payload}`, 'DELETE'))
    .map(response => deleteFolderSuccess(response))
    .catch(error => Observable.of({
      type: DELETE_FOLDER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const bookmarkFolderFetchEpic = action$ => action$
  .ofType(BOOKMARK_FOLDERS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bookmark/getFolder/?${action.payload ? action.payload : ''}`, 'GET'))
    .map(response => bookmarkFolderFetchSuccess(response))
    .catch(error => Observable.of({
      type: BOOKMARK_FOLDERS_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const createBookmarkEpic = action$ => action$
  .ofType(CREATE_BOOKMARK)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bookmark/createBookmark`, 'POST', true, action.payload))
    .map(response => createBookmarkSuccess(response))
    .catch(error => Observable.of({
      type: CREATE_BOOKMARK_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const editBookmarkEpic = action$ => action$
  .ofType(EDIT_BOOKMARK)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bookmark/modifyBookmark`, 'POST', true, action.payload))
    .map(response => editBookmarkSuccess(response))
    .catch(error => Observable.of({
      type: EDIT_BOOKMARK_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export const deleteBookmarkEpic = action$ => action$
  .ofType(DELETE_BOOKMARK)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/bookmark/modifyBookmark`, 'POST', true, action.payload))
    .map(response => deleteBookmarkSuccess(response))
    .catch(error => Observable.of({
      type: DELETE_BOOKMARK_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function createFolderReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case CREATE_FOLDER: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case CREATE_FOLDER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case CREATE_FOLDER_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function deleteFolderReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case DELETE_FOLDER: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_FOLDER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_FOLDER_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}


export function bookmarkFolderFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case BOOKMARK_FOLDERS: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case BOOKMARK_FOLDERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case BOOKMARK_FOLDERS_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function createBookmarkReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case CREATE_BOOKMARK: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case CREATE_BOOKMARK_SUCCESS: {
      return {
        ...state,
        data: {
          id: action.payload.response.data.doc_id,
          status: true
        },
        loading: false,
        error: false,
        flag: true
      }
    }
    case CREATE_BOOKMARK_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function editBookmarkReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case EDIT_BOOKMARK: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case EDIT_BOOKMARK_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case EDIT_BOOKMARK_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

export function deleteBookmarkReducer (state = INITIAL_STATE_OBJECT, action = null) {
  switch (action.type) {
    case DELETE_BOOKMARK: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case DELETE_BOOKMARK_SUCCESS: {
      return {
        ...state,
        data: {
          id: action.payload.response.data.doc_id,
          status: false
        },
        loading: false,
        error: false,
        flag: true
      }
    }
    case DELETE_BOOKMARK_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
