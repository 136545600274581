import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import ToolTip from 'react-portal-tooltip'
import { ClickOutside } from 'reusable-react-components'
import { withRouter } from 'react-router-dom'
import { modalAction } from '../../../common/container/Modal/logic'
import { style, getRedirectUrl, setAnalytics, checkPermission } from '../../../common/utils'
import TrendsGraph from '../../container/TrendsGraph'
import { CloseOnEscape } from '../../../common/components'
import Mixpanel from '../../../common/mixpanel';

class SponsorListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTooltipActive: false
    }
    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
    this.addToSearch = this.addToSearch.bind(this)
    this.excludeFromSearch = this.excludeFromSearch.bind(this)
    this.updateSearchQuery = this.updateSearchQuery.bind(this)
  }
  componentDidMount() {
    if (this.props.scrollId) {
      document.getElementById(this.props.scrollId).addEventListener('scroll', this.hideTooltip, false)
    }
  }
  componentWillUnmount() {
    if (this.props.scrollId) {
      document.getElementById(this.props.scrollId).removeEventListener('scroll', this.hideTooltip)
    }
  }
  showTooltip() {
    this.setState({ isTooltipActive: true })
  }

  hideTooltip() {
    this.setState({ isTooltipActive: false })
  }

  addToSearch() {
    const { data } = this.props
    const q = {
      values: data.name,
      type: data.type,
      class: data.class,
      id: data.id,
      operator: 'AND'
    }
    this.updateSearchQuery(q)
  }

  excludeFromSearch() {
    const { data } = this.props
    const q = {
      values: data.name,
      type: data.type,
      class: data.class,
      id: data.id,
      operator: 'NOT'
    }
    this.updateSearchQuery(q)
  }

  updateSearchQuery(newTerm) {
    this.props.onClickSearch(newTerm)
  }

  onViewDetailsClick = (viewNoPermission) => {
    const { data } = this.props
    const params = {
      doc: data.id,
      q: `@${data.name.replace(/ /g, '~')}::${data.id}`,
      type: data.type,
      class: data.class,
      id: data.id
    }
    if (viewNoPermission || checkPermission('ontosight_academics_user')) {
      this.props.modalAction({
        type: 'premium',
        open: true,
        dialog: true,
        size: 'large'
      })
      Mixpanel.track('noPermissionEvent', { action: 'View details sponsor list', url: getRedirectUrl('author', params), permission: 'no permission' })
      setAnalytics('noPermissionTrigger', 'noPermission', JSON.stringify({ url: getRedirectUrl('author', params), permission: 'no permission', view: 'view details' }))
    } else {
      Mixpanel.track('viewDetailsEvent', { action: 'View details sponsor list', url: getRedirectUrl('author', params) })
      setAnalytics('viewDetailsTrigger', 'viewDetails', getRedirectUrl('author', params));
      window.open(getRedirectUrl('author', params, {}, false));
    }
  }

  render() {
    const { data } = this.props
    const q = [{
      value: data.name,
      type: data.type,
      class: data.class,
      id: data.id,
      operator: ''
    }]
    const viewNoPermission = !checkPermission('author_view')
    return (
      <div className='list-item' ref={(element) => { this.element = element }} onClick={this.showTooltip} role='presentation' >
        {this.props.name}
        <CloseOnEscape onEscape={this.hideTooltip}>
          <ToolTip style={style} active={this.state.isTooltipActive} position='top' arrow='center' parent={this.state.isTooltipActive && this.element}>
            <ClickOutside onClickOutside={() => this.hideTooltip()} >
              <div className='tooltip-container'>
                <div>{this.props.name}</div>
                <TrendsGraph value={q} />
                <div className='search-filter-container'>
                  <p role='presentation' onClick={this.addToSearch} className='filter-element'>Add to search | </p>
                  <p role='presentation' onClick={this.excludeFromSearch} className='filter-element'>Exclude from search</p>
                </div>
                <div className='search-filter-container' >
                  {this.props.type === 'Authors' && <p role='presentation' onClick={() => this.onViewDetailsClick(viewNoPermission)} className='filter-element'>{(viewNoPermission || checkPermission('ontosight_academics_user')) && <span className='card-premium padding-sm-right' />}<FormattedMessage id="View Details" defaultMessage="View Details" /></p>}
                </div>
              </div>
            </ClickOutside>
          </ToolTip>
        </CloseOnEscape>
      </div>
    )
  }
}

SponsorListItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClickSearch: PropTypes.func,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  scrollId: PropTypes.string,
  modalAction: PropTypes.func.isRequired
}

SponsorListItem.defaultProps = {
  onClickSearch: null,
  scrollId: ''
}

export default withRouter(connect(null, {
  modalAction
})(SponsorListItem))
