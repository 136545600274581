import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown } from '../../../common/components'

class YearRange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startYear: props.startYear,
      endYear: props.endYear
    }
    this.renderStartYear = this.renderStartYear.bind(this)
    this.renderEndYear = this.renderEndYear.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.startYear !== this.state.startYear || nextProps.endYear !== this.state.endYear) {
      this.setState({
        startYear: nextProps.startYear,
        endYear: nextProps.endYear
      })
    }
  }
  onChange(value, type) {
    if (type === 'start-year') {
      this.setState({
        startYear: value || null,
        endYear: value ? this.state.endYear >= value ? this.state.endYear : value : null
      }, () => this.props.onChange([this.state.startYear, this.state.endYear]))
    } else {
      this.setState({
        endYear: value
      }, () => this.props.onChange([this.state.startYear, this.state.endYear]))
    }
  }
  renderStartYear() {
    const { intl } = this.props
    const yearList = Array.from(new Array((this.props.defaultEndYear - this.props.defaultStartYear) + 1), (val, index) => Number.parseInt(index + this.props.defaultStartYear, 10))
    return (
      <div style={{ padding: 5 }} >
        <div>
          <FormattedMessage id="From" defaultMessage='From' />
        </div>
        <div className='start-year'>
          <Dropdown clear={this.props.clear} minLength={1} title={intl.formatMessage({ id: 'Start Year', defaultMessage: 'Start Year' })} active={this.state.startYear ? [this.state.startYear] : []} options={yearList} onChange={value => this.onChange(value[0] ? parseInt(value[0], 10) : null, 'start-year')} />
        </div>
      </div>
    )
  }
  renderEndYear() {
    const { intl } = this.props
    const yearList = Array.from(new Array((this.props.defaultEndYear - (this.state.startYear || this.props.defaultStartYear)) + 1), (val, index) => Number.parseInt(index + (this.state.startYear || this.props.defaultStartYear), 10))
    return (
      <div style={{ padding: 5 }} >
        <div>
          <FormattedMessage id="To" defaultMessage='To' />
        </div>
        <div className='end-year'>
          <Dropdown disabled={this.state.startYear === null} minLength={1} title={intl.formatMessage({ id: 'End Year', defaultMessage: 'End Year' })} active={this.state.endYear ? [this.state.endYear] : []} options={yearList} onChange={value => this.onChange(value[0] ? parseInt(value[0], 10) : null, 'end-year')} />
        </div>
      </div>
    )
  }
  render() {
    return (
      <Fragment>
        {this.renderStartYear()}
        {this.renderEndYear()}
      </Fragment>
    )
  }
}

YearRange.propTypes = {
  startYear: PropTypes.number,
  endYear: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  defaultStartYear: PropTypes.number,
  defaultEndYear: PropTypes.number,
  intl: PropTypes.object.isRequired,
  clear: PropTypes.bool,
}

YearRange.defaultProps = {
  startYear: null,
  endYear: new Date().getFullYear(),
  defaultStartYear: 1971,
  defaultEndYear: new Date().getFullYear(),
  clear: false
}

export default injectIntl(YearRange)
