import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import Tooltip from 'react-portal-tooltip'
import { Legends } from '../../../components'
import { style } from '../../../../common/utils'

class Bar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTooltipActive: false,
      index: 0,
      selectBar: this.props.selectBar
    }
    this.marginLeft = this.props.marginLeft
    this.marginRight = 20
    this.marginTop = 25
    this.marginBottom = 130
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
    this.id = (new Date()).getTime()
  }
  componentWillMount() {
    this.x = d3.scaleBand()
    this.y = d3.scaleLinear()
    const xDom = this.props.data.map(d => (d.display_name ? d.display_name : d.category))
    const yDom = this.props.data.map(d => d.value)
    this.x.domain(xDom)
    this.y.domain([0, d3.max(yDom)])
    if (this.props.orient === 'vertical') {
      this.x.range([0, this.width])
      this.y.range([this.height, 0])
    } else {
      this.x.range([this.height, 0]).padding(0.2)
      this.y.range([0, this.width])
    }
    if (this.y.ticks().length > 1) {
      const offset = this.y.ticks()[2] - this.y.ticks()[0]
      this.y.domain([this.y.domain()[0], this.y.domain()[1] + offset])
    }
    this.setState({
      x: this.x,
      y: this.y
    })
  }
  componentDidMount() {
    this.renderAxis()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.width !== this.props.width || nextProps.height !== this.props.height) {
      this.width = nextProps.width - this.marginLeft - this.marginRight
      this.height = nextProps.height - this.marginTop - this.marginBottom
      if (this.props.orient === 'vertical') {
        this.x.range([0, this.width])
        this.y.range([this.height, 0])
      } else {
        this.x.range([this.height, 0]).padding(0.2)
        this.y.range([0, this.width])
      }
      this.setState({
        x: this.x,
        y: this.y
      }, () => {
        this.renderAxis()
      })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.selectBar !== prevProps.selectBar) {
      this.setState({
        selectBar: this.props.selectBar
      })
    }
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      const xDom = this.props.data.map(d => (d.display_name ? d.display_name : d.category))
      const yDom = this.props.data.map(d => d.value)
      this.x.domain(xDom)
      this.y.domain([0, d3.max(yDom)])
      if (this.y.ticks().length > 1) {
        const offset = this.y.ticks()[2] - this.y.ticks()[0]
        this.y.domain([this.y.domain()[0], this.y.domain()[1] + offset])
      }
      this.setState({
        x: this.x,
        y: this.y
      }, () => {
        this.renderAxis()
      })
    }
  }
  wrap(textList, width) {
    textList.each((d, i) => {
      const text = d3.select(`#text_x_${i}_${this.props.id}`)
      const originalText = text.text()
      const words = text
        .text()
        .split(/\s+/)
        .reverse()
      let word
      let line = []
      let lineNumber = 0
      const lineHeight = 1.1 // em
      const y = text.attr('y')
      const dy = parseFloat(text.attr('dy'))
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', -10)
        .attr('y', y)
        .attr('dy', `${dy}em`)
      if (words.length > 2) {
        tspan.attr('x', -10).text(`${originalText.slice(0, 10)}..`)
      } else {
        word = words.pop()
        while (word) {
          line.push(word)
          tspan.text(line.join(' '))
          if (tspan.node().getComputedTextLength() > width * 0.7) {
            line.pop()
            tspan.text(line.join(' '))
            line = [word]
            tspan = text
              .append('tspan')
              .attr('x', -10)
              .attr('y', y)
              .attr('dy', `${((lineNumber * lineHeight) + dy)}em`)
              .text(word)
          }
          lineNumber += 1
          word = words.pop()
        }
      }
    })
  }
  customTicks() {
    const xnode = `#xaxis_${this.props.id}`
    const ynode = `#yaxis_${this.props.id}`
    if (this.props.xaxisWrap === 'ellipsis') {
      d3.select(xnode)
        .selectAll('.tick text')
        .style('text-anchor', 'end')
        .style('text-transform', 'capitalize')
        .text((d) => {
          const reg = /[A-Za-z0-9]+/
          if (reg.test(d)) {
            return (d.length > 12 ? `${d.substring(0, 12)}...` : d)
          }
          return (d.length > 4 ? `${d.substring(0, 4)}...` : d)
        })
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-35)');
    } else {
      d3.select(xnode)
        .selectAll('.tick text')
        .style('text-anchor', 'end')
        .attr('id', (d, i) => `text_x_${i}_${this.props.id}`)
        .call((d) => {
          this.wrap(d, this.state.x.bandwidth())
        })
    }

    d3.select(xnode)
      .selectAll('.tick text')
      .append('title')
      .text(d => d)

    d3.select(ynode)
      .selectAll('.tick text')
      .append('title')
      .text(d => d)
  }
  renderAxis() {
    const xnode = `#xaxis_${this.props.id}`
    const xaxis = this.props.orient === 'vertical' ? d3.axisBottom(this.state.x) : d3.axisLeft(this.state.x)
    d3.select(xnode).call(xaxis).selectAll('text')
      .style('font-size', '12px')
    const ynode = `#yaxis_${this.props.id}`
    const yRange = this.state.y.domain()[1] - this.state.y.domain()[0]
    const yTicks = Math.min(yRange, 10)
    const yaxis = this.props.orient === 'vertical' ? d3.axisLeft(this.state.y).ticks(yTicks) : d3.axisBottom(this.state.y).ticks(yTicks)
    d3.select(ynode).call(yaxis).selectAll('text')
      .style('font-size', '12px')
    this.customTicks()
  }
  renderGraph() {
    const width = Math.min(30, this.state.x.bandwidth())
    return this.props.data.map((d, i) => (
      <g key={`${this.id}${i}`}>
        <rect
          className='handicon'
          key={`bar${this.id}${i}`}
          id={`bar-${i}-${this.id}`}
          x={this.state.x(d.display_name ? d.display_name : d.category) + ((this.state.x.bandwidth() - width) / 2)}
          y={this.state.y(d.value)}
          width={d.value === 0 ? 0 : width}
          height={this.height - this.state.y(d.value)}
          fill={this.props.changeOnClick && this.state.selectBar === (d.display_name ? d.display_name : d.category) ? this.props.selectColor : (
            this.props.multipleColor ? this.props.color[i] : this.props.color[0]
          )}
          onClick={() => {
            const obj = {}
            obj[this.props.labelX.replace(/ /g, '')] = this.props.data.map(d1 => d1.category)
            obj.x_axis_filter = d.category
            obj.display_name = d.display_name ? d.display_name : d.category
            obj.x_axis = this.props.labelX.replace(/ /g, '')
            if (this.props.labelX === 'Asset Classes') {
              obj.type = 'bar'
            } else {
              obj.type = 'barGraph'
            }
            obj.graph_type = 'bar_graph'
            obj.id = d.key || d.category
            this.props.redirect(obj)
          }}
          onMouseMove={() => {
            this.setState({
              isTooltipActive: true,
              index: i
            })
          }
          }
          onMouseLeave={() => this.setState({
            isTooltipActive: false
          })}
        />
      </g>
    ))
  }
  renderLabelX() {
    return (
      <text
        transform={`translate(${this.marginLeft + (this.width / 2) + this.props.offsetX}, ${this.height + this.marginTop + (this.marginBottom / 1.5) + this.props.offsetY})`}
        textAnchor='middle'
        className='graph-font-family capitalize-me'
      >
        {this.props.labelX}
      </text>
    )
  }
  renderLabelY() {
    return (
      <text
        transform={`translate(${this.marginLeft / 4}, ${(this.height / 1.9) + this.marginTop})rotate(-90)`}
        textAnchor='middle'
        className={`graph-font-family ${this.props.yaxisClass ? 'capitalize-me' : ''}`}
      >
        {this.props.labelY}
      </text>
    )
  }
  renderDiv() {
    if (!this.props.data.length) {
      return null
    }
    return (
      <li
        role='presentation'
        className={`tooltip-container-tool tooltipList ${this.props.tooltipBlack ? 'dashboard-tooltip' : ''}`}
        onClick={() => {
          const obj = {}
          if (this.props.labelX === 'Asset Classes') {
            obj.type = 'bar'
          } else {
            obj.type = 'barGraph'
          }
          obj[this.props.labelX.replace(/ /g, '')] = this.props.data.map(d1 => d1.category)
          obj.display_name = this.props.data[this.state.index].display_name ? this.props.data[this.state.index].display_name : this.props.data[this.state.index].category
          obj.x_axis_filter = this.props.data[this.state.index].category
          obj.x_axis = this.props.labelX.replace(/ /g, '')
          obj.graph_type = 'bar_graph'
          obj.id = this.props.data[this.state.index].key || this.props.data[this.state.index].category
          this.props.redirect(obj)
        }}
      >
        <div className='col1'>
          <div className='tooltipbullet' style={{ opacity: 1, backgroundColor: this.props.multipleColor ? this.props.color[this.state.index] : this.props.color[0] }} />
        </div>
        <div className='col2'>
          {this.props.data[this.state.index].display_name ? this.props.data[this.state.index].display_name : this.props.data[this.state.index].category}
        </div>
        <div className='col3'>
          {this.props.data[this.state.index].value}
        </div>
      </li>
    )
  }

  renderHorizontalGraph() {
    const widthBar = Math.min(10, this.state.x.bandwidth())
    return this.props.data.map((d, i) => (
      <g key={`${this.id}${i}`}>
        <rect
          className='handicon'
          key={`bar${this.id}${i}`}
          id={`bar-${i}-${this.id}`}
          x={0}
          y={this.state.x(d.category) + ((this.state.x.bandwidth() - widthBar) / 2)}
          width={this.state.y(d.value)}
          height={widthBar}
          fill={this.props.multipleColor ? this.props.color[i] : this.props.color[0]}
          rx={0}
          ry={0}
          onClick={() => {
            const obj = {}
            obj[this.props.labelX.replace(/ /g, '')] = this.props.data.map(d1 => d1.category)
            obj.x_axis_filter = d.category
            obj.display_name = d.display_name ? d.display_name : d.category
            obj.x_axis = this.props.labelX.replace(/ /g, '')
            if (this.props.labelX === 'Asset Classes') {
              obj.type = 'bar'
            } else {
              obj.type = 'barGraph'
            }
            obj.graph_type = 'bar_graph'
            this.props.redirect(obj)
          }}
          onMouseMove={() => {
            this.setState({
              isTooltipActive: true,
              index: i
            })
          }
          }
          onMouseLeave={() => this.setState({
            isTooltipActive: false
          })}
        />
      </g>
    ))
  }

  renderBackgroundBar() {
    const widthBar = Math.min(10, this.state.x.bandwidth())
    return this.props.data.map(d => (
      <rect
        x={0}
        y={this.state.x(d.category) + ((this.state.x.bandwidth() - widthBar) / 2)}
        height={widthBar}
        width={this.width}
        rx={7}
        ry={7}
        fill='#ccc'
      />
    ))
  }

  render() {
    const styles = this.props.tooltipBlack ?
      style : {
        style: {
          'z-index': 21
        },
        arrowStyle: {
        }
      }
    return [
      <g key={1} className='custom'>

        <g
          transform={`translate(${this.marginLeft}, ${this.marginTop})`}
        >
          <g
            className='x axis'
            id={`xaxis_${this.props.id}`}
            transform={this.props.orient === 'vertical' ? `translate(0, ${this.height})` : ''}
          />
          <g
            className='y axis'
            id={`yaxis_${this.props.id}`}
            transform={this.props.orient === 'vertical' ? '' : `translate(0, ${this.height})`}
          />
          {this.props.backgroundBar && this.renderBackgroundBar()}
          {this.props.orient === 'vertical' ? this.renderGraph() : this.renderHorizontalGraph()}
        </g>
        {this.props.labelX ? this.renderLabelX() : null}
        {this.props.labelY ? this.renderLabelY() : null}
      </g>,
      <div key={2} >
        {this.props.showLegends ?
          <Legends legends={this.props.color} borderRadius={this.props.border} /> : null}
      </div>,
      <Tooltip key={3} className='tooltip' style={styles} id={`barGraphTooltip${this.id}`} active={this.state.isTooltipActive} position='top' parent={`#bar-${this.state.index}-${this.id}`}>
        <ul>
          {this.renderDiv()}
        </ul>
      </Tooltip>
    ]
  }
}

Bar.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  id: PropTypes.any.isRequired,
  color: PropTypes.array,
  border: PropTypes.number,
  labelX: PropTypes.string.isRequired,
  labelY: PropTypes.string.isRequired,
  showLegends: PropTypes.bool,
  marginLeft: PropTypes.number,
  redirect: PropTypes.func,
  tooltipBlack: PropTypes.bool,
  orient: PropTypes.string,
  backgroundBar: PropTypes.bool,
  changeOnClick: PropTypes.bool,
  selectColor: PropTypes.string,
  selectBar: PropTypes.string,
  xaxisWrap: PropTypes.string.isRequired,
  multipleColor: PropTypes.bool.isRequired,
  yaxisClass: PropTypes.bool,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number
}


Bar.defaultProps = {
  border: 0,
  color: ['#1973BD'],
  showLegends: false,
  marginLeft: 100,
  redirect: null,
  tooltipBlack: false,
  orient: 'vertical',
  backgroundBar: false,
  changeOnClick: false,
  selectColor: 'orange',
  selectBar: '',
  yaxisClass: false,
  offsetX: 0,
  offsetY: 0
}

export default Bar
